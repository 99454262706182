import React from "react";
import { connect } from "react-redux";
import upkeepIconDarkBlue from "./images/upkeep_icon_darkblue.svg";
import cyclicMoneyPoolDarkBlue from "./images/cyclic_money_pool_darkblue.svg";
import fundraiserIconPoolDarkBlue from "./images/fundraiser_icon_darkblue.svg";

const ServicesBlock = ({ isAuthenticated, history }) => {
  const handleNavigateToService = (service) => {
    if (isAuthenticated) {
      history.push(`/dashboard/${service}`);
    } else {
      history.push(`/${service}`);
    }
  };

  return (
    <div className="d-flex flex-wrap justify-content-center">
      <div className="max-width-300 margin-8 margin-md-24 padding-8 cursor-pointer border-radius-14 box-shadow bg-white"
        onClick={() => {
          handleNavigateToService("upkeep");
        }}
      >
        <div className="margin-top-0 margin-top-md-10 text-center">
          <img className="max-width-100" src={upkeepIconDarkBlue} />
        </div>
        <h3 className="font-size-20 margin-top-14 margin-bottom-14 text-center orange">
          Recurring Upkeep Stipend
        </h3>
        <p className="font-size-15 margin-bottom-0 margin-bottom-md-10 text-center">
          Set up a recurring upkeep contribution among family members or
          friends for dependent loved ones
        </p>
      </div>

      <div className="max-width-300 margin-8 margin-md-24 padding-8 cursor-pointer border-radius-14 box-shadow bg-white"
        onClick={() => {
          handleNavigateToService("money-pool");
        }}
      >
        <div className="margin-top-0 margin-top-md-10 text-center">
          <img className="max-width-100" src={cyclicMoneyPoolDarkBlue} />
        </div>
        <h3 className="font-size-20 margin-top-14 margin-bottom-14 text-center orange">
          Cyclic Money Pool
        </h3>
        <p className="font-size-15 margin-bottom-0 margin-bottom-md-10 text-center">
          They call it "Ajo" or "Osusu". Set up a rotational
          "contribute-and-carry" fund among the people you trust
        </p>
      </div>

      <div className="max-width-300 margin-8 margin-md-24 padding-8 cursor-pointer border-radius-14 box-shadow bg-white"
        onClick={() => {
          handleNavigateToService("fundraiser");
        }}
      >
        <div className="margin-top-0 margin-top-md-10 text-center">
          <img className="max-width-100" src={fundraiserIconPoolDarkBlue} />
        </div>
        <h3 className="font-size-20 margin-top-14 margin-bottom-14 text-center orange">
          Fundraising
        </h3>
        <p className="font-size-15 margin-bottom-0 margin-bottom-md-10 text-center">
          A very easy, fast and reliable way to privately or publicly raise
          funds for people or causes that are dear to you.
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: !!state.auth.userToken,
});

export default connect(mapStateToProps)(ServicesBlock);
