import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useSpring, animated } from 'react-spring';
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import SocialLogin from "./SocialLogin";
import {
  signIn,
  socialMediaSignIn,
} from "../../store/actions/auth";
import emailIcon from "../images/email_icon_darkblue.svg";
import lockIcon from "../images/lock_icon_darkblue.svg";
import loginImageBg from "../images/login_image_bg.svg";
import MultiFactorAuth from "./MultiFactorAuth"
import { NotificationManager } from "react-notifications";

const Login = (props) => {
  const [loginError, setLoginError] = useState("");
  const [isErrorUnverifiedEmail, setIsErrorUnverifiedEmail] = useState(false);
  const [isMfaVisible, setIsMfaVisible] = useState(false);
  const [enabledMfas, setEnabledMfas] = useState([]);
  const [socialAccessToken, setSocialAccessToken] = useState("");
  const [appleAuthCredentials, setAppleAuthCredentials] = useState({});
  const [socialLoginError, setSocialLoginError] = useState("");
  const [isUsingSocialAuthentication, setIsUsingSocialAuthentication] = useState(false);
  const [socialMediaType, setSocialMediaType] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const fadeStyles = useSpring({
    from: { opacity: 0 },
    config: { duration: 2000 },
    loop: {
      opacity: 1,
    },
  })

  const slideStyles = useSpring({
    from: { x: 100, opacity: 0 },
    config: { duration: 1000 },
    loop: {
      x: 0, opacity: 1,
    },
  })

  const handleLogin = async (values) => {
    const res = await props.dispatch(signIn({
      ...values,
      email: values.email.toLowerCase(),
    }))

    if (res.error) {
      if (res.error.loginError) {
        setLoginError(res.error.loginError);

        if (res.error.isErrorUnverifiedEmail) {
          setIsErrorUnverifiedEmail(true);
        }
      }
      else if (res.error.mfaRequired) {
        setEnabledMfas(res.error.mfaRequired.data.enabled_mfas)

        setIsMfaVisible(true)
      } else if (res.error.invalidOtp) {
        NotificationManager.error(res.error.invalidOtp.message, "Error!")
      }
    } else {
      setIsMfaVisible(false)
    }
  }

  const handleSocialLogin = (values) => {
    setSocialLoginError("")

    setSocialAccessToken(values.access_token)

    if (values.socialMediaType == "apple") {
      setAppleAuthCredentials(values)
    }

    props.dispatch(socialMediaSignIn(values))
      .then((res) => {
        console.log(res.error)
        if (res.error) {
          if (res.error.loginError) {
            setSocialLoginError(res.error.loginError);
          }
          else if (res.error.mfaRequired) {
            setEnabledMfas(res.error.mfaRequired.data.enabled_mfas)

            setIsMfaVisible(true)
          } else if (res.error.invalidOtp) {
            NotificationManager.error(res.error.invalidOtp.message, "Error!")
          }
        } else {
          setIsMfaVisible(false)
        }
      })
      .catch(err => {
        setSocialLoginError(err.error)

      })
  }

  useEffect(() => {
    document.title = "Login"
  }, [])

  return (

    <div className="content-container">
      <div className="row">
        <animated.div className="col-6 stretch-full-sm" style={fadeStyles}>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={Yup.object({
              email: Yup.string()
                .required("Email is required")
                .email("Must be an email"),
              password: Yup.string().required("Password is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setIsUsingSocialAuthentication(false)

              handleLogin(values)
                .then(() => {
                  setSubmitting(false);
                })
            }}
          >
            {({
              isSubmitting,
              values
            }) => (
              <Form className="d-flex flex-column align-items-center">
                <div>
                  <h1 className="font-size-21 font-size-md-32 margin-bottom-10 margin-bottom-sm-20">
                    Sign in to PouchFunding
                  </h1>
                </div>
                <div className="max-width-350">
                  <SocialLogin
                    setSocialMediaType={setSocialMediaType}
                    socialLoginError={socialLoginError}
                    setSocialLoginError={setSocialLoginError}
                    socialAccessToken={socialAccessToken}
                    setSocialAccessToken={setSocialAccessToken}
                    setEnabledMfas={setEnabledMfas}
                    setIsMfaVisible={setIsMfaVisible}
                    handleSocialLogin={handleSocialLogin}
                    setIsUsingSocialAuthentication={setIsUsingSocialAuthentication}
                  />
                </div>
                <p className="font-size-16 padding-top-10 padding-top-md-20 padding-bottom-10 padding-bottom-md-20 grey">
                  or use your email account
                </p>
                <div className="max-width-550 margin-bottom-12">
                  <div className="d-flex margin-bottom-4 align-items-center border-radius-10 border-1 border-color-logo-magenta bg-pale-navy">
                    <div className="fixed-width-80 d-flex align-items-center justify-content-center">
                      <img width="32" src={emailIcon} />
                    </div>
                    <Field
                      className="input padding-top-14 padding-top-md-22 padding-bottom-14 padding-bottom-md-22 padding-left-0 padding-right-10 font-size-15 font-size-lg-18 border-0 focus-none bg-transparent"
                      placeholder="Email"
                      type="email"
                      name="email"
                    />
                  </div>
                  <ErrorMessage
                    className="red font-size-12"
                    name="email"
                    component="div"
                  />
                </div>
                <div className="max-width-550 margin-bottom-8">
                  <div className="d-flex margin-bottom-4 align-items-center border-radius-10 border-1 border-color-logo-magenta bg-pale-navy">
                    <div className="fixed-width-80 d-flex align-items-center justify-content-center">
                      <img width="32" src={lockIcon} />
                    </div>
                    <Field
                      className="input padding-top-14 padding-top-md-22 padding-bottom-14 padding-bottom-md-22 padding-left-0 padding-right-10 font-size-15 font-size-lg-18 border-0 focus-none bg-transparent"
                      type={`${isPasswordVisible ? "text" : "password"}`}
                      name="password"
                      placeholder="Password"
                    />
                    <i
                      className={`fa fa-eye${isPasswordVisible ? "" : "-slash"} font-size-15 font-size-lg-17 padding-right-10`}
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    ></i>
                  </div>
                  <ErrorMessage
                    className="red font-size-12"
                    name="password"
                    component="div"
                  />
                </div>
                <div className="font-size-16">
                  <div className="red padding-top-12">{loginError}</div>
                  {isErrorUnverifiedEmail ? (
                    <div className="padding-top-12">
                      <span>Click</span>&nbsp;
                      <Link
                        to="/request-confirmation-email"
                        className="text-decoration-none normal-blue"
                      >
                        here
                      </Link>&nbsp;
                      <span>to request a new confirmation email</span>
                    </div>
                  ) : null}
                </div>
                <div className="font-size-16 padding-top-10 padding-bottom-10 grey">
                  <Link
                    to="/forgot-password"
                    className="text-decoration-none"
                  >
                    Forgot your password?
                  </Link>
                </div>
                <button
                  className="btn max-width-250 font-size-15 font-size-md-17 margin-top-10 margin-top-md-20 margin-bottom-10 margin-bottom-md-20  padding-16 padding-md-20 border-radius-14 bg-logo-magenta white"
                  type="submit"
                  disabled={isSubmitting}
                >
                  SIGN IN
                </button>
                <div>
                  <span className="font-size-16 padding-top-10 padding-bottom-10 grey">
                    Don't have an account?
                  </span>
                  <b className="margin-left-10 font-size-17">
                    <Link to="/register" className="text-decoration-none">
                      Sign Up
                    </Link>
                  </b>
                </div>

                <Modal
                  ariaHideApp={false}
                  isOpen={isMfaVisible}
                  onRequestClose={() => setIsMfaVisible(false)}
                  className="modal max-width-650 padding-0"
                  overlayClassName="modal-backdrop"
                >
                  <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
                    <h1 className="font-size-20 font-size-md-22 logo-magenta">
                      Multi-Factor Authentication
                    </h1>
                    <i
                      onClick={() => setIsMfaVisible(false)}
                      className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
                    ></i>
                  </div>

                  <div className="horizontal-line" />

                  <div
                    style={{ maxHeight: "80vh" }}
                    className="overflow-scroll-y padding-10 padding-top-sm-20 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
                  >
                    <MultiFactorAuth
                      is_authenticator_app_enabled={enabledMfas.includes("authenticator_app")}
                      is_phone_two_factor_enabled={enabledMfas.includes("phone_otp")}
                      handleClose={() => setIsMfaVisible(false)}
                      isUsingSocialAuthentication={isUsingSocialAuthentication}
                      handleSocialLogin={handleSocialLogin}
                      handleLogin={handleLogin}
                      values={values}
                      socialMediaType={socialMediaType}
                      socialAccessToken={socialAccessToken}
                      appleAuthCredentials={appleAuthCredentials}
                    />
                  </div>
                </Modal>
              </Form>
            )}
          </Formik>
        </animated.div>
        <div className="col-6 d-block-hidden-sm padding-right-0 padding-right-lg-60">
          <animated.img className="stretch-full" style={slideStyles} src={loginImageBg} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Login);
