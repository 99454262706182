import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { NotificationManager } from "react-notifications";
import {
  generateTransactionRef
} from "../../../../../store/actions/transaction";
import {
  donateFromBank,
  donateFromWallet,
  registerFundraiserPaymentAttempt,
} from "../../../../../store/actions/fundraiser";
import { getConfirmAlert, getCurrencySymbol, isEnoughMoneyInWallet, numberWithCommas, refreshPage } from "../../../../../utilities";
import MoneyInputField from "../../../../utilityComponents/MoneyInputField"

const EnterDonation = ({
  isAuthenticated,
  dispatch,
  user,
  handleClose,
  transaction_ref,
  fundraiser,
  handleOnPaymentSuccess,
  history
}) => {
  const formikRef = useRef(null)
  const [resetConfigKey, setResetConfigKey] = useState(0);
  const [paymentConfig, setPaymentConfig] = useState({
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    payment_options: "all",
    customizations: {
      // title: 'my Payment Title',
      // description: 'Payment for items in cart',
      logo: process.env.REACT_APP_POUCH_FUNDING_FAVICON_URL,
    },
    // customer: {
    //   email: formikRef.current?.values.email,
    //   name: `${formikRef.current?.first_name} ${formikRef.current?.last_name}`,
    // },
  })
  const search = useLocation().search;

  const first_name = new URLSearchParams(search).get("first_name")
  const last_name = new URLSearchParams(search).get("last_name")
  const amount = new URLSearchParams(search).get("amount")
  const email = new URLSearchParams(search).get("email")
  const is_anonymous = new URLSearchParams(search).get("is_anonymous")
  const is_following_updates = new URLSearchParams(search).get("is_following_updates")
  const comment = new URLSearchParams(search).get("comment")

  const handleFlutterPayment = useFlutterwave(paymentConfig, [resetConfigKey]);

  const options = [
    {
      label: "Pay with bank",
      value: "bank",
    },
    {
      label: "Pay with wallet",
      value: "wallet",
    },
  ];

  const handleUpdatePaymentConfig = (values) => {
    setPaymentConfig({
      ...paymentConfig,
      tx_ref: transaction_ref,
      // tx_ref: new Date(),
      amount: values.amount,
      currency: values.currency,
      customer: {
        email: values.email,
        name: `${values.first_name} ${values.last_name}`,
      },
      meta: {
        service_id: fundraiser.fundraiser_details.id,
        service_type: "fundraiser",
        sub_service_type: "donation",
        user_id: user.id ? user.id : "",
        donationDetails: JSON.stringify(values)
      }
    });

    // Reset the config key to reload UseFlutterwave
    setResetConfigKey(() => {
      const k = resetConfigKey;
      return k + 1;
    });
  };

  const handleDonationPayment = (values) => {
    if (values.pay_from == "bank") {
      handleRegisterPaymentAttempt(values)
      handleFlutterPayment({
        callback: (resp) => {
          closePaymentModal();
          dispatch(
            donateFromBank({
              fundraiser_id: fundraiser.fundraiser_details.id,
              transaction_id: resp.transaction_id,
              is_anonymous: values.is_anonymous,
              comment: values.comment,
              email: values.email,
              first_name: values.first_name,
              last_name: values.last_name,
              is_following_updates: values.is_following_updates
            })
          ).then((res) => {
            // closePaymentModal();

            if (res.error) {

            } else {
              if (res.success == true) {
                NotificationManager.success(res.message, "Success!")
                handleOnPaymentSuccess()
                handleClose();

                // Refresh the page after 4000 milliseconds to avoid the flutterwave bug of recurring response
                refreshPage(4000)
              }
            }
          });

          dispatch(generateTransactionRef());
        },
        onClose: () => {
          dispatch(generateTransactionRef());
        },
      });
    } else {
      // if (isEnoughMoneyInWallet(values.amount, values.currency)) {
      //   getConfirmAlert({
      //     title: "Confirm Donation!",
      //     message: `Your ${values.currency} wallet will be debited the sum of ${getCurrencySymbol(values.currency)}${numberWithCommas(values.amount)}.`,
      //     onClickYes: () => {
      //       dispatch(
      //         donateFromWallet({
      //           fundraiser_id: fundraiser.fundraiser_details.id,
      //           donation: values.amount,
      //           currency: values.currency,
      //           is_anonymous: values.is_anonymous,
      //           comment: values.comment,
      //           is_following_updates: values.is_following_updates
      //         })
      //       ).then((res) => {
      //         if (res.error) {
      //           NotificationManager.success(res.error.message, "Error!")
      //         } else {
      //           if (res.success == true) {
      //             NotificationManager.success(res.message, "Success!")
      //             handleOnPaymentSuccess()
      //             handleClose();
      //           }
      //         }
      //       });
      //     },
      //     onClickNo: () => null,
      //   });
      // } else {
      //   NotificationManager.error(
      //     `You do not have enough money in you ${values.currency} wallet. Please top up first.`,
      //     "Notification")
      // }
    }
  };

  const handleRegisterPaymentAttempt = (values) => {
    dispatch(registerFundraiserPaymentAttempt({
      fundraiser_id: fundraiser.fundraiser_details.id,
      email: values.email,
      query_string: String(new URLSearchParams({ ...values, isMakingDonation: true }))
    }))
  }

  useEffect(() => {
    // Generate a transaction ref
    dispatch(generateTransactionRef());

    handleUpdatePaymentConfig(formikRef.current?.values)
  }, []);

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={{
          amount: amount ? amount : "",
          currency: fundraiser.fundraiser_details.goal_amount_currency,
          pay_from: "bank",
          first_name: user.first_name ? user.first_name : first_name,
          last_name: user.last_name ? user.last_name : last_name,
          email: user.email ? user.email : email,
          is_anonymous: is_anonymous ? JSON.parse(is_anonymous) : false,
          is_following_updates: is_following_updates ? JSON.parse(is_following_updates) : false,
          comment: comment ? comment : "",
        }}
        validationSchema={Yup.object({
          amount: Yup.number()
            .typeError("Amount must be a number")
            .positive("Amount must be a positive number")
            .integer("Amount must be a whole number")
            .required("Amount is required")
            .nullable(),
          first_name: Yup.string().required("First name is required"),
          last_name: Yup.string().required("Last name is required"),
          email: Yup.string()
            .email("Field must be email")
            .required("Email is required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleDonationPayment(values);
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
        }) => (
          <Form>
            <div>
              <div className="row">
                <div className="col-sm-6 padding-right-0 padding-right-sm-10 padding-top-8 padding-bottom-8">
                  <label className="form-label">Amount</label>
                  <div>
                    <div className="form-control d-flex padding-0 fixed-height-40">
                      <div className="font-size-16 border-top-left-radius-8 border-bottom-left-radius-8 padding-top-4 padding-left-12 padding-right-12 bg-lighter-blue">
                        <b>{getCurrencySymbol(values.currency)}</b>
                      </div>
                      <MoneyInputField
                        value={values.amount}
                        onChange={(amount) => {
                          // setTest(values.amount)
                          setFieldValue("amount", amount)
                          handleUpdatePaymentConfig({
                            ...values,
                            amount
                          });

                        }}
                        placeholder="Enter donation amount"
                      />
                    </div>
                    <ErrorMessage
                      className="error-message"
                      name="amount"
                      component="div"
                    />
                  </div>
                </div>
                {/* {isAuthenticated ? (
                  <div className="col-sm-6 padding-left-0 padding-left-sm-10 padding-top-8 padding-bottom-8">
                    <label className="form-label">Payment Source</label>
                    <Field
                      component={() => (
                        <Select
                          className="form-control padding-0"
                          classNamePrefix="react-select"
                          placeholder="Select Bank"
                          options={options}
                          value={
                            options
                              ? options.find(
                                (option) => option.value === values.pay_from
                              )
                              : ""
                          }
                          onChange={(option) => {
                            setFieldValue("pay_from", option.value);
                            handleUpdatePaymentConfig({
                              ...values,
                              is_followinpay_fromg_updates: option.value
                            })
                          }}
                        />
                      )}
                    />
                  </div>
                ) : null} */}
              </div>

              <div className="row">
                <div className="col-sm-6 padding-right-0 padding-right-sm-10 padding-top-8 padding-bottom-8">
                  <label className="form-label">First Name</label>
                  <Field
                    className="form-control"
                    placeholder="First Name"
                    name="first_name"
                    onChange={(event) => {
                      setFieldValue("first_name", event.target.value)
                      handleUpdatePaymentConfig({
                        ...values,
                        first_name: event.target.value
                      })
                    }}
                  // onBlur={() => {
                  //   handleUpdatePaymentConfig(values);
                  // }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="first_name"
                    component="div"
                  />
                </div>
                <div className="col-sm-6 padding-left-0 padding-left-sm-10 padding-top-8 padding-bottom-8">
                  <label className="form-label">Last Name</label>
                  <Field
                    className="form-control"
                    placeholder="Last Name"
                    name="last_name"
                    onChange={(event) => {
                      setFieldValue("last_name", event.target.value)
                      handleUpdatePaymentConfig({
                        ...values,
                        last_name: event.target.value
                      })
                    }}
                  // onBlur={() => {
                  //   handleUpdatePaymentConfig(values)
                  // }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="last_name"
                    component="div"
                  />
                </div>
              </div>

              <div className="padding-top-8 padding-bottom-8">
                <label className="form-label">Email</label>
                <Field
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  onChange={(event) => {
                    setFieldValue("email", event.target.value)
                    handleUpdatePaymentConfig({
                      ...values,
                      email: event.target.value
                    })
                  }}
                // onBlur={() => {
                //   handleUpdatePaymentConfig(values);
                // }}
                />
                <ErrorMessage
                  className="error-message"
                  name="email"
                  component="div"
                />
              </div>

              <div className="padding-top-8 padding-bottom-8">
                <label className="form-label">
                  Do you want your name to appear on the list of donors?
                </label>
                <div className="flex">
                  <label>
                    <input
                      className="radio-btn"
                      type="radio"
                      name="is_anonymous"
                      checked={values.is_anonymous == false}
                      onChange={() => {
                        setFieldValue("is_anonymous", false)
                        handleUpdatePaymentConfig({
                          ...values,
                          is_anonymous: false
                        });
                      }}
                    />
                    Yes &nbsp;&nbsp;
                  </label>
                  <label>
                    <input
                      className="radio-btn"
                      type="radio"
                      name="is_anonymous"
                      checked={values.is_anonymous == true}
                      onChange={() => {
                        setFieldValue("is_anonymous", true)
                        handleUpdatePaymentConfig({
                          ...values,
                          is_anonymous: true
                        })
                      }}
                    />
                    No
                  </label>
                </div>
              </div>

              <div className="padding-top-8 padding-bottom-8">
                <label className="form-label">
                  Would you like to receive email updates on this fundraiser?
                </label>
                <div className="flex">
                  <label>
                    <input
                      className="radio-btn"
                      type="radio"
                      name="is_following_updates"
                      checked={values.is_following_updates == true}
                      onChange={() => {
                        setFieldValue("is_following_updates", true)
                        handleUpdatePaymentConfig({
                          ...values,
                          is_following_updates: true
                        })
                      }}
                    />
                    Yes &nbsp;&nbsp;
                  </label>
                  <label>
                    <input
                      className="radio-btn"
                      type="radio"
                      name="is_following_updates"
                      checked={values.is_following_updates == false}
                      onChange={() => {
                        setFieldValue("is_following_updates", false)
                        handleUpdatePaymentConfig({
                          ...values,
                          is_following_updates: false
                        })
                      }}
                    />
                    No
                  </label>
                </div>
              </div>

              <div className="padding-top-8 padding-bottom-8">
                <label className="form-label">Comment (optional)</label>
                <Field
                  className="form-control"
                  component="textarea"
                  rows="4"
                  placeholder="Type your comment here..."
                  name="comment"
                  onChange={(event) => {
                    setFieldValue("comment", event.target.value)
                    handleUpdatePaymentConfig({
                      ...values,
                      comment: event.target.value
                    })
                  }}
                // onBlur={() => {
                //   handleUpdatePaymentConfig(values);
                // }}
                />
              </div>

              <div className="padding-top-12 padding-bottom-12">
                <button
                  className="btn padding-12 border-radius-10 font-size-16 max-width-120 bg-orange white"
                  type="button"
                  onClick={handleSubmit}
                >
                  Donate
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    transaction_ref: state.transaction.transaction_ref,
    isAuthenticated: !!state.auth.userToken,
  };
};

export default connect(mapStateToProps)(EnterDonation);
