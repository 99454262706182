import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { generateTransactionRef } from "../../../store/actions/transaction";
import { creditPouchWallet } from "../../../store/actions/pouchWallet";
import { getCurrencySymbol, numberWithCommas, refreshPage } from "../../../utilities";
import MoneyInputField from "../../utilityComponents/MoneyInputField"
import { NotificationManager } from "react-notifications";
import FormattedMoney from "../../utilityComponents/FormattedMoney";

const TopUpWallet = ({
  user,
  transaction_ref,
  setIsPageLoading,
  dispatch,
  handleClose,
}) => {
  const [currencies, setCurrencies] = useState([]);
  const [resetConfigKey, setResetConfigKey] = useState(0);
  const [paymentConfig, setPaymentConfig] = useState({
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    payment_options: "all",
    customizations: {
      // title: 'my Payment Title',
      // description: 'Payment for items in cart',
      logo: process.env.REACT_APP_POUCH_FUNDING_FAVICON_URL,
    },
  });

  const handleResetConfigKey = () => {
    setResetConfigKey(() => {
      const k = resetConfigKey;
      return k + 1;
    });
  };

  const handleFlutterPayment = useFlutterwave(paymentConfig, [resetConfigKey]);

  const handleUpdatePaymentConfig = (values) => {
    setPaymentConfig({
      ...paymentConfig,
      tx_ref: transaction_ref,
      amount: values.amount,
      currency: values.currency,
      customer: {
        email: user.email,
        phonenumber: user.phone_number,
        name: `${user.first_name} ${user.last_name}`
      },
      meta: {
        service_type: "wallet"
      }
    });

    // Reset the config key to reload UseFlutterwave
    handleResetConfigKey();
  };

  const handleTopUp = (values) => {
    handleFlutterPayment({
      callback: (response) => {
        closePaymentModal(); // this will close the modal programmatically

        dispatch(
          creditPouchWallet({
            transaction_id: response.transaction_id,
            service_type: "wallet"
          })
        ).then((res) => {
          if (res.error) {
            NotificationManager.error(res.error.message, "Error!")
          } else {
            if (res.success == true) {
              NotificationManager.success(res.message, "Success!")
              handleClose();

              // Refresh the page after 4000 milliseconds to avoid the flutterwave bug of recurring response
              refreshPage(4000)
            }
          }
        });

        dispatch(generateTransactionRef());
        handleResetConfigKey();
      },
      onClose: () => {
        dispatch(generateTransactionRef());
        handleResetConfigKey();
      },
    });
  };

  useEffect(() => {
    // Generate a transaction ref
    dispatch(generateTransactionRef());

    // Reformat the currencies structure
    const currencies = user.supported_currencies.map((currency) => {
      return {
        label: `${currency}`,
        value: currency,
      };
    });

    setCurrencies(currencies);
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          amount: "",
          currency: "",
          email: user.email,
        }}
        validationSchema={Yup.object({
          amount: Yup.number()
            .typeError("Amount must be a number")
            .positive("Amount must be a positive number")
            .integer("Amount must be a whole number")
            .required("Amount is required")
            .nullable(),
          currency: Yup.string().required("Currency is required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          // handleResetConfigKey()

          handleTopUp(values);
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
        }) => (
          <Form>
            <div className="padding-top-12 padding-bottom-12">
              <label className="form-label font-size-15">Currency</label>
              <Field
                component={() => (
                  <Select
                    className="form-control padding-0 border-color-logo-magenta"
                    classNamePrefix="react-select"
                    placeholder="Select currency"
                    options={currencies}
                    value={
                      currencies
                        ? currencies.find(
                          (option) => option.value === values.currency
                        )
                        : ""
                    }
                    onChange={(option) => {
                      setFieldValue("currency", option.value);
                      handleUpdatePaymentConfig({
                        ...values,
                        currency: option.value,
                      });
                    }}
                  />
                )}
              />
              <ErrorMessage
                className="error-message"
                name="currency"
                component="div"
              />
            </div>

            <div className="padding-top-12 padding-bottom-12">
              <label className="form-label font-size-15">Amount</label>
              <div className="form-control d-flex padding-0 border-color-logo-magenta fixed-height-40">
                <div className="d-flex align-items-center justify-content-center max-width-80 bg-logo-magenta white border-top-left-radius-8 border-bottom-left-radius-8">
                  {getCurrencySymbol(values.currency) ? (
                    <span>{getCurrencySymbol(values.currency)}</span>
                  ) : (
                    <span>- - -</span>
                  )}
                </div>
                <MoneyInputField
                  onChange={(amount) => {
                    setFieldValue("amount", amount)
                    handleUpdatePaymentConfig({
                      ...values,
                      amount
                    });
                  }}
                  placeholder="Enter amount"
                />
              </div>
              <ErrorMessage
                className="error-message"
                name="amount"
                component="div"
              />
              {values.currency == "NGN" &&
                values.amount >= 10000 &&
                <span className="red">Note: Your wallet will be credited with {getCurrencySymbol(values.currency)}{numberWithCommas(values.amount - 50)} due to a ₦50 stamp duty.</span>
              }
            </div>

            <div className="d-flex justify-content-end">
              <button
                className="btn margin-top-24 margin-bottom-20 padding-12 bg-logo-magenta white max-width-250 font-size-17 border-radius-8"
                type="button"
                onClick={handleSubmit}
              >
                <b>Proceed</b>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    transaction_ref: state.transaction.transaction_ref,
  };
};

export default connect(mapStateToProps)(TopUpWallet);
