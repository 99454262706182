import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import countryList from "react-select-country-list";
import { convertCountryCodeToName, convertSnakeToStandardCasing, getConfirmAlert, getFileNameFromFileUrl } from "../../../../utilities";
import { deleteNGODirector } from "../../../../store/actions/charity_ngo";
import { NotificationManager } from "react-notifications";
import NgoDirectorDetails from "./NgoDirectorDetails";

const CharityNgoDirectors = ({
  dispatch,
  charityNgo,
  setCharityNgo,
  setIsCreatingNew,
  setVisibleCharityNgoSetupSlides,
  setIsPageLoading
}) => {

  const [countries, setCountries] = useState([]);
  const [isError, setIsError] = useState("");
  const [isEditingOrAddingNewDirector, setIsEditingOrAddingNewDirector] = useState(false);
  const [editedDirector, setEditedDirector] = useState({});

  useEffect(() => {
    // Get country list
    const options = countryList().getData();
    setCountries(options);

  }, [])

  return (
    <div>
      <div className="padding-bottom-8">
        <h2 className="logo-magenta font-size-20 font-size-sm-21">DIRECTORS</h2>
      </div>
      <div className="horizontal-line " />
      <div>Add at least one director that runs the organization. Maximum of 3 directors.</div>

      {charityNgo && charityNgo.directors.length > 0 ?
        charityNgo.directors.map((director, i) => {
          return (
            <div key={i}>
              <div className="box-shadow black border-radius-6 padding-left-8 padding-right-8 padding-top-12 padding-bottom-12">
                <div className="row ">
                  <div className="col-sm-6 d-flex align-items-center">
                    <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                      <label className="grey font-size-13 line-height-13">First Name</label>
                      <p className="font-size-14 line-height-14">
                        {director.first_name}
                      </p>
                    </div>
                    <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                      <label className="grey font-size-13 line-height-13">Last Name</label>
                      <p className="font-size-14 line-height-14 text-break">
                        {director.last_name}
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 d-flex align-items-center">
                    <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                      <label className="grey font-size-13 line-height-13">Email</label>
                      <p className="font-size-14 line-height-14 text-break">
                        {director.email}
                      </p>
                    </div>
                    <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                      <label className="grey font-size-13 line-height-13">Role</label>
                      <p className="font-size-14 line-height-14 text-break">
                        {convertSnakeToStandardCasing(director.role)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-sm-6 d-flex align-items-center">
                    <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                      <label className="grey font-size-13 line-height-13">Country</label>
                      <p className="font-size-14 line-height-14 text-break">
                        {convertCountryCodeToName(director.country)}
                      </p>
                    </div>
                    <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                      <label className="grey font-size-13 line-height-13">State</label>
                      <p className="font-size-14 line-height-14 text-break">
                        {director.state}
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 d-flex align-items-center">
                    <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                      <label className="grey font-size-13 line-height-13">City</label>
                      <p className="font-size-14 line-height-14">
                        {director.city}
                      </p>
                    </div>
                    <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                      <label className="grey font-size-13 line-height-13">Address</label>
                      <p className="font-size-14 line-height-14 text-break">
                        {director.street_address}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-sm-6 d-flex align-items-center">
                    <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                      <label className="grey font-size-13 line-height-13">Phone Number</label>
                      <p className="font-size-14 line-height-14 text-break">
                        {director.phone_number}
                      </p>
                    </div>
                    <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                      <label className="grey font-size-13 line-height-13">Identity Number</label>
                      <p className="font-size-14 line-height-14 text-break">
                        {director.identity_number}
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 d-flex align-items-center">
                    <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                      <label className="grey font-size-13 line-height-13">Type of ID</label>
                      <p className="font-size-14 line-height-14">
                        {convertSnakeToStandardCasing(director.type_of_identity)}
                      </p>
                    </div>
                    <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                      <label className="grey font-size-13 line-height-13">Uploaded ID</label>
                      <p className="font-size-14 line-height-14 text-break">
                        {(director.identity_file_url) &&
                          <a
                            className="text-decoration-underline"
                            href={director.identity_file_url}
                            target="_blank">
                            {getFileNameFromFileUrl(director.identity_file_url)}</a>
                        }
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="d-flex col-6 padding-top-6 padding-bottom-6 padding-4">
                    <button
                      type="button"
                      className="btn margin-4 bg-logo-magenta white"
                      onClick={() => {
                        setEditedDirector(director)
                        setIsEditingOrAddingNewDirector(true)
                      }}
                    >Edit</button>

                    <button
                      type="button"
                      className="btn margin-4 bg-red white"
                      onClick={() => {
                        getConfirmAlert({
                          title: "Confirm delete",
                          message:
                            "Are you sure you want to delete this director?",
                          onClickYes: () => {
                            setIsPageLoading([true, "Loading..."]);
                            dispatch(deleteNGODirector(director.id))
                              .then((response) => {
                                setIsPageLoading([false]);
                                if (response.error) {
                                  NotificationManager.error(response.error.message, "Error!")
                                } else {
                                  if (response.success == true) {
                                    setCharityNgo(response.data);
                                  }
                                }
                              });
                          },
                          onClickNo: () => { },
                        });
                      }}
                    >Delete</button>
                  </div>
                </div>

              </div>
            </div>
          )
        }) :
        <div>You are yet to add directors</div>
      }

      {isError && <div className="error-message">{isError} </div>}

      <button
        type="button"
        className="btn border-radius-4 white bg-logo-magenta fixed-width-150 fixed-height-50 font-size-16 margin-top-10"
        onClick={() => {
          setEditedDirector({})
          setIsEditingOrAddingNewDirector(true)
          setIsError("")
        }}
      >Add a director</button>

      <div className="horizontal-line margin-top-20 margin-bottom-30" />

      <div className="d-flex justify-content-end margin-bottom-30">
        <button
          className="btn font-size-15 font-size-md-18 margin-right-30 margin-right-sm-50 max-width-160 padding-10 padding-md-16 border-color-logo-magenta logo-magenta border-radius-12"
          onClick={() => {
            setIsCreatingNew(false);
            setVisibleCharityNgoSetupSlides("EDIT_DETAILS")
          }}
        >
          <b>Previous</b>
        </button>
        <button
          className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-logo-magenta white border-radius-12"
          type="submit"
          onClick={() => {
            if (charityNgo.directors.length > 0) {
              setVisibleCharityNgoSetupSlides("DOCUMENTS")
            } else {
              setIsError("You must add at least one director")
            }
          }}
        >
          <b>Next</b>
        </button>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isEditingOrAddingNewDirector}
        onRequestClose={() => setIsEditingOrAddingNewDirector(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            NGO Director
          </h1>
          <i
            onClick={() => setIsEditingOrAddingNewDirector(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-0 padding-left-md-40 padding-right-md-40">
          <NgoDirectorDetails
            director={editedDirector}
            charityNgo={charityNgo}
            setCharityNgo={setCharityNgo}
            handleClose={() => setIsEditingOrAddingNewDirector(false)}
          />
        </div>
      </Modal>

    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CharityNgoDirectors);
