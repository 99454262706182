import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import userIcon from "./images/user_icon_darkblue.svg";
import emailIcon from "./images/email_icon_darkblue.svg";
import { submitContactForm } from "../store/actions/auth";
import { NotificationManager } from "react-notifications";

const ContactUs = ({
  dispatch,
  user
}) => {
  useEffect(() => {
    document.title = "Contact Us"
  }, [])

  return (
    <div className="content-container">
      <div className="d-flex justify-content-center">
        <div className="max-width-1200">
          <div className="row">
            <div className="col-lg-5 bg-grad-orange-magenta-bottom-right border-top-left-radius-30 border-bottom-left-radius-30">
              <div className="padding-top-40 padding-top-md-120 padding-bottom-40 padding-bottom-md-120 padding-left-20 padding-left-md-50 padding-right-20 padding-right-md-100">
                {/* <div className="margin-bottom-24">
            <h2 className="font-size-18 margin-bottom-4 white">
              Our Location
            </h2>
            <p className="font-size-13">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
              donec consectetur semper nunc in molestie.
            </p>
          </div> */}
                <div className="margin-bottom-24">
                  <h2 className="font-size-18 margin-bottom-4 white">
                    Contact Details
                  </h2>
                  <p className="font-size-13">
                    Email: support@pouchfunding.com
                  </p>
                </div>
                {/* <div>
            <h2 className="font-size-18 margin-bottom-4 white">
              Contact Us
            </h2>
            <div className="font-size-13">
              <p>phone : 0203-980-14-79</p>
              <p>mobile: 0203-980-14-79</p>
            </div>
          </div> */}
              </div>
            </div>

            <div className="col-lg-7 bg-lighter-grey padding-10 padding-md-40 border-top-right-radius-30 border-bottom-right-radius-30">
              <h1 className="font-size-24 margin-top-30 margin-bottom-0 margin-bottom-md-30">
                Get in touch
              </h1>
              <div>
                <Formik
                  initialValues={{
                    name: user ? user.first_name + " " + user.last_name : "",
                    email: user ? user.email : "",
                    subject: "",
                    message: "",
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string().required("Name is required"),
                    email: Yup.string()
                      .required("Email is required")
                      .email("Must be an email"),
                    subject: Yup.string().required("Subject is required"),
                    message: Yup.string().required("Message is required"),
                  })}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    dispatch(submitContactForm(values))
                      .then(res => {
                        setSubmitting(false)
                        if (res.error) {
                          NotificationManager.error(res.error.message, "Error!")
                        } else {
                          NotificationManager.success(res.message, "Success!")
                          resetForm()
                        }
                      })
                  }}
                >
                  {({
                    isSubmitting,
                  }) => (
                    <Form>
                      <div className="row margin-bottom-0 margin-bottom-lg-14">
                        <p className="col-lg-2 d-flex padding-top-12 font-size-14 font-size-lg-16">
                          <div>Name</div>
                          <div className="d-block-display-lg">:</div>
                        </p>
                        <div className="col-lg-10 stretch-full-lg">
                          <div className="d-flex margin-bottom-4 align-items-center border-radius-10 border-1 border-color-logo-magenta bg-pale-navy">
                            <div className="fixed-width-80 d-flex align-items-center justify-content-center">
                              <img width="22" src={userIcon} />
                            </div>
                            <Field
                              className="input padding-top-10 padding-top-md-14 padding-bottom-10 padding-bottom-md-14 padding-left-0 padding-right-10 font-size-14 font-size-lg-16 border-0 focus-none bg-transparent"
                              placeholder="Enter Full name"
                              type="text"
                              name="name"
                            />
                          </div>
                          <ErrorMessage
                            className="red font-size-12"
                            name="name"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="row margin-bottom-0 margin-bottom-lg-14">
                        <p className="col-lg-2 d-flex padding-top-12 font-size-14 font-size-lg-16">
                          <div>Email</div>
                          <div className="d-block-display-lg">:</div>
                        </p>
                        <div className="col-lg-10 stretch-full-lg">
                          <div className="d-flex margin-bottom-4 align-items-center border-radius-10 border-1 border-color-logo-magenta bg-pale-navy">
                            <div className="fixed-width-80 d-flex align-items-center justify-content-center">
                              <img width="24" src={emailIcon} />
                            </div>
                            <Field
                              className="input padding-top-10 padding-top-md-14 padding-bottom-10 padding-bottom-md-14 padding-left-0 padding-right-10 font-size-14 font-size-lg-16 border-0 focus-none bg-transparent"
                              placeholder="Enter email"
                              type="email"
                              name="email"
                            />
                          </div>
                          <ErrorMessage
                            className="red font-size-12"
                            name="email"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="row margin-bottom-0 margin-bottom-lg-14">
                        <p className="col-lg-2 d-flex padding-top-12 font-size-14 font-size-lg-16">
                          <div>Subject</div>
                          <div className="d-block-display-lg">:</div>
                        </p>
                        <div className="col-lg-10 stretch-full-lg">
                          <div className="d-flex margin-bottom-4 align-items-center border-radius-10 border-1 border-color-logo-magenta bg-pale-navy">
                            <div className="fixed-width-80 d-flex align-items-center justify-content-center">
                              <i className="far fa-file-alt	font-size-22 logo-magenta"></i>
                            </div>
                            <Field
                              className="input padding-top-10 padding-top-md-14 padding-bottom-10 padding-bottom-md-14 padding-left-0 padding-right-10 font-size-14 font-size-lg-16 border-0 focus-none bg-transparent"
                              placeholder="Enter Subject"
                              type="text"
                              name="subject"
                            />
                          </div>
                          <ErrorMessage
                            className="red font-size-12"
                            name="subject"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="row margin-bottom-0 margin-bottom-lg-14">
                        <p className="col-lg-2 d-flex padding-top-12 font-size-14 font-size-lg-16">
                          <div>Message</div>
                          <div className="d-block-display-lg">:</div>
                        </p>
                        <div className="col-lg-10 stretch-full-lg">
                          <div className="d-flex margin-bottom-4 border-radius-10 border-1 border-color-logo-magenta bg-pale-navy">
                            <div className="fixed-width-80 margin-top-6 margin-top-md-12 d-flex justify-content-center">
                              <i className="fa fa-pencil-square-o font-size-24 logo-magenta"></i>
                            </div>
                            <Field
                              className="input-textarea fixed-height-250 padding-top-10 padding-top-md-14 padding-bottom-10 padding-bottom-md-14 padding-left-0 padding-right-10 font-size-14 font-size-lg-16 border-0 focus-none bg-transparent"
                              component="textarea"
                              placeholder="Drop your message                              "
                              type="text"
                              name="message"
                            />
                          </div>
                          <ErrorMessage
                            className="red font-size-12"
                            name="message"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="row margin-bottom-0 margin-bottom-lg-14">
                        <p className="col-lg-2 d-block-hidden-lg"></p>
                        <div className="col-lg-10 stretch-full-lg">
                          <button
                            className="btn max-width-180 font-size-15 font-size-md-16 margin-top-30 margin-top-lg-40 margin-bottom-40 margin-bottom-md-20  padding-16 padding-md-20 border-radius-8 bg-logo-magenta white"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Submit Message
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ContactUs);