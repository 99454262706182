import React, { useState, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddSocialMediaVideoModal = ({ handleSaveSocialMediaVideo }) => {
  const [urlValidityError, setUrlValidityError] = useState(null);

  const checkYouTubeUrlValidity = (browser_url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let is_url_valid = false;
    let embed_url = null;
    const match = browser_url.match(regExp);
    if (match) {
      is_url_valid = match && match[7].length == 11 ? true : false;
      embed_url = `https://www.youtube.com/embed/${match[7]}`;
    }

    return {
      is_url_valid,
      browser_url,
      embed_url,
      thumbnail_url: `http://img.youtube.com/vi/${match[7]}/0.jpg`
    };
  };


  const checkFacebookUrlValidity = (browser_url) => {
    let is_url_valid = false;
    const splitBrowser_url = browser_url.split("?");
    const mainVideoUrl = splitBrowser_url[0];

    //Check if splitBrowser_url[0] contains "facebook.com"
    is_url_valid = /facebook.com/i.test(mainVideoUrl);

    let video_id = null
    const splitUrl = browser_url.split("?v=")
    video_id = splitUrl[1]
    console.log(video_id)
    if (!video_id) {
      video_id = browser_url.split("/videos/")[1]
      // Check if the video_id is more than 15 characters
      if (video_id.split("").length > 15) {
        video_id = video_id.substring(0, 15)
      }
    }

    let thumbnail_url = process.env.NODE_ENV == "production" ?
      `https://graph.facebook.com/${video_id}/thumbnails` :
      'https://www.gstatic.com/webp/gallery3/1.png'


    return {
      is_url_valid,
      browser_url,
      embed_url: `https://www.facebook.com/plugins/video.php?href=${mainVideoUrl}`,
      thumbnail_url
    };
  };

  const checkInstagramUrlValidity = (browser_url) => {
    let is_url_valid = false;

    const splitBrowser_url = browser_url.split("?");

    let mainVideoUrl = splitBrowser_url[0];

    // console.log(mainVideoUrl);
    //Check if there is no trailing "/" at the end of the mainVideoUrl
    const piecedUrlStrings = mainVideoUrl.split("");
    if (piecedUrlStrings[piecedUrlStrings.length - 1] !== "/") {
      mainVideoUrl = mainVideoUrl + "/";
    }

    //Check if splitBrowser_url[0] contains "facebook.com"
    is_url_valid = /instagram.com/i.test(mainVideoUrl);

    let embed_url = process.env.NODE_ENV == "production" ?
      `https://graph.facebook.com/v10.0/instagram_oembed?url=${mainVideoUrl}&access_token=${process.env.REACT_APP_FACEBOOK_APP_ID}|${process.env.REACT_APP_FACEBOOK_CLIENT_TOKEN}` :
      `${mainVideoUrl}embed`

    let thumbnail_url = process.env.NODE_ENV == "production" ?
      `https://graph.facebook.com/v10.0/instagram_oembed?url=${mainVideoUrl}&maxwidth=320&fields=thumbnail_url&access_token=${process.env.REACT_APP_FACEBOOK_CLIENT_TOKEN}` :
      'https://www.gstatic.com/webp/gallery3/1.png'

    return {
      is_url_valid,
      browser_url,
      embed_url,
      thumbnail_url
    };
  };

  return (
    <div>
      <Formik
        initialValues={{
          social_media_type: null,
          browser_url: null,
          is_url_valid: false,
          embed_url: null,
          thumbnail_url: null,
          video_id: null,
          iframeSize: {
            width: null,
            height: null,
          },
        }}
        validationSchema={Yup.object().shape({
          social_media_type: Yup.string()
            .required("Select social media type.")
            .nullable(),
          browser_url: Yup.string().required("Url is required.").nullable(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (!values.is_url_valid) {
            setUrlValidityError("Url is invalid. Please enter a valid link.");
            return;
          }
          handleSaveSocialMediaVideo(values);
          setSubmitting(false);
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleReset,
          isSubmitting,
          values,
        }) => (
          <Form>
            <div>
              <span>Add a social media video link</span>
            </div>

            <div className="column">
              <select
                className="custom-input"
                //  style={{ width: "100%", maxWidth: "600px" }}
                name="social_media_type"
                onChange={(e) => {
                  setFieldValue("social_media_type", e.target.value);
                }}
              >
                <option value selected disabled hidden>
                  Select social media
                </option>
                <option value="youtube">Youtube</option>
                <option value="facebook">Facebook</option>
                <option value="instagram">Instagram</option>
              </select>
              <ErrorMessage
                className="error-message"
                name="social_media_type"
                component="div"
              />
            </div>

            {values.social_media_type ? (
              <div className="column">
                <Field
                  className="custom-input"
                  placeholder={`Paste your ${values.social_media_type} link`}
                  name="browser_url"
                />
                <ErrorMessage
                  className="error-message"
                  name="browser_url"
                  component="div"
                />
                {values.browser_url && !values.is_url_valid ? (
                  <span className="error-message">{urlValidityError}</span>
                ) : null}
              </div>
            ) : null}

            <div className="column">
              <button
                className="button button-normal button--green"
                onClick={() => {
                  if (values.social_media_type == "youtube" || values.social_media_type == "YouTube") {
                    if (values.browser_url) {
                      const urlValidity = checkYouTubeUrlValidity(
                        values.browser_url
                      );

                      setFieldValue("is_url_valid", urlValidity.is_url_valid);
                      if (urlValidity.is_url_valid) {
                        setFieldValue("embed_url", urlValidity.embed_url);
                        setFieldValue("thumbnail_url", urlValidity.thumbnail_url);

                      } else {
                        setFieldValue("embed_url", null);
                        setFieldValue("thumbnail_url", null);
                      }

                      setFieldValue("iframeSize.width", "560");
                      setFieldValue("iframeSize.height", "315");
                    }
                  } else if (values.social_media_type == "facebook" || values.social_media_type == "Facebook") {
                    if (values.browser_url) {
                      const urlValidity = checkFacebookUrlValidity(
                        values.browser_url
                      );
                      setFieldValue("is_url_valid", urlValidity.is_url_valid);
                      if (urlValidity.is_url_valid) {
                        setFieldValue("embed_url", urlValidity.embed_url);
                        setFieldValue("thumbnail_url", urlValidity.thumbnail_url);
                      } else {
                        setFieldValue("embed_url", null);
                        setFieldValue("thumbnail_url", null);
                      }

                      setFieldValue("iframeSize.width", "560");
                      setFieldValue("iframeSize.height", "315");
                    }
                  } else if (values.social_media_type == "instagram" || values.social_media_type == "Instagram") {
                    if (values.browser_url) {
                      const urlValidity = checkInstagramUrlValidity(
                        values.browser_url
                      );

                      setFieldValue("is_url_valid", urlValidity.is_url_valid);
                      if (urlValidity.is_url_valid) {
                        setFieldValue("embed_url", urlValidity.embed_url);
                        setFieldValue("thumbnail_url", urlValidity.thumbnail_url);
                      } else {
                        setFieldValue("embed_url", null);
                        setFieldValue("thumbnail_url", null);
                      }

                      setFieldValue("iframeSize.width", "320");
                      setFieldValue("iframeSize.height", "440");
                    }
                  }

                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { AddSocialMediaVideoModal as default };
