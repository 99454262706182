import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import CurrentWindowWidth from "../../../../CurrentWindowWidth";
import dummyPrivateFundraiserCoverImage from "../../setup/privateFundraiserCover.jpg"
import navLeftIcon from "../../../../images/nav_left_icon.svg";
import navRightIcon from "../../../../images/nav_right_icon.svg";

const FundraiserCoverMedia = ({ fundraiser }) => {
  const [coverMediaType, setCoverMediaType] = useState("");
  const [coverMediaHeight, setCoverMediaHeight] = useState(0);
  const [socialMediaWidth, setSocialMediaWidth] = useState("100%");
  const [isMainCoverVisible, setIsMainCoverVisible] = useState(true);
  const [currentPositionOfAdditionalPicture, setCurrentPositionOfAdditionalPicture] = useState(0);
  const [positionOfLastAdditionalPicture] = useState(fundraiser.additional_cover_pictures.length);
  const [isNavigationPossible, setIsNavigationPossible] = useState(false);

  let windowWidth = CurrentWindowWidth();

  const handleNavigateCoverMediaRight = () => {
    if (isNavigationPossible) {
      if (isMainCoverVisible) {
        setIsMainCoverVisible(false)
        setCurrentPositionOfAdditionalPicture(1)
      } else {
        if (positionOfLastAdditionalPicture > currentPositionOfAdditionalPicture) {
          setCurrentPositionOfAdditionalPicture(currentPositionOfAdditionalPicture + 1)
        }
      }
    }
  }

  const handleNavigateCoverMediaLeft = () => {
    if (isNavigationPossible && !isMainCoverVisible && currentPositionOfAdditionalPicture > 0) {
      if (currentPositionOfAdditionalPicture == 1) {
        setCurrentPositionOfAdditionalPicture(0)
        setIsMainCoverVisible(true)
      } else {
        setCurrentPositionOfAdditionalPicture(currentPositionOfAdditionalPicture - 1)
      }
    }
  }

  const coverMediaRef = useCallback(
    (node) => {
      if (node !== null) {
        const coverMediaDimensions = node.getBoundingClientRect();

        setCoverMediaHeight(coverMediaDimensions.width * 0.483);
        if (fundraiser.cover_media.social_media_type) {
          if (
            fundraiser.cover_media.social_media_type == "facebook" ||
            fundraiser.cover_media.social_media_type == "Facebook"
          ) {
            setCoverMediaHeight(coverMediaDimensions.width * 0.85);
            setSocialMediaWidth("80%");
          } else if (
            fundraiser.cover_media.social_media_type == "youtube" ||
            fundraiser.cover_media.social_media_type == "YouTube"
          ) {
            setCoverMediaHeight(coverMediaDimensions.width * 0.5);
          } else if (
            fundraiser.cover_media.social_media_type == "instagram" ||
            fundraiser.cover_media.social_media_type == "Instagram"
          ) {
            setCoverMediaHeight(coverMediaDimensions.width * 1);
            setSocialMediaWidth("80%");
          }
        }
      }
    },
    [windowWidth]
  );

  useEffect(() => {
    if (fundraiser.cover_media.social_media_type) {
      setCoverMediaType("socialMedia");
    } else {
      if (fundraiser.cover_media.embed_link) {
        if (fundraiser.cover_media.file_type == "video") {
          setCoverMediaType("video");
        } else if (fundraiser.cover_media.file_type == "image") {
          setCoverMediaType("image");
        }
      }
    }

    if (fundraiser.additional_cover_pictures.length > 1) {
      setIsNavigationPossible(true)
    }
  }, []);

  return (
    <div className="relative">
      <div
        className="absolute top-50-pct transformY-50 "
        onClick={handleNavigateCoverMediaLeft}>
        {isNavigationPossible && currentPositionOfAdditionalPicture > 0 &&
          <img
            className={`${windowWidth <= 320 ? "fixed-width-25" : "fixed-width-50"}`}
            src={navLeftIcon}
          />}
      </div>
      <div
        className="absolute top-50-pct left-100-pct transform-100-minus-50-minus"
        onClick={handleNavigateCoverMediaRight}
      >
        {isNavigationPossible && positionOfLastAdditionalPicture > currentPositionOfAdditionalPicture &&
          <img
            className={`${windowWidth <= 320 ? "fixed-width-25" : "fixed-width-50"}`}
            src={navRightIcon}
          />
          // <button type="button">Right</button>
        }
      </div>
      <div>
        {isMainCoverVisible ?
          <div>
            {fundraiser && !fundraiser.fundraiser_details.is_public && !coverMediaType &&
              <div className="relative">
                <div className="absolute top-0 bottom-0 left-0 right-0 d-flex align-items-center justify-content-center white font-size-24 font-size-sm-26">
                  <div>
                    <p className="text-center padding-2">Private Fundraiser</p>
                    <p className="text-center padding-2">By</p>
                    <p className="text-center padding-2">{fundraiser.organizer.name}</p>
                  </div>
                </div>
                <img
                  // className="border-radius-6"
                  width="100%"
                  style={{ maxHeight: "200px" }}
                  src={dummyPrivateFundraiserCoverImage}
                />
              </div>}

            {coverMediaType == "image" && (
              <img
                ref={coverMediaRef}
                // className="border-radius-10"
                width="100%"
                height={coverMediaHeight + "px"}
                src={`${fundraiser.cover_media.embed_link}?${fundraiser.cover_media.embed_link}`}
              />
            )}

            {coverMediaType == "video" && (
              <video
                ref={coverMediaRef}
                width="100%"
                height={coverMediaHeight + "px"}
                style={{
                  outline: "none",
                }}
                src={`${fundraiser.cover_media.embed_link}?${fundraiser.cover_media.embed_link}`}
                controls
              />
            )}

            {coverMediaRef && coverMediaType == "socialMedia" && (
              <center className="bg-black padding-10">
                <iframe
                  ref={coverMediaRef}
                  width={socialMediaWidth}
                  height={coverMediaHeight + "px"}
                  src={`${fundraiser.cover_media.embed_link}?${fundraiser.cover_media.embed_link}`}
                  frameBorder="0"
                  allowFullScreen="allowFullScreen"
                ></iframe>
              </center>
            )}
          </div> :
          <div>
            <img
              ref={coverMediaRef}
              // className="border-radius-10"
              width="100%"
              height={coverMediaHeight + "px"}
              src={`${fundraiser.additional_cover_pictures[currentPositionOfAdditionalPicture - 1].file_url}?${fundraiser.additional_cover_pictures[currentPositionOfAdditionalPicture - 1].file_url}`}
            />
          </div>
        }
      </div>
    </div >
  )
};


export default connect()(FundraiserCoverMedia);
