import React, { useState, useRef, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { NotificationManager } from "react-notifications";
import {
  base64ToBlob,
  getConfirmAlert,
} from "../../../../utilities";
import AddOrTakePhotoModal from "./fragments/AddOrTakePhotoModal";
import PhotoCameraModal from "./fragments/PhotoCameraModal";
import ImageEditor from "./fragments/ImageEditor";
import {
  deleteAdditionalCoverPicture,
  uploadAdditionalCoverPicture,
} from "../../../../store/actions/fundraiser";

const UploadAdditionalCoverPicture = ({
  dispatch,
  fundraiserId,
  additionalCoverPictures,
  setFundraiser,
  setIsPageLoading,
}) => {
  const [editableCoverPhoto, setEditableCoverPhoto] = useState(null);
  const [isAddOrTakePhotoModalOpen, setIsAddOrTakePhotoModalOpen] = useState(
    false
  );
  const [isPhotoCameraModalVisible, setIsPhotoCameraModalVisible] = useState(
    false
  );
  const [isImageEditorVisible, setIsImageEditorVisible] = useState(false);

  const coverPhotoInput = useRef(null);

  const onClickRetakePhoto = () => {
    setIsImageEditorVisible(false);
    setIsAddOrTakePhotoModalOpen(true);
  };

  const onClickTakePhoto = () => {
    setIsAddOrTakePhotoModalOpen(false);
    setIsPhotoCameraModalVisible(true);
  };

  const onClickUploadPhoto = () => {
    setIsAddOrTakePhotoModalOpen(false);
    coverPhotoInput.current.click();
  };

  const handleTakePhoto = (dataUri) => {
    setIsPhotoCameraModalVisible(false); //To close photo camera aperture
    setEditableCoverPhoto(dataUri); //For the image to be edited
    setIsImageEditorVisible(true); //Show editable picture preview
  };

  const handleSaveImage = async (croppedImage) => {
    setIsPageLoading([true, "Uploading..."]);
    const blob = base64ToBlob(croppedImage);
    const fileType = `${blob.type.split("/")[0]}.${blob.type.split("/")[1]}`;

    let formData = new FormData(); //formdata object
    formData.append("file", blob, fileType);
    formData.append("fundraiser_id", fundraiserId);

    dispatch(uploadAdditionalCoverPicture(formData))
      .then((response) => {
        setIsPageLoading([false]);
        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            setIsImageEditorVisible(false);
            setFundraiser(response.data);
          }
        }
      });
  };


  const handleDeletePicture = (pictureId) => {
    setIsPageLoading([true, "Deleting..."]);
    dispatch(
      deleteAdditionalCoverPicture(pictureId)
    ).then((response) => {
      setIsPageLoading([false]);
      if (response.error) {
        NotificationManager.error(response.error.message, "Error!")
      } else {
        if (response.success == true) {
          setFundraiser(response.data);
        }
      }
    });
  };

  return (
    <div className="padding-left-0 padding-right-0">
      <div>
        <input
          hidden
          ref={coverPhotoInput}
          name="coverPhoto"
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.currentTarget.files[0];
            if (file) {
              if (file.size > 1000000) {
                //Maximum of 10MB
                alert("File too large. Max. 1MB");
              } else {
                let reader = new FileReader();
                reader.onloadend = () => {
                  setEditableCoverPhoto(reader.result);
                  setIsImageEditorVisible(true);
                };
                reader.readAsDataURL(file);
              }
            }
          }}
        />

        <button
          className="button button--darkblue max-width-200"
          type="button"
          onClick={() => setIsAddOrTakePhotoModalOpen(true)}
        >Upload or take photo</button> <br />
        <span>You can add up to 5 additional photos</span>

        <div className="row">
          {additionalCoverPictures.length > 0 && additionalCoverPictures.map((fileObj, index) => (
            <Fragment key={index}>
              <div className="min-width-130 max-width-130 margin-left-0 margin-right-8 margin-top-4 margin-bottom-4 margin-left-sm-0
               margin-right-sm-10 margin-top-sm-10 margin-bottom-sm-10 margin-sm-right-10 cursor-pointer bg-lighter-blue ">
                <div className="relative">
                  <div
                    className="fixed-height-100"
                  >
                    <img
                      width="100%"
                      height="100%"
                      className="d-block stretch-full"
                      src={`${fileObj.file_url}?${fileObj.file_url}`}
                    />
                  </div>
                  <i
                    className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                    onClick={() => {
                      getConfirmAlert({
                        title: "Confirm delete!",
                        message: "Are you sure you want to delete this picture?",
                        onClickYes: () => {
                          handleDeletePicture(fileObj.id)
                        },
                        onClickNo: () => null,
                      });
                    }}
                  ></i>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isImageEditorVisible}
        onRequestClose={() => setIsImageEditorVisible(false)}
        className="modal"
        overlayClassName="modal-backdrop">

        <ImageEditor
          src={editableCoverPhoto}
          handleSaveImage={handleSaveImage}
          onClickRetakePhoto={onClickRetakePhoto}
        />
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isAddOrTakePhotoModalOpen}
        onRequestClose={() => setIsAddOrTakePhotoModalOpen(false)}
        className="modal"
        overlayClassName="modal-backdrop"
      >
        <AddOrTakePhotoModal
          onClickTakePhoto={onClickTakePhoto}
          onClickUploadPhoto={onClickUploadPhoto}
        />
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isPhotoCameraModalVisible}
        onRequestClose={() => setIsPhotoCameraModalVisible(false)}
        className="modal black-background"
        overlayClassName="modal-backdrop"
        contentLabel="Take a picture"
      >
        <PhotoCameraModal handleTakePhoto={handleTakePhoto} />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(UploadAdditionalCoverPicture);
