import React from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import { getConfirmAlert } from "../../../../../utilities";
import NationalPhoneNumber from "../../../../utilityComponents/NationalPhoneNumber";

const BeneficiaryBankDetailsTable = ({
  dispatch,
  setIsPageLoading,
  setIsBeneficiaryBankDetailsModal,
  thirdPartyBeneficiary,
  setFundraiser,
  setCreateNewBankDetails,
  removeThirdPartyBeneficiary,
  setIsForSomeoneElse,
  canEdit = true
}) => {
  return (
    <div>
      {thirdPartyBeneficiary ? (
        <div>
          <div className="padding-left-md-0 padding-right-md-50 padding-top-8 padding-bottom-8">
            <h3 className="logo-magenta font-size-17 font-size-sm-18">Third Party Beneficiary</h3>
          </div>
          <table >
            <tbody>
              <tr>
                <td className="font-size-13 font-size-sm-14">Full Name</td>
                <td className="font-size-13 font-size-sm-14">
                  <b>{thirdPartyBeneficiary.full_name}</b>
                </td>
              </tr>
              <tr >
                <td className="font-size-13 font-size-sm-14">Email</td>
                <td>
                  <b>{thirdPartyBeneficiary.email}</b>
                </td>
              </tr>
              <tr>
                <td className="font-size-13 font-size-sm-14">Phone No.</td>
                <td className="font-size-13 font-size-sm-14">
                  <b>
                    <NationalPhoneNumber
                      internationalNumber={thirdPartyBeneficiary.phone_number}
                    /></b>
                </td>
              </tr>
              <tr>
                <td className="font-size-13 font-size-sm-14">Bank Name</td>
                <td className="font-size-13 font-size-sm-14">
                  <b>{thirdPartyBeneficiary.bank_name}</b>
                </td>
              </tr>
              <tr>
                <td className="font-size-13 font-size-sm-14">Account No.</td>
                <td className="font-size-13 font-size-sm-14">
                  <b>{thirdPartyBeneficiary.bank_account_number}</b>
                </td>
              </tr>
              <tr>
                <td className="font-size-13 font-size-sm-14">Country</td>
                <td className="font-size-13 font-size-sm-14">
                  <b>{countryList().getLabel(thirdPartyBeneficiary.country_code)}</b>
                </td>
              </tr>
            </tbody>
          </table>
          {
            canEdit &&
            <div>
              <div
                className="edit-text"
                style={{
                  fontSize: "13px",
                  margin: "10px 0px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
                onClick={() => {
                  setIsBeneficiaryBankDetailsModal(true);
                  setCreateNewBankDetails(false);
                }}
              >
                Change Details
              </div>
              <div
                className="delete-text float-right"
                style={{
                  fontSize: "13px",
                  margin: "10px 0px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
                onClick={() => {
                  getConfirmAlert({
                    title: "Confirm delete",
                    message:
                      "Are you sure you want to delete this bank account details?",
                    onClickYes: () => {
                      setIsPageLoading([true, "Loading..."]);

                      dispatch(
                        removeThirdPartyBeneficiary(thirdPartyBeneficiary)
                      ).then((res) => {
                        setIsPageLoading([false]);
                        if (res.error) {
                          NotificationManager.error(res.error.message, "Error!")
                        } else {
                          if(res.success = true){
                            setIsForSomeoneElse();
                            setFundraiser(res.data);
                          }
                        }
                      });
                    },
                    onClickNo: () => { },
                  });
                }}
              >
                Remove Details
              </div>
            </div>
          }
        </div>
      ) : null}
    </div>
  );
};

export default connect()(BeneficiaryBankDetailsTable);
