import { getUser } from "./auth"
import axiosInstance from "../../helpers/axiosInstance";

//Credit a user pouch wallet after payment
export const creditPouchWallet = (data) => {
  return async (dispatch, getState) => {
    return axiosInstance.post("/pouch-wallets/pay-to-wallet", data)
      .then(response => {
        dispatch(getUser())
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};


//Withdraw from wallet
export const p2pCreditTransfer = (values) => {
  return async (dispatch, getState) => {
    return axiosInstance.post('/pouch-wallets/p2p-credit-transfer', values)
      .then(response => {
        dispatch(getUser())
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

//Transfer from wallet to bank account
export const transferFromWalletToBank = (data) => {
  return async (dispatch, getState) => {
    return axiosInstance.post("/pouch-wallets/withdraw-from-wallet", data)
      .then(response => {
        dispatch(getUser())
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};


// Get wallet transactions
export const getWalletTransactions = (data) => {
  return async (dispatch, getState) => {
    return axiosInstance
      .get(
        `/pouch-wallets/get-wallet-transactions`,
        { params: data }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { errorMessage: error ? error.response.data : null };
      });
  };
};

//  Get a Transaction
export const getTransaction = (id) => {
  return async (dispatch, getState) => {
    return axiosInstance
      .get(`/pouch-wallets/get-transaction/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { errorMessage: error ? error.response.data : null };
      });
  };
};

//  Cancel initiated wallet transfer
export const cancelInitiatedTransfer = (transaction_id) => {
  return async (dispatch, getState) => {
    return axiosInstance
      .patch(`/pouch-wallets/cancel-initiated-transfer/${transaction_id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { errorMessage: error ? error.response.data : null };
      });
  };
};

