import React, { useEffect, useState, Fragment } from "react";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import {
  uploadAddressProofDoc,
  deleteAddressProofDoc,
  getUser
} from "../../../../store/actions/auth";
import { getDocumentFileformatFromUrl, getConfirmAlert, getFileNameFromFileUrl, convertSnakeToStandardCasing } from "../../../../utilities";
import PdfViewer from "../../../utilityComponents/PdfViewer";
import PdfThumbnail from "../../../utilityComponents/PdfThumbnail";
import ImageViewer from "../../../utilityComponents/ImageViewer";

const SubmitAddressProofDocs = ({
  user,
  documents,
  setIsPageLoading,
  dispatch,
  handleClose,
}) => {
  const [viewedDocument, setViewedDocument] = useState("");
  const [isImageViewerVisible, setIsImageViewerVisible] = useState(false);
  const [isPdfViewerVisible, setIsPdfViewerVisible] = useState(false);
  const [latestFile, setLatestFile] = useState("");
  const [isEditAddressDetailsOpen, setIsEditAddressDetailsOpen] = useState(false);
  const [selectedDocumentTypeValue, setSelectedDocumentTypeValue] = useState("");
  const [addressDetails, setAddressDetails] = useState(user);
  const [countries, setCountries] = useState([]);

  const handleUploadDocument = (values) => {
    setIsPageLoading([true, "Uploading..."]);

    const formData = new FormData();
    formData.append("file", latestFile);
    formData.append("document_type", values.document_type);
    formData.append("country", values.country);
    formData.append("state", values.state);
    formData.append("city", values.city);
    formData.append("street_address", values.street_address);

    dispatch(uploadAddressProofDoc(formData))
      .then((response) => {

        setIsPageLoading([false]);

        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            NotificationManager.success(response.message, "Success!")
            dispatch(getUser())
            setIsEditAddressDetailsOpen(false);
            setLatestFile("");
            setSelectedDocumentTypeValue("")
          }
        }
      });
  };

  const handleRemoveDocument = (data) => {
    setIsPageLoading([true, "Uploading..."]);

    dispatch(deleteAddressProofDoc(data))
      .then(res => {
        setIsPageLoading([false]);

        if (res.error) {
          NotificationManager.error(res.error.message, "Error!")
        } else {
          if (res.success == true) {
            dispatch(getUser())
            setLatestFile("");
            setSelectedDocumentTypeValue("")
          }
        }
      })
  }

  useEffect(() => {
    // Get country list
    const options = countryList().getData();
    setCountries(options);

  }, [])

  return (
    <div>
      <div className="row">
        {documents.map((fileObj, index) => (
          <Fragment key={index}>
            <div className="min-width-130 max-width-130 margin-4 margin-sm-10 cursor-pointer bg-lighter-blue border-radius-6">
              <div className="relative">
                <div
                  className="fixed-height-100"
                  onClick={() => {
                    setViewedDocument(fileObj);
                    if (getDocumentFileformatFromUrl(fileObj.file_url) == "pdf") {
                      setIsPdfViewerVisible(true);
                    } else if (getDocumentFileformatFromUrl(fileObj.file_url) == "image") {
                      setIsImageViewerVisible(true);
                    }
                  }}
                >
                  {getDocumentFileformatFromUrl(fileObj.file_url) == "pdf" ? (
                    <PdfThumbnail height={90} document={fileObj} />
                  ) : (
                    <img
                      width="100%"
                      height="100%"
                      className="d-block stretch-full border-top-radius-6"
                      src={`${fileObj.file_url}?${fileObj.file_url}`}
                    />
                  )}
                </div>

                <i
                  className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                  onClick={() => {
                    getConfirmAlert({
                      title: "Confirm delete!",
                      message: "Are you sure you want to delete this document?",
                      onClickYes: () => {
                        handleRemoveDocument(fileObj)
                      },
                      onClickNo: () => null,
                    });
                  }}
                ></i>
              </div>
              <div
                className="font-size-12 padding-4 padding-left-8 padding-right-8 text-truncate border-bottom-radius-6"
                onClick={() => {
                  setViewedDocument(fileObj);
                  if (getDocumentFileformatFromUrl(fileObj.file_url) == "pdf") {
                    setIsPdfViewerVisible(true);
                  } else if (getDocumentFileformatFromUrl(fileObj.file_url) == "image") {
                    setIsImageViewerVisible(true);
                  }
                }}
              >
                <b>{getFileNameFromFileUrl(fileObj.file_url)}</b>
              </div>
              <div className="padding-left-8 padding-right-8 padding-bottom-8 font-size-12">
                {convertSnakeToStandardCasing(fileObj.verification_status)}
              </div>
            </div>
          </Fragment>
        ))}
      </div>

      <center className="red" >
        This document won't be made public. And it does not have to bear your name, just your current address.
      </center>

      <Dropzone
        onDrop={(acceptedFiles) => {
          setLatestFile(acceptedFiles[0]);
          setIsEditAddressDetailsOpen(true)
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="d-flex align-content-center justify-content-center padding-top-20 padding-bottom-20">
            <div
              className="d-inline-block cursor-pointer"
              style={{ outline: "none" }}
              {...getRootProps()}
            >
              <input {...getInputProps()} accept="image/*,.pdf" />
              <p className="d-inline-block font-size-13 font-size-sm-14 line-height-16 line-height-sm-22 padding-6 padding-left-16 padding-right-16 white border-radius-30 bg-charcoal opacity-75">
                Drag 'n' drop some files here, or click to select files
              </p>
            </div>
          </div>
        )}
      </Dropzone>

      <Modal
        ariaHideApp={false}
        isOpen={isPdfViewerVisible}
        onRequestClose={() => setIsPdfViewerVisible(false)}
        className="modal max-width-800 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div>
          <PdfViewer
            document={viewedDocument}
            handleClose={() => setIsPdfViewerVisible(false)} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isImageViewerVisible}
        onRequestClose={() => setIsImageViewerVisible(false)}
        className="modal max-width-1000 padding-0 bg-black"
        overlayClassName="modal-backdrop"
      >
        <div>
          <ImageViewer
            maxHeightImage={600}
            document={viewedDocument}
            handleClose={() => setIsImageViewerVisible(false)}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isEditAddressDetailsOpen}
        onRequestClose={() => setIsEditAddressDetailsOpen(false)}
        className="modal max-width-1000 padding-0"
        overlayClassName="modal-backdrop"
      >
        <Formik
          initialValues={{
            country: user.country,
            state: user.state,
            city: user.city,
            street_address: user.street_address,
            document_type: selectedDocumentTypeValue
          }}

          validationSchema={Yup.object({
            document_type: Yup.string().required("Select document type"),
            country: Yup.string().required("Country is required"),
            state: Yup.string().required("State is required"),
            city: Yup.string().required("City is required"),
            street_address: Yup.string().required("Steet address is required"),
          })}

          onSubmit={(values, { setSubmitting }) => {
            handleUploadDocument(values)
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            isSubmitting,
            values,
          }) => (
            <Form>
              <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40 justify-content-between align-items-center">
                <h1 className="font-size-20 font-size-sm-22 logo-magenta">
                  Address Information
                </h1>
                <i
                  onClick={() => handleClose()}
                  className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
                ></i>
              </div>

              <div className="padding-left-10 padding-right-10 border-1 border-color-light-grey border-radius-8">
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Document Type</label>
                  <Field
                    component={() => (
                      <Select
                        className="form-control padding-0"
                        classNamePrefix="react-select"
                        placeholder="Select Documentation Type"
                        options={user.address_proof_options}
                        value={
                          user.address_proof_options
                            ? user.address_proof_options.find(
                              (option) => option.value === selectedDocumentTypeValue
                            )
                            : ""
                        }
                        onChange={(option) => {
                          setSelectedDocumentTypeValue(option.value);
                          setFieldValue("document_type", option.value);
                        }}
                      />
                    )}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="document_type"
                    component="div"
                  />
                </div>

                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Country</label>
                  <Field
                    component={() => (
                      <Select
                        className="form-control padding-0"
                        classNamePrefix="react-select"
                        placeholder="Select country"
                        options={countries}
                        value={
                          countries
                            ? countries.find(
                              (option) => option.value === values.country
                            )
                            : ""
                        }
                        onChange={(option) => {
                          setFieldValue("country", option.value);
                        }}
                      />
                    )}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="country"
                    component="div"
                  />
                </div>
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">State</label>
                  <Field
                    className="form-control"
                    placeholder="Enter State or Province"
                    name="state"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="state"
                    component="div"
                  />
                </div>
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">City</label>
                  <Field
                    className="form-control"
                    placeholder="Enter City"
                    name="city"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="city"
                    component="div"
                  />
                </div>
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Address</label>
                  <Field
                    className="form-control"
                    placeholder="Enter Street Address"
                    name="street_address"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="street_address"
                    component="div"
                  />
                </div>

                {/* <Geolocation /> */}

                <button
                  className="btn bg-logo-magenta white font-size-16 margin-top-20 margin-bottom-12 padding-10 max-width-180"
                  onClick={handleSubmit}
                  type="submit"
                >
                  <b>Save Document</b>
                </button>
              </div>

            </Form>
          )}
        </Formik>

      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(SubmitAddressProofDocs);
