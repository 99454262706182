import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div>
        <div className="footerlinks">
          <div className="footerlinks_box">
            <div>
              <div className="footerlinks_box_title">Company</div>
              <ul>
                <li>
                  <NavLink to="/about-us">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/blog">Blog</NavLink>
                </li>
                {/* <li>Testimonials</li>
                <li>Careers</li> */}
              </ul>
            </div>
          </div>
          {/* <div className="footerlinks_box">
          <div>
            <div className="footerlinks_box_title">Services</div>
            <ul>
              <li>
                <NavLink to="/money-pool">
                  Cyclic Money Pool
                </NavLink>
              </li>
              <li>
                <NavLink to="/upkeep">
                  Upkeep Money
                </NavLink>
              </li>
              <li>
                <NavLink to="/fundraiser">
                  Fundraiser
                </NavLink>
              </li>
            </ul>
          </div>
        </div> */}
          <div className="footerlinks_box">
            <div>
              <div className="footerlinks_box_title">Legal</div>
              <ul>
                <li>
                  <NavLink to="/terms-and-conditions">
                    Terms and Conditions
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/privacy-policy">
                    Privacy Policy
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="footerlinks_box">
            <div>
              <div className="footerlinks_box_title">Help and Support</div>
              <ul>
                <li>
                  <NavLink to="/faq">FAQ</NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us">Contact Us</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p>
          Copyright © 2022 PouchFunding. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export { Footer as default };
