import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const FundraiserSetupFormHeader = ({ activeSlide, slideNames }) => {
  
  const handGetClassName = (slideName1, slideName2) => {
    if (activeSlide == slideName1 || activeSlide == slideName2) {
      return "active";
    } else if (
      slideNames.indexOf(slideName1) < slideNames.indexOf(activeSlide)
    ) {
      return "done";
    } else {
      return "not-active";
    }
  };

  return (
    <div className="setup-modal-progress">
      <div
        className={`first-page ${handGetClassName(
          "createFundraiserDescription"
        )} 
            ${handGetClassName("editFundraiserDescription")}`}
      >
        <span>1</span>
        <label className="d-block-hidden-sm">Description</label>
      </div>
      <div className={handGetClassName("coverMedia")}>
        <span>2</span>
        <label className="d-block-hidden-sm">Cover Media</label>
      </div>
      <div className={handGetClassName("supportingDocs")}>
        <span>3</span>
        <label className="d-block-hidden-sm">Supporting Docs</label>
      </div>
      <div className={handGetClassName("paymentDetails")}>
        <span>4</span>
        <label className="d-block-hidden-sm">Payment Details</label>
      </div>
      <div className={handGetClassName("fundraiserSummary", "thanks")}>
        <span>5</span>
        <label className="d-block-hidden-sm">Finish</label>
      </div>
    </div>
  );
};

export default connect()(FundraiserSetupFormHeader);
