import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import { connect } from "react-redux";
import { getPrivacyPolicy } from "../../store/actions/blog";
import moment from "moment";

const PrivacyPolicy = ({ dispatch }) => {
  const [privacyPolicy, setPrivacyPolicy] = useState({})
  const [error, setError] = useState("")

  useEffect(() => {
    document.title = "Privacy Policy"

    dispatch(getPrivacyPolicy())
      .then(res => {
        if (res.error) {
          setError(res.error.message)
        } else {
          if (res.success) {
            setPrivacyPolicy(res.data)
          }
        }
      })
  }, [])

  return (
    <div  className="content-container justify-content-center">
      {error ?
        error
        :
        <div>
          <center>
            <h1>
              {privacyPolicy.title}
            </h1>
          </center>
          <br />
          <div>
            Last updated <b>{moment(privacyPolicy.updated_at).format("YYYY-MM-DD")}</b>
          </div>
          <br />
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(privacyPolicy.content) }} />
        </div>
      }
    </div>
  );
};

export default connect()(PrivacyPolicy);

