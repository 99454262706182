import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { getMySales } from "../../../../store/actions/agent";
import { convertSnakeToStandardCasing } from "../../../../utilities"
import FilterSorter from "../../../utilityComponents/FilterSorter";
import NewPagination from "../../../utilityComponents/NewPagination";

const MySales = ({ dispatch }) => {
  const [initialParams, setInitialParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  });
  const [mySalesObj, setMySalesObj] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [initialTotalCount, setInitialTotalCount] = useState(0);

  const handleGetMySales = async (params) => {
    const res = await dispatch(getMySales(params))
    if (res.error) {
      NotificationManager.error(res.error.message, "Error!")
    } else {
      if (res.success == true) {
        setMySalesObj(res.data);
        setInitialParams(res.data.original_query_params)
        return res.data
      }
    }
  };

  useEffect(() => {
    handleGetMySales(initialParams)
      .then(data => {
        if (data) {
          if (data) {
            setInitialTotalCount(data.total_count)
          }
        }
      })
  }, []);

  return (
    <div>
      {
        initialTotalCount > 0 &&
        <div>
          <FilterSorter
            sorterOptions={[
              { label: 'Created Date', value: 'created_at' }
            ]}
            initialSortBy={initialParams.sort_by}
            queryOrder={initialParams.query_order}
            filterOptions={[
              {
                status: [
                  { label: "All", value: '' },
                  { label: 'Pending', value: 'PENDING' },
                  { label: 'Running', value: 'RUNNING' },
                  { label: 'Ended', value: 'ENDED' },
                ]
              }
            ]}
            onSelectQuery={(values) => {
              setIsSearching(true)
              const newQueryObj = {
                ...initialParams,
                ...values
              }
              setInitialParams(newQueryObj)
              handleGetMySales(newQueryObj)
            }}
          />
        </div>}

      {mySalesObj &&
        mySalesObj.sales &&
        mySalesObj.sales.length > 0 ?
        <div>
          {mySalesObj.sales.map((sale, index) => {
            return (
              <div key={index}>
                <div className="padding-top-12 padding-bottom-12">
                  <div className="row box-shadow logo-magenta border-radius-6 padding-top-4 padding-bottom-4 padding-left-8 padding-right-8">
                    <div className="row align-items-center padding-top-10 padding-bottom-10 padding-left-4 padding-right-4">
                      <div className="row align-items-center">
                        <div className="col-sm-4 padding-top-6 padding-bottom-6 padding-4">
                          <label className="grey font-size-13 line-height-13">ID</label>
                          <p className="font-size-14 line-height-14">
                            #{sale.id}
                          </p>
                        </div>
                        <div className="col-sm-8 padding-top-6 padding-bottom-6 padding-4">
                          <label className="grey font-size-13 line-height-13">Customer Name</label>
                          <p className="font-size-14 line-height-14 text-break">
                            {sale.customer_full_name}
                          </p>
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-sm-4 align-items-center padding-top-6 padding-bottom-6 padding-4">
                          <label className="grey font-size-13 line-height-13 text-break">Email</label>
                          <p className="font-size-14 line-height-14 text-break">
                            {sale.customer_email}
                          </p>
                        </div>

                        <div className="col-sm-4 padding-top-6 padding-bottom-6 padding-4">
                          <label className="grey font-size-13 line-height-13">Date</label>
                          <p className="font-size-14 line-height-14">
                            {moment(String(sale.created_at)).format("DD MMM YYYY")}
                          </p>
                        </div>

                        <div className="col-sm-4 padding-top-6 padding-bottom-6 padding-4">
                          <label className="grey font-size-13 line-height-13">Status</label>
                          <p className="font-size-14 line-height-14">
                            {convertSnakeToStandardCasing(sale.status)}
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          {
            (mySalesObj && (mySalesObj.total_count > initialParams.no_of_items_per_page)) ?
              <NewPagination
                {...initialParams}
                handleGetQuery={handleGetMySales}
              /> :
              null
          }
        </div> :
        <center>
          <p className="grey padding-top-30 padding-bottom-10">You don't have any sales {isSearching && "that matches this query"}</p>
        </center>
      }

    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(MySales);
