import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import moment from "moment";
import Truncate from "react-truncate";
import { NotificationManager } from "react-notifications";
import FormattedMoney from "../../../utilityComponents/FormattedMoney";
import { buyerMarkFundraiserOrderAsDelivered, getDonationsIMade, getMyMerchandiseOrders } from "../../../../store/actions/fundraiser"
import MerchandiseOrderDetails from "./components/MerchandiseOrderDetails";
import FilterSorter from "../../../utilityComponents/FilterSorter";
import { convertSnakeToStandardCasing, getConfirmAlert } from "../../../../utilities";
import NewPagination from "../../../utilityComponents/NewPagination";
import CoverMediaThumbnail from "./components/CoverMediaThumbnail";

const MyCommitments = ({
  dispatch,
  history,
  // setIsPageLoading
}) => {
  const [donationsIMadeObj, setDonationsIMadeObj] = useState(null);
  const [merchandiseOrdersObj, setMerchandiseOrdersObj] = useState(null);
  const [donationsQueryParams, setDonationsQueryParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: 'asc',
    sort_by: "created_at",
    total_count: 0
  });
  const [merchandiseOrdersQueryParams, setMerchandiseOrdersQueryParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: 'asc',
    sort_by: "created_at",
    total_count: 0
  });
  const [isSearchingDonations, setIsSearchingDonations] = useState(false);
  const [isSearchingMerchandiseOrders, setIsSearchingMerchandiseOrders] = useState(false);
  const [visibleComponent, setVisibleComponent] = useState("myDonations");
  const [isMerchandiseOrderVisible, setIsMerchandiseOrderVisible] = useState(false);
  const [openedOrderDetails, setOpenedOrderDetails] = useState({});
  const [initialDonationCount, setInitialDonationCount] = useState(0);
  const [initialMerchandiseOrderCount, setInitialMerchandiseOrderCount] = useState(0);

  const handleGetDonationsIMade = async (params) => {
    const res = await dispatch(getDonationsIMade(params))
    if (res.error) {
      NotificationManager.error(res.error.message, "Error!")
    } else {
      if (res.success == true) {
        setDonationsIMadeObj(res.data)
        setDonationsQueryParams(res.data.original_query_params)
        return res.data
      }
    }
  }

  const handleGetMerchandiseOrders = async (params) => {
    const res = await dispatch(getMyMerchandiseOrders(params))
    if (res.error) {
      NotificationManager.error(res.error.message, "Error!")
    } else {
      if (res.success == true) {
        setMerchandiseOrdersObj(res.data)
        setMerchandiseOrdersQueryParams(res.data.original_query_params)
        return res.data
      }
    }
  }

  useEffect(() => {
    handleGetDonationsIMade(donationsQueryParams)
      .then(data => {
        if (data) {
          setInitialDonationCount(data.total_count)
        }
      })

    handleGetMerchandiseOrders(merchandiseOrdersQueryParams)
      .then(data => {
        if (data) {
          setInitialMerchandiseOrderCount(data.total_count)
        }
      })
  }, [])

  return (
    <div className="flex-1">
      <div className="d-flex margin-bottom-20">
        <div
          className={`btn margin-right-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "myDonations" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange logo-magenta"}`}
          onClick={() => setVisibleComponent("myDonations")}>
          My Donations
        </div>

        <div
          className={`btn margin-left-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "merchandiseOrders" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange logo-magenta"}`}
          onClick={() => setVisibleComponent("merchandiseOrders")}>
          My Merchandise Orders
        </div>
      </div>

      {visibleComponent == "myDonations" &&
        <div className="flex-1">
          {initialDonationCount > 0 &&
            <div className="margin-bottom-24">
              <FilterSorter
                sorterOptions={[
                  { label: 'Date', value: 'created_at' },
                ]}
                initialSortBy={donationsQueryParams.sort_by}
                queryOrder={donationsQueryParams.query_order}
                filterOptions={[
                  {
                    status: [
                      { label: "All", value: '' },
                      { label: 'Pending', value: 'PENDING' },
                      { label: 'Cleared', value: 'CLEARED' },
                      { label: 'Declined', value: 'DECLINED' },
                      { label: 'Refunded', value: 'REFUNDED' },
                    ]
                  }
                ]}
                onSelectQuery={(values) => {
                  setIsSearchingDonations(true)
                  const newQueryObj = {
                    ...donationsQueryParams,
                    ...values
                  }
                  handleGetDonationsIMade(newQueryObj)
                }}
              />
            </div>}
          {
            donationsIMadeObj && donationsIMadeObj.donations && donationsIMadeObj.donations.length > 0 ?
              donationsIMadeObj.donations.map((donation, index) => {
                return (
                  <div
                    key={index}
                    className="d-flex cursor-pointer box-shadow margin-bottom-20 border-radius-6 padding-top-6 padding-bottom-6 padding-left-10 padding-right-10"
                    onClick={() => {
                      history.push(`/fundraiser/${donation.fundraiser_slug}`)
                    }}
                  >
                    <div className="min-width-90 max-width-90">
                      <CoverMediaThumbnail
                        isBorderRadiusTopLeft={true}
                        coverMedia={donation.fundraiser_details.cover_media}
                        fundraiserDetails={donation.fundraiser_details}
                      />
                    </div>

                    <div className="logo-magenta stretch-full padding-left-10">
                      <b className="d-block stretch-full padding-top-4 padding-bottom-4">
                        <Truncate lines={2}>
                          {donation.title}
                        </Truncate>
                      </b>

                      <div className="row">
                        <div className="d-flex col-sm-6 padding-top-4 padding-bottom-4">
                          <div className="col-6">
                            <label className="grey font-size-13 line-height-13">ID</label>
                            <div className="d-flex font-size-14 line-height-14">
                              #{donation.id}
                            </div>
                          </div>
                          <div className="col-6">
                            <label className="grey font-size-13 line-height-13">Fundraiser ID</label>
                            <div className="d-flex font-size-14 line-height-14">
                              #{donation.fundraiser_id}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 padding-top-4 padding-bottom-4">
                          <label className="grey font-size-13 line-height-13">Payer's Name</label>
                          <div className="d-flex font-size-14 line-height-14 text-break">
                            <p className="padding-right-6">{donation.first_name}</p>
                            <p>{donation.last_name}</p>
                          </div>
                        </div>

                        <div className="d-flex col-sm-6">
                          <div className="col-6 padding-top-4 padding-bottom-4">
                            <label className="grey font-size-13 line-height-13">Date</label>
                            <p className="font-size-14 line-height-14">
                              {moment(donation.created_at).format("Do MMM YY")}
                            </p>
                          </div>
                          <div className="col-6 padding-top-4 padding-bottom-4">
                            <label className="grey font-size-13 line-height-13">Amount</label>
                            <p className="d-block font-size-14 line-height-14 normal-green">
                              <FormattedMoney value={donation.donation} currency={donation.donation_currency} />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }) :
              <div className="d-flex align-items-center justify-content-center flex-1">
                <p className="grey font-size-15">You do not have any donation {isSearchingDonations ? <span>that matches this query</span> : null} </p>
              </div>
          }

          {
            (donationsIMadeObj && (donationsIMadeObj.total_count > donationsQueryParams.no_of_items_per_page)) ?
              <NewPagination
                {...donationsQueryParams}
                handleGetQuery={handleGetDonationsIMade}
              /> :
              null
          }
        </div>
      }

      {
        visibleComponent == "merchandiseOrders" &&
        <div className="flex-1">
          {initialMerchandiseOrderCount > 0 &&
            <div className="margin-bottom-24">
              <FilterSorter
                sorterOptions={[
                  { label: 'Date', value: 'created_at' },
                ]}
                initialSortBy={merchandiseOrdersQueryParams.sort_by}
                queryOrder={merchandiseOrdersQueryParams.query_order}
                filterOptions={[
                  {
                    status: [
                      { label: "All", value: '' },
                      { label: 'Pending', value: 'PENDING' },
                      { label: 'Fulfilled', value: 'FULFILLED' },
                      { label: 'Cancelled', value: 'CANCELLED' }
                    ]
                  }
                ]}
                onSelectQuery={(values) => {
                  setIsSearchingMerchandiseOrders(true)
                  const newQueryObj = {
                    ...merchandiseOrdersQueryParams,
                    ...values
                  }
                  handleGetMerchandiseOrders(newQueryObj)
                }}
              />
            </div>}

          {
            merchandiseOrdersObj && merchandiseOrdersObj.orders && merchandiseOrdersObj.orders.length ?
              merchandiseOrdersObj.orders.map((order, index) => {
                return (
                  <div
                    key={index}
                    className="d-flex box-shadow margin-bottom-20 border-radius-6 padding-top-6 padding-bottom-6 padding-left-10 padding-right-10"
                    onClick={() => {
                      // handleGetPublicFundraiser(donation.fundraiser_slug)
                    }}
                  >

                    <div className="min-width-90 max-width-90">
                      <CoverMediaThumbnail
                        isBorderRadiusTopLeft={true}
                        coverMedia={order.fundraiser_details.cover_media}
                        fundraiserDetails={order.fundraiser_details}
                      />
                    </div>

                    <div className="logo-magenta stretch-full padding-left-10">
                      <b className="d-block stretch-full padding-top-4 padding-bottom-4" >
                        <Truncate lines={2}>
                          {order.fundraiser_title}
                        </Truncate>
                      </b>

                      <div className="row">
                        <div className="d-flex col-sm-4 padding-top-4 padding-bottom-4">
                          <div className="col-6">
                            <label className="grey font-size-13 line-height-13">Order ID</label>
                            <p className="font-size-14 line-height-14">
                              # {order.id}
                            </p>
                          </div>
                          <div className="col-6">
                            <label className="grey font-size-13 line-height-13">Fundraiser ID</label>
                            <p className="font-size-14 line-height-14">
                              # {order.fundraiser_id}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex col-sm-4">
                          <div className="col-6 padding-top-4 padding-bottom-4">
                            <label className="grey font-size-13 line-height-13"> Status</label>
                            <p className="font-size-14 line-height-14">
                              {convertSnakeToStandardCasing(order.status)}
                            </p>
                          </div>

                          <div className="col-6 padding-top-4 padding-bottom-4">
                            <label className="grey font-size-13 line-height-13">Total Amount</label>
                            <p className="d-block font-size-14 line-height-14 normal-green">
                              <FormattedMoney value={order.total_amount} currency={order.total_amount_currency} />
                            </p>
                          </div>
                        </div>

                        <div className="d-flex col-sm-4">
                          <div className=" padding-top-4 padding-bottom-4">
                            <label className="grey font-size-13 line-height-13">Date</label>
                            <p className="font-size-14 line-height-14">
                              {moment(order.created_at).format("Do MMM YY, HH:mm:a")}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex col-sm-4 align-items-center padding-top-4 padding-bottom-4">
                          <div className="d-flex max-width-280 align-items-center justify-content-between">
                            <button
                              className="btn font-size-12 font-size-md-13 bg-blue max-width-120 margin-right-4 padding-0 padding-top-6 padding-bottom-6"
                              type="button"
                              onClick={() => {
                                setOpenedOrderDetails(order)
                                setIsMerchandiseOrderVisible(true)
                              }}
                            >
                              Order Details
                            </button>
                            <button
                              className="btn font-size-12 font-size-md-13 bg-fundraiser-color max-width-120 margin-left-4 padding-0 padding-top-6 padding-bottom-6"
                              type="button"
                              onClick={() => {
                                history.push("/fundraiser/" + order.fundraiser_slug)
                              }}
                            >
                              To Fundraiser
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              :
              <div className="d-flex align-items-center justify-content-center flex-1">
                <p className="grey font-size-15">You do not have any merchandise order {isSearchingMerchandiseOrders ? <span>that matches this query</span> : null} </p>
              </div>
          }
          {
            (merchandiseOrdersObj && (merchandiseOrdersObj.total_count > merchandiseOrdersQueryParams.no_of_items_per_page)) ?
              <NewPagination
                {...merchandiseOrdersQueryParams}
                handleGetQuery={handleGetMerchandiseOrders}
              /> :
              null
          }
        </div>
      }

      <Modal
        ariaHideApp={false}
        isOpen={isMerchandiseOrderVisible}
        onRequestClose={() => {
          setIsMerchandiseOrderVisible(false);
        }}
        className="modal max-width-960 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Merchandise Order Details
          </h1>
          <i
            onClick={() => setIsMerchandiseOrderVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-8 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
        >
          {
            (openedOrderDetails.status == "PENDING" || openedOrderDetails.status == "FULFILLED") &&
            <div className="d-flex align-items-center margin-bottom-10">
              <button
                className="btn bg-orange-red padding-8 max-width-270 margin-right-20 font-size-15"
                type="button"
                onClick={() => {
                  getConfirmAlert({
                    title: "Confirm!",
                    message: `Are you sure you want to mark this order as delivered? You won't be able to reverse it`,
                    onClickYes: () => {
                      dispatch(buyerMarkFundraiserOrderAsDelivered({
                        order_id: openedOrderDetails.id
                      }))
                        .then(res => {
                          if (res.error) {
                            NotificationManager.error(res.error.message, "Error!")
                          } else {
                            if (res.success == true) {
                              NotificationManager.success(res.message, "Success!")
                              setOpenedOrderDetails(res.data)
                              handleGetMerchandiseOrders(merchandiseOrdersQueryParams)
                            }
                          }
                        })
                    },
                    onClickNo: () => null
                  })
                }}
              >
                Mark Order as Delivered
              </button>
            </div>
          }
          <MerchandiseOrderDetails
            orderDetails={openedOrderDetails}
          />
        </div>
      </Modal>
    </div>
  );
};


export default connect()(MyCommitments);
