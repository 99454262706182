import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import LoadingPage from "../../../utilityComponents/LoadingPage";
import PreviewFundraiser from "./components/PreviewFundraiser";
import { getFundraiserByPublic } from "../../../../store/actions/fundraiser";

const ViewFundraiser = ({
  history,
  dispatch,
  match
}) => {
  const fundraiserSlug = match.params.fundraiserSlug;

  const [isPageLoading, setIsPageLoading] = useState([false]);
  const [fundraiser, setFundraiser] = useState();
  const [error, setError] = useState("");

  useEffect(() => {
    setIsPageLoading([true, "Loading..."])

    dispatch(getFundraiserByPublic(fundraiserSlug))
      .then((res) => {
        setIsPageLoading([false])
        if (res.error) {
          setError(res.error.message)
        } else {
          if (res.success == true) {
            document.title = res.data.fundraiser_details.title;
            setFundraiser(res.data);
          }
        }
      });
  }, [
    // fundraiserId
  ]);

  return (
    <div className="content-container">
      <div className="d-flex justify-content-center padding-top-10 padding-top-md-15">
        <LoadingPage isPageLoading={isPageLoading} />
        <div className="max-width-1200">
          <div>
            {error ?
              <div className="fixed-height-400" >
                <center >
                  <div className="margin-bottom-50 margin-top-50">
                    <b className="red font-size-16" >{error} </b>
                  </div>

                  <button
                    className="btn padding-8 bg-grad-blue-lightblue white min-width-250 font-size-16"
                    type="button"
                    onClick={() => {
                      history.push("/browse-fundraisers")
                    }}
                  >Browse Fundraisers</button>
                </center>
              </div> :
              <div>
                {fundraiser &&
                  fundraiser.fundraiser_details &&
                  fundraiser.fundraiser_details.slug == fundraiserSlug ? (
                  <PreviewFundraiser
                    history={history}
                    isSetupPreview={false}
                    fundraiser={fundraiser}
                  />
                ) : null}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(ViewFundraiser);
