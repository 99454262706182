import React from "react";
import { connect } from "react-redux";
import { NotificationManager } from 'react-notifications';
import { requestUserAccountDeletion, reverseUserAccountDeletion } from "../../../../store/actions/auth";
import { getConfirmAlert } from "../../../../utilities";

const AccountDeletionRequest = ({
  user,
  dispatch,
  handleClose,
  setIsPageLoading
}) => {

  const handleRequestDeleteAccount = () => {
    setIsPageLoading([true, "Loading..."])
    dispatch(requestUserAccountDeletion())
      .then(res => {
        if (res.error) {
          NotificationManager.error(res.error.message, "Error!")
        } else {
          if (res.success == true) {
            NotificationManager.success(res.message, "Success!")
          }
        }
      })
      .finally(() => {
        setIsPageLoading([false])
      })
  }


  const handleReverseAccountDeletion = () => {
    setIsPageLoading([true, "Loading..."])
    dispatch(reverseUserAccountDeletion())
      .then(res => {
        if (res.error) {
          NotificationManager.error(res.error.message, "Error!")
        } else {
          if (res.success == true) {
            NotificationManager.success(res.message, "Success!")
          }
        }
      })
      .finally(() => {
        setIsPageLoading([false])
      })
  }

  return (
    <div>
      <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 justify-content-between 
      align-items-center">
        <h1 className="font-size-20 font-size-md-22 dark-blue">
          Account Deletion Request for PouchFunding
        </h1>
        <i onClick={() => handleClose()}
          className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"></i>
      </div>

      <div className="horizontal-line margin-0" />
      <div className="padding-10 padding-sm-20">
        <p>If you wish to delete your account with PouchFunding, please click the button below or contact us at
          support@pouchfunding.com. Deleting your account will remove your personal data and account information from our systems
          after a period of 2 weeks from the day of the request, 2 weeks after the last fundraiser you created.</p>
        <br />
        <h2>Data Deletion Information:</h2>

        <ul>
          <li>
            <b>Deleted Data:</b> Upon deletion, your account information, personal details, and usage data will be permanently removed
            from our servers.
          </li>
          <li>
            <b>Data Retention:</b> Some data may be retained for up to 5 years as required by law or for legitimate business purposes.
          </li>
        </ul>
        {
          user.user_account_deletion_request ?
            <div className="d-flex justify-content-between align-items-center">
              <span className="red">You have requested for your account to be deleted.</span>
              <button className="btn bg-green white font-size-16 margin-top-20 margin-bottom-20 padding-10 max-width-250"
                onClick={() => {
                  handleReverseAccountDeletion()
                }}>
                <b>Reverse Account Deletion</b>
              </button>
            </div> :
            <div className="d-flex justify-content-end">
              <button className="btn bg-red white font-size-16 margin-top-20 margin-bottom-20 padding-10 max-width-250"
                onClick={() => {
                  getConfirmAlert({
                    title: "Confirm!",
                    message: "Are you sure you want to delete your user account with us? Once deleted, it cannot be reversed!",
                    onClickYes: () => {
                      handleRequestDeleteAccount()
                    },
                    onClickNo: () => null,
                    variant: "danger"
                  })
                }}>
                <b>Request Account Deletion</b>
              </button>
            </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(AccountDeletionRequest);
