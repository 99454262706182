import React, { useState, Fragment, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { getConfirmAlert } from "../../../../../utilities";

const ManagerBankDetailsTable = ({
  dispatch,
  setIsPageLoading,
  fundraiser,
  setFundraiser,
  editFundraiserDetails,
  setIsSelectSavedBankVisible,
  canEdit = true
}) => {
  return (
    <div>
      {fundraiser ?
        <div>
          <div className="padding-left-md-0 padding-right-md-50 padding-top-8 padding-bottom-8">
            <h3 className="logo-magenta font-size-17 font-size-sm-18">Your Bank Account Details</h3>
            <span className="red" >Ensure that this account belongs to you</span>
          </div>
          <table>
            <tbody>
              <tr>
                <td>Bank Account Name</td>
                <td><b>{fundraiser.bank_account_details.bank_account_name}</b></td>
              </tr>
              <tr>
                <td>Bank Name</td>
                <td><b>{fundraiser.bank_account_details.bank_name}</b></td>
              </tr>
              <tr>
                <td>Bank Account Number</td>
                <td><b>{fundraiser.bank_account_details.bank_account_number}</b></td>
              </tr>
            </tbody>
          </table>

          {canEdit &&
            <div className="d-flex justify-content-between align-items-center">
              <div
                className="btn bg-orange white padding-top-4 padding-bottom-4 padding-left-10 padding-right-10"
                onClick={() => {
                  setIsSelectSavedBankVisible(true);
                }}>
                Change Details
              </div>
              <div
                className="btn bg-red white padding-top-4 padding-bottom-4 padding-left-10 padding-right-10"
                onClick={() => {
                  getConfirmAlert({
                    title: "Confirm delete",
                    message:
                      "Are you sure you want to delete this bank account details?",
                    onClickYes: () => {
                      setIsPageLoading([true, "Loading..."]);

                      const fundraiserDetails = {
                        ...fundraiser.fundraiser_details,
                        bank_account_detail_id: null
                      }
                      dispatch(editFundraiserDetails(fundraiserDetails))
                        .then((response) => {
                          setIsPageLoading([false]);
                          if (response.error) {
                            NotificationManager.error(response.error.message, "Error!")
                          } else {
                            if (response.success == true) {
                              setFundraiser(response.data);
                            }
                          }
                        });
                    },
                    onClickNo: () => { },
                  });
                }}>
                Remove Details
              </div>
            </div>}
        </div> : null}

    </div>
  );
};

export default connect()(ManagerBankDetailsTable);
