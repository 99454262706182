import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import MyRegisteredNGOs from "./MyRegisteredNGOs";
import CharityNGOSetupSlides from "./CharityNGOSetupSlides";
import { deleteUploadedNGODocument, getCharityNGO, createOrUpdateNGO, uploadNgoDocument } from "../../../../store/actions/charity_ngo";

const MyCharityNGO = ({
  history,
  dispatch,
  setIsPageLoading
}) => {

  const [isRegisterCharityNGOVisible, setIsRegisterCharityNGOVisible] = useState(
    false
  );
  const [isMyRegisteredNGOs, setIsMyRegisteredNGOs] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(true);
  const [charityNgo, setCharityNgo] = useState(null)
  const [
    visibleCharityNgoSetupSlides,
    setVisibleCharityNgoSetupSlides,
  ] = useState(null);
  const [ngoToEditId] = useState(new URLSearchParams(history.location.search).get('editNgoId'))

  const handleSubmitCharityNgo = (values) => {
    setIsPageLoading([true, "Uploading..."]);

    const formData = new FormData();

    if (!isCreatingNew && values.id) {
      // Editing
      formData.append("charity_ngo_id", values.id);
    }

    if (values.logo_file) {
      formData.append("logo_file", values.logo_file);
    }

    // formData.append("certificate_file", values.certificate_file);
    formData.append("organization_name", values.organization_name);
    formData.append("registration_number", values.registration_number);
    formData.append("country", values.country);
    formData.append("state", values.state);
    formData.append("city", values.city);
    formData.append("street_address", values.street_address);
    formData.append("phone_number", values.phone_number);

    dispatch(createOrUpdateNGO(formData))
      .then((response) => {

        setIsPageLoading([false]);

        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            setCharityNgo(response.data)
            setIsCreatingNew(false)
            setVisibleCharityNgoSetupSlides("DIRECTORS")
          }
        }
      });
  };

  const handleUploadNgoDocument = async (ngoId, file, documentType) => {
    setIsPageLoading([true, "Uploading..."]);

    const formData = new FormData();
    formData.append("charity_ngo_id", ngoId);
    formData.append("file", file);
    formData.append("document_type", documentType);

    const response = await dispatch(uploadNgoDocument(formData))

    setIsPageLoading([false]);

    if (response.error) {
      NotificationManager.error(response.error.message, "Error!")
    } else {
      if (response.success == true) {
        NotificationManager.success(response.message, "Success!")
        setCharityNgo(response.data)
      }
    }

    return response
  };

  // Get an NGO
  const handleGetNGO = async (ngoId) => {
    setIsPageLoading([true, "Uploading..."]);

    const response = await dispatch(getCharityNGO(ngoId))

    setIsPageLoading([false]);

    if (response.error) {
      NotificationManager.error(response.error.message, "Error!")
    } else {
      if (response.success == true) {
        setCharityNgo(response.data)
      }
    }

    return response
  }

  // About to edit an NGO
  const handleAboutToEditNGO = async (ngoId) => {
    const r = await handleGetNGO(ngoId)
    if (r.success) {
      setIsCreatingNew(false)
      setVisibleCharityNgoSetupSlides("EDIT_DETAILS")
      setIsRegisterCharityNGOVisible(true)
      return r
    } else {
      NotificationManager.error(r.error.message, "Error!")
    }
  }

  // Delete an uploaded NGO document
  const handleDeleteDocument = async (data) => {
    setIsPageLoading([true, "Uploading..."]);

    const response = await dispatch(deleteUploadedNGODocument(data))

    setIsPageLoading([false]);

    if (response.error) {
      NotificationManager.error(response.error.message, "Error!")
    } else {
      if (response.success == true) {
        NotificationManager.success(response.message, "Success!")
        setCharityNgo(response.data)
      }
    }

    return response
  }

  const handleCloseSetupSlides = () => {
    setIsRegisterCharityNGOVisible(false)

    if (ngoToEditId) {
      history.push("/dashboard/account")
    }
  }

  const handleCloseMyRegisteredNGOs = () => {
    setIsMyRegisteredNGOs(false)

    if (ngoToEditId) {
      history.push("/dashboard/account")
    }
  }

  useEffect(() => {
    if (ngoToEditId) {
      handleAboutToEditNGO(ngoToEditId)
    }
  }, [])

  return (
    <div>
      <div>
        <div className="padding-bottom-18">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full logo-magenta padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => {
              setIsCreatingNew(true)
              setVisibleCharityNgoSetupSlides("DETAILS")
              setIsRegisterCharityNGOVisible(true)
            }}
          >
            <i
              className="fa fa-registered font-size-22 fixed-width-20 margin-right-20 margin-right-sm-24"
            ></i>
            <span className="font-size-14 font-size-sm-17">
              Register A Charity NGO
            </span>
          </div>
        </div>

        <div className="padding-top-18 padding-bottom-10">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full logo-magenta padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsMyRegisteredNGOs(true)}
          >
            <i
              className="fas fa-ribbon font-size-22 fixed-width-20 margin-right-20 margin-right-sm-24"
            ></i>
            <span className="font-size-14 font-size-sm-17">My Registered NGOs</span>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isRegisterCharityNGOVisible}
        onRequestClose={() => handleCloseSetupSlides()}
        className="modal max-width-850 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Register A Charity NGO
          </h1>
          <i
            onClick={() => handleCloseSetupSlides()}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="overflow-scroll-y setup-modal padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
          style={{ maxHeight: "80vh" }}>
          <CharityNGOSetupSlides
            isCreatingNew={isCreatingNew}
            setIsPageLoading={setIsPageLoading}
            setIsCreatingNew={setIsCreatingNew}
            setCharityNgo={setCharityNgo}
            charityNgo={charityNgo}
            setVisibleCharityNgoSetupSlides={setVisibleCharityNgoSetupSlides}
            handleSubmitCharityNgo={handleSubmitCharityNgo}
            handleUploadNgoDocument={handleUploadNgoDocument}
            handleDeleteDocument={handleDeleteDocument}
            visibleCharityNgoSetupSlides={visibleCharityNgoSetupSlides}
            history={history}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isMyRegisteredNGOs}
        onRequestClose={() => handleCloseMyRegisteredNGOs()}
        className="modal max-width-1000 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            My Registered NGOs
          </h1>
          <i
            onClick={() => handleCloseMyRegisteredNGOs()}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div className="overflow-scroll-y padding-10 padding-top-sm-20 padding-bottom-sm-40 padding-left-md-40 padding-right-md-40"
          style={{ minHeight: "60vh", maxHeight: "80vh" }}>
          <MyRegisteredNGOs
            setIsPageLoading={setIsPageLoading}
            setIsRegisterCharityNGOVisible={setIsRegisterCharityNGOVisible}
            setIsCreatingNew={setIsCreatingNew}
            setVisibleCharityNgoSetupSlides={setVisibleCharityNgoSetupSlides}
            handleAboutToEditNGO={handleAboutToEditNGO}
            handleClose={() => {
              handleCloseMyRegisteredNGOs()
            }}
            handleUploadNgoDocument={handleUploadNgoDocument}
          />
        </div>
      </Modal>
    </div>
  );
};

export default connect()(MyCharityNGO);
