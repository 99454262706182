import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import countryList from "react-select-country-list";
import { connect } from "react-redux";
import Modal from "react-modal";
import moment from "moment";
import { NotificationManager } from 'react-notifications';
import { base64ToBlob, getConfirmAlert } from "../../../../utilities";
import AddOrTakePhotoModal from "../../../services/fundraiser/setup/fragments/AddOrTakePhotoModal";
import PhotoCameraModal from "../../../services/fundraiser/setup/fragments/PhotoCameraModal";
import ImageEditor from "../../../services/fundraiser/setup/fragments/ImageEditor";
import {
  getUser,
  updateUser,
  uploadProfilePicture,
  deleteProfilePicture
} from "../../../../store/actions/auth";
import PhoneInputField from "../../../utilityComponents/PhoneInputField"
import SendPhoneVerificationOtp from "../../../utilityComponents/SendPhoneVerificationOtp"
import VerifyPhoneNumber from "../../../utilityComponents/VerifyPhoneNumber"
import DatePickerField from "../../../utilityComponents/DatePickerField";
import BVNVerification from "../../../authForms/BVNVerification";

const ProfileSettings = ({
  user,
  setIsPageLoading,
  dispatch,
  handleClose
}) => {
  const [changePhoto, setChangePhoto] = useState(null);
  const [isAddOrTakePhotoModalOpen, setIsAddOrTakePhotoModalOpen] = useState(
    false
  );
  const [isPhotoCameraModalVisible, setIsPhotoCameraModalVisible] = useState(
    false
  );
  const [isImageEditorVisible, setIsImageEditorVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [toEditPhoneNumber, setToEditPhoneNumber] = useState(false);
  const [isSendPhoneOtpVisible, setIsSendPhoneOtpVisible] = useState(false);
  const [isVerifyPhoneNumberVisible, setIsVerifyPhoneNumberVisible] = useState(false);
  const [isBVNSettingsVisible, setIsBVNSettingsVisible] = useState(false);
  const [genderOptions] = useState([
    { label: "Male", value: "MALE" },
    { label: "Female", value: "FEMALE" }
  ]);

  const profileImage = useRef(null);

  const onClickRetakePhoto = () => {
    setIsImageEditorVisible(false);
    setIsAddOrTakePhotoModalOpen(true);
  };

  const onClickTakePhoto = () => {
    setIsAddOrTakePhotoModalOpen(false);
    setIsPhotoCameraModalVisible(true);
  };

  const onClickUploadPhoto = () => {
    setIsAddOrTakePhotoModalOpen(false);
    profileImage.current.click();
  };

  const handleTakePhoto = (dataUri) => {
    setIsPhotoCameraModalVisible(false); //To close photo camera aperture
    setChangePhoto(dataUri); //For the image to be edited
    setIsImageEditorVisible(true); //Show editable picture preview
  };

  const handleSaveImage = (croppedImage) => {
    setIsPageLoading([true, "Uploading..."]);
    const convertBase64ToBlob = base64ToBlob(croppedImage);
    const fileType = `${convertBase64ToBlob.type.split("/")[0]}.${convertBase64ToBlob.type.split("/")[1]
      }`;
    let formData = new FormData(); //formdata object
    formData.append("file", convertBase64ToBlob, fileType)

    dispatch(uploadProfilePicture(formData))
      .then((response) => {
        setIsPageLoading([false]);
        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            NotificationManager.success(response.data.message, "Success!")
            setIsImageEditorVisible(false);
            dispatch(getUser());
          }
        }
      });
  };

  useEffect(() => {
    // Get country list
    const options = countryList().getData();
    setCountries(options);

    // Get currency list
    const currencies = user.supported_currencies.map((cur) => {
      return {
        label: cur,
        value: cur,
      };
    })
    setCurrencies(currencies);

    dispatch(getUser())
  }, []);

  return (
    <div>
      <Formik
        initialValues={user}

        validationSchema={Yup.object({
          first_name: Yup.string().required("Frst name is required"),
          last_name: Yup.string().required("last name is required"),
          email: Yup.string().required("Email is required"),
          phone_number: Yup.string().required("Phone number is required"),
          city: Yup.string().required("City is required"),
          state: Yup.string().required("State is required"),
          country: Yup.string().required("Country is required"),
          street_address: Yup.string().required("Street address is required"),
        })}

        onSubmit={(values, props) => {
          dispatch(updateUser(values))
            .then((res) => {
              props.setSubmitting(false);
              if (res.error) {
                NotificationManager.error(res.error.message, 'Error message')
              } else {
                const user = res.data
                props.setFieldValue("is_phone_verified", user.is_phone_verified)
                setToEditPhoneNumber(false)
                NotificationManager.success('Success!', 'Your profile has been updated!')
              }
            })
        }}
      >
        {({
          setFieldValue,
          handleSubmit,
          values,
        }) => (
          <Form>
            <div className="row margin-bottom-10">
              <div
                className="d-flex justify-content-center col-sm-3"
                onClick={() => {
                  setIsAddOrTakePhotoModalOpen(true);
                }}
              >
                {user.profile_picture ? (
                  <img
                    className="d-block bg-lighter-blue fixed-width-160 fixed-height-160 rounded-circle"
                    src={`${user.profile_picture}?${Date.now()}`}
                  />
                ) : (
                  <i
                    className="fa fa-user-circle lighter-blue"
                    style={{ fontSize: "150px" }}
                  ></i>
                )}
              </div>
              <div className="col-sm-9 margin-top-20 margin-top-sm-0 d-flex flex-column justify-content-around padding-left-0 padding-left-sm-30">
                <div className="d-flex">
                  <div
                    className="btn max-width-200 stretch-full-sm font-size-13 font-size-md-15 bg-logo-magenta padding-6 white"
                    onClick={() => {
                      setIsAddOrTakePhotoModalOpen(true);
                    }}
                  >
                    <b>
                      {user.profile_picture
                        ? "Upload New Photo"
                        : "Take or Upload a Photo"}
                    </b>
                  </div>
                  {user.profile_picture ? (
                    <div
                      className="btn max-width-200 stretch-full-sm font-size-13 font-size-md-15 bg-white padding-6 red border-color-red margin-left-10 margin-left-sm-30"
                      onClick={() => {
                        getConfirmAlert({
                          title: "Confirm Delete Photo",
                          message:
                            "Are you sure you want to delete this photo?",
                          onClickYes: () => {
                            setIsPageLoading([true, "Removing..."]);
                            dispatch(deleteProfilePicture()).then(
                              (response) => {
                                setIsPageLoading([false]);
                                if (response.error) {
                                  NotificationManager.error(response.error.message, "Error!")
                                } else {
                                  dispatch(getUser());
                                  NotificationManager.success(response.message, "Success!")
                                }
                              }
                            );
                          },
                          onClickNo: () => { },
                        });
                      }}
                    >
                      <b>Remove</b>
                    </div>
                  ) : null}
                </div>

                <div className="grey font-size-14 font-size-sm-16 margin-top-10 margin-top-sm-0">
                  <p> You can upload gif, jpg, jpeg or png image files. Max size of 10MB.</p>
                </div>
                <input
                  hidden
                  ref={profileImage}
                  name="coverMedia"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.currentTarget.files[0];
                    if (file) {
                      if (file.size > 10000000) {
                        //Maximum of 10MB
                        alert("File too large. Max. 10MB");
                      } else {
                        let reader = new FileReader();
                        reader.onloadend = () => {
                          setChangePhoto(reader.result);
                          setIsImageEditorVisible(true);
                        };
                        reader.readAsDataURL(file);
                      }
                    }
                  }}
                />
              </div>
            </div>

            {isImageEditorVisible ? (
              <div>
                <ImageEditor
                  src={changePhoto}
                  // addFundraiserCoverMedia={addFundraiserCoverMedia}
                  handleSaveImage={handleSaveImage}
                  onClickRetakePhoto={onClickRetakePhoto}
                  aspectRatio={1}
                  setIsPageLoading={setIsPageLoading}
                />
              </div>
            ) : null}

            {false &&
              <div className="padding-top-10 padding-bottom-10">
                <b className="padding-right-10">
                  Verify your profile with BVN so we can know you better
                </b>
                <button
                  className="btn bg-logo-magenta"
                  type="button"
                  onClick={() => {
                    setIsBVNSettingsVisible(true)
                  }}
                >
                  Verify
                </button>
              </div>}

            <div className="row">
              <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">First Name</label>
                <Field
                  className="form-control"
                  placeholder="Enter first name"
                  name="first_name"
                  disabled={user.is_bvn_verified}
                />
                <ErrorMessage
                  className="error-message"
                  name="first_name"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">Last Name</label>
                <Field
                  className="form-control"
                  placeholder="Enter last name"
                  name="last_name"
                  disabled={user.is_bvn_verified}
                />
                <ErrorMessage
                  className="error-message"
                  name="last_name"
                  component="div"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">Email</label>
                <Field
                  className="form-control"
                  placeholder="Enter email"
                  name="email"
                  disabled={true}
                />
                <ErrorMessage
                  className="error-message"
                  name="email"
                  component="div"
                />
              </div>
              <div
                className="col-sm-6 padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                <div className="d-flex align-items-center justify-content-between">
                  <label className="form-label">
                    Phone Number {values.is_phone_verified ?
                      <span className="normal-green">(Verified)</span>
                      :
                      <span className="red">(Not Verified) </span>
                    }
                  </label>

                  {values.is_phone_verified ?
                    <div className="max-width-100">
                      {values.is_phone_verified && !toEditPhoneNumber ?
                        <button
                          className="max-width-100 border-radius-6 font-size-12 padding-6 margin-bottom-4"
                          type="button"
                          onClick={() => setToEditPhoneNumber(true)}
                        >
                          Edit
                        </button>
                        :
                        <span>
                          {toEditPhoneNumber &&
                            <button
                              className="max-width-100 bg-orange-red border-radius-6 font-size-12 padding-6 margin-bottom-4"
                              type="button"
                              onClick={() => {
                                dispatch(getUser())
                                  .then((user) => {
                                    setFieldValue("phone_number", user.phone_number)
                                    setToEditPhoneNumber(false)
                                  })
                              }}
                            >
                              Cancel Edit
                            </button>
                          }
                        </span>
                      }
                    </div> :
                    <button
                      className="max-width-100 border-radius-6 font-size-12 padding-6 margin-bottom-4"
                      type="button"
                      onClick={() => {
                        if (values.phone_number != user.phone_number) {
                          handleSubmit()
                        }
                        setIsSendPhoneOtpVisible(true)
                      }}
                    >
                      Verify
                    </button>
                  }
                </div>

                <div className="form-control padding-4 padding-left-10 padding-right-10">
                  <PhoneInputField
                    placeholderText="Enter phone number"
                    value={values.phone_number}
                    onChange={setFieldValue}
                    name="phone_number"
                    disabled={values.is_phone_verified && !toEditPhoneNumber}
                  />
                </div>
                <ErrorMessage
                  className="error-message"
                  name="phone_number"
                  component="div"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 padding-top-10 padding-bottom-10">
                <label className="form-label">Company</label>
                <Field
                  className="form-control"
                  placeholder="Enter company name"
                  name="company_name"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">State or Province</label>
                <Field
                  className="form-control"
                  placeholder="Enter state"
                  name="state"
                />
                <ErrorMessage
                  className="error-message"
                  name="state"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">Country</label>
                <Field
                  component={() => (
                    <Select
                      className="form-control padding-0"
                      classNamePrefix="react-select"
                      placeholder="Select country"
                      options={countries}
                      value={
                        countries
                          ? countries.find(
                            (option) => option.value === values.country
                          )
                          : ""
                      }
                      onChange={(option) => {
                        setFieldValue("country", option.value);
                      }}
                      isDisabled={user.is_bvn_verified}
                    />
                  )}
                />
                <ErrorMessage
                  className="error-message"
                  name="country"
                  component="div"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">Address</label>
                <Field
                  className="form-control"
                  placeholder="Enter Street Address"
                  name="street_address"
                />
                <ErrorMessage
                  className="error-message"
                  name="street_address"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">City</label>
                <Field
                  className="form-control"
                  placeholder="Enter city"
                  name="city"
                />
                <ErrorMessage
                  className="error-message"
                  name="city"
                  component="div"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">Gender</label>
                <Field
                  component={() => (
                    <Select
                      className="form-control padding-0"
                      classNamePrefix="react-select"
                      placeholder="Select your gender"
                      options={genderOptions}
                      value={
                        genderOptions
                          ? genderOptions.find(
                            (option) => option.value === values.gender
                          )
                          : ""
                      }
                      onChange={(option) => {
                        setFieldValue("gender", option.value);
                      }}
                      disabled={user.is_bvn_verified}
                    />
                  )}
                />
                <ErrorMessage
                  className="error-message"
                  name="gender"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">Date of Birth</label>
                <DatePickerField
                  name="date_of_birth"
                  value={values.date_of_birth}
                  maxDate={new Date(moment().subtract(18, 'years'))}
                  placeholderText="Select your date of birth"
                  dateFormat="MMMM d, yyyy"
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={110}
                  scrollableYearDropdown
                  onChange={(event, selectedDate) => {
                    const currentDate = selectedDate;
                    setFieldValue("date_of_birth", currentDate);
                  }}
                  disabled={user.is_bvn_verified}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">Preferred Currency</label>
                <Field
                  component={() => (
                    <Select
                      className="form-control padding-0"
                      classNamePrefix="react-select"
                      placeholder="Select preferred currency"
                      options={currencies}
                      value={
                        currencies
                          ? currencies.find(
                            (option) =>
                              option.value === values.preferred_currency
                          )
                          : ""
                      }
                      onChange={(option) => {
                        setFieldValue("preferred_currency", option.value);
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  className="error-message"
                  name="preferred_currency"
                  component="div"
                />
              </div>
            </div>

            <button
              className="btn bg-logo-magenta white font-size-16 margin-top-20 margin-bottom-12 padding-10 max-width-180"
              onClick={handleSubmit}
            >
              <b>Save Changes</b>
            </button>
          </Form>
        )}
      </Formik>

      <Modal
        ariaHideApp={false}
        isOpen={isPhotoCameraModalVisible}
        onRequestClose={() => setIsPhotoCameraModalVisible(false)}
        className="modal black-background"
        overlayClassName="modal-backdrop"
        contentLabel="Take a picture"
      >
        <PhotoCameraModal
          handleTakePhoto={handleTakePhoto}
        />
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isAddOrTakePhotoModalOpen}
        onRequestClose={() => setIsAddOrTakePhotoModalOpen(false)}
        className="modal"
        overlayClassName="modal-backdrop"
      >
        <AddOrTakePhotoModal
          onClickTakePhoto={onClickTakePhoto}
          onClickUploadPhoto={onClickUploadPhoto}
        />
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isSendPhoneOtpVisible}
        onRequestClose={() => setIsSendPhoneOtpVisible(false)}
        className="modal max-width-550 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Send Phone Verification Code
          </h1>
          <i
            onClick={() => setIsSendPhoneOtpVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <SendPhoneVerificationOtp
            handleClose={() => setIsSendPhoneOtpVisible(false)}
            setIsVerifyPhoneNumberVisible={setIsVerifyPhoneNumberVisible}
            setIsPageLoading={setIsPageLoading}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isVerifyPhoneNumberVisible}
        onRequestClose={() => setIsVerifyPhoneNumberVisible(false)}
        className="modal max-width-550 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Verify Phone Number
          </h1>
          <i
            onClick={() => setIsVerifyPhoneNumberVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <VerifyPhoneNumber
            handleClose={() => setIsVerifyPhoneNumberVisible(false)}
            setIsPageLoading={setIsPageLoading}
            handleCloseProfileSettings={() => handleClose()}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isBVNSettingsVisible}
        onRequestClose={() => setIsBVNSettingsVisible(false)}
        className="modal max-width-550 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Verify BVN
          </h1>
          <i
            onClick={() => setIsBVNSettingsVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <BVNVerification
            handleClose={() => setIsBVNSettingsVisible(false)}
            setIsPageLoading={setIsPageLoading}
          />
        </div>
      </Modal>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ProfileSettings);
