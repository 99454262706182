import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { useSpring, animated } from 'react-spring'
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import MyRecentActivities from "../tableTemplates/MyRecentActivities";
import MyNotifications from "../tableTemplates/MyNotifications";
import dashboardHomeImg from "../images/dashboard_home_img.png";
import fundraiserSetupImage from "../images/fundraiser_setup.svg";
import testimonialSvg from "../images/testimonial_Icon.svg";
import appStore from "../images/app_store.svg";
import googlePlay from "../images/google_play.svg";
import emailIcon from "../images/email_icon.svg";
import referImage from "../images/refer.png";
import historyIcon from "../images/history_icon.svg";
import recentActivitiesIcon from "../images/recent_activitiesIcon.svg";
import settingsIcon from "../images/settings_Icon.svg";
import TestimonialForm from "../TestimonialForm";

const DashboardHome = ({ user, socketUrl }) => {

  const [recentActivityObj, setRecentActivityObj] = useState(null);
  const [recentNotificationObj, setRecentNotificationObj] = useState(null);
  const [allActivityObj, setAllActivityObj] = useState(null);
  const [isAllActivityModalVisible, setIsAllActivityModalVisible] = useState(false);
  const [isAllNotificationModalVisible, setIsAllNotificationModalVisible] = useState(false);
  const [isTestimonialVisible, setIsTestimonialVisible] = useState(false);

  const [animateFundraiserBtn, handleAnimateFundraiserBtn] = useSpring(() => ({ scale: 1 }))
  const [animateCyclicPoolBtn, handleAnimateCyclicPoolBtn] = useSpring(() => ({ scale: 1 }))
  const [animateReferBtn, handleAnimateReferBtn] = useSpring(() => ({ scale: 1 }))
  const [balances, setBalances] = useState([]);

  const recentNotificationSocketRef = useRef();
  const recentActivitySocketRef = useRef();
  const balanceRef = useRef(null)

  const fadeStyles = useSpring({
    from: { opacity: 0 },
    config: { duration: 2000 },
    loop: {
      opacity: 1,
    },
  })

  const slideStyles = useSpring({
    from: { x: 100, opacity: 0 },
    config: { duration: 1000 },
    loop: {
      x: 0, opacity: 1,
    },
  })

  const initializeNotificationSockets = (subType, limit) => {

    recentNotificationSocketRef.current = new WebSocket(
      `${socketUrl}/ws/notification/dashboard/${subType}/connect`)

    recentNotificationSocketRef.current.onopen = e => {

      recentNotificationSocketRef.current.send(JSON.stringify({
        type: "notification_obj",
        limit,
        user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
      }))
    }

    recentNotificationSocketRef.current.onmessage = e => {
      const data = JSON.parse(e.data)

      if (data.type == "notification_obj") {
        if (data.notification_obj) {
          setRecentNotificationObj(data.notification_obj)
        }
      }
    }

    recentNotificationSocketRef.current.onerror = e => {
      console.log('error', e)
    }
  }

  const initializeActivitySockets = (subType, limit) => {

    recentActivitySocketRef.current = new WebSocket(`${socketUrl}/ws/activity/dashboard/${subType}/connect`)

    recentActivitySocketRef.current.onopen = e => {
      recentActivitySocketRef.current.send(JSON.stringify({
        type: "all_activities_obj",
        limit,
        user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
      }))
    }

    recentActivitySocketRef.current.onmessage = e => {
      const data = JSON.parse(e.data)

      if (data && data.all_activities_obj) {
        if (subType == "initial") {
          setRecentActivityObj(data.all_activities_obj)
        } else if (subType == "more") {
          setAllActivityObj(data.all_activities_obj)
        }
      }
    }

    recentActivitySocketRef.current.onerror = e => {
      console.log('error', e)
    }
  }

  const handleGetBalancesBySocket = () => {

    balanceRef.current = new WebSocket(
      `${socketUrl}/ws/pouch_wallet/${user.id}/connect`)

    balanceRef.current.onopen = e => {
      balanceRef.current.send(JSON.stringify({
        type: "wallet_balances",
        user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
      }))
    }

    balanceRef.current.onmessage = e => {
      const data = JSON.parse(e.data)

      if (data.type == "wallet_balances") {
        setBalances(data.balances)
      }
    }

    balanceRef.current.onerror = e => {
      console.log('error', e)
    }
  }

  useEffect(() => {
    initializeNotificationSockets("initial", 5)

    initializeActivitySockets("initial", 5)

    handleGetBalancesBySocket()

  }, []);

  return (
    <div className="overflow-hidden">
      <div className="row">
        <div className="col-sm-6">
          <animated.div style={fadeStyles}
            className="padding-20 margin-top-8 margin-bottom-8 margin-sm-10 box-shadow border-radius-14">
            <h3 className="font-size-18 line-height-18 logo-magenta">
              <NavLink
                to="/dashboard/fundraiser"
                className="text-decoration-none"
              >
                Fundraiser
              </NavLink>
            </h3>
            <div className="d-flex flex-column align-items-center">
              <NavLink
                to="/dashboard/fundraiser"
                className="text-decoration-none"
              >
                <img
                  className="max-width-120 margin-top-8 margin-bottom-14 text-center"
                  src={fundraiserSetupImage}
                />
              </NavLink>
              <NavLink
                to="/dashboard/fundraiser/new"
                className="stretch-full text-decoration-none"
              >
                <animated.button className="btn padding-8 bg-grad-dodgerblue-green white stretch-full font-size-16"
                  style={animateFundraiserBtn}
                  onMouseEnter={() => {
                    handleAnimateFundraiserBtn({ scale: 1.05 });
                  }}
                  onMouseLeave={() => {
                    handleAnimateFundraiserBtn({ scale: 1 });
                  }}>
                  <b>Start a Fundraiser</b>
                </animated.button>
              </NavLink>
            </div>
          </animated.div>
        </div>
        <div className="col-sm-6">
          <div className="stretch-full text-decoration-none">
            <animated.div style={fadeStyles} className="padding-20 margin-top-8 margin-bottom-8 margin-sm-10 border-1 border-color-grey border-radius-14">
              <NavLink
                to="/dashboard/account?q=accountSettings"
                className="d-flex align-items-center padding-top-20 padding-bottom-20 logo-magenta text-decoration-none"
              >
                <img width="20" src={settingsIcon} />
                <b className="padding-left-10">Account Settings</b>
              </NavLink>
              <NavLink
                to="/dashboard/account?q=transactionHistory"
                className="d-flex align-items-center padding-top-20 padding-bottom-20 logo-magenta text-decoration-none"
              >
                <img width="20" src={historyIcon} />
                <b className="padding-left-10">Transaction History</b>
              </NavLink>
              <NavLink
                to="/contact-us"
                className="d-flex align-items-center padding-top-20 padding-bottom-20 logo-magenta text-decoration-none"
              >
                <img width="20" src={emailIcon} />
                <b className="padding-left-10">Contact Us</b>
              </NavLink>

              <div className="d-flex margin-top-30 margin-bottom-30 cursor-pointer align-items-center border-1 border-color-logo-magenta logo-magenta padding-10 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                onClick={() => {
                  setIsAllNotificationModalVisible(true)
                  initializeNotificationSockets("more", 10)
                }}>
                <img
                  className="fixed-width-20 margin-right-20 margin-right-sm-24"
                  src={recentActivitiesIcon}
                />
                <label className="cursor-pointer">See Recent Notifications</label>
              </div>

              <div className="d-flex margin-top-30 margin-bottom-30 cursor-pointer align-items-center border-1 border-color-logo-magenta logo-magenta padding-10 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                onClick={() => {
                  setIsAllActivityModalVisible(true)
                  initializeActivitySockets("more", 10)
                }}
              >
                <img
                  className="fixed-width-20 margin-right-20 margin-right-sm-24"
                  src={historyIcon}
                />
                <label className="cursor-pointer">See Recent Activities</label>
              </div>
            </animated.div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 d-flex flex-column align-items-center">
          <div className="stretch-full text-decoration-none">
            <animated.div style={fadeStyles} className="padding-20 margin-top-8 margin-bottom-8 margin-sm-10 border-1 border-color-grey border-radius-14">
              <NavLink
                to="/dashboard/account?q=accountSettings"
                className="d-flex align-items-center padding-top-20 padding-bottom-20 logo-magenta text-decoration-none"
              >
                <img width="20" src={settingsIcon} />
                <b className="padding-left-10">Account Settings</b>
              </NavLink>
              <NavLink
                to="/dashboard/account?q=transactionHistory"
                className="d-flex align-items-center padding-top-20 padding-bottom-20 logo-magenta text-decoration-none"
              >
                <img width="20" src={historyIcon} />
                <b className="padding-left-10">Transaction History</b>
              </NavLink>
              <NavLink
                to="/contact-us"
                className="d-flex align-items-center padding-top-20 padding-bottom-20 logo-magenta text-decoration-none"
              >
                <img width="20" src={emailIcon} />
                <b className="padding-left-10">Contact Us</b>
              </NavLink>

              <div className="d-flex margin-top-30 margin-bottom-30 cursor-pointer align-items-center border-1 border-color-logo-magenta logo-magenta padding-10 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                onClick={() => {
                  setIsAllNotificationModalVisible(true)
                  initializeNotificationSockets("more", 10)
                }}>
                <img
                  className="fixed-width-20 margin-right-20 margin-right-sm-24"
                  src={recentActivitiesIcon}
                />
                <label className="cursor-pointer">See Recent Notifications</label>
              </div>

              <div className="d-flex margin-top-30 margin-bottom-30 cursor-pointer align-items-center border-1 border-color-logo-magenta logo-magenta padding-10 padding-left-sm-36 padding-right-sm-36 border-radius-8"
                onClick={() => {
                  setIsAllActivityModalVisible(true)
                  initializeActivitySockets("more", 10)
                }}
              >
                <img
                  className="fixed-width-20 margin-right-20 margin-right-sm-24"
                  src={historyIcon}
                />
                <label className="cursor-pointer">See Recent Activities</label>
              </div>
            </animated.div>
          </div>
        </div>
        <div className="col-sm-6">
          <animated.div style={slideStyles} className="margin-top-8 margin-bottom-8 margin-sm-10">
            <img
              className="d-block stretch-full fixed-height-260 border-top-radius-14"
              src={dashboardHomeImg}
            />
            <div className="padding-10 padding-sm-20 border-left-1 border-right-1 border-bottom-1 border-color-grey border-bottom-radius-14">
              <div>
                <h1 className="font-size-22 line-height-22 margin-top-10 margin-bottom-10">
                  Download the mobile app
                </h1>
                <div className="d-flex justify-content-between">
                  <div className="margin-top-10 margin-bottom-10">
                    <a target='_blank'
                      href={"https://apps.apple.com/app/pouchfunding/id1634159014"}
                    >
                      <img className="max-width-120" src={appStore} />
                    </a>
                  </div>
                  <div className="margin-top-10 margin-bottom-10">
                    <a target='_blank'
                      href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.pouchfunding"
                    >
                      <img className="max-width-120" src={googlePlay} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </animated.div>
        </div>
      </div>
      <div className="row">
        <div className=" padding-left-md-20 margin-top-10 margin-top-md-30 margin-bottom-80 margin-bottom-md-30">
          <div className="row border-1 border-color-logo-magenta border-radius-16 padding-20">
            <div className="d-flex justify-content-center col-sm-4">
              <img
                className="max-width-120 margin-top-8 margin-bottom-14 text-center"
                src={testimonialSvg}
              />
            </div>
            <div className="d-flex justify-content-center col-sm-6">
              <div>
                <p className="logo-magenta font-size-16 font-size-sm-18">
                  Would you like to share your story with the world?
                </p>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn bg-orange white max-width-260 padding-8 margin-top-10 font-size-16"
                    type="button"
                    onClick={() => setIsTestimonialVisible(true)}
                  >
                    <b>
                      Write a testimonial
                    </b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isAllNotificationModalVisible}
        onRequestClose={() => {
          initializeNotificationSockets("initial", 5)
          setIsAllNotificationModalVisible(false)
        }}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Recent Notifications
          </h1>
          <i
            onClick={() => setIsAllNotificationModalVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line" />
        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          {recentNotificationObj && recentNotificationObj.notifications && recentNotificationObj.notifications.length ?
            <MyNotifications
              recentNotifications={recentNotificationObj.notifications}
            /> :
            <div className="font-size-14 grey line-height-14">There are no recent notifications</div>}
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isAllActivityModalVisible}
        onRequestClose={() => {
          // initializeActivitySockets("initial", 5)
          setIsAllActivityModalVisible(false)
        }}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Recent Activities
          </h1>
          <i
            onClick={() => setIsAllActivityModalVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line" />
        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          {allActivityObj && allActivityObj.activities && allActivityObj.activities.length ?
            <MyRecentActivities
              recentActivities={allActivityObj.activities}
            /> :
            <div className="font-size-14 grey line-height-14">There are no recent activities</div>}
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isTestimonialVisible}
        onRequestClose={() => {
          setIsTestimonialVisible(false)
        }}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <TestimonialForm
          handleClose={() => setIsTestimonialVisible(false)}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    socketUrl: state.auth.socketUrl
  };
};

export default connect(mapStateToProps)(DashboardHome);