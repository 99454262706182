import React, { useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import PhoneInputField from "../../../../utilityComponents/PhoneInputField";
import MoneyInputField from "../../../../utilityComponents/MoneyInputField";
import { getCurrencySymbol, readFileAsBase64DataURL, getBase64onlyString } from "../../../../../utilities"
import { addFundraiserMerchandise, editFundraiserMerchandise } from "../../../../../store/actions/fundraiser";
import dummyPrivateFundraiserCoverImage from "../../setup/privateFundraiserCover.jpg";

const MerchandiseForm = ({
  dispatch,
  initialMerchandise,
  initialFundraiserDetails,
  isAddingNewMerchandise,
  currency,
  setFundraiser,
  isCreatingNewFundraiser,
  setIsPageLoading,
  handleClose,
  onAddMerchandise,
  setMerchandise
}) => {

  const [selectedFile, setSelectedFile] = useState("")
  const [selectedFilePreview, setSelectedFilePreview] = useState("")

  const uploadPictureRef = useRef(null)

  const handleSelectFile = async (file) => {
    setSelectedFile(file)

    if (file) {
      readFileAsBase64DataURL(file, (base64) => setSelectedFilePreview(base64))
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialMerchandise}
        validationSchema={Yup.object({
          description: Yup.string().required("First name is required"),
          unit_price: Yup.number()
            .typeError("Value must be number")
            .positive("Value must be positive")
            .integer("Value must be a whole number")
            .required("unit price is required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (isCreatingNewFundraiser) {
            onAddMerchandise(values)

            handleClose()
          } else {
            if (!selectedFile && !initialMerchandise.merchandise_picture_file_url) {
              alert("Upload an image of the merchandise")
            } else {
              setIsPageLoading([true, "Loading..."]);

              let formData = new FormData()

              if (selectedFile) {
                formData.append("file", selectedFile)
              }
              formData.append("fundraiser_id", initialFundraiserDetails.id)
              formData.append("description", values.description)
              formData.append("unit_price", values.unit_price)

              if (isAddingNewMerchandise) {
                dispatch(addFundraiserMerchandise(formData))
                  .then(res => {
                    setIsPageLoading([false])

                    if (res.error) {
                      NotificationManager.error(res.error.message, "Error!")
                    } else {
                      if (res.success == true) {
                        NotificationManager.success(res.message, "Success!")
                        const fundraiser = res.data

                        setFundraiser(fundraiser)
                        setMerchandise(fundraiser.fundraiser_details.merchandise)
                        handleClose()
                      }
                    }
                  })
              } else {
                formData.append("id", initialMerchandise.id)

                dispatch(editFundraiserMerchandise(formData))
                  .then(res => {
                    setIsPageLoading([false])

                    if (res.error) {
                      NotificationManager.error(res.error.message, "Error!")
                    } else {
                      if (res.success == true) {
                        NotificationManager.success(res.message, "Success!")
                        const fundraiser = res.data

                        setFundraiser(fundraiser)
                        setMerchandise(fundraiser.fundraiser_details.merchandise)
                        handleClose()
                      }
                    }
                  })
              }
            }
          }

          setSubmitting(false);
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
        }) => (
          <Form>
            <div>
              <label className="form-label">Image</label>
              <input
                type="file"
                hidden
                ref={uploadPictureRef}
                onChange={(event) => {
                  const file = event.target.files[0]
                  handleSelectFile(file)
                  setFieldValue("file_name", file.name)

                  // So the preview file would show if there was already a file url
                  setFieldValue("merchandise_picture_file_url", null)

                  readFileAsBase64DataURL(file, (base64) => {
                    setSelectedFilePreview(base64)
                    setFieldValue("base64_file", getBase64onlyString(base64))
                  })
                }}
              />
              <div className="relative max-width-200 border-1 border-color-grey border-radius-6 cursor-pointer"
                onClick={() => uploadPictureRef.current.click()}
              >
                <img
                  className="d-block margin-bottom-10 border-radius-8 border-radius-8"
                  height="120px"
                  src={`${values.merchandise_picture_file_url
                    ? values.merchandise_picture_file_url + "?" + Date.now()
                    : selectedFilePreview
                    }`}
                />
                <span className="absolute top-50-pct left-50-pct  transform-50-50 grey">Upload Image</span>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">Description</label>
                <Field
                  className="form-control padding-10"
                  placeholder="E.g. Aso-ebi"
                  name="description"
                />
                <ErrorMessage
                  className="error-message"
                  name="description"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">Unit Price</label>
                <div className="form-control d-flex padding-0 fixed-height-40">
                  <div className="max-width-80 font-size-18 d-flex align-items-center justify-content-center bg-logo-magenta white border-top-left-radius-0 border-bottom-left-radius-0">
                    {getCurrencySymbol(currency)}
                  </div>
                  <MoneyInputField
                    value={values.unit_price}
                    onChange={(value) => {
                      setFieldValue("unit_price", value)
                    }}
                    placeholder="Enter unit price"
                  />
                </div>
                <ErrorMessage
                  className="error-message"
                  name="unit_price"
                  component="div"
                />
              </div>
            </div>

            <button
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitting}
              className="btn max-width-180 border-radius-0 bg-logo-magenta white font-size-17 margin-top-20 margin-bottom-30 margin-bottom-sm-16 padding-10 padding-top-12 padding-bottom-12"
            >
              <b>Done</b>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connect()(MerchandiseForm);

