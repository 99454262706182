import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Modal from "react-modal";
import { NotificationManager } from "react-notifications";
import { getWithdrawnFundsList, getFundsWithdrawalSummary, withdrawRaisedFunds } from "../../../../../store/actions/fundraiser";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";
import ConfirmRaisedFundWithdrawal from "./ConfirmRaisedFundWithdrawal";
import ConfirmFundWithdrawal from "./ConfirmFundWithdrawal";
import { convertSnakeToStandardCasing } from "../../../../../utilities";
import FilterSorter from "../../../../utilityComponents/FilterSorter";
import FundraiserWithdrawalDetails from "./FundraiserWithdrawalDetails";
import NewPagination from "../../../../utilityComponents/NewPagination";


const Withdrawals = ({
  dispatch, fundraiserId,
  setIsPageLoading
}) => {

  const [queryParams, setQueryParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  })
  const [withdrawnFundsObj, setWithdrawnFundsObj] = useState(null)

  const [isEnterAmountVisible, setIsEnterAmountVisible] = useState(false)
  const [isConfirmWithrawalVisible, setIsConfirmWithrawalVisible] = useState(false)
  const [summaryOfWithdrawal, setSummaryOfWithdrawal] = useState({})
  const [amountToWithdraw, setAmountToWithdraw] = useState(null)
  const [visibleComponent, setVisibleComponent] = useState("summary")
  const [initialHistoryCount, setInitialHistoryCount] = useState(0);
  const [isSearchingDonations, setIsSearchingDonations] = useState(false);
  const [isWithdrawalDetailsVisible, setIsWithdrawalDetailsVisible] = useState(false)
  const [openedWithdrawalDetails, setOpenedWithdrawalDetails] = useState(false);

  const handleGetwithdrawals = async (queryParams) => {
    const res = await dispatch(getWithdrawnFundsList({
      ...queryParams,
      fundraiserId
    }))
    if (res.success) {
      setWithdrawnFundsObj(res.data)
      setQueryParams(res.data.original_query_params)
      return res.data
    }
  }

  const handleGetWithdrawalDetails = (data) => {
    dispatch(getFundsWithdrawalSummary(data))
      .then(res => {
        if (res.error) {
          NotificationManager.error(res.error.message, "Error!")
        } else {
          if (res.success == true) {
            setIsEnterAmountVisible(false)

            setSummaryOfWithdrawal(res.data)
            setIsConfirmWithrawalVisible(true)
          }
        }
      })
  }

  // Withdraw funds raised
  const handleMakeWithdrawal = (data) => {
    dispatch(withdrawRaisedFunds(data))
      .then(res => {
        if (res.error) {
          NotificationManager.error(res.error.message, "Error!")
        } else {
          if (res.success == true) {
            setIsConfirmWithrawalVisible(false)
            handleGetwithdrawals(queryParams)
            NotificationManager.success(res.message, "Success!")
          }
        }
      })
      .catch(err => {
        alert(err.message)
      })
  }

  useEffect(() => {
    handleGetwithdrawals(queryParams)
      .then(data => {
        if (data) {
          setInitialHistoryCount(data.total_count)
        }
      })
  }, [])


  return (
    <div className="padding-bottom-20">
      <div className="d-flex margin-bottom-20">
        <div
          className={`btn padding-8 margin-right-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "summary" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange logo-magenta"}`}
          onClick={() => setVisibleComponent("summary")}>
          Summary
        </div>
        <div
          className={`btn padding-8 margin-left-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "history" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange logo-magenta"}`}
          onClick={() => setVisibleComponent("history")}>
          History
        </div>
      </div>

      {visibleComponent == "summary" &&
        <div>
          {withdrawnFundsObj ?
            <div>
              <div className="d-flex padding-top-12 padding-bottom-12">
                <div className="max-width-170">Total Amount Raised</div>
                <b className="normal-green font-size-15">
                  <FormattedMoney
                    value={withdrawnFundsObj.total_donations}
                    currency={withdrawnFundsObj.currency} />
                </b>
              </div>

              <div className="d-flex padding-top-12 padding-bottom-12">
                <div className="max-width-170">Total Merchandise Sales</div>
                <b className="light-normal-blue font-size-15">
                  <FormattedMoney
                    value={withdrawnFundsObj.total_merchandise_inflow}
                    currency={withdrawnFundsObj.currency} />
                </b>
              </div>

              <div className="d-flex padding-top-12 padding-bottom-12">
                <div className="max-width-170">Total Amount Withdrawn</div>
                <b className="red font-size-15 font-size-15">
                  <FormattedMoney
                    value={withdrawnFundsObj.total_withdrawals}
                    currency={withdrawnFundsObj.currency} />
                </b>
              </div>

              <div className="d-flex padding-top-12 padding-bottom-12">
                <div className="max-width-170">Available Balance</div>
                <b className="logo-magenta font-size-15">
                  <FormattedMoney
                    value={withdrawnFundsObj.total_withdrawable_balance}
                    currency={withdrawnFundsObj.currency} />
                </b>
              </div>

              <div className="d-flex padding-top-12 padding-bottom-12">
                <div className="max-width-170">Overall Balance</div>
                <b className="orange font-size-15">
                  <FormattedMoney
                    value={withdrawnFundsObj.overall_balance}
                    currency={withdrawnFundsObj.currency} />
                </b>
              </div>

              <div className="d-flex align-items-center padding-top-16 padding-bottom-16">
                <button className="btn bg-logo-magenta padding-10 white max-width-140 margin-right-20  margin-right-sm-30 font-size-15"
                  type="button"
                  onClick={() => {
                    handleGetWithdrawalDetails({
                      fundraiser_id: fundraiserId,
                      is_withdrawing_all: true
                    })
                  }}>
                  Withdraw All
                </button>

                <button className="btn bg-orange padding-10 white max-width-140 font-size-15"
                  type="button"
                  onClick={() => {
                    if (withdrawnFundsObj.total_withdrawable_balance <= 0) {
                      NotificationManager.error("No funds to withdraw", "Error!")
                    } else {
                      // Open the modal to enter the amount to withdraw
                      setIsEnterAmountVisible(true)
                    }
                  }}
                >
                  Withdraw Part
                </button>
              </div>

            </div> :
            null}
        </div>
      }

      {/* <div className="horizontal-line margin-top-20 margin-bottom-20" /> */}

      {
        visibleComponent == "history" &&
        <div>
          {initialHistoryCount > 0 &&
            <div className="margin-bottom-24">
              <FilterSorter
                sorterOptions={[
                  { label: 'Date', value: 'created_at' },
                ]}
                initialSortBy={queryParams.sort_by}
                queryOrder={queryParams.query_order}
                filterOptions={[
                  {
                    status: [
                      { label: "All", value: '' },
                      { label: 'Pending', value: 'PENDING' },
                      { label: 'Cleared', value: 'CLEARED' },
                      { label: 'Declined', value: 'DECLINED' },
                      { label: 'Refunded', value: 'REFUNDED' },
                    ]
                  }
                ]}
                onSelectQuery={(values) => {
                  setIsSearchingDonations(true)
                  const newQueryObj = {
                    ...queryParams,
                    ...values
                  }
                  handleGetwithdrawals(newQueryObj)
                }}
              />
            </div>}

          {withdrawnFundsObj && withdrawnFundsObj.withdrawals.length > 0 ?
            <div
              style={{ maxHeight: "300px" }}
              className="overflow-scroll-y padding-2"
            >
              {withdrawnFundsObj.withdrawals.map((withdrawal, index) => {
                return (
                  <div key={index}
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenedWithdrawalDetails(withdrawal)
                      setIsWithdrawalDetailsVisible(true)
                    }} >
                    <div className="row box-shadow logo-magenta margin-bottom-20 border-radius-6 padding-top-6 padding-bottom-6 padding-left-10 padding-right-10">
                      <div className="row col-md-7">
                        <div className="d-flex col-sm-7">
                          <div className="col-6 padding-top-4 padding-bottom-4 padding-4">
                            <label className="grey font-size-13 line-height-13">ID</label>
                            <p className="font-size-14 line-height-14">#{withdrawal.id}</p>
                          </div>
                          <div className="col-6 padding-4">
                            <label className="grey font-size-13 line-height-13">Amount</label>
                            <p className="font-size-14 line-height-14 normal-green">
                              <FormattedMoney value={withdrawal.amount} currency={withdrawal.amount_currency} />
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-5 padding-top-4 padding-bottom-4 padding-4">
                          <label className="grey font-size-13 line-height-13">Date</label>
                          <p className="font-size-14 line-height-14">
                            {moment(withdrawal.created_at).format("DD MMM YYYY, HH:mm a")}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex col-md-5">
                        <div className="col-6 padding-4">
                          <label className="grey font-size-13 line-height-13">Beneficiary</label>
                          <div className="font-size-14 line-height-14 text-break">
                            {withdrawal.transfer && withdrawal.transfer.beneficiary_name}
                          </div>
                        </div>
                        <div className="col-6 padding-4">
                          <label className="grey font-size-13 line-height-13">Status</label>
                          <p className={`font-size-14 line-height-14 
                          ${convertSnakeToStandardCasing(withdrawal.status) == "Pending" ? "red" : "normal-green"}`}>
                            {convertSnakeToStandardCasing(withdrawal.status)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {
                withdrawnFundsObj && withdrawnFundsObj.total_count > queryParams.no_of_items_per_page ?
                  <NewPagination
                    {...queryParams}
                    handleGetQuery={handleGetwithdrawals}
                  /> :
                  null
              }
            </div>
            :
            <center className="font-size-14 grey padding-top-1">
              You do not have any withdrawal history {isSearchingDonations && <span>that matches this query</span>}
            </center>}
        </div>
      }

      <Modal
        ariaHideApp={false}
        isOpen={isEnterAmountVisible}
        onRequestClose={() => setIsEnterAmountVisible(false)}
        className="modal max-width-560 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-14 padding-bottom-12 padding-bottom-sm-14 padding-left-md-20 padding-right-md-20 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Withdrawal Amount
          </h1>
          <i
            onClick={() => setIsEnterAmountVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div className="padding-8">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-10 padding-bottom-20 padding-left-md-10 padding-right-md-10"
          >
            {withdrawnFundsObj &&
              <ConfirmRaisedFundWithdrawal
                fundraiserId={fundraiserId}
                amountToWithdraw={amountToWithdraw}
                currency={withdrawnFundsObj.currency}
                balance={withdrawnFundsObj.balance}
                setAmountToWithdraw={setAmountToWithdraw}
                handleGetWithdrawalDetails={handleGetWithdrawalDetails}
                setIsPageLoading={setIsPageLoading} />
            }
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isConfirmWithrawalVisible}
        onRequestClose={() => setIsConfirmWithrawalVisible(false)}
        className="modal max-width-680 padding-0"
        overlayClassName="modal-backdrop"
      >
        <ConfirmFundWithdrawal
          fundraiserId={fundraiserId}
          summaryOfWithdrawal={summaryOfWithdrawal}
          handleMakeWithdrawal={handleMakeWithdrawal}
          handleGetWithdrawalDetails={handleGetWithdrawalDetails}
          setIsPageLoading={setIsPageLoading}
          setIsConfirmWithrawalVisible={setIsConfirmWithrawalVisible} />
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isWithdrawalDetailsVisible}
        onRequestClose={() => setIsWithdrawalDetailsVisible(false)}
        className="modal max-width-680 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Withdrawn Fund Details
          </h1>
          <i
            onClick={() => setIsWithdrawalDetailsVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />
        <div style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30">
          <FundraiserWithdrawalDetails
            details={openedWithdrawalDetails} />
        </div>
      </Modal>

    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Withdrawals);
