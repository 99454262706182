import React from "react";
import thanksImage from "../../../images/Image 1.svg"

const FundraiserThanksPage = ({
  handleGetFundraisersIManage,
  handleClose
}) => {
  return (
    <div className="padding-left-10 padding-right-10 border-1 border-color-light-grey border-radius-8">
      <div className="d-flex justify-content-center padding-top-20 padding-bottom-20">
        <div className="d-flex align-items-center max-width-820">
          <img className="d-block col-6 d-block-hidden-sm" src={thanksImage} />
          <div className="col-6 stretch-full-sm">
            <h1 className="font-size-24 font-size-sm-30 font-size-md-40 text-center">
              Thank you for setting up a fund raiser
              </h1>
            <div className="d-flex justify-content-center margin-top-10 margin-top-sm-30 margin-bottom-10 margin-bottom-sm-30">
              <button
                className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-logo-magenta white border-radius-12"
                onClick={() => {
                  handleGetFundraisersIManage();
                  handleClose();
                }}  >
                <b>Done</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FundraiserThanksPage as default };
