import React from "react";
import { connect } from "react-redux";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import linkIcon from "../../../../images/link_icon.svg"

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const ShareIcons = ({ fundraiserDetails, url, setIsUrlCopiedVisible }) => {

  const handleCopyUrl = () => {
    setIsUrlCopiedVisible(true);

    setTimeout(() => {
      setIsUrlCopiedVisible(false);
    }, 2000);
  };

  return (
    <div className="d-flex" >
      <div
        className="fixed-width-30 padding-right-20 padding-left-0"
      >
        <FacebookShareButton
          url={url}
          quote={`${fundraiserDetails.title} - ${fundraiserDetails.story}`}
          style={{ width: "0px", height: "0px" }}
        >
          <FacebookIcon width="28px" height="28px" round />
        </FacebookShareButton>
      </div>
      <div
        className="fixed-width-30 padding-right-20 padding-left-10">
        <TwitterShareButton
          url={url}
          title={fundraiserDetails.title}
          style={{ width: "0px", height: "0px" }}>
          <TwitterIcon width="28px" height="28px" round />
        </TwitterShareButton>
      </div>
      <div
        className="fixed-width-30 padding-right-20 padding-left-20">
        <TelegramShareButton
          url={url}
          title={fundraiserDetails.title}
          style={{ width: "0px", height: "0px" }}>
          <TelegramIcon width="28px" height="28px" round />
        </TelegramShareButton>
      </div>
      <div
        className="fixed-width-30 padding-right-20 padding-left-20">
        <WhatsappShareButton url={url} title={fundraiserDetails.title}
          style={{ width: "0px", height: "0px" }}>
          <WhatsappIcon width="28px" height="28px" round />
        </WhatsappShareButton>
      </div>
      <div
        className="fixed-width-30 padding-right-20 padding-left-20">
        <LinkedinShareButton
          url={url}
          title={fundraiserDetails.title}
          summary={fundraiserDetails.story}
          source="www.pouchfunding.com"
          style={{ width: "0px", height: "0px" }}
        >
          <LinkedinIcon width="28px" height="28px" round />
        </LinkedinShareButton>
      </div>
      <div
        className="fixed-width-30 padding-right-20 padding-left-20"
      >
        <EmailShareButton
          url={url}
          subject={fundraiserDetails.title}
          body={fundraiserDetails.story}
          style={{ width: "0px", height: "0px" }}
        >
          <EmailIcon width="28px" height="28px" round />
        </EmailShareButton>
      </div>
      <CopyToClipboard
        text={url}
        onCopy={() => {
          handleCopyUrl();
        }}
      >
        <img width="28px" height="28px" className=" margin-left-20 cursor-pointer" src={linkIcon} />
        {/* <div style={{ height: "24px", width: "24px" }} className="bg-logo-magenta border-radius-15 padding-left-4 margin-left-20">

          <i
            className="fa fa-link font-size-14 white cursor-pointer"></i>
        </div> */}
      </CopyToClipboard>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ShareIcons);
