import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment"
import Truncate from "react-truncate";
import { NotificationManager } from "react-notifications";
import {
  addFundraiserUpdate,
  deleteFundraiserUpdate,
  getFundraiserUpdates
} from "../../../../../store/actions/fundraiser";
import { getConfirmAlert } from "../../../../../utilities";
const Updates = ({
  dispatch,
  fundraiser
}) => {

  const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false);
  const [updateText, setUpdateText] = useState("");
  const [updatesObj, setUpdatesObj] = useState({});
  const [queryParams, setQueryParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: "asc"
  });

  const handleAddFundraiserUpdate = (data) => {
    dispatch(addFundraiserUpdate(data))
      .then((res) => {
        if (res.error) {
          NotificationManager.error(res.error.message, "Error!")
        } else {
          if (res.success == true) {
            NotificationManager.success(res.message, "Success!")
            handleGetFundraiserUpdates(queryParams)
            setIsUpdateFormVisible(false);
            setUpdateText("");
          }
        }
      });
  };

  const handleDeleteFundraiserUpdate = (updateId) => {
    getConfirmAlert({
      title: "Confirm delete!",
      message: "Are you sure you want to delete this update?",
      onClickYes: () => {
        dispatch(deleteFundraiserUpdate(updateId)).then((res) => {
          if (res.error) {
            NotificationManager.error(res.error.message, "Error!")
          } else {
            if (res.success == true) {
              NotificationManager.success(res.message, "Success!")
              handleGetFundraiserUpdates(queryParams)
              setIsUpdateFormVisible(false);
            }
          }
        });
      },
      onClickNo: () => null,
    });
  };

  const handleGetFundraiserUpdates = (queryParams) => {
    dispatch(getFundraiserUpdates(
      fundraiser.fundraiser_details.id, queryParams))
      .then(res => {
        if (res.error) {
          NotificationManager.error(res.error.message, "Error!")
        } else {
          if (res.success) {
            setUpdatesObj(res.data)
            setQueryParams(queryParams)
          }
        }
      })
  }

  useEffect(() => {
    handleGetFundraiserUpdates(queryParams)
  }, [])


  return (
    <div className="padding-bottom-20">
      <div>
        <button
          className="btn bg-logo-magenta max-width-150 white border-radius-8 font-size-15 padding-8"
          onClick={() => {
            setIsUpdateFormVisible(true);
          }}
          type="button"
        >
          Add new update
        </button>

        <div>
          {updatesObj.updates && (updatesObj.updates.length || isUpdateFormVisible) ? (
            <div>
              <div className="horizontal-line margin-top-22 margin-bottom-12" />

              <h2 className="logo-magenta font-size-18 font-size-md-19 ">
                Updates
              </h2>
            </div>
          ) : null}
          <div>
            {isUpdateFormVisible ? (
              <div className="d-flex align-items-center">
                <div className="stretch-full margin-top-12 margin-bottom-12">
                  <textarea
                    className="form-control fixed-height-200"
                    onChange={(e) => {
                      setUpdateText(e.target.value);
                    }}
                  ></textarea>
                  <button
                    className="btn bg-light-green margin-top-6 margin-bottom-6 white font-size-14 padding-6 padding-left-10 padding-right-10 border-radius-6"
                    disabled={!updateText}
                    onClick={() =>
                      handleAddFundraiserUpdate({
                        fundraiser_id: fundraiser.fundraiser_details.id,
                        text: updateText,
                      })
                    }
                    type="button"
                  >
                    <b>Save</b>
                  </button>
                </div>

              </div>
            ) : null}
          </div>

          <div>
            {updatesObj.updates && updatesObj.updates.map((update, index) => {
              return (
                <div
                  key={index}
                  className="d-flex align-items-center padding-10 bg-pale-navy border-radius-6 margin-top-10 margin-bottom-10"
                >
                  <div className="stretch-full padding-right-6 padding-right-sm-20">
                    <div className="font-size-11 font-size-sm-13 line-height-16 line-height-sm-17">
                      <Truncate lines={2}>{update.text}</Truncate>
                    </div>
                    <div className="font-size-10 float-end opacity-75">
                      {moment(
                        new Date(update.created_at),
                        "MMMM Do YYYY, h:mm:ss a"
                      ).fromNow()}
                    </div>
                  </div>
                  <button
                    className="btn bg-red white max-width-60 font-size-11 padding-4 border-radius-6"
                    type="button"
                    onClick={() => {
                      handleDeleteFundraiserUpdate(update.id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              );
            })}
          </div>

          {updatesObj.no_of_items_per_page < updatesObj.total_count &&
            <div
              onClick={() => {
                const newQuery = {
                  ...queryParams,
                  no_of_items_per_page: queryParams.no_of_items_per_page + 10
                }

                handleGetFundraiserUpdates(newQuery)
              }} >
              See more
            </div>}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Updates);
