import { getUser } from './auth'
import axiosInstance from "../../helpers/axiosInstance";

//Get all fundraisers I manage
export const getAllFundraisersIManage = (data) => {
  return async (dispatch, getState) => {
    return axiosInstance.get(
      `/services/fundraisers/get-my-fundraisers`,
      { params: data }
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

//Get all public fundraisers
export const getAllFundraisersListByPublic = (data) => {
  return async (dispatch, getState) => {
    const token = getState().auth.userToken
    return axiosInstance.get(
      `/services/fundraisers/get-fundraisers-list-by-public`,
      { params: data }
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Get a specific fundraiser by public
export const getFundraiserByPublic = (slug) => {
  return async (dispatch, getState) => {
    return axiosInstance.get(`/services/fundraisers/get-fundraiser-by-public?fundraiser_slug=${slug}`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
        return { error: error ? error.response.data : null }
      })
  }
};

// Get Fundraiser By Owner
export const getFundraiserByOwner = (id) => {
  return async (dispatch, getState) => {
    return axiosInstance.get(`/services/fundraisers/get-fundraiser-by-owner?fundraiser_id=${id}`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};


// Create a fundraiser details
export const createFundraiser = (fundraiserDetails) => {
  return async (dispatch, getState) => {
    return axiosInstance.post('/services/fundraisers/create-fundraiser', fundraiserDetails)
      .then(res => {
        return res.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Edit fundraiser details
export const editFundraiserDetails = (fundraiserDetails) => {
  return async (dispatch, getState) => {
    return axiosInstance.patch('/services/fundraisers/edit-fundraiser', fundraiserDetails)
      .then(res => {
        return res.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Add Third Party Beneficiary
export const addThirdPartyBeneficiary = (thirdPartyBeneficiary) => {
  return async (dispatch, getState) => {
    return axiosInstance.post('/services/fundraisers/add-3rd-party-beneficiary', thirdPartyBeneficiary)
      .then(res => {
        return res.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Remove Third Party Beneficiary
export const removeThirdPartyBeneficiary = (thirdPartyBeneficiary) => {
  console.log(thirdPartyBeneficiary)
  return async (dispatch, getState) => {
    return axiosInstance.patch("/services/fundraisers/remove-3rd-party-beneficiary", thirdPartyBeneficiary)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Add a fundraiser cover media
export const addFundraiserCoverMedia = (coverMedia) => {
  return async (dispatch, getState) => {
    return axiosInstance.post('/services/fundraisers/add-fundraiser-cover-media', coverMedia)
      .then(res => {
        return res.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Remove fundraiser cover media
export const removeFundraiserCoverMedia = (data) => {
  return async (dispatch) => {
    return axiosInstance.delete("/services/fundraisers/remove-fundraiser-cover-media",
      {
        params: data
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Add a fundraiser cover media
export const addFundraiserDocument = (fundraiserDocument) => {
  return async (dispatch, getState) => {
    return axiosInstance.post('/services/fundraisers/add-fundraiser-document', fundraiserDocument)
      .then(res => {
        return res.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Remove fundraiser document
export const removeFundraiserDocument = (id, fileObj) => {
  return async (dispatch, getState) => {
    return axiosInstance.post("/services/fundraisers/remove-fundraiser-document", fileObj)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Donate from bank
export const donateFromBank = (values) => {
  return async (dispatch, getState) => {
    return axiosInstance.post("/services/fundraisers/donate-from-bank", values)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Donate from wallet
export const donateFromWallet = (values) => {
  return async (dispatch, getState) => {
    return axiosInstance.post("/services/fundraisers/donate-from-wallet", values)
      .then((response) => {
        dispatch(getUser())
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Get a list of donations with or without comments
export const publicGetDonationsList = (values) => {
  let url = `/services/fundraisers/public-get-donations`
  if (values.withCommentsOnly) {
    url = url + `?with_comments_only=${values.withCommentsOnly}`
  }
  return async () => {
    return axiosInstance.get(url, {
      params: values
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Add fundraiser update
export const addFundraiserUpdate = (data) => {
  return async () => {
    return axiosInstance.post("/services/fundraisers/add-fundraiser-update", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Delete fundraiser update
export const deleteFundraiserUpdate = (updateId) => {
  return async () => {
    return axiosInstance.delete(`/services/fundraisers/delete-fundraiser-update/${updateId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Get the withdrawal details
export const getWithdrawnFundsList = (params) => {
  return async () => {
    return axiosInstance.get(
      `/services/fundraisers/get-withdrawn-funds-list/${params.fundraiserId}`,
      { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Get the withdrawal funds summary
export const getFundsWithdrawalSummary = (data) => {
  return async () => {
    return axiosInstance.post(
      `/services/fundraisers/get-funds-withdrawal-summary`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Withdraw raised funds
export const withdrawRaisedFunds = (data) => {
  return async () => {
    return axiosInstance.post(
      `/services/fundraisers/withdraw-raised-funds`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Submit fundraiser
export const submitFundraiser = (fundraiserId) => {
  return async () => {
    return axiosInstance.post(
      `/services/fundraisers/submit-fundraiser/${fundraiserId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Add assistant manager to fundraiser
export const addFundraiserAssistantManager = (data) => {
  return async () => {
    return axiosInstance.post(
      "/services/fundraisers/fundraiser-manager-adds-assistant-manager", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Remove assistant manager to fundraiser
export const removeFundraiserAssistantManager = (data) => {
  return async () => {
    return axiosInstance.post(
      "/services/fundraisers/fundraiser-manager-removes-assistant-manager", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Add merchandise to fundraiser
export const addFundraiserMerchandise = (data) => {
  return async () => {
    return axiosInstance.post(
      "/services/fundraisers/add-fundraiser-merchandise", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Editfundraiser merchandise
export const editFundraiserMerchandise = (data) => {
  return async () => {
    return axiosInstance.patch(
      "/services/fundraisers/edit-fundraiser-merchandise", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};


// Delete merchandise from fundraiser
export const deleteFundraiserMerchandise = (merchandiseId) => {
  return async () => {
    return axiosInstance.delete(
      "/services/fundraisers/delete-fundraiser-merchandise/" + merchandiseId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Get my donations made
export const getDonationsIMade = (data) => {
  return async () => {
    return axiosInstance.get(
      "/services/fundraisers/get-donations-i-made", { params: data })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Get my ordered merchandise
export const getMyMerchandiseOrders = (data) => {
  return async () => {
    return axiosInstance.get(
      "/services/fundraisers/get-my-merchandise-orders", { params: data })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};


// Create merchandise order
export const createMerchandiseOrder = (data) => {
  return async () => {
    return axiosInstance.post(
      "/services/fundraisers/create-fundraiser-merchandise-order", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// Get the merchandise orders people placed for a specifc fundraiser
export const getReceivedMerchandiseOrders = (data) => {
  return async () => {
    return axiosInstance.get(
      "/services/fundraisers/get-received-merchandise-orders/" + data.fundraiser_id, { params: data })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// A fundraiser manager changes the status of the order
export const managerUpdatesMerchandiseOrderStatus = (data) => {
  return async () => {
    return axiosInstance.patch(
      "/services/fundraisers/manager-updates-merchandise-order-status", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// A list of fundraiser updates
export const getFundraiserUpdates = (fundraiserId, data) => {
  return async () => {
    return axiosInstance.get(
      `/services/fundraisers/get-fundraiser-updates/${fundraiserId}`, { params: data })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// To unfollow a fundraiser update
export const unfollowFundraiserUpdates = (data) => {
  return async () => {
    return axiosInstance.post(
      "/services/fundraisers/unfollow-fundraiser-updates", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// For a buyer to mark a merchandise order as delivered
export const buyerMarkFundraiserOrderAsDelivered = (data) => {
  return async () => {
    return axiosInstance.patch(
      "/services/fundraisers/buyer-update-merchandise-order-as-delivered", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// For a fundraiser manager to enable or disable a fundraiser
export const enableOrDisableFundraiser = (data) => {
  return async () => {
    return axiosInstance.patch(
      `/services/fundraisers/enable-or-disable-fundraiser/${data.action}/${data.fundraiserId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// For only the fundraiser manager to add their bank account to a fundraiser
export const addFundraiserManagerBankAccount = (data) => {
  return async () => {
    return axiosInstance.post(
      "/services/fundraisers/link-manager-bank-account-to-fundraiser", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// For only the fundraiser manager to remove their bank account from a fundraiser
export const removeFundraiserManagerBankAccount = (data) => {
  return async () => {
    return axiosInstance.post(
      `/services/fundraisers/unlink-manager-bank-account-from-fundraiser/${data.fundraiser_id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// To register the latest payment attempt by the donor
export const registerFundraiserPaymentAttempt = (data) => {
  return async () => {
    return axiosInstance.post(
      `/services/fundraisers/register-payment-attempt`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// To upload an additional cover picture
export const uploadAdditionalCoverPicture = (data) => {
  return async () => {
    return axiosInstance.post(
      `/services/fundraisers/add-fundraiser-additional-cover-picture`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// To delete an additional cover picture
export const deleteAdditionalCoverPicture = (pictureId) => {
  return async () => {
    return axiosInstance.delete(
      `/services/fundraisers/delete-fundraiser-additional-cover-picture/${pictureId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// To link an NGO's bank account to fundraiser
export const linkNGOBankAccountToFundraiser = (data) => {
  return async () => {
    return axiosInstance.post(
      `/services/fundraisers/link-ngo-bank-account-to-fundraiser`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};








