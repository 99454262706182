import React, { useState, useContext } from "react";

const AddOrMakeVideoModal = ({ onClickMakeVideo, onClickUploadVideo }) => {
  return (
    <div>
      <button
        className="button add-or-take-btn"
        onClick={() => onClickMakeVideo()}
      >
        Make a video
      </button>
      <button
        className="button add-or-take-btn "
        style={{
          backgroundColor: "#2b74bc",
        }}
        onClick={() => onClickUploadVideo()} 
      >
        Upload a video
      </button>
    </div>
  );
};

export { AddOrMakeVideoModal as default };
