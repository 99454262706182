import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import Modal from "react-modal";
import { convertCountryCodeToName, convertSnakeToStandardCasing, getConfirmAlert } from "../../../../utilities"
import { deleteCharityNgo, getMyRegisteredNGOs } from "../../../../store/actions/charity_ngo";
import CharityNGOOverview from "./CharityNGOOverview";
import RenderHtmlTemplate from "../../../utilityComponents/RenderHtmlTemplate";

const MyRegisteredNGOs = ({
  dispatch,
  handleAboutToEditNGO,
  handleClose,
  setIsPageLoading,
  handleUploadNgoDocument
}) => {
  const [myRegisteredNGOs, setMyRegisteredNGOs] = useState(null);
  const [isOverviewVisible, setIsOverviewVisible] = useState(false);
  const [specificCharityNgo, setSpecificCharityNgo] = useState(false);

  const handleGetMyRegisteredNGOs = async (params) => {
    const res = await dispatch(getMyRegisteredNGOs(params))
    if (res.error) {
      NotificationManager.error(res.error.message, "Error!")
    } else {
      if (res.success == true) {
        setMyRegisteredNGOs(res.data);
      }
    }
  };



  useEffect(() => {
    handleGetMyRegisteredNGOs()
  }, []);

  return (
    <div>
      {myRegisteredNGOs &&
        myRegisteredNGOs.length > 0 ?
        <div>
          {myRegisteredNGOs.map((ngo, index) => {
            return (
              <div key={index}>
                <div className="padding-top-12 padding-bottom-12">
                  <div className="row box-shadow black border-radius-6 padding-top-4 padding-bottom-4 padding-left-8 padding-right-8">
                    <div className="row align-items-center padding-top-10 padding-bottom-10 padding-left-4 padding-right-4">
                      <div className="row align-items-center">
                        <div className="col-sm-4 padding-top-6 padding-bottom-6 padding-4">
                          <label className="grey font-size-13 line-height-13">ID</label>
                          <p className="font-size-14 line-height-14">
                            #{ngo.id}
                          </p>
                        </div>
                        <div className="col-sm-8 padding-top-6 padding-bottom-6 padding-4">
                          <label className="grey font-size-13 line-height-13">Organization Name</label>
                          <p className="font-size-14 line-height-14 text-break">
                            {ngo.organization_name}
                          </p>
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-sm-4 align-items-center padding-top-6 padding-bottom-6 padding-4">
                          <label className="grey font-size-13 line-height-13 text-break">Country</label>
                          <p className="font-size-14 line-height-14 text-break">
                            {convertCountryCodeToName(ngo.country)}
                          </p>
                        </div>

                        <div className="col-sm-8 d-flex">
                          <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                            <label className="grey font-size-13 line-height-13">Created Date</label>
                            <p className="font-size-14 line-height-14">
                              {moment(String(ngo.created_at)).format("DD MMM YYYY")}
                            </p>
                          </div>

                          <div className="col-6 padding-top-6 padding-bottom-6 padding-4">
                            <label className="grey font-size-13 line-height-13">Status</label>
                            <div className="font-size-14 line-height-14">
                              {convertSnakeToStandardCasing(ngo.status)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="d-flex font-size-14 line-height-14 justify-content-end">
                      {["DRAFT", "QUERY"].includes(ngo.status) &&
                        <div>
                          <button
                            type="button"
                            className="btn white fixed-width-60 bg-blue border-radius-4 margin-5"
                            onClick={() => {
                              handleAboutToEditNGO(ngo.id)
                                .then(r => {
                                  if (r.success) {
                                    handleClose()
                                  }
                                })
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      }

                      {["DRAFT", "QUERY", "PENDING_APPROVAL"].includes(ngo.status) &&
                        <div>
                          <button
                            type="button"
                            className="btn white fixed-width-60 bg-red border-radius-4 margin-5"
                            onClick={() => {
                              getConfirmAlert({
                                title: "Confirm delete!",
                                message: "Are you sure you want to delete this charity NGO?",
                                onClickYes: () => {
                                  setIsPageLoading([true, "Uploading..."]);

                                  dispatch(deleteCharityNgo(ngo))
                                    .then((response) => {
                                      setIsPageLoading([false]);

                                      if (response.error) {
                                        NotificationManager.error(response.error.message, "Error!")
                                      } else {
                                        if (response.success == true) {
                                          NotificationManager.success(response.message, "Success!")
                                          handleGetMyRegisteredNGOs()
                                        }
                                      }
                                    });
                                },
                                onClickNo: () => null,
                              });
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      }

                      {["APPROVED", "REJECTED", "QUERY"].includes(ngo.status) &&
                        <button
                          type="button"
                          className="btn white fixed-width-60 bg-logo-magenta border-radius-4 margin-5"
                          onClick={() => {
                            setSpecificCharityNgo(ngo)
                            setIsOverviewVisible(true)
                          }}
                        >
                          View
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div> :
        <center>
          <p className="grey padding-top-30 padding-bottom-10">You don't have any registered NGO</p>
        </center>
      }

      <Modal
        ariaHideApp={false}
        isOpen={isOverviewVisible}
        onRequestClose={() => setIsOverviewVisible(false)}
        className="modal max-width-950 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40
         padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Charity NGO
          </h1>
          <i
            onClick={() => setIsOverviewVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="overflow-scroll-y padding-10 padding-top-0 padding-left-md-40 padding-right-md-40"
          style={{ maxHeight: "80vh" }} >

          {["QUERY", "DRAFT"].includes(specificCharityNgo.status) &&
            <div className="padding-top-10 padding-bottom-10">
              <b className="red">QUERY</b>
              <div className="padding-top-4 padding-bottom-14 red">
                <RenderHtmlTemplate html={specificCharityNgo.query} />
              </div>
              <button
                className="btn white bg-blue border-radius-4 margin-left-0 margon-right-10 margin-bottom-10"
                type="button"
                onClick={() => handleAboutToEditNGO(specificCharityNgo.id)
                  .then(r => {
                    if (r.success) {
                      handleClose()
                    }
                  })}
              >Edit</button>
              <div className="horizontal-line " />
            </div>
          }
          <CharityNGOOverview
            charityNgo={specificCharityNgo}
            setCharityNgo={setSpecificCharityNgo}
            handleUploadNgoDocument={handleUploadNgoDocument}
          />
        </div>
      </Modal>

    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(MyRegisteredNGOs);
