import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import PhoneInputField from "../../../../utilityComponents/PhoneInputField";
import * as Yup from "yup";
import VerifyBankAccount from "../../../../payments/VerifyBankAccount"
import { removeFundraiserManagerBankAccount } from "../../../../../store/actions/fundraiser";

const BeneficiaryBankDetails = ({
    dispatch,
    fundraiser_id,
    setIsPageLoading,
    setIsBeneficiaryBankDetailsModal,
    fundraiser_details,
    thirdPartyBeneficiary,
    setFundraiser,
    addThirdPartyBeneficiary
}) => {

    const [isBankAccountVerified, setIsBankAccountVerified] = useState(false)

    return (
        <div>
            {thirdPartyBeneficiary ?
                <Formik
                    initialValues={thirdPartyBeneficiary}

                    validationSchema={Yup.object({
                        full_name: Yup.string().required("Full name is required"),
                        email: Yup.string()
                            .email("Must be a valid email"),
                        phone_number: Yup.string().required(
                            "Phone number is required"
                        ),
                        bank_name: Yup.string().required("Bank name is required"),
                        bank_account_number: Yup.number()
                            .typeError("Must be a number")
                            .positive("Must be a positive number")
                            .integer("Must be a whole number")
                            .required("Bank account no. is required"),
                    })}

                    onSubmit={(values, { setSubmitting }) => {
                        setIsPageLoading([true, "Loading..."]);
                        values.fundraiser_id = fundraiser_id;

                        if (fundraiser_details.bank_account_detail_id) {
                            dispatch(removeFundraiserManagerBankAccount({
                                fundraiser_id: fundraiser_details.id
                            }))
                                .then((response) => {
                                    setSubmitting(false);
                                    if (response.error) {
                                        NotificationManager.error(response.error.message, "Error!")
                                    } else {
                                        if (response.success == true) {
                                            dispatch(addThirdPartyBeneficiary(values))
                                                .then(res => {
                                                    setIsPageLoading([false]);
                                                    if (res.error) {
                                                        NotificationManager.error(res.error.message, "Error!")
                                                    } else {
                                                        if (res.success == true) {
                                                            NotificationManager.success(res.message, "Success!")
                                                            setFundraiser(res.data);
                                                            setIsBeneficiaryBankDetailsModal(false);
                                                        }
                                                    }
                                                })
                                        }
                                    }
                                });
                        } else {
                            dispatch(addThirdPartyBeneficiary(values))
                                .then(response => {
                                    setSubmitting(false);
                                    setIsPageLoading([false]);
                                    if (response.error) {
                                        NotificationManager.error(response.error.message, "Error!")
                                    } else {
                                        if (response.success == true) {
                                            NotificationManager.success(response.message, "Success!")
                                            setFundraiser(response.data);
                                            setIsBeneficiaryBankDetailsModal(false);
                                        }
                                    }
                                })
                        }
                    }}
                >
                    {({
                        handleSubmit,
                        setFieldValue,
                        values
                    }) => (
                        <Form>
                            {!isBankAccountVerified ?
                                <div>
                                    <VerifyBankAccount
                                        setIsPageLoading={setIsPageLoading}
                                        handleOnAccountVerification={(verifiedBank) => {
                                            setFieldValue("full_name", verifiedBank.bank_account_name)
                                            setFieldValue("bank_name", verifiedBank.bank_name)
                                            setFieldValue("bank_account_number", verifiedBank.bank_account_number)
                                            setFieldValue("bank_code", verifiedBank.account_bank)
                                            setFieldValue("country_code", verifiedBank.country_code)
                                            setFieldValue("bank_branch_name", verifiedBank.bank_branch_name)
                                            setFieldValue("bank_branch_code", verifiedBank.bank_branch_code)

                                            setIsBankAccountVerified(true)
                                        }}
                                    />
                                </div>
                                :
                                <div>
                                    <div className="padding-top-10 padding-bottom-10">
                                        <label className="form-label">Full Name</label>
                                        <Field
                                            className="form-control"
                                            name="full_name"
                                            disabled={true}
                                        />
                                        <ErrorMessage
                                            className="error-message"
                                            name="full_name"
                                            component="div"
                                        />
                                    </div>

                                    <div className="padding-top-10 padding-bottom-10">
                                        <label className="form-label">Email (Optional)</label>
                                        <Field
                                            className="form-control"
                                            placeholder="Enter email"
                                            name="email"
                                        />
                                        <ErrorMessage
                                            className="error-message"
                                            name="email"
                                            component="div"
                                        />
                                    </div>

                                    <div className="padding-top-10 padding-bottom-10">
                                        <label className="form-label">Phone Number</label>
                                        <div className="form-control padding-4 padding-left-10 padding-right-10">
                                            <PhoneInputField
                                                name="phone_number"
                                                value={thirdPartyBeneficiary.phone_number}
                                                onChange={setFieldValue}
                                                placeholderText="Enter phone number"
                                                type="number"
                                            />
                                        </div>
                                        <ErrorMessage
                                            className="error-message"
                                            component="div"
                                            name="phone_number"
                                        />
                                    </div>

                                    <div className="padding-top-10 padding-bottom-10">
                                        <label className="form-label">Bank Name</label>
                                        <Field
                                            className="form-control"
                                            name="bank_name"
                                            disabled={true}
                                        />

                                        <ErrorMessage
                                            className="error-message"
                                            name="bank_name"
                                            component="div"
                                        />
                                    </div>

                                    <div className="padding-top-10 padding-bottom-10">
                                        <label className="form-label">
                                            Bank Account Number
                                        </label>
                                        <Field
                                            className="form-control"
                                            name="bank_account_number"
                                            disabled={true}
                                        />
                                        <ErrorMessage
                                            className="error-message"
                                            name="bank_account_number"
                                            component="div"
                                        />
                                    </div>

                                    {
                                        values.bank_branch_name && <div className="padding-top-10 padding-bottom-10">
                                            <label className="form-label">
                                                Bank Branch
                                            </label>
                                            <Field
                                                className="form-control"
                                                name="bank_branch_name"
                                                disabled={true}
                                            />
                                        </div>
                                    }

                                    <div className="d-flex justify-content-between align-items-center padding-top-24 padding-bottom-10">
                                        <button
                                            type="submit"
                                            className="btn max-width-170 bg-logo-magenta white padding-8 padding-top-10 padding-bottom-10 font-size-15"
                                            onClick={handleSubmit}
                                        >
                                            Save Bank Details
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn max-width-140 bg-orange white padding-8 padding-top-10 padding-bottom-10 font-size-15"
                                            onClick={() => {
                                                setIsBankAccountVerified(false)
                                            }}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>}

                        </Form>
                    )}
                </Formik> : null}
        </div>
    );
};

const mapStateToProps = (state, props) => {
    return {
        user: state.auth.user,
    };
};

export default connect(mapStateToProps)(BeneficiaryBankDetails);
