import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Modal from "react-modal";
import moment from "moment";
import Truncate from "react-truncate";
import { NotificationManager } from "react-notifications";
import AllFundraisers from "../services/fundraiser/public/AllFundraisers";
import MyCommitments from "../services/fundraiser/public/MyCommitments";
import PreviewFundraiser from "../services/fundraiser/public/components/PreviewFundraiser";
import ProgressBar from "../utilityComponents/ProgressBar";
import {
  getFundraiserByOwner,
  getAllFundraisersIManage,
} from "../../store/actions/fundraiser";
import FundraiserSetupSlides from "../services/fundraiser/setup/FundraiserSetupSlides";
import CoverMediaThumbnail from "../services/fundraiser/public/components/CoverMediaThumbnail";
import ShareOptions from "../services/fundraiser/public/components/ShareOptions";
import FundraiserView from "../services/fundraiser/setup/view/Index";
import FormattedMoney from "../utilityComponents/FormattedMoney";
import fundraiserSetupIcon from "../images/fundraiser_setup.svg";
import fundraiserIcon from "../images/fundraiser_icon_darkblue.svg";
import UseOnClickOutside from "../UseOnClickOutside";
import { convertSnakeToStandardCasing } from "../../utilities";
import FilterSorter from "../utilityComponents/FilterSorter";
import NewPagination from "../utilityComponents/NewPagination";

const Fundraiser = ({
  history,
  match,
  setIsCreatingNew,
  dispatch,
  setIsPageLoading,
  windowWidth,
  mobileBreakpoint,
  desktopBreakpoint,
}) => {
  const search = useLocation().search;

  const [canGoBack] = useState(JSON.parse(new URLSearchParams(search).get('canGoBack')));
  const [visibleComponent, setVisibleComponent] = useState(new URLSearchParams(search).get("visibleComponent"));
  const [fundraiser, setFundraiser] = useState(null);
  const [isFundraiserPreviewModal, setIsFundraiserPreviewModal] = useState(false);
  const [isFundraiserSummaryVisible, setIsFundraiserSummaryVisible] = useState(false);
  const [fundraiserPreview, setFundraiserPreview] = useState({});
  const [fundraisersIManage, setFundraisersIManage] = useState([]);
  const [initialFundraiserCount, setInitialFundraiserCount] = useState(0);
  const [totalFundraiserCount, setTotalFundraiserCount] = useState(0);

  const [isShareOptionsVisible, setIsShareOptionsVisible] = useState(false);
  const [shareFundraiserDetails, setShareFundraiserDetails] = useState(false);

  const [editViewShareBtnContainerIndex, setEditViewShareBtnContainerIndex] = useState(null);

  const [coverMediaHeight, setCoverMediaHeight] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  const handleContainerIndex = (index) => {
    for (let i = 0; i < fundraisersIManage.length; i++) {
      if (i == index) {
        setEditViewShareBtnContainerIndex(index);
      }
    }
  };

  const coverMediaRef = useCallback(
    (node) => {
      if (node !== null) {
        setCoverMediaHeight(node.getBoundingClientRect().width);
      }
    },
    [windowWidth, coverMediaHeight]
  );

  const editViewShareContainerRef = useRef();
  UseOnClickOutside(editViewShareContainerRef, () => setEditViewShareBtnContainerIndex(null));

  const [query, setQuery] = useState({
    page: 1,
    no_of_items_per_page: 5,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  })

  const action = match.params.action;
  const fundraiserId = match.params.id;

  const handleSetColumn = (mobileCol, tabletCol, desktopCol) => {
    if (windowWidth <= mobileBreakpoint) {
      return mobileCol;
    } else if (windowWidth <= desktopBreakpoint) {
      return tabletCol;
    } else if (windowWidth > desktopBreakpoint) {
      return desktopCol;
    }
  };

  const handleGetFundraisersIManage = async (query) => {
    setIsPageLoading([true, "Loading Fundraiser"]);

    const response = await dispatch(getAllFundraisersIManage(query))

    setIsPageLoading([false]);

    if (response.error) {
      NotificationManager.error(response.error.message, "Error!")
    } else {
      if (response.success == true) {
        setFundraisersIManage(response.data.fundraisers);
        setTotalFundraiserCount(response.data.total_count);

        setQuery(response.data.original_query_params)

        return response.data
      }
    }
  };

  const handleGetFundraiserByOwner = async (fundraiserId) => {
    if (!fundraiser) {
      setIsPageLoading([true, "Loading..."]);
    }
    const response = await dispatch(getFundraiserByOwner(fundraiserId));

    setIsPageLoading([false]);

    if (response.error) {
      NotificationManager.error(response.error.message, "Error!")
    } else {
      if (response.success == true) {
        setFundraiser(response.data);
        return response.data
      }
    }
  };

  useEffect(() => {
    if (visibleComponent) {
      // From the url query params
      setVisibleComponent(visibleComponent)
    } else {
      setVisibleComponent("myFundraisers")
    }

    handleGetFundraisersIManage(query)
      .then(data => {
        if (data) {
          setInitialFundraiserCount(data.total_count)
        }
      })

    if ((action == "edit" || action == "view") && fundraiserId) {
      handleGetFundraiserByOwner(fundraiserId)
        .then((fundraiser) => {
          setFundraiser(fundraiser);
        });
    }
  }, []);

  return (
    <div>
      {!action ? (
        <div className="d-flex">
          <div className="col-9 padding-right-0 padding-right-lg-20 stretch-full-lg">
            <div className="padding-10 padding-md-20 bg-lighter-blue border-radius-12">
              <div className="row align-items-center">
                <div className="d-block-hidden-sm col-sm-7">
                  <h1 className="font-size-26 font-size-lg-28 logo-magenta">
                    Fundraiser
                  </h1>
                  <h4 className="padding-top-4 font-size-16 logo-magenta">
                    How it Works
                  </h4>
                  <div className="padding-top-4">
                    <p className="font-size-15 padding-bottom-10">
                      <i className="fa fa-circle font-size-10 logo-magenta margin-right-10"></i>
                      <span>Fill Fundraising details</span>
                    </p>
                    <p className="font-size-15 padding-bottom-10">
                      <i className="fa fa-circle font-size-10 logo-magenta margin-right-10"></i>
                      <span>Upload documents</span>
                    </p>
                    <p className="font-size-15 padding-bottom-10">
                      <i className="fa fa-circle font-size-10 logo-magenta margin-right-10"></i>
                      <span>Set up payment details</span>
                    </p>
                  </div>
                </div>

                <div className="col-5 d-flex justify-content-end">
                  <div>
                    <img
                      className="max-width-200"
                      src={fundraiserSetupIcon}
                    />
                    <div className="d-flex justify-content-center">
                      <button
                        onClick={() => history.push(`/dashboard/fundraiser/new`)}
                        className="d-block-hidden-sm btn max-width-180 margin-top-10 margin-bottom-10 padding-12 padding-sm-8 border-radius-10 bg-fundraiser-color white"
                      >
                        <b>Set Up a Fundraiser</b>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-7 d-flex justify-content-end">
                  <button
                    onClick={() => history.push(`/dashboard/fundraiser/new`)}
                    className="d-block-display-sm btn max-width-160 font-size-13 margin-left-6 padding-6 border-radius-10 bg-fundraiser-color white"
                  >
                    <b>Set Up a Fundraiser</b>
                  </button>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center font-size-12 padding-top-12 padding-top-sm-40 padding-bottom-10 padding-bottom-sm-20">
              <div
                className={
                  // isMyFundraisers == false
                  visibleComponent != "myFundraisers"
                    ? "btn padding-8 bg-transparent grey"
                    : "btn padding-8 border-radius-10 bg-logo-magenta white darkblue"
                }
                onClick={() => {
                  if (visibleComponent != "myFundraisers") {
                    history.push(`/dashboard/fundraiser?visibleComponent=myFundraisers`)
                  }
                }}
              >
                <b>My Fundraisers</b>
              </div>
              <div
                className={
                  // isMyFundraisers == false
                  visibleComponent != "myCommitments"
                    ? "btn padding-8 bg-transparent grey"
                    : "btn padding-8 border-radius-10 bg-logo-magenta white darkblue"
                }
                onClick={() => {
                  if (visibleComponent != "myCommitments") {
                    history.push(`/dashboard/fundraiser?visibleComponent=myCommitments`)
                  }
                }}
              >
                <b>My Commitments</b>
              </div>
              <div
                className={
                  !visibleComponent || visibleComponent != "publicFundraisers"
                    ? "btn padding-8 bg-transparent grey"
                    : "btn padding-8 border-radius-10 bg-logo-magenta white darkblue"
                }
                onClick={() => {
                  if (visibleComponent != "publicFundraisers") {
                    history.push(`/dashboard/fundraiser?visibleComponent=publicFundraisers`)
                  }
                }}
              >
                <b>Public</b>
              </div>
            </div>

            {visibleComponent && visibleComponent == "myFundraisers" &&
              <div>
                {initialFundraiserCount > 0 &&
                  <div>
                    <FilterSorter
                      sorterOptions={[
                        { label: 'Created Date', value: 'created_at' },
                      ]}
                      initialSortBy={query.sort_by}
                      queryOrder={query.query_order}
                      filterOptions={[
                        {
                          status: [
                            { label: "All", value: '' },
                            { label: 'Form Pending', value: 'FORM_PENDING' },
                            { label: 'Pending', value: 'PENDING' },
                            { label: 'Active', value: 'ACTIVE' },
                            { label: 'Ended', value: 'ENDED' },
                            { label: 'Suspended', value: 'SUSPENDED' },
                            { label: 'Inactive', value: 'INACTIVE' },
                          ]
                        }
                      ]}
                      onSelectQuery={(values) => {
                        setIsSearching(true)
                        const newQueryObj = {
                          ...query,
                          ...values
                        }
                        handleGetFundraisersIManage(newQueryObj)
                      }}
                    />
                  </div>
                }

                {fundraisersIManage.length > 0 ? (
                  <div>
                    {fundraisersIManage.map((fundraiserDetails, index) => {
                      return (
                        <div key={index}
                          className="d-flex relative overflow-hidden margin-top-20 margin-bottom-20 box-shadow border-radius-6"
                        >
                          <div ref={coverMediaRef} className="col border-top-left-radius-8"
                            // className={"col-" + `${handleSetColumn(3, 3, 2)}`} 
                            style={{ maxHeight: coverMediaHeight + "px" }}
                          >
                            {fundraiserDetails.cover_media &&
                              <CoverMediaThumbnail
                                isBorderRadiusTopLeft={true}
                                key={fundraiserDetails.cover_media.embed_link}
                                coverMedia={fundraiserDetails.cover_media}
                                fundraiserDetails={fundraiserDetails}
                              />
                            }
                          </div>

                          <div className={"col-" + `${handleSetColumn(9, 10, 10)}`}>
                            <div className="row padding-6 padding-sm-10">
                              <div className="col-sm-8 padding-right-sm-10 stretch-full-sm">
                                <div className="d-flex align-items-center justify-content-between margin-top-10 margin-bottom-10">
                                  <h3 className="stretch-full font-size-15 font-size-sm-16 line-height-15 line-height-sm-20 logo-magenta">
                                    <Truncate lines={2}>
                                      {fundraiserDetails.title}
                                    </Truncate>
                                  </h3>
                                  <div
                                    onClick={() => handleContainerIndex(index)}
                                    className="d-flex d-flex-display-sm justify-content-center align-items-center cursor-pointer bg-pale-navy padding-6 rounded-circle fixed-width-20 fixed-height-20"
                                  >
                                    <i className="fas fa-ellipsis-v font-size-14 hover-logo-magenta dark-grey"></i>
                                  </div>

                                  {editViewShareBtnContainerIndex == index ? (
                                    <div
                                      ref={editViewShareContainerRef}
                                      className="d-flex flex-column absolute top-0 bottom-0 right-0 d-flex-display-sm justify-content-between padding-4 padding-left-14 padding-right-14 logo-magenta bg-pale-navy radius-4">
                                      <span
                                        onClick={() => {
                                          history.push(
                                            `/dashboard/fundraiser/edit/${fundraiserDetails.id}`
                                          );
                                        }}
                                        className="font-size-14 hover-logo-magenta dodgerblue cursor-pointer">
                                        Edit
                                      </span>
                                      <span
                                        onClick={() => {
                                          history.push(
                                            `/dashboard/fundraiser/view/${fundraiserDetails.id}?canGoBack=true`
                                          );
                                        }} className="font-size-14 hover-logo-magenta orange-red cursor-pointer">View</span>
                                      <span
                                        onClick={() => {
                                          handleGetFundraiserByOwner(
                                            fundraiserDetails.id
                                          ).then((fundraiser) => {
                                            setFundraiserPreview(fundraiser);
                                            setIsFundraiserPreviewModal(true);
                                          });
                                        }}
                                        className="font-size-14 hover-logo-magenta orange-red cursor-pointer">Preview</span>

                                      <span
                                        onClick={() => {
                                          setShareFundraiserDetails(
                                            fundraiserDetails
                                          );
                                          setIsShareOptionsVisible(true);
                                        }}
                                        className="font-size-14 hover-logo-magenta orange cursor-pointer" >Share</span>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="d-flex margin-top-4 margin-bottom-4 margin-md-bottom-6">
                                  <div className="margin-top-6 margin-right-6 margin-bottom-6">
                                    <p className="font-size-13 line-height-14 line-height-sm-16">
                                      {moment(
                                        fundraiserDetails.created_at
                                      ).format("Do MMMM YYYY")}
                                    </p>
                                    <div className="font-size-12 line-height-14 grey">
                                      Created Date
                                    </div>
                                  </div>
                                  <div className="margin-top-6 margin-right-6 margin-bottom-6">
                                    {fundraiserDetails.end_date && <div>
                                      <p className="font-size-13 line-height-14 line-height-sm-16">
                                        {moment(
                                          fundraiserDetails.end_date
                                        ).format("Do MMMM YYYY")}
                                      </p>
                                      <div className="font-size-12 line-height-14 grey">
                                        End Date
                                      </div></div>}

                                  </div>
                                </div>
                                <div className="d-flex justify-content-between padding-top-10">
                                  <div>
                                    <p className="font-size-13 line-height-14 line-height-sm-16">
                                      # {fundraiserDetails.id}
                                    </p>
                                    <div className="font-size-12 line-height-14 grey">
                                      I.D
                                    </div>
                                  </div>
                                  <div>
                                    <p className="stretch-full font-size-14 line-height-15 line-height-sm-20">
                                      {fundraiserDetails.is_public ? "Public" : "Private"}
                                    </p>
                                    <div className="font-size-12 line-height-14 grey">
                                      Type
                                    </div>
                                  </div>
                                  <div>
                                    <p className="font-size-13 line-height-14 line-height-sm-16">
                                      {convertSnakeToStandardCasing(fundraiserDetails.status)}
                                    </p>
                                    <div className="font-size-12 line-height-14 grey">
                                      Status
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-sm-4 d-flex flex-column justify-content-between padding-left-2 padding-left-lg-8 ${windowWidth >= 576 && "border-color-blue border-left-2"}`}>


                                <ProgressBar status={fundraiserDetails.percent_raised} />

                                {fundraiserDetails.required_actions.length ? (
                                  <div className="d-flex align-items-center padding-top-10">
                                    <i className="fa fa-circle font-size-8 red padding-right-4"></i>
                                    <b
                                      className="font-size-11 red"
                                    >
                                      Action Required
                                    </b>
                                  </div>
                                ) : null}

                                <b className="font-size-13 padding-top-10 padding-bottom-10">
                                  <Truncate lines={1}>
                                    <FormattedMoney
                                      value={fundraiserDetails.amount_raised}
                                      currency={
                                        fundraiserDetails.goal_amount_currency
                                      }
                                    />
                                    &nbsp;raised
                                    {fundraiserDetails.is_public && (
                                      <span>
                                        &nbsp;of&nbsp;
                                        <FormattedMoney
                                          value={fundraiserDetails.goal_amount}
                                          currency={
                                            fundraiserDetails.goal_amount_currency
                                          }
                                        />
                                        &nbsp;target
                                      </span>
                                    )}
                                  </Truncate>
                                </b>

                                <div className="d-flex flex-wrap d-flex-hidden-sm justify-content-between">
                                  {fundraiserDetails.is_fundraiser_editable == true &&
                                    <div onClick={() => {
                                      history.push(`/dashboard/fundraiser/edit/${fundraiserDetails.id}`)
                                    }}
                                      className="col-3 padding-right-2">
                                      <button className="btn stretch-full padding-2 padding-top-4 padding-bottom-4 font-size-11 bg-logo-magenta white radius-8">
                                        Edit
                                      </button>
                                    </div>
                                  }

                                  {/* {fundraiserDetails.is_fundraiser_editable == true ?
                                    <div
                                      onClick={() => {
                                        handleGetFundraiserByOwner(
                                          fundraiserDetails.id
                                        ).then((fundraiser) => {
                                          setFundraiserPreview(fundraiser);
                                          setIsFundraiserPreviewModal(true);
                                        });
                                      }}
                                      className="col-3 padding-left-2 padding-right-2">
                                      <button className="btn stretch-full padding-2 padding-top-4 padding-bottom-4 font-size-11 bg-orange-red border-color-orang-red white radius-8">
                                        Preview
                                      </button>
                                    </div> : <div onClick={() => {
                                      history.push(
                                        `/dashboard/fundraiser/view/${fundraiserDetails.id}`
                                      );
                                    }}
                                      className="col-3 padding-left-2 padding-right-2">
                                      <button className="btn stretch-full padding-2 padding-top-4 padding-bottom-4  font-size-11 bg-orange border-color-orange white radius-8">
                                        View
                                      </button>
                                    </div>
                                  } */}
                                  <div
                                    onClick={() => {
                                      handleGetFundraiserByOwner(
                                        fundraiserDetails.id
                                      ).then((fundraiser) => {
                                        setFundraiserPreview(fundraiser);
                                        setIsFundraiserPreviewModal(true);
                                      });
                                    }}
                                    className="col-3 padding-left-2 padding-right-2">
                                    <button className="btn stretch-full padding-2 padding-top-4 padding-bottom-4 font-size-11 bg-orange-red border-color-orang-red white radius-8">
                                      Preview
                                    </button>
                                  </div>

                                  <div onClick={() => {
                                    history.push(
                                      `/dashboard/fundraiser/view/${fundraiserDetails.id}?canGoBack=true`
                                    );
                                  }}
                                    className="col-3 padding-left-2 padding-right-2">
                                    <button className="btn stretch-full padding-2 padding-top-4 padding-bottom-4  font-size-11 bg-orange border-color-orange white radius-8">
                                      View
                                    </button>
                                  </div>

                                  <div className="col-3 padding-left-2">
                                    <button
                                      className="btn stretch-full padding-2 padding-top-4 padding-bottom-4  font-size-11 bg-normal-green border-color-normal-green white radius-8"
                                      onClick={() => {
                                        setShareFundraiserDetails(
                                          fundraiserDetails
                                        );
                                        setIsShareOptionsVisible(true);
                                      }}
                                    >
                                      Share
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <center className="padding-top-40 padding-bottom-40">
                    <img className="d-block max-width-80" src={fundraiserIcon} />
                    <p className="font-size-18 logo-magenta">You do not have any fundraiser {isSearching ? "that matches this query" : null}</p>
                  </center>
                )}

                {
                  totalFundraiserCount > query.no_of_items_per_page ?
                    <NewPagination
                      {...query}
                      handleGetQuery={handleGetFundraisersIManage}
                    /> :
                    null
                }

              </div>}

            {visibleComponent && visibleComponent == "myCommitments" &&
              <div className="flex-1">
                <MyCommitments
                  setIsPageLoading={setIsPageLoading}
                  history={history}
                />
              </div>}

            {visibleComponent && visibleComponent == "publicFundraisers" &&
              <div>
                <AllFundraisers
                  isDashboard={true}
                  noOfRange={12}
                  setIsPageLoading={setIsPageLoading}
                />
              </div>}
          </div>

          {/* <div className="col-3 d-block-hidden-lg">
            <p className="margin-bottom-10">RECENT FUNDRAISER ACTIVITIES</p>
            <MyRecentActivities recentActivities={recentActivities} />
          </div> */}
        </div>
      ) : null}

      {action == "new" || (action == "edit" && fundraiserId) ? (
        <div className="setup-modal max-width-1000">
          <div className="padding-6 padding-left-md-40 padding-right-md-40 padding-top-md-30 padding-bottom-md-30">
            <FundraiserSetupSlides
              history={history}
              fundraiserId={fundraiserId}
              fundraiser={fundraiser}
              isCreatingNew={action == "new" ? true : false}
              setIsPageLoading={setIsPageLoading}
              setIsCreatingNew={setIsCreatingNew}
              setFundraiser={setFundraiser}
              handleGetFundraisersIManage={() => {
                handleGetFundraisersIManage(query);
              }}
            />
          </div>
        </div>
      ) : null}

      {action == "view" && fundraiser ? (
        <div className="padding-left-10 padding-right-10">
          <div className="d-inline-block">
            <NavLink to="/dashboard/fundraiser"
              className="d-flex align-center text-decoration-none align-items-center logo-magenta hover-dodgerblue cursor-pointer"
            >
              <i className="fas fa-long-arrow-alt-left font-size-24 font-size-sm-30 padding-right-10"></i>
              <span className="d-block-hidden-sm font-size-16 font-size-sm-18">
                Dashboard
              </span>
            </NavLink>
          </div>

          <h1 className="padding-top-8 padding-bottom-8 padding-left-0 padding-right-0 padding-left-md-20 padding-right-md-20 logo-magenta font-size-21 font-size-sm-24">
            <Truncate lines={2}>
              {fundraiser.fundraiser_details.title}
            </Truncate>
          </h1>

          <div className="horizontal-line" />

          <FundraiserView
            history={history}
            setIsPageLoading={setIsPageLoading}
            setIsCreatingNew={null}
            canEdit={false}
            fundraiser={fundraiser}
            setFundraiser={setFundraiser}
            setFundraiserPreview={setFundraiserPreview}
          />
        </div>
      ) : null}

      <Modal
        ariaHideApp={false}
        isOpen={isFundraiserPreviewModal}
        onRequestClose={() => setIsFundraiserPreviewModal(false)}
        className="modal max-width-1000 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-50 padding-right-md-50 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Preview
          </h1>
          <i
            onClick={() => setIsFundraiserPreviewModal(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "80vh" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40"
          >
            <PreviewFundraiser
              isSetupPreview={true}
              fundraiser={fundraiserPreview}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isFundraiserSummaryVisible}
        onRequestClose={() => setIsFundraiserSummaryVisible(false)}
        className="modal max-width-950 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Fundraiser View
          </h1>
          <i
            onClick={() => setIsFundraiserSummaryVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-logo-magenta"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <FundraiserView
              history={history}
              setIsPageLoading={setIsPageLoading}
              setIsCreatingNew={null}
              canEdit={false}
              fundraiser={fundraiserPreview}
              setFundraiser={setFundraiser}
              setFundraiserPreview={setFundraiserPreview}
            />
          </div>
        </div>
      </Modal>


      <Modal
        ariaHideApp={false}
        isOpen={isShareOptionsVisible}
        onRequestClose={() => setIsShareOptionsVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-20 padding-right-md-20 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Please Share
          </h1>
          <i
            onClick={() => setIsShareOptionsVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-logo-magenta"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-10 padding-left-md-10 padding-right-md-10"
          >
            <ShareOptions fundraiserDetails={shareFundraiserDetails} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default connect()(Fundraiser);
