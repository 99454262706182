import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from 'react-spring'
import { connect } from "react-redux";
import moment from "moment";
import Modal from "react-modal";
import { NotificationManager } from 'react-notifications';
import { useLocation } from "react-router-dom";
import { convertSnakeToStandardCasing, fetchWalletBalances } from "../../utilities";
import {
  getWalletTransactions,
  getTransaction
} from "../../store/actions/pouchWallet";
import { getTransferDetails } from "../../store/actions/transaction"
import { getUser } from "../../store/actions/auth";
import TopUpWallet from "./pouchWallet/TopUpWallet";
import MakeWithdrawal from "./pouchWallet/MakeWithdrawal";
import TransferCredit from "./pouchWallet/TransferCredit";
import BalancesSlider from "./pouchWallet/BalancesSlider";
import TransactionDetails from "./pouchWallet/TransactionDetails";
import pouchWalletImg from "../images/pouch_wallet_img.svg";
import pouchWalletIcon from "../images/pouch_wallet_icon_darkblue.svg";
import FormattedMoney from "../utilityComponents/FormattedMoney";
import AllBalances from "./pouchWallet/AllBalances";
import FilterSorter from "../utilityComponents/FilterSorter";
import NewPagination from "../utilityComponents/NewPagination";

const PouchWallet = ({
  socketUrl,
  dispatch,
  user,
  setIsPageLoading,
  history,
  balances
}) => {

  const [animateTopUpBtn, handleAnimateTopUpBtn] = useSpring(() => ({ scale: 1 }))
  const [animateTransferCreditBtn, handleAnimateTransferCreditBtn] = useSpring(() => ({ scale: 1 }))
  const [animateWithdrawBtn, handleAnimateWithdrawBtn] = useSpring(() => ({ scale: 1 }))

  const balanceRef = useRef(null)

  const fadeStyles = useSpring({
    from: { opacity: 0 },
    config: { duration: 2000 },
    loop: {
      opacity: 1,
    },
  })

  const slideStyles = useSpring({
    from: { x: 100, opacity: 0 },
    config: { duration: 1000 },
    loop: {
      x: 0, opacity: 1,
    },
  })

  const query = useLocation().search

  const [subSegment] = useState((new URLSearchParams(query).get('q')))
  const [walletQueryParams, setWalletQueryParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  });
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [specificTransaction, setSpecificTransaction] = useState([]);
  const [isTopUpModalVisible, setIsTopUpModalVisible] = useState(false);
  const [isMakeWithdrawalVisible, setIsMakeWithdrawalVisible] = useState(false);
  const [isTransferCreditVisble, setIsTransferCreditVisble] = useState(false);
  const [isAllBalancesVisible, setIsAllBalancesVisible] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  // const [balances, setBalances] = useState([]);

  const [
    isWalletTransactionDetailsVisble,
    setIsWalletTransactionDetailsVisble,
  ] = useState(false);

  const [seeMoreRange, setSeeMoreRange] = useState(3);
  const [totalCount, setTotalCount] = useState(null);
  const [intitialTotalCount, setIntitialTotalCount] = useState(0);

  const [selectedTransferDetails, setSelectedTransferDetails] = useState(null)

  let currencies = user.supported_currencies.map(item => {
    return {
      label: item,
      value: item
    }
  })

  currencies = [{ label: "All", value: "" }, ...currencies]

  const handleGetTransfer = (transfer_id, wallet_txn_id) => {
    dispatch(getTransferDetails(transfer_id))
      .then(res => {
        if (res.error) {
          NotificationManager.error(res.error.message, 'Error Message!')
        } else {
          setSelectedTransferDetails({
            ...res.data,
            transfer_type: "wallet_to_bank",
            wallet_transaction_id: wallet_txn_id
          })
        }
      })
  }

  const handleGetSpecificWalletTxn = async (id) => {
    setIsPageLoading([true, "Loading..."]);

    const response = await dispatch(getTransaction(id))

    setIsPageLoading([false]);

    if (response.error) {
      NotificationManager.error(response.error.message, 'Error!')
    } else {
      if (response.success == true) {
        setSpecificTransaction(response.data);
        return response.data
      }
    }
  };

  const handleGetWalletTransactions = async data => {
    // setIsPageLoading([true, "Loading..."]);
    const response = await dispatch(getWalletTransactions(data))
    if (response.error) {
      NotificationManager.error(response.error.message, "Error!")
    } else {
      if (response.success == true) {
        setWalletTransactions(response.data.transactions);
        setTotalCount(response.data.total_count)
        setWalletQueryParams(response.data.original_query_params)
        return response.data
      }
    }
  };

  // const handleGetBalancesBySocket = () => {

  //   balanceRef.current = new WebSocket(
  //     `${socketUrl}/ws/pouch_wallet/${user.id}/connect`)

  //   balanceRef.current.onopen = e => {
  //     balanceRef.current.send(JSON.stringify({
  //       type: "wallet_balances",
  //       user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
  //     }))
  //   }

  //   balanceRef.current.onmessage = e => {
  //     const data = JSON.parse(e.data)

  //     if (data.type == "wallet_balances") {
  //       setBalances(data.balances)
  //     }
  //   }

  //   balanceRef.current.onerror = e => {
  //     console.log('error', e)
  //   }
  // }


  useEffect(() => {
    fetchWalletBalances(user)

    dispatch(getUser())

    handleGetWalletTransactions(walletQueryParams)
      .then(data => {
        if (data) {
          setIntitialTotalCount(data.total_count)
        }
      })

    // handleGetBalancesBySocket()

    if (subSegment == "topUp") {
      setIsTopUpModalVisible(true)
    }
  }, []);

  return (
    <div>
      <h1 className="logo-magenta font-size-20 font-size-sm-24 padding-bottom-16 padding-bottom-sm-30">
        Pouch Wallet
      </h1>
      <div className="row">
        <div className="col-sm-6 stretch-full-sm">
          <animated.div className="row max-width-480 stretch-full-sm padding-14 padding-md-28 border-radius-8 box-shadow"
            style={fadeStyles}>
            <div className="d-flex flex-column col-md-6 justify-content-between">
              <div className="font-size-17 font-size-sm-18 text-center logo-magenta padding-bottom-10">
                <b>Wallet Balance</b>
              </div>
              <BalancesSlider
                balances={balances}
              />
              <div className="d-flex align-items-center justify-content-center padding-top-14 padding-bottom-14">
                <button
                  className="btn bg-light-normal-blue"
                  type="button"
                  onClick={() => {
                    setIsAllBalancesVisible(true)
                  }}
                >See Details</button>
              </div>
            </div>
            <div className="col-md-6">
              <animated.button className="btn padding-10 stretch-full font-size-16 bg-orange white"
                style={animateTopUpBtn}
                onClick={() => setIsTopUpModalVisible(true)}
                onMouseEnter={() => {
                  handleAnimateTopUpBtn({ scale: 1.05 });
                }}
                onMouseLeave={() => {
                  handleAnimateTopUpBtn({ scale: 1 });
                }}
              >
                <b>Top Up</b>
              </animated.button>
              <animated.button
                className="btn margin-top-20 margin-bottom-20 padding-10 stretch-full font-size-16 bg-logo-magenta white"
                style={animateWithdrawBtn}
                onClick={() => setIsMakeWithdrawalVisible(true)}
                onMouseEnter={() => {
                  handleAnimateWithdrawBtn({ scale: 1.05 });
                }}
                onMouseLeave={() => {
                  handleAnimateWithdrawBtn({ scale: 1 });
                }}
              >
                <b>Withdraw</b>
              </animated.button>
              <animated.button
                className="btn padding-10 stretch-full font-size-16 bg-transparent logo-magenta border-color-logo-magenta"
                style={animateTransferCreditBtn}
                onClick={() => setIsTransferCreditVisble(true)}
                onMouseEnter={() => {
                  handleAnimateTransferCreditBtn({ scale: 1.05 });
                }}
                onMouseLeave={() => {
                  handleAnimateTransferCreditBtn({ scale: 1 });
                }}
              >
                <b>Transfer Credit</b>
              </animated.button>
            </div>
          </animated.div>
        </div>
        <div className="col-sm-6 d-block-hidden-sm">
          <animated.img style={slideStyles} className="max-width-300" src={pouchWalletImg} />
        </div>
      </div>

      <div className="row align-items-center justify-content-between font-size-sm-23 padding-top-10 padding-top-sm-20 logo-magenta">
        <b className="col-sm-9 font-size-20 font-size-sm-22 padding-top-10 padding-bottom-0 padding-bottom-sm-10 padding-right-0 padding-right-sm-10">
          Wallet Transactions
        </b>
      </div>

      <div className="font-size-10 font-size-md-12">
        {intitialTotalCount > 0 &&
          <div>
            <FilterSorter
              sorterOptions={[
                { label: 'Created Date', value: 'created_at' },
                { label: 'Amount', value: 'amount' }
              ]}
              initialSortBy={walletQueryParams.sort_by}
              queryOrder={walletQueryParams.query_order}
              filterOptions={[
                {
                  status: [
                    { label: "All", value: '' },
                    { label: 'Pending', value: 'PENDING' },
                    { label: 'Complete', value: 'COMPLETE' },
                    { label: 'Cancelled', value: 'CANCELLED' },
                    { label: 'Failed', value: 'FAILED' }
                  ]
                },
                {
                  destination: [
                    { label: "All", value: '' },
                    { label: 'Admin', value: 'admin' },
                    { label: 'Owner bank account', value: 'owner_bank_account' },
                    { label: 'Wallet', value: 'wallet' },
                  ]
                },
                {
                  source: [
                    { label: "All", value: '' },
                    { label: 'Pending', value: 'PENDING' },
                    { label: 'Complete', value: 'COMPLETE' },
                    { label: 'Cancelled', value: 'CANCELLED' },
                    { label: 'Failed', value: 'FAILED' },
                  ]
                },
                {
                  transaction_type: [
                    { label: "All", value: '' },
                    { label: 'Credit', value: 'credit' },
                    { label: 'Debit', value: 'debit' }
                  ]
                },
                {
                  currency: currencies
                }
              ]}
              onSelectQuery={(values) => {
                setIsSearching(true)
                const newQueryObj = {
                  ...walletQueryParams,
                  ...values
                }
                handleGetWalletTransactions(newQueryObj)
              }}
            />
          </div>
        }

        <div
          // style={{ maxHeight: "1000px" }}
          className="margin-bottom-20 padding-top-20"
        >
          {walletTransactions && walletTransactions.length > 0 ? (
            <div>
              <div className="row d-flex-hidden-sm align-items-center padding-8">
                <div className="col-sm-6 row align-items-center">
                  <div className="col-3 padding-right-2">ID</div>
                  <div className="col-5 padding-left-2 padding-right-2">Status</div>
                  <div className="col-4 padding-left-2 padding-right-2">Amount</div>
                </div>
                <div className="col-sm-6 row align-items-center">
                  <div className="col-3 padding-left-2 padding-right-2">Type</div>
                  <div className="col-5 padding-left-2 padding-right-2">
                    Dest./Source
                  </div>
                  <div className="col-4 padding-left-2 padding-right-2">Date</div>
                </div>
              </div>
              <div>
                {walletTransactions.map((walletTransaction, index) => {
                  return (
                    <div
                      style={{ maxHeight: "200px" }}
                      className="box-shadow bg-white border-radius-6 margin-bottom-14 overflow-scroll-y padding-left-8 padding-right-8"
                      key={index}
                    >
                      <div className=" row align-items-center padding-top-10 padding-bottom-10 font-size-12">
                        <div className="col-sm-6 row align-items-center margin-bottom-6">
                          <div className="col-3 padding-right-2">
                            <div className="d-block-display-sm grey">
                              ID
                            </div>
                            <div className="text-break">
                              <b>#{walletTransaction.id}</b>
                            </div>
                          </div>
                          <div className="col-5" >
                            <div className="d-block-display-sm grey">
                              Status
                            </div>
                            <div
                              className={`d-flex align-items-center padding-left-2 padding-right-2 ${walletTransaction.status == "COMPLETE"
                                ? "normal-green"
                                : ""
                                } ${walletTransaction.status == "PENDING"
                                  ? "light-normal-blue"
                                  : ""
                                } ${walletTransaction.status == "CANCELLED"
                                  ? "orange-red"
                                  : ""
                                }`}
                            >
                              <div
                                className={`d-flex align-items-center justify-content-center margin-top-2 margin-right-4 fixed-width-20 fixed-height-20 
                               rounded-circle ${walletTransaction.status == "COMPLETE"
                                    ? "bg-lighter-normal-green"
                                    : ""
                                  } ${walletTransaction.status == "PENDING"
                                    ? "bg-lighter-normal-blue"
                                    : ""
                                  } ${walletTransaction.status == "CANCELLED"
                                    ? "bg-light-pink"
                                    : ""
                                  }`}
                              >
                                <i className="fa fa-circle font-size-9 font-size-lg-10"></i>
                              </div>
                              <div><b>{convertSnakeToStandardCasing(walletTransaction.status)}</b></div>
                            </div>
                          </div>
                          <div className="col-4 padding-left-2 padding-right-2">
                            <div className="d-block-display-sm grey">
                              Amount
                            </div>
                            <div>
                              <b>
                                <FormattedMoney
                                  value={walletTransaction.amount}
                                  currency={walletTransaction.amount_currency}
                                />
                              </b>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 row align-items-center padding-top-6 padding-top-sm-0 margin-bottom-6">
                          <div className="col-3 padding-left-0 padding-left-sm-2 padding-right-2">
                            <div className="d-block-display-sm grey">
                              Type
                            </div>
                            <div>
                              <b>
                                {convertSnakeToStandardCasing(walletTransaction.transaction_type)}
                              </b>
                            </div>
                          </div>

                          <div className="col-5 padding-left-0 padding-left-sm-2 padding-right-2">
                            <div className="d-block-display-sm grey">
                              Date
                            </div>
                            <div>
                              <b>
                                {moment(walletTransaction.created_at).format(
                                  "DD MMM 'YY, HH:mm a"
                                )}
                              </b>
                            </div>
                          </div>

                          <div className="col-4 padding-left-2">
                            <animated.button
                              className="btn max-width-70 bg-logo-magenta white border-radius-6 font-size-9 font-size-md-10 padding-4 padding-sm-6"
                              onClick={async () => {
                                const res = await handleGetSpecificWalletTxn(walletTransaction.id)
                                if (res) {
                                  setIsWalletTransactionDetailsVisble(true);
                                }
                              }}
                            >
                              <b>Details</b>
                            </animated.button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {
                (walletTransactions && (walletQueryParams.total_count > walletQueryParams.no_of_items_per_page)) ?
                  <NewPagination
                    {...walletQueryParams}
                    handleGetQuery={handleGetWalletTransactions}
                  /> :
                  null
              }
            </div>
          ) : (
            <center className="padding-top-40 padding-bottom-40">
              <img className="d-block max-width-70" src={pouchWalletIcon} />
              <h1 className="font-size-18 logo-magenta">You do not have any wallet transaction {isSearching && "that matches this query"}</h1>
            </center>
          )}
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isTopUpModalVisible}
        onRequestClose={() => {
          setIsTopUpModalVisible(false)
          history.push("/dashboard/pouch-wallet")
        }}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Top Up Your Wallet
          </h1>
          <i
            onClick={() => {
              setIsTopUpModalVisible(false)
              history.push("/dashboard/pouch-wallet")
            }}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line" />
        <div className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40">
          <TopUpWallet
            handleClose={() => {
              setIsTopUpModalVisible(false)
              history.push("/dashboard/pouch-wallet")
            }} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isMakeWithdrawalVisible}
        onRequestClose={() => setIsMakeWithdrawalVisible(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Withdraw to a Bank Account
          </h1>
          <i
            onClick={() => setIsMakeWithdrawalVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line" />
        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <MakeWithdrawal
            setIsPageLoading={setIsPageLoading}
            balances={balances}
            handleClose={() => {
              setIsMakeWithdrawalVisible(false)
              history.push("/dashboard/pouch-wallet")
            }}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isTransferCreditVisble}
        onRequestClose={() => setIsTransferCreditVisble(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Transfer to Another User
          </h1>
          <i
            onClick={() => setIsTransferCreditVisble(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line" />
        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <TransferCredit
            setIsPageLoading={setIsPageLoading}
            balances={balances}
            handleClose={() => {
              setIsTransferCreditVisble(false)
              history.push("/dashboard/pouch-wallet")
            }} 
            setSpecificTransaction={setSpecificTransaction} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isWalletTransactionDetailsVisble}
        onRequestClose={() => setIsWalletTransactionDetailsVisble(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Wallet Transaction Details
          </h1>
          <i
            onClick={() => setIsWalletTransactionDetailsVisble(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line" />
        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <TransactionDetails
            specificTransaction={specificTransaction}
            setIsPageLoading={setIsPageLoading}
            walletQueryParams={walletQueryParams}
            handleGetWalletTransactions={handleGetWalletTransactions}
            handleGetTransfer={handleGetTransfer}
            handleGetSpecificWalletTxn={handleGetSpecificWalletTxn}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isAllBalancesVisible}
        onRequestClose={() => setIsAllBalancesVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            My Pouch Wallet Balance
          </h1>
          <i
            onClick={() => setIsAllBalancesVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line" />
        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <AllBalances
            balances={balances}
          />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    balances: state.auth.walletBalances,
    socketUrl: state.auth.socketUrl
  };
};

export default connect(mapStateToProps)(PouchWallet);
