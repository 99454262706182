import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import moment from "moment"
import { publicGetDonationsList, getReceivedMerchandiseOrders, managerUpdatesMerchandiseOrderStatus } from "../../../../../store/actions/fundraiser"
import { NotificationManager } from "react-notifications";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";
import MerchandiseOrderDetails from "../../public/components/MerchandiseOrderDetails";
import { convertSnakeToStandardCasing, getConfirmAlert } from "../../../../../utilities";
import ViewDonation from "./ViewDonation";
import FilterSorter from "../../../../utilityComponents/FilterSorter";
import defaultAvatar from "../../../../images/defaultAvatar.png"
import NewPagination from "../../../../utilityComponents/NewPagination";

const Commitments = ({
  dispatch, fundraiserDetails, fundraiser_thumbnail
}) => {
  const [donationsQueryParams, setDonationsQueryParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  })
  const [ordersQueryParams, setOrdersQueryParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  })
  const [donationsObj, setDonationsObj] = useState(null)
  const [merchandiseOrdersObj, setMerchandiseOrdersObj] = useState(null)
  const [visibleComponent, setVisibleComponent] = useState("donations")
  const [isMerchandiseOrderVisible, setIsMerchandiseOrderVisible] = useState(false);
  const [openedOrderDetails, setOpenedOrderDetails] = useState({});
  const [openedDonation, setOpenedDonation] = useState({});
  const [isViewDonationVisible, setIsViewDonationVisible] = useState(false);
  const [initialDonationCount, setInitialDonationCount] = useState(0);
  const [initialMerchandiseOrderCount, setInitialMerchandiseOrderCount] = useState(0);
  const [isSearchingDonations, setIsSearchingDonations] = useState(false);
  const [isSearchingMerchandiseOrders, setIsSearchingMerchandiseOrders] = useState(false);

  const handleGetDonations = async (donationsQueryParams) => {
    const res = await dispatch(publicGetDonationsList({
      ...donationsQueryParams,
      fundraiser_slug: fundraiserDetails.slug,
    }))
    if (res.error) {
      NotificationManager.error(res.error.message, "Error!")
    } else {
      if (res.success == true) {
        setDonationsObj(res.data)
        setDonationsQueryParams(res.data.original_query_params)
        return res.data
      }
    }
  }

  const handleGetMerchandiseOrders = async (ordersQueryParams) => {
    const res = await dispatch(getReceivedMerchandiseOrders({
      ...ordersQueryParams,
      fundraiser_id: fundraiserDetails.id,
    }))
    if (res.error) {
      NotificationManager.error(res.error.message, "Error!")
    } else {
      setMerchandiseOrdersObj(res.data)
      setOrdersQueryParams(res.data.original_query_params)
      return res.data
    }
  }

  const handleChangeOrderStatus = (data) => {
    dispatch(managerUpdatesMerchandiseOrderStatus(data))
      .then(res => {
        if (res.error) {
          NotificationManager.error(res.error.message, "Error!")
        } else {
          if (res.success == true) {
            NotificationManager.success(res.message, "Success!")
            setOpenedOrderDetails(res.data)
            handleGetMerchandiseOrders(ordersQueryParams)
          }
        }
      })
      .catch(err => {

      })
  }

  useEffect(() => {
    handleGetDonations(donationsQueryParams)
      .then(data => {
        if (data) {
          setInitialDonationCount(data.total_count)
        }
      })

    handleGetMerchandiseOrders(ordersQueryParams)
      .then(data => {
        if (data) {
          setInitialMerchandiseOrderCount(data.total_count)
        }
      })
  }, [])

  return (
    <div className="padding-bottom-20">
      <div className="d-flex margin-bottom-20">
        <div
          className={`btn padding-8 margin-right-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "donations" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange logo-magenta"}`}
          onClick={() => setVisibleComponent("donations")}>
          Donations
        </div>
        <div
          className={`btn padding-8 margin-left-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "merchandiseOrders" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange logo-magenta"}`}
          onClick={() => setVisibleComponent("merchandiseOrders")}>
          Merchandise Orders
        </div>
      </div>

      <div>
        {visibleComponent == "donations" &&
          <div>

            {initialDonationCount > 0 &&
              <div className="margin-bottom-24">
                <FilterSorter
                  sorterOptions={[
                    { label: 'Date', value: 'created_at' },
                  ]}
                  initialSortBy={donationsQueryParams.sort_by}
                  queryOrder={donationsQueryParams.query_order}
                  filterOptions={[
                    {
                      status: [
                        { label: "All", value: '' },
                        { label: 'Pending', value: 'PENDING' },
                        { label: 'Cleared', value: 'CLEARED' },
                        { label: 'Declined', value: 'DECLINED' },
                        { label: 'Refunded', value: 'REFUNDED' },
                      ]
                    }
                  ]}
                  onSelectQuery={(values) => {
                    setIsSearchingDonations(true)
                    const newQueryObj = {
                      ...donationsQueryParams,
                      ...values
                    }
                    handleGetDonations(newQueryObj)
                  }}
                />
              </div>}

            {donationsObj && donationsObj.donations.length > 0 ?
              <div className="font-size-12 font-size-md-14 border-color-light-grey border-radius-4">
                {donationsObj.donations.map((donation, index) => {
                  return (
                    <div key={index} >
                      <div
                        onClick={() => {
                          setOpenedDonation(donation)
                          setIsViewDonationVisible(true)
                        }}
                        className="row cursor-pointer box-shadow logo-magenta margin-bottom-20 border-radius-6 padding-top-6 padding-bottom-6 padding-left-10 padding-right-10">
                        <div className="d-flex col-md-4">
                          <div className="col-4 padding-top-4 padding-bottom-4 padding-4">
                            <label className="grey font-size-13 line-height-13"></label>
                            <img
                              width="40"
                              height="40"
                              className="border-radius-20 d-block"
                              src={(donation.profile_picture && !donation.is_anonymous) ?
                                donation.profile_picture :
                                defaultAvatar} />

                          </div>
                          <div className="col-4 padding-top-4 padding-bottom-4 padding-4">
                            <label className="grey font-size-13 line-height-13">ID</label>
                            <p className="font-size-14 line-height-14">#{donation.id}</p>
                          </div>
                          <div className="col-4 padding-4">
                            <label className="grey font-size-13 line-height-13">Status</label>
                            <p className="font-size-14 line-height-14">{convertSnakeToStandardCasing(donation.status)}</p>
                          </div>
                        </div>
                        <div className="d-flex col-md-4">
                          <div className="col-8 padding-4">
                            <label className="grey font-size-13 line-height-13">Donor</label>
                            <p className="font-size-14 line-height-14 text-break">{donation.is_anonymous ? "Anonymous" : donation.first_name + " " + donation.last_name}</p>
                          </div>
                          <div className="col-4 padding-4">
                            <label className="grey font-size-13 line-height-13">Settled Amount</label>
                            <div className="font-size-14 line-height-14 normal-green">
                              <FormattedMoney value={donation.settled_amount} currency={donation.settled_amount_currency} /></div>
                          </div>
                        </div>
                        <div className="d-flex col-md-4">
                          <div className="col-8 padding-top-4 padding-bottom-4 padding-4">
                            <label className="grey font-size-13 line-height-13">Date</label>
                            <p className="font-size-14 line-height-14">{moment(donation.created_at).format("DD MMM YYYY, HH:mm a")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {
                  donationsObj && donationsObj.total_count > donationsQueryParams.no_of_items_per_page ?
                    <NewPagination
                      {...donationsQueryParams}
                      handleGetQuery={handleGetDonations}
                    /> :
                    null
                }
              </div>
              :
              <center className="font-size-14 grey padding-top-20 padding-bottom-20">
                You do not have any donation history {isSearchingDonations && <span>that matches this query</span>}
              </center>}
          </div>}

        {visibleComponent == "merchandiseOrders" &&
          <div>
            {initialMerchandiseOrderCount > 0 &&
              <div className="margin-bottom-24">
                <FilterSorter
                  sorterOptions={[
                    { label: 'Date', value: 'created_at' },
                  ]}
                  initialSortBy={ordersQueryParams.sort_by}
                  queryOrder={ordersQueryParams.query_order}
                  filterOptions={[
                    {
                      status: [
                        { label: "All", value: '' },
                        { label: 'Pending', value: 'PENDING' },
                        { label: 'Fulfilled', value: 'FULFILLED' },
                        { label: 'Cancelled', value: 'CANCELLED' }
                      ]
                    }
                  ]}
                  onSelectQuery={(values) => {
                    setIsSearchingMerchandiseOrders(true)
                    const newQueryObj = {
                      ...ordersQueryParams,
                      ...values
                    }
                    handleGetMerchandiseOrders(newQueryObj)
                  }}
                />
              </div>}

            {merchandiseOrdersObj && merchandiseOrdersObj.orders.length > 0 ?
              <div>
                {merchandiseOrdersObj.orders.map((order, index) => {
                  return (
                    <div
                      className="row box-shadow logo-magenta cursor-pointer margin-bottom-20 border-radius-6 padding-top-6 padding-bottom-6 padding-left-10 padding-right-10"
                      key={index}
                      onClick={() => {
                        setOpenedOrderDetails(order)
                        setIsMerchandiseOrderVisible(true)
                      }}
                    >
                      <div className="row col-md-4">
                        <div className="col-4 padding-4">
                          <label className="grey font-size-13 line-height-13"></label>
                          <img
                            width="40"
                            height="40"
                            className="border-radius-20 d-block"
                            src={order.profile_picture ? order.profile_picture : defaultAvatar} />
                        </div>
                        <div className="col-4 padding-4">
                          <label className="grey font-size-13 line-height-13">Order ID</label>
                          <p className="font-size-14 line-height-14"># {order.id}</p>
                        </div>
                        <div className="col-4 padding-4">
                          <label className="grey font-size-13 line-height-13">Status</label>
                          <p className="font-size-14 line-height-14">
                            {convertSnakeToStandardCasing(order.status)}
                          </p>
                        </div>
                      </div>
                      <div className="row col-md-4">
                        <div className="col-8 padding-4">
                          <label className="grey font-size-13 line-height-13">Buyer</label>
                          <p className="font-size-14 line-height-14">
                            {order.first_name} {order.last_name}
                          </p>
                        </div>
                        <div className="col-4 padding-4">
                          <label className="grey font-size-13 line-height-13">Settled Amount</label>
                          <div className="font-size-14 line-height-14 normal-green">
                            <FormattedMoney value={order.settled_amount} currency={order.settled_amount_currency} />
                          </div>
                        </div>
                      </div>
                      <div className="row col-md-4">
                        <div className="col-8 padding-4">
                          <label className="grey font-size-13 line-height-13">Date</label>
                          <p className="font-size-14 line-height-14">
                            {moment(order.created_at).format("DD MMM YYYY, HH:mm a")}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {
                  merchandiseOrdersObj && merchandiseOrdersObj.total_count > ordersQueryParams.no_of_items_per_page ?
                    <NewPagination
                      {...ordersQueryParams}
                      handleGetQuery={handleGetMerchandiseOrders}
                    /> :
                    null
                }
              </div>
              :
              <center className="font-size-14 grey text-center">
                You do not have any merchandise order history {isSearchingMerchandiseOrders && <span>that matches this query</span>}
              </center>
            }
          </div>
        }
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isMerchandiseOrderVisible}
        onRequestClose={() => setIsMerchandiseOrderVisible(false)}
        className="modal max-width-960 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Merchandise Order Details
          </h1>
          <i
            onClick={() => setIsMerchandiseOrderVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div
          className="overflow-scroll-y padding-8 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          style={{ maxHeight: "80vh" }}
        >
          {
            openedOrderDetails.status == "PENDING" &&
            <div className="d-flex align-items-center margin-bottom-10">
              <button
                className="btn bg-orange-red padding-8 max-width-120 margin-right-20 font-size-15"
                type="button"
                onClick={() => {
                  getConfirmAlert({
                    title: "Confirm!",
                    message: `Are you sure you want to cancel this order? You won't be able to reverse it`,
                    onClickYes: () => {
                      handleChangeOrderStatus({
                        order_id: openedOrderDetails.id,
                        new_status: "CANCELLED"
                      })
                    },
                    onClickNo: () => null
                  })
                }}
              >
                Cancel Order
              </button>
              <button
                className="btn bg-blue padding-8 max-width-120 font-size-15"
                type="button"
                onClick={() => {
                  getConfirmAlert({
                    title: "Confirm!",
                    message: `Are you sure you want to mark this order as fufilled? You won't be able to reverse it`,
                    onClickYes: () => {
                      handleChangeOrderStatus({
                        order_id: openedOrderDetails.id,
                        new_status: "FULFILLED"
                      })
                    },
                    onClickNo: () => null
                  })
                }}
              >
                Fulfill Order
              </button>
            </div>
          }
          <MerchandiseOrderDetails
            thumbnail_url={fundraiser_thumbnail}
            orderDetails={openedOrderDetails}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isViewDonationVisible}
        onRequestClose={() => setIsViewDonationVisible(false)}
        className="modal max-width-960 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Donation Details
          </h1>
          <i
            onClick={() => setIsViewDonationVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div className="padding-8">
          <div
            style={{ maxHeight: "80vh" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <ViewDonation
              donation={openedDonation}
            />
          </div>
        </div>

      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Commitments);
