import React, { useState } from "react";
import Modal from "react-modal";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import QRCode from 'qrcode.react'
import {
  getAuthenticatorAppUriWhenNotAuthenticated,
  retrieveUserSecurityQuestions,
  sendEmailAuthenticationOtp,
  sendPhoneOtp,
  sendPhoneOtpPreLogin,
  verifySecurityAnswerWithEmailPassword
} from "../../store/actions/auth";
import RetrieveAuthenticatorQRCode from "./RetrieveAuthenticatorQRCode";

const MultiFactorAuth = ({
  isAuthenticated,
  dispatch,
  is_authenticator_app_enabled,
  is_phone_two_factor_enabled,
  handleSocialLogin,
  handleLogin,
  values,
  socialAccessToken,
  socialMediaType,
  isUsingSocialAuthentication,
  appleAuthCredentials,
  authenticationMethod
}) => {
  const [phoneOtp, setPhoneOtp] = useState(null);
  const [authenticatorAppOtp, setAuthenticatorAppOtp] = useState(null);
  const [retrievedSecurityData, setRetrievedSecurityData] = useState({});
  const [retrievedAuthenticatorUri, setRetrievedAuthenticatorUri] = useState(null);
  const [isAuthenticatorQRCodeVisible, setIsAuthenticatorQRCodeVisible] = useState(null);
  const [isRetrieveQRCodeVisible, setIsRetrieveQRCodeVisible] = useState(false);
  const [isEmailAuthOptVisible, setIsEmailAuthOptVisible] = useState(false);

  const handleSendPhoneOtpPreLogin = async (data) => {
    const res = await dispatch(sendPhoneOtpPreLogin(data))
    if (res.error) {
      NotificationManager.error(res.error.message, "Error!")
    } else {
      if (res.success == true) {
        NotificationManager.success(res.message, "Success!")
      }
    }
  }

  const handleSendEmailAuthenticationOtp = async (data) => {
    const res = await dispatch(sendEmailAuthenticationOtp(data))
    if (res.error) {
      NotificationManager.error(res.error.message, "Error!")
    } else {
      if (res.success == true) {
        NotificationManager.success(res.message, "Success!")
        setIsEmailAuthOptVisible(true)
      }
    }
  }

  return (
    <div className="padding-bottom-10 padding-bottom-sm-0">
      {
        is_authenticator_app_enabled &&
        <div className="padding-top-12 padding-bottom-12">
          <label className="form-label">Authenticator App</label>

          <form className="row align-items-center">
            <div className="col-sm-5 padding-right-12">
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setAuthenticatorAppOtp(e.target.value)
                }}
              />
            </div>

            <div className="row align-items-center col-sm-7">
              <div className="col-6 padding-right-8">
                <button
                  className="btn stretch-full bg-blue padding-8 margin-top-12 margin-top-sm-0"
                  type="submit"
                  onClick={() => {
                    if (isUsingSocialAuthentication) {

                      if (socialMediaType == "apple") {
                        // If apple authentication, remove the code and insert its value to access_token. This is because code cannot be reused
                        if (appleAuthCredentials.code) {
                          const access_token = appleAuthCredentials.code
                          appleAuthCredentials.access_token = access_token
                          delete appleAuthCredentials.code
                        }
                      }

                      handleSocialLogin({
                        access_token: socialAccessToken,
                        social_media_access_token: socialAccessToken,
                        ...values,
                        ...appleAuthCredentials,
                        socialMediaType,
                        otp_type: "authenticator_app",
                        otp: authenticatorAppOtp,
                        authentication_method: authenticationMethod
                      })
                    } else {
                      handleLogin({
                        ...values,
                        otp_type: "authenticator_app",
                        otp: authenticatorAppOtp
                      })
                    }
                  }}

                  disabled={!(authenticatorAppOtp && /^\d{6}$/gm.test(authenticatorAppOtp))}
                >
                  Verify Authenticator OTP
                </button>
              </div>

              <div className="col-6 padding-left-8">
                <button
                  className="btn stretch-full bg-orange padding-8 margin-top-12 margin-top-sm-0"
                  type="button"
                  onClick={() => {
                    // If apple authentication, remove the code and insert its value to access_token. This is because code cannot be reused
                    if (appleAuthCredentials.code) {
                      const access_token = appleAuthCredentials.code
                      appleAuthCredentials.access_token = access_token
                      delete appleAuthCredentials.code
                    }

                    dispatch(retrieveUserSecurityQuestions({
                      social_access_token: socialAccessToken,
                      social_media_access_token: socialAccessToken,
                      ...appleAuthCredentials,
                      email: values.email,
                      password: values.password,
                      social_media_type: socialMediaType,
                    }))
                      .then(res => {
                        if (res.error) {
                          NotificationManager.error(res.error.message, "Error!")
                        } else {
                          if (res.success == true) {
                            setRetrievedSecurityData(res.data)
                            setIsRetrieveQRCodeVisible(true)
                          }
                        }
                      })
                  }}
                >
                  Retrieve Authenticator Code
                </button>
              </div>
            </div>
          </form>
        </div>
      }

      {
        is_phone_two_factor_enabled &&
        <div>
          <div className="d-flex align-items-center padding-top-40 padding-bottom-40">
            <p className="col-sm-5 margin-right-12">
              Mobile Phone OTP
            </p>
            <div className="col-6 padding-right-8">
              <button
                className="btn max-width-150 bg-light-normal-blue padding-8"
                type="button"
                onClick={() => {
                  // onRequestPhoneOtp();

                  if (isAuthenticated) {
                    dispatch(sendPhoneOtp())
                      .then(res => {
                        if (res.error) {
                          NotificationManager.error(res.error.message, "Error")
                        } else {
                          if (res.success) {
                            NotificationManager.success(res.message, "Success")
                          }
                        }
                      })
                  } else {
                    // If apple authentication, remove the code and insert its value to access_token. This is because code cannot be reused
                    if (appleAuthCredentials.code) {
                      const access_token = appleAuthCredentials.code
                      appleAuthCredentials.access_token = access_token
                      delete appleAuthCredentials.code
                    }

                    handleSendPhoneOtpPreLogin({
                      social_access_token: socialAccessToken,
                      social_media_access_token: socialAccessToken,
                      ...appleAuthCredentials,
                      email: values.email,
                      password: values.password,
                      is_social: isUsingSocialAuthentication,
                      social_media_type: socialMediaType
                    })
                  }
                }}
              >
                Generate OTP
              </button>
            </div>
          </div>

          <form className="row align-items-center padding-top-12 padding-bottom-12">
            <div className="col-sm-5 padding-right-sm-12">
              <input
                className="form-control"
                type="text"
                onChange={(e) => {
                  setPhoneOtp(e.target.value)
                }}
              />
            </div>
            <div className="col-sm-6 padding-left-sm-12">
              <button
                className="btn bg-fundraiser-color max-width-150 padding-8 margin-top-12 margin-top-sm-0"
                type="submit"
                onClick={() => {

                  if (isUsingSocialAuthentication) {

                    // If apple authentication, remove the code and insert its value to access_token. This is because code cannot be reused
                    if (appleAuthCredentials.code) {
                      const access_token = appleAuthCredentials.code
                      appleAuthCredentials.access_token = access_token
                      delete appleAuthCredentials.code
                    }

                    handleSocialLogin({
                      access_token: socialAccessToken,
                      social_media_access_token: socialAccessToken,
                      ...values,
                      ...appleAuthCredentials,
                      socialMediaType,
                      otp_type: "phone_otp",
                      otp: phoneOtp,
                      authentication_method: authenticationMethod
                    })
                  } else {
                    handleLogin({
                      ...values,
                      otp_type: "phone_otp",
                      otp: phoneOtp
                    })
                  }
                }}
                disabled={!(phoneOtp && /^\d{6}$/gm.test(phoneOtp))}
              >
                Verify Phone OTP
              </button>
            </div>
          </form>
        </div>
      }

      <Modal
        ariaHideApp={false}
        isOpen={isRetrieveQRCodeVisible}
        onRequestClose={() => setIsRetrieveQRCodeVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Retrieve QR Code
          </h1>
          <i
            onClick={() => setIsRetrieveQRCodeVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div className="padding-8">
          <div
            style={{ maxHeight: "80vh", minHeight: "45vh" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <RetrieveAuthenticatorQRCode
              questions={retrievedSecurityData.questions}
              has_verified_phone={retrievedSecurityData.has_verified_phone}
              handleClose={() => setIsRetrieveQRCodeVisible(false)}
              onSubmitAnswer={async data => {
                const res = await dispatch(verifySecurityAnswerWithEmailPassword({
                  ...appleAuthCredentials,
                  email: values.email,
                  password: values.password,
                  social_access_token: socialAccessToken,
                  social_media_access_token: socialAccessToken,
                  social_media_type: socialMediaType,
                  question_id: data.question_id,
                  answer: data.answer
                }))

                if (res.error) {
                  NotificationManager.error(res.error.message, "Error!")
                  return res
                } else {
                  if (res.success == true) {
                    return res
                  }
                }
              }}
              onCorrectAnswers={async () => {
                await handleSendEmailAuthenticationOtp({
                  ...appleAuthCredentials,
                  email: values.email,
                  password: values.password,
                  social_access_token: socialAccessToken,
                  social_media_access_token: socialAccessToken,
                  social_media_type: socialMediaType,
                })
              }}
              setIsEmailAuthOptVisible={setIsEmailAuthOptVisible}
              isEmailAuthOptVisible={isEmailAuthOptVisible}

              onRequestAuthenticatorAppUri={async data => {
                const res = await dispatch(
                  getAuthenticatorAppUriWhenNotAuthenticated({
                    ...appleAuthCredentials,
                    otp: data.emailOtp,
                    email: values.email,
                    password: values.password,
                    social_access_token: socialAccessToken,
                    social_media_access_token: socialAccessToken,
                    social_media_type: socialMediaType,
                    type: data.type,
                    data: data.data
                  }))

                if (res.error) {
                  NotificationManager.error(res.error.message, "Error!")
                  return res
                } else {
                  if (res.success == true) {
                    return res
                  }
                }
              }}
              onRetrieveUriSuccessful={data => {
                setRetrievedAuthenticatorUri(data.uri)
                setIsAuthenticatorQRCodeVisible(true)
              }}
              onRequestPhoneOtp={() => {
                if (isAuthenticated) {
                  dispatch(sendPhoneOtp())
                    .then(res => {
                      if (res.error) {
                        NotificationManager.error(res.error.message, "Error")
                      } else {
                        if (res.success) {
                          NotificationManager.success(res.message, "Success")
                        }
                      }
                    })
                } else {
                  handleSendPhoneOtpPreLogin({
                    ...appleAuthCredentials,
                    email: values.email,
                    password: values.password,
                    social_access_token: socialAccessToken,
                    social_media_access_token: socialAccessToken,
                    is_social: isUsingSocialAuthentication,
                    social_media_type: socialMediaType
                  })
                }
              }}
            // phoneOtpPreLoginParams={{
            //   email: values.email,
            //   password: values.password,
            //   social_access_token: socialAccessToken,
            // social_media_access_token: socialAccessToken,
            //   is_social: isUsingSocialAuthentication
            // }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isAuthenticatorQRCodeVisible}
        onRequestClose={() => setIsAuthenticatorQRCodeVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Scan QR Code
          </h1>
          <i
            onClick={() => setIsAuthenticatorQRCodeVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div className="padding-8">
          <div
            style={{ maxHeight: "80vh", minHeight: "45vh" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <p className="logo-magenta font-size-16">Use an authenticator app to scan this code and enter your generated otp in the authentication form</p>

            <div className="d-flex justify-content-center padding-top-20 padding-bottom-20">
              <div className="col-sm-12">
                <QRCode value={retrievedAuthenticatorUri} />
              </div>
            </div>

            <button
              className="btn max-width-160 stretch-full-sm margin-bottom-20 bg-logo-magenta padding-top-8 padding-bottom-8 font-size-16"
              type="button"
              onClick={() => setIsAuthenticatorQRCodeVisible(false)}
            >Done</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
    isAuthenticated: !!state.auth.userToken,
  };
};

export default connect(mapStateToProps)(MultiFactorAuth);