import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import Truncate from "react-truncate";
import CurrentWindowWidth from "../CurrentWindowWidth";
import "./react-pdf-styles.css";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfViewer = ({ document, handleClose }) => {
  const [noOfPdfPages, setNoOfPdfPages] = useState(null);
  const [pdfPageNum, setPdfPageNum] = useState(1);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [scale, setScale] = useState(1);

  let windowWidth = CurrentWindowWidth();

  const handleOnPdfLoadSuccess = ({ numPages }) => {
    setNoOfPdfPages(numPages);
  };

  return (
    <div>
      <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
        <h1 className="stretch-full font-size-18 font-size-md-22 font-size-md-22 logo-magenta">
          <center>
            <Truncate lines={1}>{document.description} </Truncate>
          </center>
        </h1>
        <i
          onClick={() => handleClose()}
          className="fa fa-close font-size-20 font-size-sm-22 logo-magenta cursor-pointer hover-red"
        ></i>
      </div>
      <div className="horizontal-line margin-0" />
      <center>
        <div className="d-flex justify-content-between orange-red max-width-100 padding-top-10">
          <i
            onClick={() => {
              //Anti-clockwise rotation
              const newAngle = rotationAngle - 90;
              setRotationAngle(newAngle);
            }}
            className="fa fa-rotate-left font-size-17 cursor-pointer"
          ></i>
          <i
            onClick={() => {
              //Clockwise rotation
              const newAngle = rotationAngle + 90;
              setRotationAngle(newAngle);
            }}
            className="fa fa-rotate-right font-size-17 cursor-pointer"
          >
          </i>
          <span onClick={() => {
            if (scale > 0.5) {
              setScale(scale - 0.1)
            }
          }}>
            <b>-</b></span>
          <span onClick={() => {
            if (scale < 2) {
              setScale(scale + 0.1)
            }
          }} ><b>+</b></span>
        </div>
      </center>

      <div
        style={{ height: window.innerHeight * 0.8, overflow: "scroll" }}
        className="d-flex justify-content-center padding-10 padding-top-sm-30 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
      >
        <Document
          // file={document.file_url}
          file="http://www.africau.edu/images/default/sample.pdf"
          onLoadSuccess={handleOnPdfLoadSuccess}
          rotate={rotationAngle}
        >
          <Page pageNumber={pdfPageNum}
            height={700 * scale}
            width={windowWidth > 547 && (Number.isInteger(rotationAngle / 180) || rotationAngle / 180 == 0) ?
              null :
              windowWidth * scale}
          />
          {/*
          {Array.from(new Array(noOfPdfPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}*/}

        </Document>
      </div>

      <div className="d-flex justify-content-between logo-magenta padding-10 padding-left-md-40 padding-right-md-40 bg-lighter-blue border-bottom-radius-8">
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => {
            if (pdfPageNum > 1) {
              setPdfPageNum(pdfPageNum - 1)
            }
          }}
        >
          <i className="far fa-arrow-alt-circle-left font-size-20"></i>
          <div className="padding-left-10 font-size-16">Previous</div>
        </div>

        <div>
          <span>Page </span>
          <b>{pdfPageNum}</b> of <span>{noOfPdfPages}</span>
        </div>

        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => {
            if (pdfPageNum < noOfPdfPages) {
              setPdfPageNum(pdfPageNum + 1);
            }
          }}
        >
          <div className="padding-right-10 font-size-16">Next</div>
          <i className="far fa-arrow-alt-circle-right font-size-20"></i>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
