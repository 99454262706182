import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import 'react-circular-progressbar/dist/styles.css';
import {
  getAllFundraisersListByPublic
} from "../../../../store/actions/fundraiser";
import { history } from "../../../../router/AppRouter";
import CurrentWindowWidth from "../../../CurrentWindowWidth";
import FundraiserFrame from "./FundraiserFrame";

const AllFundraisers = ({
  dispatch,
  isDashboard,
  noOfRange,
  // setIsPageLoading
}) => {
  const [seeMoreRange, setSeeMoreRange] = useState(noOfRange);
  const [fundraisers, setFundraisers] = useState([]);
  const [totalFundraiserCount, setTotalFundraiserCount] = useState(null);
  const [coverMediaHeight, setCoverMediaHeight] = useState(0);
  const [containerWidth, getContainerWidth] = useState(0);
  const [colValue, setColValue] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [queryParams, setQueryParams] = useState({
    start_query: 0,
    end_query: seeMoreRange,
    categories: selectedCategories,
    query_text: ""
  });
  const [searchQueryText, setSearchQueryText] = useState("");

  let windowWidth = CurrentWindowWidth();

  const containerRef = useCallback(
    (node) => {
      if (node !== null) {
        getContainerWidth(node.getBoundingClientRect().width);
      }
    },
    [windowWidth]
  );

  const coverMediaRef = useCallback(
    (node) => {
      if (node !== null) {
        setCoverMediaHeight(node.getBoundingClientRect().width * 0.483);
      }
    },
    [windowWidth, colValue]
  );

  useEffect(() => {
    if (containerWidth > 1200) {
      setColValue(3);
    }
    if (containerWidth <= 1200) {
      setColValue(4);
    }
    if (containerWidth <= 900) {
      if (isDashboard) {
        setColValue(4);
      } else {
        setColValue(5);
      }
    }
    if (containerWidth <= 700) {
      setColValue(12);
    }
  }, [containerWidth, coverMediaHeight]);

  const handleGetAllPublicFundraisers = (data) => {
    // setIsPageLoading([true, "Loading..."]);
    dispatch(getAllFundraisersListByPublic(data))
      .then((response) => {
        // setIsPageLoading([false]);
        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            setAvailableCategories(response.data.categories)
            const fundraiser = response.data
            setTotalFundraiserCount(fundraiser.total_count);
            setFundraisers(fundraiser.fundraisers);
          }
        }
      });
  };

  useEffect(() => {
    handleGetAllPublicFundraisers(queryParams);

  }, [seeMoreRange]);

  return (
    <div>
      <div className="padding-left-8 padding-left-sm-22 padding-right-8 padding-right-sm-22">

        <h1 className="font-size-20 font-size-sm-21">Browse Fundraisers</h1>

        <div class="d-flex align-items-center justify-content-between border-1 border-color-grey border-radius-0 bg-white padding-6 
        padding-left-12 padding-right-16 margin-top-6 margin-top-sm-20 margin-bottom-6">
          <input
            class="input font-size-16 border-0 focus-none"
            type="text"
            placeholder="Search"
            value={searchQueryText}
            onChange={(e) => {
              setSearchQueryText(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleGetAllPublicFundraisers({
                  ...queryParams,
                  query_text: searchQueryText
                })
              }
            }}
            onBlur={() => {
              handleGetAllPublicFundraisers({
                ...queryParams,
                query_text: searchQueryText
              })
            }}
          />
          <i class="fa fa-search opacity-50 font-size-20 cursor-pointer"
            // disabled={!searchQueryText}
            onClick={() => {
              handleGetAllPublicFundraisers({
                ...queryParams,
                query_text: searchQueryText
              })
            }}></i>
        </div>
        {/* {
          searchQueryText &&
          <span
            className=" margin-top-8 margin-bottom-8"
            onClick={() => {
              setSearchQueryText("")
              const newQueryParams = {
                ...queryParams,
                query_text: ""
              }
              setQueryParams(newQueryParams)
              handleGetAllPublicFundraisers(newQueryParams)
            }}>X
          </span>
        } */}

        <div className="top-20 padding-bottom-20">
          <label className="form-label margin-top-16">Filter by category</label>
          <div className="d-flex flex-wrap align-items-center">
            {selectedCategories.length > 0 && selectedCategories.map((item, index) => {
              let eachLabel = ""

              availableCategories.forEach(cat => {
                if (cat.value == item) {
                  eachLabel = cat.label
                }
              })
              return (
                <div key={index}
                  className="d-flex align-items-center margin-right-12 margin-top-8 margin-bottom-8 padding-6 padding-left-10 padding-right-10 bg-pale-navy border-radius-0"
                >
                  <div className="flex-grow-1 margin-right-10 logo-magenta">{eachLabel}</div>
                  <i className="fa fa-close orange-red cursor-pointer hover-dodgerblue" onClick={() => {
                    const newArr = selectedCategories.filter(ct => ct != item)
                    setSelectedCategories([...newArr])

                    const newQueryParams = {
                      ...queryParams,
                      categories: newArr.length > 0 ? JSON.stringify(newArr) : []
                    }

                    setQueryParams(newQueryParams)

                    handleGetAllPublicFundraisers(newQueryParams);
                  }}></i>
                </div>
              );

            })}
            {selectedCategories.length ?
              <div>
                <button className="btn bg-logo-magenta fixed-width-90 white margin-top-8 margin-bottom-8"
                  onClick={() => {
                    setSelectedCategories([])
                    const newQueryParams = {
                      ...queryParams,
                      categories: []
                    }
                    setQueryParams(newQueryParams)
                    handleGetAllPublicFundraisers(newQueryParams);
                  }}>{selectedCategories.length > 0 && "Clear All"}
                </button>
              </div> : null}
          </div>

          <Select
            className="form-control padding-0 max-width-250 margin-top-8"
            classNamePrefix="react-select"
            placeholder="Select category"
            options={availableCategories}
            value={{
              label: "Choose a categrory",
              value: ""
            }}
            onChange={(option) => {
              //Check if the category has already been selected
              if (!selectedCategories.includes(option.value)) {
                const initialArray = selectedCategories
                initialArray.push(option.value)
                setSelectedCategories([...initialArray])

                const newQueryParams = {
                  ...queryParams,
                  categories: initialArray.length > 0 ? JSON.stringify(initialArray) : []
                }
                setQueryParams(newQueryParams)
                handleGetAllPublicFundraisers(newQueryParams);
              }
            }}
          />
        </div>
      </div>

      <div
        ref={containerRef}
        className="d-flex flex-wrap padding-left-4 padding-right-4"
      >
        {fundraisers.length > 0 ? (
          fundraisers.map((fundraiser, index) => {
            return (
              <div
                key={index}
                ref={coverMediaRef}
                className={
                  "d-flex padding-4 padding-bottom-10 padding-sm-20 col-" +
                  `${colValue}`
                }
                onClick={() => {
                  history.push(`/fundraiser/${fundraiser.slug}`);
                }}
              >
                <FundraiserFrame fundraiser={fundraiser} coverMediaHeight={coverMediaHeight} />
              </div>
            );
          })
        ) : (
          <p className="padding-top-40 padding-bottom-40 font-size-15 grey text-center stretch-full">
            No fundraisers matches this query. Please try another keyword.
          </p>
        )}
      </div>

      {seeMoreRange < totalFundraiserCount ? (
        <div
          className="d-flex align-items-center grey cursoe-pointer"
          onClick={() => {
            let range = seeMoreRange;
            range = range + noOfRange;
            setSeeMoreRange(range);
            const newQueryParams = {
              ...queryParams,
              end_query: range
            }
            setQueryParams(newQueryParams)
            handleGetAllPublicFundraisers(newQueryParams);
          }}
        >
          <span>See more</span>
          <i className="fa fa-angle-right padding-left-10 font-size-28"></i>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(AllFundraisers);
