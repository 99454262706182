import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfsample from "./Press-Jury-business-model (3).pdf"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfThumbnail = ({ document, width, height }) => {
  return (
    <div>
      <Document
        // file={document.file_url}
        // file={{ "url": "http://www.africau.edu/images/default/sample.pdf" }}
        file={{
          url: document.file_url,
          // httpHeaders: { 'X-CustomHeader': '40359820958024350238508234' },
          withCredentials: true
        }}
      >
        <Page pageNumber={1} height={height} />
      </Document>
    </div>
  );
};

export default PdfThumbnail;
