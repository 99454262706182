import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import CreateFundraiserDescription from "./fragments/FundraiserDescription";
import EditFundraiserDescription from "./fragments/FundraiserDescription";
import UploadCoverMedia from "./UploadCoverMedia";
import SupportingDocument from "./SupportingDocument";
import PaymentDetails from "./PaymentDetails";
import FundraiserOverview from "./view/FundraiserOverview"
import FundraiserThanksPage from "./FundraiserThanksPage";
import FundraiserSetupFormHeader from "./fragments/FundraiserSetupFormHeader";

const FundraiserSetupSlides = ({
  history,
  user,
  fundraiser,
  fundraiserId,
  setFundraiser,
  setFundraiserId,
  setIsPageLoading,
  isCreatingNew = true,
  setIsCreatingNew,
  handleGetFundraisersIManage,
}) => {
  const [initialFundraiserDetails, setInitialFundraiserDetails] = useState({
    title: "",
    story: "",
    goal_amount: null,
    upkeep_picture: "",
    goal_amount_currency: user.preferred_currency,
    is_public: true,
    is_goal_set: false,
    is_amount_raised_visible: true,
    end_date: null,
    category: "",
    is_selling_merchandise: false,
    for_who: "INDIVIDUAL",
    charity_ngo: {},
    country: user.country ? user.country : "",
    state: "",
    city: ""
  })
  const [
    visibleFundraiserSetupSlide,
    setVisibleFundraiserSetupSlide,
  ] = useState(null);
  const [slideNames] = useState([
    "createFundraiserDescription",
    "editFundraiserDescription",
    "coverMedia",
    "supportingDocs",
    "paymentDetails",
    "fundraiserSummary",
    "thanks",
  ]); // Always maintain this order

  //Get the url query parameters
  const search = useLocation().search;
  const activeSlide = new URLSearchParams(search).get("activeSlide")
  const agentID = new URLSearchParams(search).get("agentID")

  useEffect(() => {
    if (agentID) {
      initialFundraiserDetails["agent_id"] = agentID
      setInitialFundraiserDetails(initialFundraiserDetails)
    }
  }, [])

  useEffect(() => {
    if (isCreatingNew) {
      setVisibleFundraiserSetupSlide("createFundraiserDescription");
    } else {
      if (activeSlide) {
        setVisibleFundraiserSetupSlide(activeSlide);
      } else {
        setVisibleFundraiserSetupSlide("editFundraiserDescription");
      }
    }
  }, [isCreatingNew]);

  return (
    <div>
      <h2 className="font-size-22 font-size-sm-24 logo-magenta">Set Up a Fundraiser</h2>

      <div className="horizontal-line  margin-top-10 margin-bottom-20" />

      <FundraiserSetupFormHeader
        activeSlide={visibleFundraiserSetupSlide}
        slideNames={slideNames}
      />

      <div className="padding-top-10">

        {visibleFundraiserSetupSlide == "createFundraiserDescription" && (
          <CreateFundraiserDescription
            history={history}
            initialFundraiserDetails={initialFundraiserDetails}
            setFundraiser={setFundraiser}
            isCreatingNew={isCreatingNew}
            setFundraiserId={setFundraiserId}
            setIsPageLoading={setIsPageLoading}
            setVisibleFundraiserSetupSlide={setVisibleFundraiserSetupSlide}
          />
        )}

        {visibleFundraiserSetupSlide == "editFundraiserDescription" && fundraiser && (
          <EditFundraiserDescription
            history={history}
            setFundraiser={setFundraiser}
            isCreatingNew={isCreatingNew}
            setFundraiserId={setFundraiserId}
            initialFundraiserDetails={fundraiser.fundraiser_details}
            setIsPageLoading={setIsPageLoading}
            setVisibleFundraiserSetupSlide={setVisibleFundraiserSetupSlide}
          />
        )}

        {visibleFundraiserSetupSlide == "coverMedia" && fundraiser && (
          <UploadCoverMedia
            history={history}
            fundraiserId={fundraiserId}
            coverMedia={fundraiser.cover_media}
            additionalCoverPictures={fundraiser.additional_cover_pictures}
            setFundraiser={setFundraiser}
            setIsPageLoading={setIsPageLoading}
            setIsCreatingNew={setIsCreatingNew}
            setVisibleFundraiserSetupSlide={setVisibleFundraiserSetupSlide}
            isPrivateFundraiser={!fundraiser.fundraiser_details.is_public}
          />
        )}

        {visibleFundraiserSetupSlide == "supportingDocs" && fundraiser && (
          <SupportingDocument
            history={history}
            fundraiser={fundraiser}
            documents={fundraiser.documents}
            setFundraiser={setFundraiser}
            setIsPageLoading={setIsPageLoading}
            setVisibleFundraiserSetupSlide={setVisibleFundraiserSetupSlide}
          />
        )}

        {visibleFundraiserSetupSlide == "paymentDetails" && fundraiser && (
          <PaymentDetails
            history={history}
            fundraiserId={fundraiserId}
            thirdPartyBeneficiary={fundraiser.third_party_beneficiary}
            fundraiser_details={fundraiser.fundraiser_details}
            fundraiser={fundraiser}
            setFundraiser={setFundraiser}
            setIsPageLoading={setIsPageLoading}
            setVisibleFundraiserSetupSlide={setVisibleFundraiserSetupSlide}
          />
        )}

        {visibleFundraiserSetupSlide == "fundraiserSummary" && fundraiser && (
          <FundraiserOverview
            history={history}
            setFundraiser={setFundraiser}
            fundraiser={fundraiser}
            setIsPageLoading={setIsPageLoading} />
        )}

        {visibleFundraiserSetupSlide == "thanks" && (
          <FundraiserThanksPage
            history={history}
            handleGetFundraisersIManage={handleGetFundraisersIManage}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(FundraiserSetupSlides);
