import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FundraiserOverview from "./FundraiserOverview"
import Settings from "./Settings"
import Commitments from "./Commitments"
import Withdrawals from "./Withdrawals"
import Updates from "./Updates"
import NoOfUnseenMessages from "../../../../utilityComponents/NoOfUnseenMessages";
import ChatBox from "../../../../utilityComponents/ChatBox";

const FundraiserView = ({
  history,
  setIsPageLoading,
  fundraiser,
  setFundraiser,
  canEdit = true,
}) => {

  const [visibleComponent, setVisibleComponent] = useState("overview")

  const handleSwitchComponent = (component) => {
    setVisibleComponent(component)
  }

  useEffect(() => {
    setIsPageLoading([true, "Loading..."]);
    if (fundraiser) {
      setIsPageLoading([false]);
    }
  }, [fundraiser]);

  return (
    <div>
      <div className="d-flex flex-wrap max-width-580 align-items-center justify-content-between padding-top-10 padding-top-md-16 padding-bottom-10 padding-bottom-md-16 padding-left-0 padding-left-md-16 padding-right-0 padding-right-md-16 font-size-14 font-size-sm-18">
        <div className={`btn font-size-12 font-size-sm-14 line-height-14 margin-2 margin-sm-4 margin-top-4 margin-bottom-4 padding-6 padding-left-sm-12 padding-right-sm-12 border-color-dark-grey
           ${visibleComponent == "overview" ? "bg-orange border-color-orange white" : "bg-transparent dark-grey"}`}
          onClick={() => handleSwitchComponent("overview")}>
          Overview
        </div>
        <div className={`btn font-size-12 font-size-sm-14 line-height-14 margin-2 margin-sm-4 margin-top-4 margin-bottom-4 padding-6 padding-left-sm-12 padding-right-sm-12 border-color-dark-grey
           ${visibleComponent == "commitments" ? "bg-orange border-color-orange white" : "bg-transparent dark-grey"}`}
          onClick={() => handleSwitchComponent("commitments")}>
          Commitments
        </div>
        <div className={`btn font-size-12 font-size-sm-14 line-height-14 margin-2 margin-sm-4 margin-top-4 margin-bottom-4 padding-6 padding-left-sm-12 padding-right-sm-12 border-color-dark-grey
           ${visibleComponent == "withdrawals" ? "bg-orange border-color-orange white" : "bg-transparent dark-grey"}`}
          onClick={() => handleSwitchComponent("withdrawals")}>
          Withdrawals
        </div>
        <div className={`btn font-size-12 font-size-sm-14 line-height-14 margin-2 margin-sm-4 margin-top-4 margin-bottom-4 padding-6 padding-left-sm-12 padding-right-sm-12 border-color-dark-grey
           ${visibleComponent == "updates" ? "bg-orange border-color-orange white" : "bg-transparent dark-grey"}`}
          onClick={() => handleSwitchComponent("updates")}>
          Updates
        </div>
        <div className={`btn font-size-12 font-size-sm-14 line-height-14 margin-2 margin-sm-4 margin-top-4 margin-bottom-4 padding-6 padding-left-sm-12 padding-right-sm-12 border-color-dark-grey
           ${visibleComponent == "settings" ? "bg-orange border-color-orange white" : "bg-transparent dark-grey"}`}
          onClick={() => handleSwitchComponent("settings")} >
          Settings
        </div>
        <div className={`d-flex align-items-center btn font-size-12 font-size-sm-14 line-height-14 margin-left-2 margin-left-sm-4 margin-top-4 margin-bottom-4 padding-6 padding-left-sm-12 padding-right-sm-12 border-color-dark-grey
           ${visibleComponent == "chat" ? "bg-orange border-color-orange white" : "bg-transparent dark-grey"}`}
          onClick={() => handleSwitchComponent("chat")} >
          <span>
            Chat
          </span>
          <div>
            <NoOfUnseenMessages
              service_id={fundraiser.fundraiser_details.id}
              service_type={"fundraiser"}
            />
          </div>
        </div>
      </div>

      <div className="horizontal-line" />

      <div className="padding-0 padding-left-md-20 padding-right-md-20">

        {visibleComponent == "overview" ?
          <div>
            <h2 className="padding-top-10 padding-bottom-10 font-size-18 font-size-sm-20 orange">Overview</h2>
            <FundraiserOverview
              viewOnly={true}
              history={history}
              setFundraiser={setFundraiser}
              fundraiser={fundraiser}
              setIsPageLoading={setIsPageLoading} />
          </div> : null
        }

        {visibleComponent == "settings" ?
          <div>
            <h2 className="padding-top-10 padding-bottom-10 font-size-18 font-size-sm-20 orange"> Settings</h2>
            <Settings
              history={history}
              fundraiser={fundraiser}
              setIsPageLoading={setIsPageLoading}
              setFundraiser={setFundraiser} />  </div> : null
        }

        {visibleComponent == "commitments" ?
          <div>
            <h2 className="padding-top-10 padding-bottom-10 font-size-18 font-size-sm-20 orange">Commitments</h2>
            <Commitments
              history={history}
              fundraiser_thumbnail={fundraiser.cover_media.thumbnail_url}
              fundraiserDetails={fundraiser.fundraiser_details} />
          </div> : null
        }

        {visibleComponent == "withdrawals" ?
          <div>
            <h2 className="padding-top-10 padding-bottom-10 font-size-18 font-size-sm-20 orange">Withdrawals</h2>
            <Withdrawals
              history={history}
              fundraiserId={fundraiser.fundraiser_details.id}
              setIsPageLoading={setIsPageLoading} />
          </div> : null
        }

        {visibleComponent == "updates" ?
          <div>
            <h2 className="padding-top-10 padding-bottom-10 font-size-18 font-size-sm-20 orange">
              Updates
            </h2>
            <Updates
              history={history}
              fundraiser={fundraiser}
              setFundraiser={setFundraiser} />
          </div> : null
        }

        {visibleComponent == "chat" ?
          <ChatBox
            service_type="fundraiser"
            service_id={fundraiser.fundraiser_details.id}
          />
          : null}

        {canEdit ? (
          <div className="d-flex justify-content-end margin-top-10 margin-top-sm-30 margin-bottom-10">
            <button
              className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-orange border-color-orange white border-radius-12"
              onClick={() => null}
            >
              <b>Submit</b>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(FundraiserView);
