import axiosInstance from "../../helpers/axiosInstance";

// For a user to submit their registered company
export const createOrUpdateNGO = (data) => {
  return async () => {
    return axiosInstance.post("/charity_ngo/create-or-update-charity-ngo", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For a user to delete their registered company
export const deleteCharityNgo = (data) => {
  return async () => {
    return axiosInstance.delete("/charity_ngo/delete-charity-ngo/" + data.id)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For a user to get one of their charity ngo
export const getCharityNGO = (ngoId) => {
  return async () => {
    return axiosInstance.get("/charity_ngo/get-a-charity-ngo/" + ngoId)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For a user to their charity ngos
export const getMyRegisteredNGOs = () => {
  return async () => {
    return axiosInstance.get("/charity_ngo/get-all-my-registered-charity-ngo")
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};


// For a user to upload an NGO document
export const uploadNgoDocument = (data) => {
  return async () => {
    return axiosInstance.post("/charity_ngo/upload-charity-ngo-file", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For a user to delete an uploaded NGO document
export const deleteUploadedNGODocument = (data) => {
  return async () => {
    return axiosInstance.patch("/charity_ngo/delete-uploaded-ngo-file", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};


// For the public to search for verified charities
export const searchVerifiedCharities = (params) => {
  return async () => {
    return axiosInstance.get("/charity_ngo/search-verified-charities", { params })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For the user to link an existing bank account to their charity NGO
export const linkBankAccountToNGO = (data) => {
  return async () => {
    return axiosInstance.post("/charity_ngo/link-bank-account-to-ngo", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For the user to unlink an existing bank account from their charity NGO
export const unlinkBankAccountFromNGO = (data) => {
  return async () => {
    return axiosInstance.post("/charity_ngo/unlink-bank-account-from-ngo", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For the user to add a new bank account to their NGO
export const saveNGOBankAccount = (data) => {
  return async () => {
    return axiosInstance.post("/charity_ngo/save-charity-ngo-bank-account", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For the user to completely delete a linked bank account from the system
export const deleteCharityNGOBankAccount = (data) => {
  return async () => {
    return axiosInstance.post("/charity_ngo/delete-charity-ngo-bank-account", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For the user to finally submit the NGO
export const submitNGO = (ngoId) => {
  return async () => {
    return axiosInstance.patch("/charity_ngo/submit-charity-ngo/" + ngoId)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For the user to add a director of an NGO
export const createOrUpdateNGODirector = (data) => {
  return async () => {
    return axiosInstance.post("/charity_ngo/create-or-update-ngo-director", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// For the user to delete a director of an NGO
export const deleteNGODirector = (directorId) => {
  return async () => {
    return axiosInstance.delete("/charity_ngo/delete-ngo-director/"  + directorId)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};



