import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axiosInstance from "../../../../../helpers/axiosInstance";
import ShareIcons from "./ShareIcons";


const ShareOptions = ({ fundraiserDetails }) => {
  const [url, setUrl] = useState("");
  const [isUrlCopiedVisible, setIsUrlCopiedVisible] = useState(false);

  const handleCopyUrl = () => {
    setIsUrlCopiedVisible(true);

    setTimeout(() => {
      setIsUrlCopiedVisible(false);
    }, 2000);
  };

  useEffect(() => {

    setUrl(
      `${axiosInstance.defaults.baseURL}/fundraiser/${fundraiserDetails.slug}`
    );
  }, []);

  return (
    <div>
      <p className="font-size-14 font-size-md-17 padding-top-4 padding-bottom-14">
        Please help this cause by sharing on your favourite social networks
      </p>

      <ShareIcons fundraiserDetails={fundraiserDetails} url={url} setIsUrlCopiedVisible={setIsUrlCopiedVisible} />

      <div className="padding-top-10 padding-top-sm-30">
        <p className="font-size-20">Copy link and paste anywhere!</p>
      </div>
      <div className="padding-bottom-10">
        {isUrlCopiedVisible ? (
          <p className="font-size-20 padding-top-6 orange-red">Copied</p>
        ) : null}
        <div className="row">
          <div className="col-sm-9 padding-right-0 padding-right-sm-10 padding-top-10 padding-bottom-14">
            <CopyToClipboard
              className="input stretch-full font-size-14 padding-10 border-color-grey"
              text={url}
              onCopy={() => {
                handleCopyUrl();
              }}
            >
              <p>{url}</p>
            </CopyToClipboard>
          </div>
          <div className="col-sm-3 d-flex align-items-center justify-content-end">
            <CopyToClipboard
              text={url}
              onCopy={() => {
                handleCopyUrl();
              }}
            >
              <button className="btn padding-12 border-radius-10 font-size-20 stretch-full-sm max-width-120 bg-orange white">
                <b>Copy</b>
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ShareOptions);
