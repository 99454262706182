import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import moment from "moment";
import DOMPurify from 'dompurify';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { getPost } from "../../store/actions/blog";
import BlogFooter from "./BlogFooter";
import CurrentWindowWidth from "../CurrentWindowWidth";
import axiosInstance from "../../helpers/axiosInstance";

const BlogArticle = ({
  dispatch,
  history,
  match
}) => {
  let windowWidth = CurrentWindowWidth();

  const [post, setPost] = useState("");
  const [slug] = useState(match.params.slug);
  const [url] = useState(axiosInstance.defaults.baseURL + match.url);
  const [error, setError] = useState("");
  const [coverImgHeight, setCoverImgHeight] = useState(0);
  const [coverImgWidth, setCoverImgWidth] = useState(0);
  const [initialCoverImageHeight, setInitialCoverImageHeight] = useState(0);

  const handleGetPost = (slug) => {
    dispatch(getPost(slug))
      .then(res => {
        if (res.error) {
          setError(res.error.message)
        } else {
          if (res.success == true) {
            document.title = res.data.title;
            setPost(res.data)
          }
        }
      })
  }

  const coverImageRef = useCallback(
    (node) => {
      if (node !== null) {
        console.log(node.getBoundingClientRect().height)
        setCoverImgHeight(node.getBoundingClientRect().width * 0.5);
        setCoverImgWidth(node.getBoundingClientRect().width)
        setInitialCoverImageHeight(node.getBoundingClientRect().height)
      }
    },
    [windowWidth, coverImgHeight]
  )


  useEffect(() => {
    if (slug) {
      handleGetPost(slug)
    }
  }, [])

  return (
    <div className="content-container">
      <div className=" d-flex justify-content-center">
        <div>
          {error ?
            <p className="error-message">
              {error}
            </p>
            :
            <div className="padding-left-md-20 padding-right-md-20">
              <div className="d-inline-block">
                <div
                  className="d-flex align-items-center grey hover-logo-magenta cursor-pointer"
                  onClick={() => {
                    history.push("/blog")
                  }}
                >
                  <i className="fa fa-angle-left font-size-20" ></i>
                  <span className="padding-left-8 font-size-14">All Articles</span>
                </div>
              </div>

              <h2 className="padding-top-10 padding-bottom-20 font-size-28 font-size-md-30 line-height-35 logo-magenta">
                {post.title}
              </h2>

              <div className="d-flex align-items-center font-size-12">
                <span>By </span>&nbsp;
                <span className="red">{post.author ?
                  <span>{post.author.first_name} {post.author.last_name} </span> :
                  "Anonymous"} </span>
                <i className="fa fa-circle font-size-6 black padding-left-10 padding-right-10"></i>
                <span className="grey">{moment(post.created_on).format("Do MMMM YYYY")}</span>
              </div>

              {/* <div className="row align-items-center padding-top-20 padding-bottom-20">
          <img src={inImg} className="fixed-width-50" />
          <img src={twitterImg} className="fixed-width-50 margin-left-20 margin-right-20" />
          <img src={fbImg} className="fixed-width-50" />
        </div> */}

              <div className="d-flex padding-top-8 padding-bottom-8">
                <div className="padding-4 text-center">
                  <FacebookShareButton
                    className="fixed-width-40 fixed-height-40"
                    url={url}
                    quote={post.title}
                  >
                    <FacebookIcon width="40px" height="40px" borderRadius="6px" />
                  </FacebookShareButton>
                </div>
                <div className="padding-4 text-center">
                  <TwitterShareButton
                    className="fixed-width-40 fixed-height-40"
                    url={url}
                    title={post.title}>
                    <TwitterIcon width="40px" height="40px" borderRadius="6px" />
                  </TwitterShareButton>
                </div>
                <div className="padding-4 text-center">
                  <TelegramShareButton
                    className="fixed-width-40 fixed-height-40"
                    url={url}
                    title={post.title}>
                    <TelegramIcon width="40px" height="40px" borderRadius="6px" />
                  </TelegramShareButton>
                </div>
                <div className="padding-4 text-center">
                  <WhatsappShareButton
                    className="fixed-width-40 fixed-height-40"
                    url={url}
                    title={post.title}>
                    <WhatsappIcon width="40px" height="40px" borderRadius="6px" />
                  </WhatsappShareButton>
                </div>
                <div className="padding-4 text-center">
                  <LinkedinShareButton
                    className="fixed-width-40 fixed-height-40"
                    url={url}
                    title={post.title}
                    // summary={post.story}
                    source="www.pouchfunding.com"
                  >
                    <LinkedinIcon width="40px" height="40px" borderRadius="6px" />
                  </LinkedinShareButton>
                </div>
                <div className=" padding-4 text-center">
                  <EmailShareButton
                    className="fixed-width-40 fixed-height-40"
                    url={url}
                    subject={post.title}
                  // body={post.content}
                  >
                    <EmailIcon width="40px" height="40px" borderRadius="6px" />
                  </EmailShareButton>
                </div>
              </div>

              <div
                ref={coverImageRef}
                className="stretch-full d-block border-radius-12"
                style={{
                  backgroundImage: `url(${post.image_url})`,
                  width: "100%",
                  height: coverImgHeight + "px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover"
                }}>
              </div>

              <div className="padding-top-20 padding-bottom-20">
                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }} />
              </div>

              <div className="margin-top-20 margin-bottom-40">
                <div className="horizontal-line" />
              </div>
              <BlogFooter history={history} />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default connect()(BlogArticle);