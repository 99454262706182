import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import countryList from "react-select-country-list";
import BeneficiaryBankDetails from "../fragments/BeneficiaryBankDetails"
import ManagerBankDetailsTable from "../fragments/ManagerBankDetailsTable";
import {
  editFundraiserDetails,
  addFundraiserAssistantManager,
  removeFundraiserAssistantManager,
  removeThirdPartyBeneficiary,
  addThirdPartyBeneficiary,
  addFundraiserManagerBankAccount,
  removeFundraiserManagerBankAccount,
  linkNGOBankAccountToFundraiser
} from "../../../../../store/actions/fundraiser";
import { getConfirmAlert } from "../../../../../utilities";
import NationalPhoneNumber from "../../../../utilityComponents/NationalPhoneNumber";
import SelectSavedBankAccount from "../../../../payments/SelectSavedBankAccount";
import VerifyBankAccount from "../../../../payments/VerifyBankAccount";
// import { saveBankAccountDetails } from "../../../../../store/actions/transaction";
import PreviewVerifiedAccount from "../../../../payments/PreviewVerifiedAccount";
import SelectNGOBankAccount from "../../../../payments/SelectNGOBankAccount";

const Settings = ({
  user,
  dispatch,
  fundraiser,
  canEdit = true,
  setIsPageLoading,
  setFundraiser
}) => {
  const [visibleComponent, setVisibleComponent] = useState("paymentSettings")
  const [isBeneficiaryBankDetailsModal, setIsBeneficiaryBankDetailsModal] = useState(false)
  const [isYourBankDetailsVisible, setIsYourBankDetailsVisible] = useState(false)
  const [isYourBankDetails, setIsYourBankDetails] = useState()
  const [is3rdPartyBankDetails, setIs3rdPartyBankDetails] = useState()
  // const [whoOwnsIt, setWhoOwnsIt] = useState("you")
  // const [isWhoOwnsItVisible, setIsWhoOwnsItVisible] = useState(false)
  const [isSelectSavedBankVisible, setIsSelectSavedBankVisible] = useState(false);
  const [isSelectNGOBankVisible, setIsSelectNGOBankVisible] = useState(false);
  const [isVerifyBankVisible, setIsVerifyBankVisible] = useState(false);
  const [isNewAccountVerified, setIsNewAccountVerified] = useState(false);
  const [newlyVerifiedAccountDetails, setNewlyVerifiedAccountDetails] = useState({});

  const handleAddYourBankDetails = () => {
    if (user.saved_bank_accounts.length) {
      setIsSelectSavedBankVisible(true);
    } else {
      setIsVerifyBankVisible(true);
    }
  };

  const handleSaveBankDetails = (bankDetails) => {
    setIsPageLoading([true, "Processing..."]);

    if (fundraiser && fundraiser.fundraiser_details.for_who == "INDIVIDUAL") {
      if (fundraiser && fundraiser.third_party_beneficiary.id) {
        dispatch(removeThirdPartyBeneficiary(fundraiser.third_party_beneficiary))
          .then(
            (response) => {
              if (response.error) {
                NotificationManager.error(response.error.message, "Error!")
              } else {
                if (response.success == true) {
                  dispatch(addFundraiserManagerBankAccount(bankDetails))
                    .then(res => {
                      if (res.error) {
                        NotificationManager.error(res.error.message, "Error!")
                      } else {
                        if (res.success == true) {
                          setFundraiser(res.data);
                          setIsVerifyBankVisible(false);
                          NotificationManager.success(res.message, "Success!")
                        }
                      }
                    }
                    );
                }
              }
            }
          );
      } else {
        dispatch(addFundraiserManagerBankAccount(bankDetails))
          .then(res => {
            if (res.error) {
              NotificationManager.error(res.error.message, "Error!")
            } else {
              if (res.success == true) {
                setFundraiser(res.data);
                setIsVerifyBankVisible(false);
                NotificationManager.success(res.message, "Success!")
              }
            }
          }
          );
      }
    }

    if (fundraiser && fundraiser.fundraiser_details.for_who == "CHARITY_NGO") {
      dispatch(linkNGOBankAccountToFundraiser(bankDetails))
        .then(res => {
          if (res.error) {
            NotificationManager.error(res.error.message, "Error!")
          } else {
            if (res.success == true) {
              setFundraiser(res.data);
              setIsSelectNGOBankVisible(false);
              NotificationManager.success(res.message, "Success!")
            }
          }
        }
        );

    }


  };

  useEffect(() => {
    setIsYourBankDetails(fundraiser.bank_account_details && fundraiser.bank_account_details.id)
  }, [fundraiser.bank_account_details && fundraiser.bank_account_details.id])

  useEffect(() => {
    setIs3rdPartyBankDetails(fundraiser.third_party_beneficiary.id)
  }, [fundraiser.third_party_beneficiary.id])


  return (
    <div className="margin-bottom-20">
      <div className="d-flex margin-bottom-20">
        <div
          className={`btn padding-8 margin-right-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "paymentSettings" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange logo-magenta"}`}
          onClick={() => setVisibleComponent("paymentSettings")}>
          Payment Settings
        </div>

        <div
          className={`btn padding-8 margin-left-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "adminSettings" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange logo-magenta"}`}
          onClick={() => setVisibleComponent("adminSettings")}>
          Admin Settings
        </div>
      </div>
      {
        visibleComponent == "paymentSettings" &&
        <div>
          {
            isYourBankDetails || is3rdPartyBankDetails ?
              <div>
                {isYourBankDetails ? (
                  <div>
                    <div className="margin-bottom-6">
                      <b className="font-size-17 font-size-sm-18 logo-magenta">
                        Your Bank Account Payment Details
                      </b>
                    </div>
                    <div className="font-size-13 font-size-sm-14">
                      <div className="row">
                        <div className="col-3 stretch-full-sm padding-top-8 padding-bottom-8">
                          Bank Account Name
                        </div>
                        <div className="col-9 stretch-full-sm padding-10 bg-white">
                          <b>
                            {fundraiser.bank_account_details.bank_account_name}</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 stretch-full-sm padding-top-8 padding-bottom-8">
                          Bank Name
                        </div>
                        <div className="col-9 stretch-full-sm padding-10 bg-white">
                          <b>
                            {fundraiser.bank_account_details.bank_name}</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 stretch-full-sm padding-top-8 padding-bottom-8">
                          Bank Account Number
                        </div>
                        <div className="col-9 stretch-full-sm padding-10 bg-white">
                          <b>
                            {fundraiser.bank_account_details.bank_account_number}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {is3rdPartyBankDetails ?
                      <div>
                        <div className="margin-bottom-10">
                          <h4 className="logo-magenta">
                            Third Party Beneficiary Payment Details
                          </h4>
                        </div>
                        <div className="font-size-12 font-size-md-14 border-color-light-grey border-radius-4">
                          <div className="row">
                            <div className="col-3 stretch-full-sm padding-top-8 padding-bottom-8">
                              Name
                            </div>
                            <div className="col-9 stretch-full-sm padding-10 bg-white">
                              <b>{fundraiser.third_party_beneficiary.full_name}</b>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3 stretch-full-sm padding-top-8 padding-bottom-8">
                              Email
                            </div>
                            <div className="col-9 stretch-full-sm padding-10 bg-white">
                              <b>{fundraiser.third_party_beneficiary.email}</b>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3 stretch-full-sm padding-top-8 padding-bottom-8">
                              Phone Number
                            </div>
                            <div className="col-9 stretch-full-sm padding-10 bg-white">
                              <b><NationalPhoneNumber
                                internationalNumber={fundraiser.third_party_beneficiary.phone_number}
                              /></b>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3 stretch-full-sm padding-top-8 padding-bottom-8">
                              Bank Name
                            </div>
                            <div className="col-9 stretch-full-sm padding-10 bg-white">
                              <b>{fundraiser.third_party_beneficiary.bank_name}</b>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3 stretch-full-sm padding-top-8 padding-bottom-8">
                              Bank Account No.
                            </div>
                            <div className="col-9 stretch-full-sm padding-10 bg-white">
                              <b>{fundraiser.third_party_beneficiary.bank_account_number}</b>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3 stretch-full-sm padding-top-8 padding-bottom-8">
                              Country
                            </div>
                            <div className="col-9 stretch-full-sm padding-10 bg-white">
                              <b>{countryList().getLabel(fundraiser.third_party_beneficiary.country_code)}</b>
                            </div>
                          </div>
                        </div>
                      </div> :
                      null}
                  </div>
                )}
              </div>
              :
              <div>
                <h4>
                  {
                    fundraiser.fundraiser_details.for_who == "INDIVIDUAL" &&
                    <div>
                      {fundraiser.fundraiser_details.is_for_someone_else ?
                        <span>You have not yet added a third party beneficiary bank account</span>
                        :
                        <span>You have not yet added your bank account</span>
                      }
                    </div>
                  }
                  {
                    fundraiser.fundraiser_details.for_who == "CHARITY_NGO" &&
                    <span>You have not yet added your charity NGO's bank account</span>
                  }
                </h4>
              </div>
          }

          {canEdit ? (
            <button
              className="btn max-width-80 font-size-16 padding-6 margin-top-14 margin-bottom-10 bg-logo-magenta white border-radius-8"
              type="button"
              onClick={() => {
                if (fundraiser.fundraiser_details.for_who == "INDIVIDUAL") {
                  if (is3rdPartyBankDetails) {
                    setIsBeneficiaryBankDetailsModal(true)
                  } else if (isYourBankDetails) {
                    handleAddYourBankDetails()
                  } else {
                    handleAddYourBankDetails()
                  }
                }
                if (fundraiser.fundraiser_details.for_who == "CHARITY_NGO") {
                  setIsSelectNGOBankVisible(true)
                }
              }}
            >
              Edit
            </button>
          ) : null}
        </div>

      }

      {visibleComponent == "adminSettings" && <div>
        <div className="margin-bottom-30">
          <div>
            {fundraiser.fundraiser_details.fundraiser_assistant_managers &&
              fundraiser.fundraiser_details.fundraiser_assistant_managers.length > 0 &&
              fundraiser.fundraiser_details.fundraiser_assistant_managers.map((manager, index) => {
                return (
                  <div key={index}>
                    <div className="d-flex padding-top-6 padding-bottom-6">
                      <div className="min-width-100">Email:</div>
                      <div className="logo-magenta text-break">{manager.email}</div>
                    </div>
                    <div className="d-flex padding-top-6 padding-bottom-6">
                      <div className="min-width-100">First Name:</div>
                      <div className="logo-magenta">{manager.first_name}</div>
                    </div>
                    <div className="d-flex padding-top-6 padding-bottom-6">
                      <div className="min-width-100">Last Name:</div>
                      <div className="logo-magenta">{manager.last_name}</div>
                    </div>

                    <button
                      className="btn bg-orange-red border-radius-8 padding-8 max-width-160 font-size-15 margin-top-10"
                      type="button"
                      onClick={() => {
                        getConfirmAlert({
                          title: "Confirm!",
                          message: "Are you sure you want to remove this co-organizer?",
                          onClickYes: () => {
                            dispatch(removeFundraiserAssistantManager({
                              fundraiser_id: fundraiser.fundraiser_details.id,
                              assistant_user_email: manager.email
                            }))
                              .then(res => {
                                if (res.error) {
                                  NotificationManager.error(res.error.message, "Error!")
                                } else {
                                  if (res.success == true) {
                                    NotificationManager.success(res.message, "Success!")
                                    setFundraiser(res.data)
                                  }
                                }
                              })
                          },
                          onClickNo: () => null
                        })
                      }}
                    >Remove Organizer</button>
                  </div>
                )
              })
            }
          </div>
        </div>

        <Formik
          initialValues={{
            fundraiser_id: fundraiser.fundraiser_details.id,
            assistant_user_email: "",
          }}
          validationSchema={Yup.object({
            assistant_user_email: Yup.string().email("Must be an email").required("Email is required"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            dispatch(addFundraiserAssistantManager(values))
              .then(res => {
                setSubmitting(false)
                if (res.error) {
                  NotificationManager.error(res.error.message, "Error!")
                } else {
                  if (res.success == true) {
                    NotificationManager.success(res.message, "Success!")
                    setFundraiser(res.data)
                    resetForm()
                  }
                }
              })
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            values,
            setFieldValue,

          }) => (
            <Form>
              <span>Invite other users to assist in managing this fundraiser</span>
              <div className="col-sm-6 padding-top-10 padding-bottom-10">
                <div className="max-width-400 stretch-full-sm padding-right-0 padding-right-sm-10">
                  <label className="form-label"><b>Co-organizer's Email</b></label>
                  <Field
                    className="form-control"
                    placeholder="Add email of the co-organizer"
                    name="assistant_user_email"
                    type="email"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="assistant_user_email"
                    component="div"
                  />
                </div>
                <button
                  className="btn bg-light-normal-blue border-radius-8 padding-8 max-width-140 font-size-15 margin-top-18"
                  type="button"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      }

      <Modal
        ariaHideApp={false}
        isOpen={isBeneficiaryBankDetailsModal}
        onRequestClose={() => {
          setIsBeneficiaryBankDetailsModal(false);
        }}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-16 font-size-md-20 logo-magenta">
            Third-Party Beneficiary Information
          </h1>
          <i
            onClick={() => setIsBeneficiaryBankDetailsModal(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <BeneficiaryBankDetails
              fundraiser_id={fundraiser.fundraiser_details.id}
              setIsPageLoading={setIsPageLoading}
              setIsBeneficiaryBankDetailsModal={
                setIsBeneficiaryBankDetailsModal
              }
              fundraiser_details={fundraiser.fundraiser_details}
              thirdPartyBeneficiary={fundraiser.third_party_beneficiary}
              setFundraiser={setFundraiser}
              addFundraiserManagerBankAccount={addFundraiserManagerBankAccount}
              addThirdPartyBeneficiary={addThirdPartyBeneficiary}
            />
          </div>
        </div>
      </Modal>

      {/* <Modal
        ariaHideApp={false}
        isOpen={isYourBankDetailsVisible}
        onRequestClose={() => {
          setIsYourBankDetailsVisible(false);
        }}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-16 font-size-md-20 logo-magenta">
            Your bank details
          </h1>
          <i
            onClick={() => setIsYourBankDetailsVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <ManagerBankDetailsTable
              setIsPageLoading={setIsPageLoading}
              fundraiser={fundraiser}
              setFundraiser={setFundraiser}
              editFundraiserDetails={editFundraiserDetails}
              setIsSelectSavedBankVisible={setIsSelectSavedBankVisible}
            />
          </div>
        </div>
      </Modal> */}
      {/* 
      <Modal
        ariaHideApp={false}
        isOpen={isWhoOwnsItVisible}
        onRequestClose={() => {
          setIsWhoOwnsItVisible(false);
        }}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-16 font-size-md-20 logo-magenta">
            Who owns this fundraiser campaign?
          </h1>
          <i
            onClick={() => setIsWhoOwnsItVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div>
            <input
              type="radio"
              name="forWHo"
              value="you"
              checked={whoOwnsIt == "you"}
              onChange={(event) => {
                setWhoOwnsIt(event.target.value)
              }}
            /> <span>You</span>
          </div>
          <div>
            <input
              type="radio"
              name="forWHo"
              value="someoneElse"
              checked={whoOwnsIt == "someoneElse"}
              onChange={(event) => {
                setWhoOwnsIt(event.target.value)
              }}
            /> <span>Someone else</span>
          </div>
        </div>
        <div>
          <button
            type="button"
            onClick={() => {
              if (whoOwnsIt == "you") {
                setIsWhoOwnsItVisible(false)
                handleAddYourBankDetails()
              } else if (whoOwnsIt == "someoneElse") {
                setIsWhoOwnsItVisible(false)
                setIsBeneficiaryBankDetailsModal(true)
              }
            }}
          >Continue</button>
        </div>
      </Modal> */}

      <Modal
        ariaHideApp={false}
        isOpen={isSelectSavedBankVisible}
        onRequestClose={() => {
          setIsSelectSavedBankVisible(false);
        }}
        className="modal max-width-550 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-18 font-size-sm-22 logo-magenta">
            Select a Saved Bank Account
          </h1>
          <i
            onClick={() => setIsSelectSavedBankVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div className="padding-8">
          <div
            style={{ maxHeight: "80vh" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <SelectSavedBankAccount
              handleSelectSavedBank={(bankDetails) => {
                handleSaveBankDetails({
                  ...bankDetails,
                  account_bank: bankDetails.bank_code,
                  is_already_saved: true,
                  fundraiser_id: fundraiser.fundraiser_details.id
                })
              }}
              handleAddNewBank={() => {
                setIsNewAccountVerified(false)
                setIsVerifyBankVisible(true)
              }}
              handleClose={() => setIsSelectSavedBankVisible(false)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isVerifyBankVisible}
        onRequestClose={() => {
          setIsVerifyBankVisible(false);
        }}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Verify Bank Account
          </h1>
          <i
            onClick={() => setIsVerifyBankVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "80vh" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            {
              !isNewAccountVerified ?
                <VerifyBankAccount
                  setIsPageLoading={setIsPageLoading}
                  handleOnAccountVerification={verifiedBank => {
                    setIsNewAccountVerified(true)
                    setNewlyVerifiedAccountDetails(verifiedBank)
                  }}
                />
                :
                <PreviewVerifiedAccount
                  verifiedAccountDetails={newlyVerifiedAccountDetails}
                  handleSaveAccount={(accountDetails) => {
                    handleSaveBankDetails({
                      ...accountDetails,
                      bank_code: accountDetails.account_bank,
                      is_already_saved: false,
                      fundraiser_id: fundraiser.fundraiser_details.id
                    })
                  }}
                />
            }
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isSelectNGOBankVisible}
        onRequestClose={() => {
          setIsSelectNGOBankVisible(false);
        }}
        className="modal max-width-550 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-18 font-size-sm-22 logo-magenta">
            Select NGO Bank Account
          </h1>
          <i
            onClick={() => setIsSelectNGOBankVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div className="padding-8">
          <div
            style={{ maxHeight: "80vh" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <SelectNGOBankAccount
              ngoBankAccounts={fundraiser.fundraiser_details.charity_ngo ?
                fundraiser.fundraiser_details.charity_ngo.bank_accounts : []}
              handleSelectNGOBank={(bankDetails) => {
                handleSaveBankDetails({
                  bank_account_id: bankDetails.id,
                  charity_ngo_id: fundraiser.fundraiser_details.charity_ngo.id,
                  fundraiser_id: fundraiser.fundraiser_details.id
                })
              }}
              handleAddNewBank={() => {
                setIsNewAccountVerified(false)
                setIsVerifyBankVisible(true)
              }}
              handleClose={() => setIsSelectNGOBankVisible(false)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Settings);
