import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import FileDropZonePreview from "./fragments/FileDropZonePreview";
import {
  addFundraiserDocument,
  removeFundraiserDocument,
} from "../../../../store/actions/fundraiser";
import { NotificationManager } from "react-notifications";

const SupportingDocument = ({
  history,
  dispatch,
  fundraiser,
  documents,
  setFundraiser,
  setIsPageLoading,
}) => {
  const [fundraiserId] = useState(fundraiser.fundraiser_details.id);
  const [isSupportingDocument, setIsSupportingDocument] = useState(
    documents && documents.length > 0
  );

  const isDocumentCompulsory = (documents) => {
    if (fundraiser.fundraiser_details.category == "medical" && documents.length == 0) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    // setIsPageLoading([true, "Loading..."]);
    if (documents) {
      setIsSupportingDocument(false);
      if (documents.length) {
        setIsSupportingDocument(true);
      }
      // setIsPageLoading([false]);
    }
  }, [documents.length]);

  // useEffect(() => {
  //   setFundraiserId(fundraiser.fundraiser_details.id)
  // }, [fundraiser.fundraiser_details]);

  return (
    <div>
      <Formik
        initialValues={{ is_supporting_document: isSupportingDocument }}
        // initialValues={{ is_supporting_document: false }}
        onSubmit={(values, { setSubmitting }) => {

          if (documents.length > 0 && !values.is_supporting_document) {
            //Delete all the fundraiser documents since no document is to be uploaded
            setIsPageLoading([true, "Loading..."]);

            dispatch(
              removeFundraiserDocument(fundraiserId, {
                fundraiser_id: fundraiserId,
                documents: documents,
              })
            ).then((response) => {
              setSubmitting(false);
              setIsPageLoading([false]);

              if (response.error) {
                NotificationManager.error(response.error.message, "Error!")
              } else {
                if (response.success == true) {
                  setFundraiser(response.data);

                  if (isDocumentCompulsory(response.data.documents) == true) {
                    alert("You need to upload at least one verifiable medical report")
                  } else {
                    if (fundraiser.fundraiser_details.for_who == "CHARITY_NGO") {
                      history.push(`/dashboard/fundraiser/edit/${fundraiserId}?activeSlide=fundraiserSummary`)
                    } else {
                      history.push(`/dashboard/fundraiser/edit/${fundraiserId}?activeSlide=paymentDetails`)
                    }
                  }
                }
              }
            });
          } else {
            if (!documents.length && values.is_supporting_document) {
              alert("Drag 'n' drop some files here, or click to select files");
            } else {
              if (isDocumentCompulsory(documents) == true) {
                alert("You need to upload at least one verifiable medical report")
              } else {
                if (fundraiser.fundraiser_details.for_who == "CHARITY_NGO") {
                  history.push(`/dashboard/fundraiser/edit/${fundraiserId}?activeSlide=fundraiserSummary`)
                } else {
                  history.push(`/dashboard/fundraiser/edit/${fundraiserId}?activeSlide=paymentDetails`)
                }
              }
            }
          }
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          values,
        }) => (
          <Form>

            <div className="padding-left-10 padding-right-10">

              <div className="padding-left-md-50 padding-right-md-50 padding-bottom-8">
                <h2 className="logo-magenta font-size-20 font-size-sm-21">Supporting Documents</h2>
              </div>

              <div className="padding-left-md-50 padding-right-md-50">
                <div className="margin-top-10 margin-bottom-10">
                  <div className="d-flex">
                    <label className="form-label">
                      Do you have any supporting document to add to this campaign?
                    </label>
                    &nbsp;
                    <label>
                      <input
                        className="radio-btn"
                        type="radio"
                        name="is_supporting_document"
                        checked={values.is_supporting_document}
                        onChange={() => {
                          // setIsSupportingDocument(true);
                          // console.log(values.is_supporting_document);
                          setFieldValue("is_supporting_document", true);
                        }}
                      />
                      <b>Yes</b> &nbsp;&nbsp;
                    </label>
                    <label>
                      <input
                        className="radio-btn"
                        type="radio"
                        name="is_supporting_document"
                        checked={!values.is_supporting_document}
                        onChange={() => {
                          setFieldValue("is_supporting_document", false);
                        }}
                      />
                      <b>No</b>
                    </label>
                  </div>
                  {values.is_supporting_document ? (
                    <div>
                      <FileDropZonePreview
                        setIsPageLoading={setIsPageLoading}
                        fundraiser_id={fundraiserId}
                        documents={documents}
                        setFundraiser={setFundraiser}
                        addFundraiserDocument={addFundraiserDocument}
                        removeFundraiserDocument={removeFundraiserDocument}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="horizontal-line margin-top-20 margin-bottom-30" />

              <div className="d-flex justify-content-end margin-bottom-30">
                <button
                  className="btn font-size-15 font-size-md-18 margin-right-30 margin-right-sm-50 max-width-160 padding-10 padding-md-16 border-color-logo-magenta logo-magenta border-radius-12"
                  onClick={() => {
                    history.push(`/dashboard/fundraiser/edit/${fundraiserId}?activeSlide=coverMedia`)
                  }}
                >
                  <b>Previous</b>
                </button>
                <button
                  className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-logo-magenta white border-radius-12"
                  type="submit"
                  onClick={handleSubmit}
                >
                  <b>Next</b>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connect()(SupportingDocument);
