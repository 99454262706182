import React from "react";
import { connect } from "react-redux";

const PreviewVerifiedAccount = ({
  verifiedAccountDetails,
  handleSaveAccount,
}) => {
  return (
    <div>
      <div className="font-size-14 font-size-sm-16 padding-top-6 padding-bottom-6">
        <div className="d-flex padding-top-8 padding-bottom-8">
          <div className="max-width-140 padding-right-10">Bank Name</div>
          <b>{verifiedAccountDetails.bank_name}</b>
        </div>
        <div className="d-flex padding-top-8 padding-bottom-8">
          <div className="max-width-140 padding-right-10">Bank Account</div>
          <b>{verifiedAccountDetails.bank_account_name}</b>
        </div>
        <div className="d-flex padding-top-8 padding-bottom-8">
          <div className="max-width-140 padding-right-10">Account Number</div>
          <b>{verifiedAccountDetails.bank_account_number}</b>
        </div>
        {verifiedAccountDetails.bank_branch_name &&
          <div className="d-flex padding-top-8 padding-bottom-8">
            <div className="max-width-140 padding-right-10">Branch</div>
            <b>{verifiedAccountDetails.bank_branch_name}</b>
          </div>
        }
      </div>

      <button
        className="btn margin-top-6 margin-bottom-18 padding-8 bg-logo-magenta white max-width-120 font-size-16 border-radius-8"
        type="button"
        onClick={() => {
          handleSaveAccount(verifiedAccountDetails);
        }}
      >
        Save
      </button>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(PreviewVerifiedAccount);
