import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Modal from "react-modal";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import countryList from "react-select-country-list";
import 'react-markdown-editor-lite/lib/index.css';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DOMPurify from 'dompurify';
import { NotificationManager } from "react-notifications";
import ReactTooltip from 'react-tooltip'
import DatePickerField from "../../../../utilityComponents/DatePickerField";
import CurrencyField from "../../../../utilityComponents/CurrencyField";
import MoneyInputField from "../../../../utilityComponents/MoneyInputField";
import { createFundraiser, editFundraiserDetails, deleteFundraiserMerchandise } from "../../../../../store/actions/fundraiser";
import MerchandiseForm from "./MerchandiseForm";
import MerchandiseView from "./MerchandiseView";
import { convertCountryCodeToName, getConfirmAlert } from "../../../../../utilities";
import doorOpenIcon from "../../../../images/door-open_icon.svg";
import { searchVerifiedCharities } from "../../../../../store/actions/charity_ngo";
import ngoDummyLogo from "../../../../images/ngo_dummy_logo.png"
import { NavLink } from "react-router-dom";

const FundraiserDescription = ({
  history,
  user,
  dispatch,
  initialFundraiserDetails,
  isCreatingNew,
  setIsPageLoading,
  setFundraiser,
}) => {

  const customStyles = {
    option: provided => ({
      ...provided,
      // color: 'white',
      // width: 200,
    }),
    control: provided => ({
      ...provided,
      color: 'black',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
      // width: 200,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: 'normal'
      // width: 200,
    })
  }

  const initialMerchandise = {
    description: "",
    unit_price: "",
    merchandise_picture_file_url: "",
    merchandise_picture_file_key: "",
    base64_file: "",
    file_name: "",
    is_available: true
  }

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [merchandise, setMerchandise] = useState([])
  const [isMerchandiseFormOpen, setIsMerchandiseFormOpen] = useState(false)
  const [isAddingNewMerchandise, setIsAddingNewMerchandise] = useState(true)
  const [editedMerchandise, setEditedMerchandise] = useState(null)
  const [myNgoOptions, setMyNgoOptions] = useState([])
  const [countries, setCountries] = useState([]);

  const handleConvertHtmlToDraft = (html) => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);

      return editorState
    } else {
      return ""
    }
  }

  const handleUploadImageCallBack = (file) => {
    return file
  }

  // const getOptionValue = (option) => option;

  // const getOptionLabel = (option) => option.organization_name;

  const handleGetVerifiedCharities = async (inputValue, callback) => {
    const res = await dispatch(searchVerifiedCharities({
      no_of_items_per_page: 10,
      page: 1,
      query_text: inputValue
    }))

    if (res.success) {
      const formatedItems = res.data.charities.map(e => {
        return {
          label: `${e.organization_name}, ${e.city}, ${convertCountryCodeToName(e.country)} - Reg. No. ${e.registration_number}`,
          value: e
        }
      })

      console.log(formatedItems)
      callback(formatedItems)
    } else {
      return []
    }
  }

  useEffect(() => {
    setEditorState(handleConvertHtmlToDraft(initialFundraiserDetails.story))

    if (!isCreatingNew && initialFundraiserDetails.merchandise) {
      setMerchandise(initialFundraiserDetails.merchandise)
    }
  }, [])

  useEffect(() => {
    // if (user.my_registered_ngos.length > 0) {
    const options = user.my_registered_ngos.map(e => {
      return {
        label: `${e.organization_name}, ${e.city}, ${convertCountryCodeToName(e.country)} - Reg. No. ${e.registration_number}`,
        value: e
      }
    })

    setMyNgoOptions(options)
    // }
  }, [])


  useEffect(() => {
    // Get country list
    const options = countryList().getData();
    setCountries(options);

  }, [])

  return (
    <div>
      {initialFundraiserDetails ? (
        <Formik
          initialValues={{
            ...initialFundraiserDetails,
            story: initialFundraiserDetails.story ? initialFundraiserDetails.story : "",
            is_selling_merchandise: (initialFundraiserDetails.merchandise && initialFundraiserDetails.merchandise.length > 0) ?
              true : false
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string()
              .required("Title is required")
              .min(25, 'Must be at least 25 characters')
              .max(100, 'Must not exceed 100 characters'),
            story: Yup.string().required("Your story is required").min(50),
            category: Yup.string().required("Category is required"),
            country: Yup.string().required("Country is required"),
            state: Yup.string().required("State is required"),
            city: Yup.string().required("City is required"),
            goal_amount: Yup.number()
              .when("is_public", {
                is: true,
                then: Yup.number()
                  .typeError("Must be a number")
                  .positive("Must be a positive number")
                  .integer("Must be a whole number")
                  .required("Goal amount is required"),
                otherwise: Yup.number().nullable(),
              })
              .when("is_goal_set", {
                is: true,
                then: Yup.number()
                  .typeError("Must be a number")
                  .positive("Must be a positive number")
                  .integer("Must be a whole number")
                  .required("Goal amount is required"),
                otherwise: Yup.number().nullable(),
              }),
            charity_ngo: Yup.object()
              .when("for_who", {
                is: (forWho) => {
                  return forWho == "CHARITY_NGO"
                },
                then: Yup.object()
                  .shape({
                    id: Yup.string().required("Charity NGO is required")
                  }),
                otherwise: Yup.object(),
              }),
          })}
          onSubmit={(values, { setSubmitting }) => {

            setIsPageLoading([true, "Loading..."]);
            if (isCreatingNew) {
              let fundraiserValues = values

              if (values.is_public == false) {
                fundraiserValues = {
                  ...values,
                  merchandise
                }
              }

              dispatch(createFundraiser(fundraiserValues))
                .then((response) => {
                  setSubmitting(false);
                  setIsPageLoading([false]);
                  if (response.error) {
                    NotificationManager.error(response.error.message, "Error!")
                  } else {
                    const fundraiser = response.data
                    setFundraiser(fundraiser);
                    history.push(`/dashboard/fundraiser/edit/${fundraiser.fundraiser_details.id}?activeSlide=coverMedia`)
                  }
                })
            } else {
              dispatch(editFundraiserDetails(values))
                .then((response) => {
                  setSubmitting(false);
                  setIsPageLoading([false]);
                  if (response.error) {
                    NotificationManager.error(response.error.message, "Error!")
                  } else {
                    if (response.success == true) {
                      const fundraiser = response.data
                      setFundraiser(fundraiser)
                      history.push(`/dashboard/fundraiser/edit/${fundraiser.fundraiser_details.id}?activeSlide=coverMedia`)
                    }
                  }
                })
            }
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            values,
            errors,
            touched
          }) => (
            <Form>
              <div className="padding-left-10 padding-right-10 border-radius-0">

                <div className="padding-left-md-50 padding-right-md-50 padding-bottom-8">
                  <h2 className="logo-magenta font-size-20 font-size-sm-21">Description</h2>
                </div>

                <div className="padding-left-md-50 padding-right-md-50">

                  {values.id && <div className="d-flex padding-top-10 padding-bottom-10">
                    <label className="form-label">ID</label> &nbsp; <label className="form-label"> <b>#{values.id}</b></label>
                  </div>}

                  <div className="padding-top-10 padding-bottom-10">
                    <label className="form-label">Title *</label>
                    <Field
                      className="form-control"
                      placeholder="Enter title"
                      name="title"
                      onBlur={(e) => {
                        setFieldValue("title", e.currentTarget.value);
                        // call the built-in handleBur
                        handleBlur(e);
                        // and do something about e
                        let someValue = e.currentTarget.value;
                      }}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="title"
                      component="div"
                    />
                  </div>

                  <div className="padding-top-10 padding-bottom-10">
                    <label className="form-label">Tell Your Story * <i className="fa fa-question-circle" data-tip data-for='story' /> </label>
                    <div className="form-control"
                      style={{ minHeight: '400px' }}
                    >
                      <Editor
                        spellCheck="true"
                        editorState={editorState}
                        onEditorStateChange={(editorState) => {
                          setEditorState(editorState)
                          setFieldValue("story", DOMPurify.sanitize(draftToHtml(convertToRaw(editorState.getCurrentContent()))))
                        }}
                        toolbar={{
                          image: {
                            previewImage: true,
                            uploadCallback: handleUploadImageCallBack,
                            alt: { present: true, mandatory: true }
                          },
                          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji', 'history'],
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                        }}
                      />
                    </div>
                    <ErrorMessage
                      className="error-message"
                      name="story"
                      component="div"
                    />
                  </div>

                  <div className="padding-top-10 padding-bottom-10">
                    <label className="form-label">
                      For Who?
                    </label>

                    <div>
                      <div className="d-flex">
                        <div>
                          <i className="fa fa-user font-size-17 logo-magenta" />
                          <input
                            className="radio-btn margin-left-8 margin-right-10"
                            type="radio"
                            name="for_who"
                            checked={values.for_who == "INDIVIDUAL"}
                            onChange={() => {
                              setFieldValue("for_who", "INDIVIDUAL");
                            }}
                          />
                        </div>
                        <div className="d-flex">
                          <div>
                            <b>Individual</b>
                            <p>Raised fund is paid into a personal account</p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div>
                          <i className="fas fa-ribbon font-size-17 logo-magenta" />
                          <input
                            className="radio-btn margin-left-10 margin-right-10"
                            type="radio"
                            name="for_who"
                            checked={values.for_who == "CHARITY_NGO"}
                            onChange={() => {
                              setFieldValue("for_who", "CHARITY_NGO");
                            }}
                          />
                        </div>
                        <div className="d-flex">
                          <div>
                            <b>Charity NGO</b>
                            <p>Raised fund is paid directly into the NGO's account</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    values.for_who == "INDIVIDUAL" ?

                      <div className="padding-top-10 padding-bottom-10">
                        <label className="form-label">
                          Campaign Type
                        </label>
                        <div>
                          <div className="d-flex">
                            <div>
                              <img src={doorOpenIcon} width={18} />
                              <input
                                className="radio-btn margin-left-8 margin-right-10"
                                type="radio"
                                name="is_public"
                                checked={values.is_public == true}
                                onChange={() => {
                                  setFieldValue("is_public", true);
                                  // setFieldValue("goal_amount", null);
                                }}
                              />
                            </div>
                            <div className="d-flex">
                              <div>
                                <b>Public</b>
                                <p>Anyone can search for this campaign on our website and apps</p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div>
                              <i className="fa fa-lock font-size-17 logo-magenta" />
                              <input
                                className="radio-btn margin-left-10 margin-right-10"
                                type="radio"
                                name="is_public"
                                checked={values.is_public == false}
                                onChange={() => {
                                  setFieldValue("is_public", false);
                                  // setFieldValue("goal_amount", null);
                                }}
                              />
                            </div>
                            <div className="d-flex">
                              <div>
                                <b>Private</b>
                                <p>It will not appear in any search result. Only visible to those who have the link</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> :
                      <div className="padding-top-10 padding-bottom-10">
                        {
                          myNgoOptions.length > 0 ?
                            <div>
                              <label className="form-label">
                                Charity NGO
                              </label>
                              <div>
                                {
                                  values.charity_ngo.id ?
                                    <div className="d-flex">
                                      <img
                                        width="50px"
                                        height="50px"
                                        src={values.charity_ngo && values.charity_ngo.logo_file_url ?
                                          values.charity_ngo.logo_file_url :
                                          ngoDummyLogo}
                                      />
                                      <div className="relative margin-left-10">
                                        <b>{values.charity_ngo.organization_name}</b>
                                        <p className="margin-right-10">{values.charity_ngo.city}, {convertCountryCodeToName(values.charity_ngo.country)}. Reg. No. {values.charity_ngo.registration_number}</p>
                                        <b className="absolute top-0 right-0 red"
                                          onClick={() => {
                                            setFieldValue("charity_ngo", {})
                                          }}>X</b>
                                      </div>
                                    </div>
                                    :
                                    <div>
                                      {/* <AsyncSelect
                                  className="form-control padding-0"
                                  classNamePrefix="react-select"
                                  placeholder="Search charity NGOs"
                                  onChange={(e) => {
                                    setFieldValue("charity_ngo", e.value)
                                    if(e.value.acceptable_currencies[0]){
                                      setFieldValue("goal_amount_currency", e.value.acceptable_currencies[0])
                                    }
                                  }}
                                  loadOptions={handleGetVerifiedCharities}
                                /> */}
                                      <Field
                                        component={() => (
                                          <Select
                                            className="form-control padding-0"
                                            classNamePrefix="react-select"
                                            placeholder="Select your charity NGO"
                                            options={myNgoOptions}
                                            value={
                                              myNgoOptions
                                                ? myNgoOptions.find(
                                                  (option) => option.value === values.charity_ngo
                                                )
                                                : ""
                                            }
                                            onChange={(option) => {
                                              setFieldValue("charity_ngo", option.value);
                                            }}
                                          />
                                        )}
                                      />
                                    </div>
                                }
                              </div>
                            </div> :
                            <div className="red">
                              You do not have an NGO registered with us. Please <NavLink to="/dashboard/account?q=charityNGO">register</NavLink> your NGO before proceeding.
                            </div>

                        }

                        {touched.charity_ngo && errors.charity_ngo && errors.charity_ngo.id ?
                          <div className="error-message">
                            {errors.charity_ngo.id}
                          </div> : null
                        }

                      </div>
                  }

                  {!values.is_public && (
                    <div className="d-flex align-items-center padding-top-10 padding-bottom-10">
                      <label><b>You want to set a goal amount?</b></label>
                      <input
                        type="checkbox"
                        checked={values.is_goal_set}
                        onChange={() => {
                          setFieldValue("is_goal_set", !values.is_goal_set);
                          if (!values.is_goal_set == false) {
                            setFieldValue("goal_amount", null);
                          }
                        }}
                      />
                    </div>
                  )}

                  {(values.is_goal_set || values.is_public) && (
                    <div className="row">
                      <div className="col-sm-6 padding-top-10 padding-bottom-10">
                        <div className="padding-right-0 padding-right-sm-10">
                          <label className="form-label">
                            Goal Amount
                            {!values.is_public ? <span>(Optional)</span> : null}
                          </label>
                          <div className="form-control d-flex padding-0 fixed-height-40">
                            <div className="max-width-80 bg-logo-magenta white border-top-left-radius-0 border-bottom-left-radius-0">
                              <CurrencyField
                                value={values.goal_amount_currency}
                                onChange={(option) => {
                                  setFieldValue(
                                    "goal_amount_currency",
                                    option.value
                                  );
                                }}
                                isCurrencyChangeable={true}
                              />
                            </div>
                            <MoneyInputField
                              value={values.goal_amount}
                              onChange={(amount) => {
                                if (amount) {
                                  setFieldValue("goal_amount", amount);
                                  setFieldValue("is_goal_set", true);
                                } else {
                                  setFieldValue("is_goal_set", false);
                                  setFieldValue("goal_amount", "");
                                }
                              }}
                              placeholder="Enter goal amount"
                            />
                          </div>
                          <ErrorMessage
                            className="error-message"
                            name="goal_amount"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {(!values.is_public && !values.is_goal_set) &&
                    <div className="d-flex align-items-center padding-top-10 padding-bottom-10">
                      <div className="margin-right-10">
                        Select Currency
                      </div>
                      <div className="form-control padding-0 max-width-100">
                        <CurrencyField
                          styles={customStyles}
                          value={values.goal_amount_currency}
                          onChange={(option) => {
                            setFieldValue(
                              "goal_amount_currency",
                              option.value
                            );
                          }}
                          isCurrencyChangeable={true}
                        />
                      </div>
                    </div>}

                  {<div className="row">
                    <div className="col-sm-6 padding-top-10 padding-bottom-10">
                      <div className="padding-right-0 padding-right-sm-10">
                        <label className="form-label">End Date (Optional)</label>
                        <DatePickerField
                          name="end_date"
                          value={values.end_date}
                          minDate={new Date(new Date().getTime() + 8.64e+7)}
                          placeholderText="End Date (Optional)"
                          onChange={(event, selectedDate) => {
                            const currentDate = selectedDate;
                            setFieldValue("end_date", currentDate);
                          }}
                        />
                        <ErrorMessage
                          className="error-message"
                          name="end_date"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>}

                  {!values.is_public && (
                    <div className="d-flex align-items-center padding-top-10 padding-bottom-10">
                      <label><b>You want the donors to see how much raised?</b> </label>
                      <input
                        type="checkbox"
                        checked={values.is_amount_raised_visible}
                        onChange={(val) => {
                          setFieldValue(
                            "is_amount_raised_visible",
                            !values.is_amount_raised_visible
                          );
                        }}
                      />
                    </div>
                  )}

                  <div className="row">
                    <div className="col-sm-6 padding-top-10 padding-bottom-10">
                      <div className="padding-right-0 padding-right-sm-10">
                        <label className="form-label">Category</label>
                        <Field
                          component={() => (
                            <Select
                              className="form-control padding-0"
                              classNamePrefix="react-select"
                              placeholder="Select category"
                              options={user.fundraiser_categories}
                              value={
                                user.fundraiser_categories
                                  ? user.fundraiser_categories.find(
                                    (option) => option.value === values.category
                                  )
                                  : ""
                              }
                              onChange={(option) => {
                                setFieldValue("category", option.value);
                              }}
                            />
                          )}
                        />
                        <ErrorMessage
                          className="error-message"
                          name="category"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 padding-top-10 padding-bottom-10">
                    <div className="padding-right-0 padding-right-sm-10">
                      <label className="form-label">Country</label>
                      <Field
                        component={() => (
                          <Select
                            className="form-control padding-0"
                            classNamePrefix="react-select"
                            placeholder="Select country"
                            options={countries}
                            value={
                              countries
                                ? countries.find(
                                  (option) => option.value === values.country
                                )
                                : ""
                            }
                            onChange={(option) => {
                              setFieldValue("country", option.value);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        className="error-message"
                        name="country"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 padding-top-10 padding-bottom-10">
                    <div className="padding-right-0 padding-right-sm-10">
                      <label className="form-label">State</label>
                      <Field
                        className="form-control"
                        placeholder="Enter State or Province"
                        name="state"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="state"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 padding-top-10 padding-bottom-10">
                    <div className="padding-right-0 padding-right-sm-10">
                      <label className="form-label">City</label>
                      <Field
                        className="form-control"
                        placeholder="Enter City"
                        name="city"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="city"
                        component="div"
                      />
                    </div>
                  </div>

                  {!values.is_public &&
                    <div className="padding-top-10 padding-bottom-10">
                      <div className="d-flex align-items-center padding-bottom-10">
                        <label>
                          <b>Do you want to sell something on this fundraiser campaign?</b>
                        </label>
                        <input
                          type="checkbox"
                          checked={values.is_selling_merchandise}
                          onChange={() => {
                            setFieldValue(
                              "is_selling_merchandise",
                              !values.is_selling_merchandise
                            );
                          }}
                        />
                      </div>
                      <div>
                        {values.is_selling_merchandise ||
                          (initialFundraiserDetails.merchandise && initialFundraiserDetails.merchandise.length > 0) ?
                          <div>
                            {merchandise.length > 0 && merchandise.map((item, index) => {

                              return (
                                <div key={index}
                                  className="row box-shadow margin-bottom-20 border-radius-0 padding-top-6 padding-bottom-6 padding-left-10 padding-right-10">
                                  <div className="col-md-8 padding-top-4 padding-bottom-4 padding-right-md-20">
                                    <MerchandiseView
                                      item={item}
                                      currency={values.goal_amount_currency}
                                    />
                                  </div>

                                  <div className="col-md-4 d-flex align-items-center padding-top-4 padding-bottom-4">
                                    <button
                                      className="btn bg-blue max-width-80 padding-6 margin-right-20 font-size-12"
                                      type="button"
                                      onClick={() => {
                                        setIsAddingNewMerchandise(false)
                                        setEditedMerchandise(item)
                                        setIsMerchandiseFormOpen(true)
                                      }}
                                    >
                                      Edit
                                    </button>

                                    <button
                                      className="btn bg-orange-red max-width-80 padding-6 font-size-12"
                                      type="button"
                                      onClick={() => {
                                        if (isCreatingNew) {
                                          const oldMerchandiseList = merchandise.filter((item, i) => index != i)
                                          setMerchandise([...oldMerchandiseList])
                                        } else {
                                          getConfirmAlert({
                                            title: "Confirm!",
                                            message: "Are you sure you want to delete  this merchandise?",
                                            onClickYes: () => {
                                              dispatch(deleteFundraiserMerchandise(item.id))
                                                .then(res => {
                                                  if (res.error) {
                                                    NotificationManager.error(res.error.message, "Error!")
                                                  } else {
                                                    if (res.success == true) {
                                                      NotificationManager.success(res.message, "Success!")
                                                      const fundraiser = res.data
                                                      setFundraiser(fundraiser)
                                                      setMerchandise([...fundraiser.fundraiser_details.merchandise])
                                                    }
                                                  }
                                                })
                                            },
                                            onClickNo: () => null
                                          })
                                        }
                                      }}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              )
                            })}

                            <button
                              className="btn bg-logo-magenta max-width-200 padding-10 font-size-15"
                              type="button"
                              onClick={() => {
                                setIsAddingNewMerchandise(true)
                                setIsMerchandiseFormOpen(true)
                              }}
                            >
                              Add New Merchandise
                            </button>
                          </div> : null
                        }
                      </div>
                    </div>
                  }
                </div>

                <div className="horizontal-line margin-top-20 margin-bottom-30" />

                <div className="d-flex justify-content-end margin-bottom-30">
                  <button
                    className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-logo-magenta white border-radius-12"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Next
                  </button>
                </div>

                <ReactTooltip id='story' aria-haspopup='true' role='example' >
                  Explain in details for what or for who you are raising the fund, the reasons, and how the fund will be spent.
                </ReactTooltip>
              </div>

              <Modal
                ariaHideApp={false}
                isOpen={isMerchandiseFormOpen}
                onRequestClose={() => setIsMerchandiseFormOpen(false)}
                className="modal max-width-650 padding-0"
                overlayClassName="modal-backdrop"
              >
                <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
                  <h1 className="font-size-20 font-size-md-22 logo-magenta">
                    Add Merchandise
                  </h1>
                  <i
                    onClick={() => setIsMerchandiseFormOpen(false)}
                    className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
                  ></i>
                </div>

                <div className="horizontal-line" />

                <div
                  style={{ maxHeight: "80vh" }}
                  className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
                >
                  <MerchandiseForm
                    currency={values.goal_amount_currency}
                    setFundraiser={setFundraiser}
                    isCreatingNewFundraiser={isCreatingNew}
                    initialMerchandise={isAddingNewMerchandise ? initialMerchandise : editedMerchandise}
                    isAddingNewMerchandise={isAddingNewMerchandise}
                    initialFundraiserDetails={initialFundraiserDetails}
                    setIsPageLoading={setIsPageLoading}
                    handleClose={() => {
                      setIsMerchandiseFormOpen(false)
                      setEditedMerchandise(null)
                    }}
                    setMerchandise={setMerchandise}
                    onAddMerchandise={(details) => {
                      const oldMerchandiseList = merchandise
                      oldMerchandiseList.push(details)
                      setMerchandise([...oldMerchandiseList])
                    }}
                  />
                </div>
              </Modal>
            </Form>
          )}
        </Formik>
      ) : null}

    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(FundraiserDescription);
