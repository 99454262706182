import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Select from "react-select";
import countryList from "react-select-country-list";
import Dropzone from "react-dropzone";
import { getConfirmAlert } from "../../../../utilities";
import PreviewFileUpload from "../../../utilityComponents/PreviewFileUpload";
import PhoneInputField from "../../../utilityComponents/PhoneInputField";

const CharityNgoDetails = ({
  user,
  initialCharityNgoDetails,
  charityNgo,
  handleSubmitCharityNgo,
  handleDeleteDocument,
  handleUploadNgoDocument,
  isCreatingNew
}) => {

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    // Get country list
    const options = countryList().getData();
    setCountries(options);

  }, [])

  return (
    <div>
      {initialCharityNgoDetails || charityNgo ? (
        <Formik
          initialValues={isCreatingNew ? initialCharityNgoDetails : charityNgo}

          validationSchema={Yup.object({
            organization_name: Yup.string().required("Organization name required"),
            registration_number: Yup.string().required("Registration number is required"),
            country: Yup.string().required("Select a country"),
            city: Yup.string().required("City is required"),
            state: Yup.string().required("State is required"),
            street_address: Yup.string().required("Street address is required"),
            phone_number: Yup.string().required("Phone number is required"),
          })}

          onSubmit={(values, { setSubmitting }) => {
            handleSubmitCharityNgo(values)
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            isSubmitting,
            values,
          }) => (
            <Form>
              <div className="padding-left-10 padding-right-10 border-radius-0">
                <div className="padding-bottom-8">
                  <h2 className="logo-magenta font-size-20 font-size-sm-21">DETAILS</h2>
                </div>
                <div className="horizontal-line " />

                <div>
                  <div className="padding-left-0  padding-top-10 padding-bottom-10">
                    <label className="form-label">Logo</label>

                    {values.logo_file || values.logo_file_url ?
                      <div className="relative max-width-100">
                        <div
                          className="fixed-height-100"
                          onClick={() => {
                            setFieldValue("logo_file", "")
                          }}
                        >
                          {
                            values.logo_file ?
                              <PreviewFileUpload file={values.logo_file} /> :
                              <img
                                width="100%"
                                height="100%"
                                className="d-block stretch-full border-top-radius-0"
                                src={`${values.logo_file_url}?${values.logo_file_url}`}
                              />
                          }
                        </div>

                        <i
                          className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                          onClick={() => {
                            getConfirmAlert({
                              title: "Confirm delete!",
                              message: "Are you sure you want to delete this file?",
                              onClickYes: () => {
                                if (values.logo_file) {
                                  setFieldValue("logo_file", "")
                                }

                                if (values.logo_file_url && values.id) {
                                  handleDeleteDocument({
                                    charity_ngo_id: values.id,
                                    document_type: "LOGO",
                                    file_url: values.logo_file_url
                                  }).then(res => {
                                    if (res.success) {
                                      setFieldValue("logo_file_url", "")
                                    }
                                  })
                                }
                              },
                              onClickNo: () => null,
                            });
                          }}
                        ></i>
                      </div>
                      :
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          if (isCreatingNew) {
                            setFieldValue("logo_file", acceptedFiles[0])
                          } else {
                            if (values.id) {
                              handleUploadNgoDocument(values.id, acceptedFiles[0], "LOGO")
                                .then(r => {
                                  if (r.success) {
                                    setFieldValue("logo_file_url", r.data.logo_file_url)
                                  }
                                })
                            }
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="d-inline-block cursor-pointer"
                            style={{ outline: "none" }}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} accept="image/*" />
                            <p className="d-inline-block max-width-100 fixed-height-80 font-size-13 font-size-sm-14 line-height-16 line-height-sm-22 padding-10 white border-radius-0 bg-charcoal opacity-75">
                              Drag 'n' drop or click to select file
                            </p>
                          </div>
                        )}
                      </Dropzone>
                    }
                  </div>

                  <div className="padding-left-0  padding-top-10 padding-bottom-10">
                    <label className="form-label">Organization Name</label>
                    <Field
                      className="form-control"
                      placeholder="Enter organization name"
                      name="organization_name"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="organization_name"
                      component="div"
                    />
                  </div>

                  <div className="padding-left-0  padding-top-10 padding-bottom-10">
                    <label className="form-label">Registration Number</label>
                    <Field
                      className="form-control"
                      placeholder="Enter organization's registration number"
                      name="registration_number"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="registration_number"
                      component="div"
                    />
                  </div>

                  <div className="padding-left-0 padding-top-10 padding-bottom-10">
                    <label className="form-label">Country</label>
                    <Field
                      component={() => (
                        <Select
                          className="form-control padding-0"
                          classNamePrefix="react-select"
                          placeholder="Select country"
                          options={countries}
                          value={
                            countries
                              ? countries.find(
                                (option) => option.value === values.country
                              )
                              : ""
                          }
                          onChange={(option) => {
                            setFieldValue("country", option.value);
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="country"
                      component="div"
                    />
                  </div>

                  <div className="padding-left-0  padding-top-10 padding-bottom-10">
                    <label className="form-label">State</label>
                    <Field
                      className="form-control"
                      placeholder="Enter State or Province"
                      name="state"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="state"
                      component="div"
                    />
                  </div>

                  <div className="padding-left-0  padding-top-10 padding-bottom-10">
                    <label className="form-label">City</label>
                    <Field
                      className="form-control"
                      placeholder="Enter City"
                      name="city"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="city"
                      component="div"
                    />
                  </div>

                  <div className="padding-left-0  padding-top-10 padding-bottom-10">
                    <label className="form-label">Street Address</label>
                    <Field
                      className="form-control"
                      placeholder="Enter Street Address"
                      name="street_address"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="street_address"
                      component="div"
                    />
                  </div>

                  <div className="padding-left-0 padding-top-10 padding-bottom-10">
                    <label className="form-label">Phone Number</label>
                    <div
                      className="form-control padding-top-4 padding-bottom-4">
                      <PhoneInputField
                        placeholderText="Enter Phone Number"
                        value={values.phone_number}
                        onChange={setFieldValue}
                        defaultCountry={values.country ? values.country : user.country}
                        name="phone_number"
                      />
                    </div>
                  </div>
                  <ErrorMessage
                    className="error-message"
                    name="phone_number"
                    component="div"
                  />
                </div>

                <div className="horizontal-line margin-top-20 margin-bottom-30" />

                <div className="d-flex justify-content-end margin-bottom-30">
                  <button
                    className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-logo-magenta white border-radius-12"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Next
                  </button>
                </div>

              </div>

            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CharityNgoDetails);
