import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import { NotificationManager } from "react-notifications";
import { getConfirmAlert, getDocumentFileformatFromUrl } from "../../../../../utilities";
import PdfViewer from "../../../../utilityComponents/PdfViewer";
import PdfThumbnail from "../../../../utilityComponents/PdfThumbnail";
import ImageViewer from "../../../../utilityComponents/ImageViewer";

const FileDropZonePreview = ({
  dispatch,
  setIsPageLoading,
  fundraiser_id,
  setFundraiser,
  documents,
  addFundraiserDocument,
  removeFundraiserDocument,
}) => {
  const [isEnterDocDescriptionOpen, setIsEnterDocDescriptionOpen] = useState(
    false
  );
  const [latestFile, setLatestFile] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [viewedDocument, setViewedDocument] = useState("");
  const [isImageViewerVisible, setIsImageViewerVisible] = useState(false);
  const [isPdfViewerVisible, setIsPdfViewerVisible] = useState(false);

  const handleOnDrop = () => {
    setIsPageLoading([true, "Uploading..."]);

    const formData = new FormData();
    formData.append("file", latestFile);
    formData.append("fundraiser_id", fundraiser_id);
    formData.append("description", documentDescription);

    dispatch(addFundraiserDocument(formData))
      .then((response) => {
        setIsPageLoading([false]);;

        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            setFundraiser(response.data);
            setIsEnterDocDescriptionOpen(false)
            setLatestFile("");
          }
        }
      });
  };

  const handleRemoveFundraiserDocument = (fileObj) => {
    setIsPageLoading([true, "Deleting..."]);
    const documentFilesArray = [];
    documentFilesArray.push(fileObj);
    dispatch(
      removeFundraiserDocument(fundraiser_id, {
        fundraiser_id: fundraiser_id,
        documents: [fileObj],
      })
    ).then((response) => {
      setIsPageLoading([false]);
      if (response.error) {
        NotificationManager.error(response.error.message, "Error!")
      } else {
        if (response.success == true) {
          setFundraiser(response.data);
        }
      }
    });
  };

  // const handleUploadFundraiserDocument = (fileName, fileObj) => {
  //   const url = fileObj.file_url;
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", fileName); //or any other extension
  //   document.body.appendChild(link);
  //   link.click();
  // };

  return (
    <div>
      <Dropzone
        onDrop={(acceptedFiles) => {
          setLatestFile(acceptedFiles[0]);
          setIsEnterDocDescriptionOpen(true);
        }}
        
      >
        {({ getRootProps, getInputProps }) => (
          <div className="d-flex align-content-center justify-content-center padding-top-20 padding-bottom-20">
            <div
              className="d-inline-block cursor-pointer"
              style={{ outline: "none" }}
              {...getRootProps()}
            >
              <input {...getInputProps()} accept="image/*, .pdf" />
              <p className="d-inline-block font-size-13 font-size-sm-14 line-height-16 line-height-sm-22 padding-6 padding-left-16 padding-right-16 white border-radius-30 bg-charcoal opacity-75">
                Drag 'n' drop some files here, or click to select files
              </p>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="row">
        {documents.map((fileObj, index) => (
          <Fragment key={index}>
            <div className="min-width-130 max-width-130 margin-4 margin-sm-10 cursor-pointer bg-lighter-blue border-radius-6">
              <div className="relative">
                <div
                  className="fixed-height-100"
                  onClick={() => {
                    setViewedDocument(fileObj);
                    if (getDocumentFileformatFromUrl(fileObj.file_url) == "pdf") {
                      setIsPdfViewerVisible(true);
                    } else if (getDocumentFileformatFromUrl(fileObj.file_url) == "image") {
                      setIsImageViewerVisible(true);
                    }
                  }}
                >
                  {getDocumentFileformatFromUrl(fileObj.file_url) == "pdf" ? (
                    <PdfThumbnail height={90} document={fileObj} />
                  ) : (
                    <img
                      width="100%"
                      height="100%"
                      className="d-block stretch-full border-top-radius-6"
                      src={`${fileObj.file_url}?${fileObj.file_url}`}
                    />
                  )}
                </div>

                <i
                  className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                  onClick={() => {
                    getConfirmAlert({
                      title: "Confirm delete!",
                      message: "Are you sure you want to delete this document?",
                      onClickYes: () => {
                        handleRemoveFundraiserDocument(fileObj)
                      },
                      onClickNo: () => null,
                    });
                  }}
                ></i>
              </div>
              <div
                className="font-size-12 padding-4 padding-left-8 padding-right-8 text-truncate border-bottom-radius-6"
                onClick={() => {
                  setViewedDocument(fileObj);
                  if (getDocumentFileformatFromUrl(fileObj.file_url) == "pdf") {
                    setIsPdfViewerVisible(true);
                  } else if (getDocumentFileformatFromUrl(fileObj.file_url) == "image") {
                    setIsImageViewerVisible(true);
                  }
                }}
              // onClick={() =>
              //   handleUploadFundraiserDocument(fileName, fileObj)
              // }
              >
                <b>{fileObj.description}</b>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
      
      <Modal
        ariaHideApp={false}
        isOpen={isEnterDocDescriptionOpen}
        onRequestClose={() => {
          setIsEnterDocDescriptionOpen(false);
          setDocumentDescription("");
          setLatestFile("");
        }}
        className="modal max-width-580 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="stretch-full font-size-18 font-size-md-22 logo-magenta">
            Enter document description
          </h1>
          <i
            onClick={() => {
              setIsEnterDocDescriptionOpen(false);
              setDocumentDescription("");
              setLatestFile("");
            }}
            className="fa fa-close font-size-20 font-size-sm-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div
          // style={{ maxHeight: "540" }}
          className="padding-10 padding-top-sm-30 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
        >
          <div>
            <input
              className="form-control"
              type="text"
              placeholder="E.g. Medical Report"
              onChange={(event) => {
                setDocumentDescription(event.target.value);
              }}
            />
          </div>
          <button
            className="btn max-width-100 bg-logo-magenta white padding-8 margin-top-12 margin-top-sm-30 font-size-15 border-radius-8"
            type="button"
            onClick={() => {
              if (documentDescription) {
                handleOnDrop();
              } else {
                alert("Please enter a description");
              }
            }}
          >
            Save
          </button>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isPdfViewerVisible}
        onRequestClose={() => setIsPdfViewerVisible(false)}
        className="modal max-width-800 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div>
          <PdfViewer
            document={viewedDocument}
            handleClose={() => setIsPdfViewerVisible(false)} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isImageViewerVisible}
        onRequestClose={() => setIsImageViewerVisible(false)}
        className="modal max-width-1000 padding-0 bg-black"
        overlayClassName="modal-backdrop"
      >
        <div>
          <ImageViewer
            maxHeightImage={600}
            document={viewedDocument}
            handleClose={() => setIsImageViewerVisible(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default connect()(FileDropZonePreview);
