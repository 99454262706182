import React from "react";
import { connect } from "react-redux";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";
import NationalPhoneNumber from "../../../../utilityComponents/NationalPhoneNumber";
import Truncate from "react-truncate";
import { convertSnakeToStandardCasing } from "../../../../../utilities";
import dummyPrivateFundraiserCoverImage from "../../../fundraiser/setup/privateFundraiserCover.jpg";
import CoverMediaThumbnail from "./CoverMediaThumbnail";

const MerchandiseOrderDetails = ({
  orderDetails
}) => {

  return (
    <div className="padding-top-10 padding-bottom-10">
      <div>
        <p className="blue font-size-18 padding-top-12 padding-bottom-6 font-weight-bold">Description</p>
        <div className="row box-shadow margin-bottom-20 border-radius-6 padding-top-6 padding-bottom-6 padding-left-10 padding-right-10">
          <div className="col-2" >
            <CoverMediaThumbnail
              isBorderRadiusTopLeft={true}
              coverMedia={orderDetails.fundraiser_details.cover_media}
              fundraiserDetails={orderDetails.fundraiser_details}
            />
          </div>

          <div className="col-10 row logo-magenta stretch-full padding-left-10">
            <div className="d-flex">
              <div className="col-4 padding-top-4 padding-bottom-4">
                <label className="grey font-size-13 line-height-13">Fundr. ID</label>
                <p className="font-size-14 line-height-14">#{orderDetails.fundraiser_id}</p>
              </div>
              <div className="col-4 padding-top-4 padding-bottom-4">
                <label className="grey font-size-13 line-height-13">Order ID</label>
                <p className="font-size-14 line-height-14">#{orderDetails.id}</p>
              </div>
              <div className="col-4 padding-top-4 padding-bottom-4">
                <label className="grey font-size-13 line-height-13">QTY</label>
                <p className="font-size-14 line-height-14">{orderDetails.ordered_items.map(item => item.quantity).reduce((a, b) => a + b, 0)}</p>
              </div>
            </div>
            <div className="d-flex">
              <div className="col-8 padding-top-4 padding-bottom-4">
                <label className="grey font-size-13 line-height-13">Total Amount</label>
                <div className="font-size-14 line-height-14 normal-green">
                  <FormattedMoney value={orderDetails.total_amount} currency={orderDetails.total_amount_currency} />
                </div>
              </div>
              <div className="col-4 padding-top-4 padding-bottom-4">
                <label className="grey font-size-13 line-height-13">Status</label>
                <p className="font-size-14 line-height-14 orange-red">{convertSnakeToStandardCasing(orderDetails.status)}</p>
              </div>
              {/* <div className="col-sm-6 padding-top-4 padding-bottom-4">
              <label className="grey font-size-13 line-height-13"></label>
              <p className="font-size-14 line-height-14"></p>
            </div> */}
            </div>
            {orderDetails.comment &&
              <div className="padding-top-4 padding-bottom-4">
                <label className="grey font-size-13 line-height-13">Comment</label>
                <p className="font-size-14">
                  {orderDetails.comment}
                </p>
              </div>
            }
          </div>
        </div>
      </div>

      <div>
        <p className="blue font-size-18 padding-top-12 padding-bottom-6 font-weight-bold">Buyer's Information</p>
        <div className="row logo-magenta box-shadow align-items-center margin-bottom-20 border-radius-6 padding-top-6 padding-bottom-6 padding-8 padding-left-sm-14 padding-right-sm-14">
          <div className="d-flex col-sm-4 padding-top-4 padding-bottom-4">
            <div className="col-4 padding-top-4 padding-bottom-4">
              <img
                width="40"
                height="40"
                className="border-radius-20 d-block"
                src={orderDetails.profile_picture} />
            </div>
            <div className="col-4 padding-top-4 padding-bottom-4">
              <label className="grey font-size-13 line-height-13">Name</label>
              <p className="font-size-14 line-height-14 text-break">
                {orderDetails.first_name} {orderDetails.last_name}
              </p>
            </div>
          </div>
          <div className="col-sm-4 padding-top-4 padding-bottom-4">
            <label className="grey font-size-13 line-height-13">Email</label>
            <p className="font-size-14 line-height-14 text-break">{orderDetails.email}</p>
          </div>
          <div className="col-sm-4 padding-top-4 padding-bottom-4">
            <label className="grey font-size-13 line-height-13 text-break">Phone Number</label>
            <p className="font-size-14 line-height-14">
              <NationalPhoneNumber
                // color={colors.logoDarkBlueColor}
                internationalNumber={orderDetails.phone_number}
              />
            </p>
          </div>
        </div>
      </div>

      <div>
        <p className="blue font-size-18 padding-top-12 padding-bottom-6 font-weight-bold">Order Items</p>

        {orderDetails.ordered_items.map((item, index) => {
          return (
            <div
              key={index}
              className="d-flex box-shadow margin-bottom-20 border-radius-6 padding-top-6 padding-bottom-6 padding-left-10 padding-right-10"
            >
              <div className="row logo-magenta stretch-full padding-left-10">
                <div className="d-flex col-sm-4">
                  <img
                    className="d-block border-radius-6 min-width-90 max-width-90 margin-top-4 margin-bottom-4"
                    height="50"
                    width="50"
                    src={item.merchandise.merchandise_picture_file_url}
                  />

                  <b className="d-block stretch-full padding-top-4 padding-left-10 padding-bottom-4" >
                    <Truncate lines={2}>
                      {item.merchandise.description}
                    </Truncate>
                  </b>
                </div>

                <div className="row col-sm-8">
                  <div className="d-flex">
                    <div className="col-4 padding-top-4 padding-bottom-4">
                      <label className="grey font-size-13 line-height-13">Amount</label>
                      <p className="d-flex align-items-center font-size-14 line-height-14 ">
                        <span className="padding-right-0 normal-green">
                          <FormattedMoney value={item.unit_price} currency={item.unit_price_currency} />
                        </span>
                        <span>/unit</span>
                      </p>
                    </div>
                    <div className="col-4 padding-top-4 padding-bottom-4">
                      <label className="grey font-size-13 line-height-13">Qty</label>
                      <div className="font-size-14 line-height-14 text-break">{item.quantity}</div>
                    </div>
                    <div className="col-4 padding-top-4 padding-bottom-4">
                      <label className="grey font-size-13 line-height-13">Sum</label>
                      <p className="d-block font-size-14 line-height-14 normal-green">
                        <FormattedMoney value={item.price_sum} currency={item.unit_price_currency} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div className="row padding-top-20 padding-bottom-20">
        <div className="col-sm-7"></div>
        <div className="d-flex col-sm-5 d-flex align-items-center">
          <b className="font-size-16 max-width-100 margin-right-28 margin-right-sm-2 logo-magenta">TOTAL</b>
          <b className="font-size-18 normal-green">
            <FormattedMoney value={orderDetails.total_amount} currency={orderDetails.total_amount_currency} />
          </b>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(MerchandiseOrderDetails);
