import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Select from "react-select";

const SelectSavedBankAccount = ({
    user,
    handleSelectSavedBank,
    handleAddNewBank,
    handleClose
}) => {

    const [savedBanks, setSavedBanks] = useState([])
    const [selectedBank, setSelectedBank] = useState(null)

    useEffect(() => {
        if (user.saved_bank_accounts.length > 0) {

            let banks = user.saved_bank_accounts.map(savedBank => {
                return {
                    label: `${savedBank.bank_name} ${savedBank.bank_account_number} ${savedBank.bank_account_name}`,
                    value: savedBank
                }
            })
            setSavedBanks(banks)
        }
    }, [])

    return (
        <div>
            <Select
                className="form-control padding-0 margin-top-4 margin-bottom-10"
                classNamePrefix="react-select"
                placeholder="Select a Bank Account"
                options={savedBanks}
                onChange={(option) => {
                    setSelectedBank(option.value)
                }}
            />
            <div>
                <div>
                    <button
                        className="btn margin-top-6 margin-bottom-10 bg-logo-magenta stretch-full font-size-16 padding-12 border-radius-8"
                        type="button"
                        onClick={() => {
                            handleSelectSavedBank(selectedBank)
                            handleClose()
                        }}
                    ><b>Submit</b></button>
                </div>

                <div className="font-size-16 stretch-full text-center">Or</div>

                <div>
                    <button
                        className="btn margin-top-10 margin-bottom-10 bg-orange stretch-full font-size-16 padding-12 border-radius-8"
                        type="button"
                        onClick={() => {
                            handleAddNewBank()
                            handleClose()
                        }}
                    >
                        <b>Add New Bank</b>
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(SelectSavedBankAccount);