import axios from "axios";

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].toString().replace(/^([\s]*)|([\s]*)$/g, "")
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
var csrftoken = getCookie('csrftoken');

const axiosSignedIn = () => {
  return (dispatch, getState) => {
    const token = getState().auth.userToken;
    axios.defaults.headers.common.Authorization = `Token ${token}`;
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'
    axios.defaults.headers.post['X-CSRFToken'] = csrftoken;
    axios.defaults.withCredentials = true
    // alert(csrftoken)
    return axios;
  };
};

export const hijackUser = (email) => {
  return (dispatch, getState) => {

    return dispatch(axiosSignedIn())
      .post(`/hijack/email/${email}/`)
      .then((res) => {
        const hijackedUser = res.data
        //Set the new user
        localStorage.setItem("user", JSON.stringify(hijackedUser));

        localStorage.setItem("isNabbing", true);

        dispatch({
          type: 'STORE_USER',
          user: hijackedUser,
        })

        dispatch({
          type: 'STORE_HIJACK_STATUS',
          isNabbing: true,
        })

        return res.data;
      })
      .catch((error) => {
        return { errorMessage: error.response.data };
      });
  };
};

export const releaseHijackeduser = (email) => {
  return (dispatch, getState) => {

    return dispatch(axiosSignedIn())
      .post(`/hijack/release-hijack/`)
      .then((res) => {
        const hijackedUser = res.data
        localStorage.setItem("user", JSON.stringify(hijackedUser));

        localStorage.setItem("isNabbing", true);

        dispatch({
          type: 'STORE_USER',
          user: hijackedUser,
        })

        dispatch({
          type: 'STORE_HIJACK_STATUS',
          isNabbing: true,
        })

        return res.data;
      })
      .catch((error) => {
        return { errorMessage: error.response.data };
      });
  };
};
