import React, { useState, useEffect } from "react";

const ProgressBar = ({ status }) => {

    return (
        <div className="progress-bar">
            {status ? <span style={{ width: `${status}%`}}></span> : null}
        </div>
    );
};

export default ProgressBar;
