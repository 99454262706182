import React from "react";
import { connect } from "react-redux";
import { convertSnakeToStandardCasing } from "../../utilities";

const SetupFormHeader = ({ activeSlide, slides }) => {

  return (
    <div className="setup-modal-progress">

      {slides.map((slide, index) => {
        return (
          <div
            key={index}
            className={
              `${index == 0 ? 'first-page' : ``} ${activeSlide == slide ? 'active' : `${index < slides.indexOf(activeSlide) ? 'done' : 'not-active'}`}`
            }
          >
            <span>{index + 1} </span>
            <label className=" d-block-hidden-sm">{convertSnakeToStandardCasing(slide)}</label>
          </div>
        )
      })}
    </div>
  );
};

export default connect()(SetupFormHeader);
