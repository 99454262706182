import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";
import { connect } from "react-redux";
import moment from "moment";
import Flag from "react-world-flags";
import ShareOptions from "./ShareOptions";
import EnterDonation from "./EnterDonation";
import PdfViewer from "../../../../utilityComponents/PdfViewer";
import PdfThumbnail from "../../../../utilityComponents/PdfThumbnail";
import ImageViewer from "../../../../utilityComponents/ImageViewer";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";
import RenderHtmlTemplate from "../../../../utilityComponents/RenderHtmlTemplate";
import FundraiserSummary from "./FundraiserSummary";
import FundraiserCoverMedia from "./FundraiserCoverMedia";
import { publicGetDonationsList } from "../../../../../store/actions/fundraiser";
import personOutlineDarkblue from "../../../../images/person_outline_darkblue.svg";
import BuyMerchandise from "./BuyMerchandise";
import MerchandiseView from "../../setup/fragments/MerchandiseView";
import { convertCountryCodeToName, getDocumentFileformatFromUrl } from "../../../../../utilities";
import ngoDummyLogo from "../../../../images/ngo_dummy_logo.png"

const PreviewFundraiser = ({
  history,
  dispatch,
  isSetupPreview,
  fundraiser
}) => {
  const search = useLocation().search;
  const isMakingDonation = new URLSearchParams(search).get("isMakingDonation")

  const [isShareOptionsVisible, setIsShareOptionsVisible] = useState(false);
  const [isEnterDonationVisible, setIsEnterDonationVisible] = useState(false);
  const [isBuyMerchandiseVisible, setIsBuyMerchandiseVisible] = useState(false);
  const [isPdfViewerVisible, setIsPdfViewerVisible] = useState(false);
  const [isImageViewerVisible, setIsImageViewerVisible] = useState(false);
  const [pointerEvents, setPointerEvents] = useState("auto");
  const [donationsParams] = useState({
    fundraiser_slug: fundraiser.fundraiser_details.slug,
    no_of_items_per_page: 5,
    page: 1,
    order: "asc"
  });
  const [donationsWithCommentsOnlyParams] = useState({
    fundraiser_slug: fundraiser.fundraiser_details.slug,
    no_of_items_per_page: 5,
    page: 1,
    withCommentsOnly: "yes",
    order: "asc"
  });
  const [donationList, setDonationList] = useState([]);
  const [
    donationListWithCommentsOnly,
    setDonationListWithCommentsOnly,
  ] = useState([]);
  const [viewedDocument, setViewedDocument] = useState("");
  const [merchandiseCart, setMerchandiseCart] = useState([]);
  const [merchandiseList, setMerchandiseList] = useState([]);

  const handleGetPublicDonations = (params) => {
    dispatch(publicGetDonationsList(params))
      .then((res) => {
        if (res.error) {
        } else {
          if (res.success == true) {
            setDonationList(res.data);
          }
        }
      });
  };

  const handleGetPublicDonationsWithCommentsOnly = (params) => {
    dispatch(publicGetDonationsList(params))
      .then((res) => {
        if (res.error) {
        } else {
          if (res.success == true) {
            setDonationListWithCommentsOnly(res.data);
          }
        }
      });
  };

  const handleCloseMakeDonation = (slug) => {
    setIsEnterDonationVisible(false)
    history.push("/fundraiser/" + slug)
  }

  useEffect(() => {
    if (isSetupPreview) {
      setPointerEvents("none");
    }

  }, [isSetupPreview]);

  useEffect(() => {
    // Get donations list wih comments only
    handleGetPublicDonationsWithCommentsOnly(donationsWithCommentsOnlyParams);

    // Initialize donations list
    handleGetPublicDonations(donationsParams);

    if (isMakingDonation && JSON.parse(isMakingDonation) == true) {
      setIsEnterDonationVisible(true)
    }
  }, [])

  useEffect(() => {
    const listOfMerchandise = fundraiser.fundraiser_details.merchandise.map((item) => {
      return {
        ...item,
        ordered_units: 0
      }
    })
    setMerchandiseList([...listOfMerchandise])
  }, [isBuyMerchandiseVisible])

  return (
    <div style={{ pointerEvents }}>
      <div className="d-flex ">
        <div className="col-8 d-flex stretch-full-md bg-white">
          <div className="max-width-750 margin-right-6 margin-right-md-10">
            <div>
              <FundraiserCoverMedia fundraiser={fundraiser} />

              <div className="font-size-12 padding-bottom-1 padding-top-1 border-bottom-1 border-top-1 border-color-logo-magenta padding-top-10 padding-bottom-10 margin-top-10">
                <span>Created {moment(fundraiser.fundraiser_details.created_at).format(
                  "DD MMM. YYYY"
                )}</span> | <span>Category: {`${fundraiser.fundraiser_details.category_label}`}</span>
              </div>
            </div>

            <div className="margin-top-20 margin-right-6 margin-right-md-10 ">
              <h1 className="font-size-22 black font-size-md-28 line-height-24 line-height-md-28 padding-bottom-10">
                {fundraiser.fundraiser_details.title}
              </h1>
            </div>

            <div className="padding-top-4 padding-bottom-14">
              {
                fundraiser.fundraiser_details.is_story_verified &&
                <div>
                  <i className="fa fa-check font-size-12 white border-radius-12 padding-4 margin-right-5 line-height-12 bg-logo-magenta"></i>
                  <b className="dark-green bold">Verified story</b>
                </div>
              }
              <RenderHtmlTemplate html={fundraiser.fundraiser_details.story} />
            </div>

            <div className="d-block-display-md padding-bottom-14">
              <FundraiserSummary
                fundraiser={fundraiser}
                donationList={donationList}
                donationsParams={donationsParams}
                setIsShareOptionsVisible={setIsShareOptionsVisible}
                setIsEnterDonationVisible={setIsEnterDonationVisible}
                handleGetPublicDonations={handleGetPublicDonations}
              />
            </div>

            <div className="font-size-20 padding-bottom-1 border-bottom-1 logo-magenta border-color-logo-magenta">
              <b className="logo-magenta" >Organizer</b>
            </div>

            <div className="d-flex margin-top-16 margin-bottom-20 align-items-center justify-content-between">
              <div className="d-flex vertical-align">
                {fundraiser.fundraiser_details.charity_ngo.id ?
                  <div>
                    {fundraiser.fundraiser_details.charity_ngo.logo_file_url ? (
                      <img
                        className="fixed-width-60 fixed-height-60 rounded-circle"
                        src={`${fundraiser.fundraiser_details.charity_ngo.logo_file_url}?${Date.now()}`}
                      />
                    ) : (
                      <img
                        width="60"
                        height="60"
                        rounded-circle
                        src={ngoDummyLogo} />
                    )}
                  </div>
                  :
                  <div>
                    {fundraiser.organizer.profile_picture ? (
                      <img
                        className="fixed-width-60 fixed-height-60 rounded-circle"
                        src={`${fundraiser.organizer.profile_picture}?${Date.now()}`}
                      />
                    ) : (
                      <i className="fa fa-user-circle font-size-60 lighter-blue"></i>
                    )}
                  </div>
                }
                <div className="margin-left-8 margin-right-8">
                  {
                    fundraiser.fundraiser_details.charity_ngo.id ?
                      <div>
                        <div className="font-size-20 line-height-20 font-weight-bold black">
                          {fundraiser.fundraiser_details.charity_ngo.organization_name}
                          {fundraiser.fundraiser_details.charity_ngo.status == "APPROVED" &&
                            <i className="fa fa-check font-size-12 white border-radius-12 padding-4 margin-left-5 line-height-12 bg-logo-magenta"></i>}

                        </div>
                        <div className="font-size-12 line-height-16 grey padding-top-5 padding-bottom-5">
                          <b>Charity NGO</b>
                        </div>
                      </div>
                      :
                      <div>
                        <div className="font-size-20 line-height-20 font-weight-bold black">
                          {fundraiser.organizer.name}
                          {fundraiser.organizer.is_identity_verified &&
                            <i className="fa fa-check font-size-12 white border-radius-12 padding-4 margin-left-5 line-height-12 bg-logo-magenta"></i>}

                        </div>
                        <div className="font-size-12 line-height-16 grey padding-top-5 padding-bottom-5">
                          <b>Organizer</b> {!fundraiser.third_party_beneficiary.bank_account_number ? <b>(Beneficiary)</b> : null}
                        </div>
                      </div>
                  }

                  <div className="d-flex align-items-center padding-top-10 padding-bottom-10">
                    <Flag code={fundraiser.fundraiser_details.country} height="14" />
                    <i className="material-icons font-size-14 orange-red padding-right-5 padding-left-5">&#xe55f;</i>
                    <div>
                      {fundraiser.fundraiser_details.city}, {fundraiser.fundraiser_details.country ?
                        convertCountryCodeToName(fundraiser.fundraiser_details.country) :
                        null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {fundraiser.third_party_beneficiary.bank_account_number ?
              <div className="d-flex margin-top-16 margin-bottom-20 align-items-center justify-content-between">
                <div className="flex vertical-align">
                  <span><b>Beneficiary:</b> {fundraiser.third_party_beneficiary.full_name}, {" "}
                    {fundraiser.third_party_beneficiary.city ? fundraiser.third_party_beneficiary.city + ", " : null}
                    {fundraiser.third_party_beneficiary.country_code} </span>
                </div>
              </div>
              : null}

            {merchandiseList.length > 0 &&
              <div>
                <div className="ont-size-20 padding-bottom-1 border-bottom-1 logo-magenta border-color-logo-magenta">
                  <b className="logo-magenta" >Merchandise</b>
                </div>
                <div className="padding-top-14">
                  {merchandiseList &&
                    merchandiseList.map((merch, index) => {
                      return (
                        <div
                          key={index}
                          className="row box-shadow margin-bottom-20 border-radius-0 padding-top-6 padding-bottom-6 padding-left-10 padding-right-10"
                        >
                          <div className="col-md-10 padding-top-4 padding-bottom-4 padding-right-md-10">
                            <MerchandiseView item={merch} currency={merch.unit_price_currency} />
                          </div>

                          <div className="d-flex align-items-center col-md-2 padding-top-4 padding-bottom-4">
                            <button
                              className="btn bg-logo-magenta font-size-13 max-width-80 min-width-60"
                              type="button"
                              onClick={() => {
                                const emptyCart = []
                                setMerchandiseCart([])
                                emptyCart.push({
                                  ...merch,
                                  ordered_units: 1
                                })
                                setMerchandiseCart([...emptyCart])

                                setIsBuyMerchandiseVisible(true)
                              }}
                            >
                              Buy
                            </button>
                          </div>
                        </div>
                      )

                    })}
                </div>
              </div>
            }

            {fundraiser.documents.length ? (
              <div className="padding-bottom-18 padding-bottom-sm-24">
                <div className="font-size-20 padding-bottom-1 border-bottom-1 logo-magenta border-color-logo-magenta">
                  <b className="logo-magenta">Additional Documentation</b>
                </div>
                <div
                  style={{ maxHeight: "260px" }}
                  className="row overflow-scroll-y"
                >
                  {fundraiser.documents.map((document, index) => {
                    return (
                      <div
                        key={index}
                        className="min-width-120 max-width-120 margin-10 cursor-pointer bg-lighter-blue border-radius-0"
                        onClick={() => {
                          setViewedDocument(document);
                          if (getDocumentFileformatFromUrl(document.file_url) == "pdf") {
                            setIsPdfViewerVisible(true);
                          } else if (
                            getDocumentFileformatFromUrl(document.file_url) == "image"
                          ) {
                            setIsImageViewerVisible(true);
                          }
                        }}
                      >
                        <div className="fixed-height-80">
                          {getDocumentFileformatFromUrl(document.file_url) == "pdf" ? (
                            <PdfThumbnail height={70} document={document} />
                          ) : (
                            <img
                              width="100%"
                              height="100%"
                              className="d-block border-top-radius-0"
                              src={`${document.file_url}?${document.file_url}`}
                            />
                          )}
                        </div>

                        <div className="font-size-11 padding-4 padding-left-6 padding-right-6 text-truncate border-bottom-radius-0">
                          <b>{document.description}</b>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {fundraiser.recent_updates.length > 0 ? (
              <div className="padding-bottom-14 padding-top-14">
                <div className="font-size-20 padding-bottom-1 logo-magenta border-bottom-1 border-color-logo-magenta">
                  <b>Latest Updates</b>
                </div>
                {fundraiser.recent_updates.map((update, index) => {
                  return (
                    <div
                      key={index}
                      className="row padding-10 margin-top-16 border-radius-0 border-1 border-color-logo-magenta"
                    >
                      <p className="col-sm-10 font-size-14 line-height-16">
                        {update.text}
                      </p>
                      <div className="col-sm-2 grey padding-left-0 padding-top-8 padding-top-sm-0 padding-left-sm-6 font-size-12">
                        {/* <span className="col-sm-6">10 Oct 2020</span> */}
                        <br className="d-block-hidden-sm" />
                        <span className="col-sm-6 padding-left-10 padding-left-sm-0">
                          {moment(
                            new Date(update.created_at),
                            "MMMM Do YYYY, h:mm:ss a"
                          ).fromNow()}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}

            <div className="font-size-20 padding-bottom-1 logo-magenta border-bottom-1 border-color-logo-magenta">
              <b className="logo-magenta">
                Comments ({donationListWithCommentsOnly.total_count ? donationListWithCommentsOnly.total_count : "0"})
              </b>
            </div>

            <div className="margin-bottom-30">
              {donationListWithCommentsOnly.donations &&
                donationListWithCommentsOnly.donations.length > 0 ? (
                <div
                  style={{ maxHeight: "500px" }}
                  className="overflow-scroll-y">
                  {donationListWithCommentsOnly.donations.map(
                    (donation, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center padding-4 padding-top-8 padding-bottom-10 border-bottom-1 border-color-logo-magenta"
                        >
                          <div className="max-width-30 fixed-height-30 rounded-circle bg-lighter-blue">
                            <img width="100%" src={personOutlineDarkblue} />
                          </div>

                          <div className="flex-grow-1 padding-left-10 padding-right-10">
                            <div className="font-size-14">
                              {!donation.is_anonymous ? (
                                <div>
                                  {donation.first_name} {donation.last_name}
                                </div>
                              ) : (
                                <div>Anonymous Donor</div>
                              )}
                              <div>
                                <b>
                                  <FormattedMoney
                                    value={donation.donation}
                                    currency={donation.donation_currency}
                                  />
                                </b>
                              </div>
                            </div>
                            <div>
                              <p className="font-size-13 line-height-15">
                                {donation.comment}
                              </p>
                              <div className="font-size-12 grey">
                                {moment(
                                  new Date(donation.created_at),
                                  "MMMM Do YYYY, h:mm:ss a"
                                ).fromNow()}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}

                  {donationListWithCommentsOnly.total_count >
                    donationListWithCommentsOnly.no_of_items_per_page && (
                      <div
                        className="orange-red padding-top-10 cursor-pointer"
                        onClick={() => {
                          handleGetPublicDonationsWithCommentsOnly({
                            ...donationsWithCommentsOnlyParams,
                            noOfItemsPerPage:
                              donationListWithCommentsOnly.no_of_items_per_page +
                              20,
                          });
                        }}
                      >
                        <b>See more</b>
                      </div>
                    )}
                </div>
              ) : (
                <p>No comments yet</p>
              )}
            </div>
          </div>
        </div>

        <div className="col-4 d-block-hidden-md">
          <div className="d-flex justify-content-end">
            <div className="min-width-220 max-width-420 padding-10 padding-md-20 margin-left-6 margin-left-md-10 bg-white box-shadow border-radius-0">
              <FundraiserSummary
                fundraiser={fundraiser}
                donationList={donationList}
                donationsParams={donationsParams}
                setIsShareOptionsVisible={setIsShareOptionsVisible}
                setIsEnterDonationVisible={setIsEnterDonationVisible}
                handleGetPublicDonations={handleGetPublicDonations}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isShareOptionsVisible}
        onRequestClose={() => setIsShareOptionsVisible(false)}
        className="modal max-width-620 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Please share
          </h1>
          <i
            onClick={() => setIsShareOptionsVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <ShareOptions fundraiserDetails={fundraiser.fundraiser_details} />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isEnterDonationVisible}
        onRequestClose={() => {
          handleCloseMakeDonation(fundraiser.fundraiser_details.slug)
        }}
        className="modal max-width-620 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Enter Donation Amount
          </h1>
          <i
            onClick={() => {
              handleCloseMakeDonation(fundraiser.fundraiser_details.slug)
            }}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <EnterDonation
              fundraiser={fundraiser}
              handleClose={() => {
                handleCloseMakeDonation(fundraiser.fundraiser_details.slug)
              }}
              handleOnPaymentSuccess={() => {
                handleCloseMakeDonation(fundraiser.fundraiser_details.slug)
              }}
              history={history}
            />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isPdfViewerVisible}
        onRequestClose={() => setIsPdfViewerVisible(false)}
        // style={{maxHeight: window.innerHeight * 0.5}}
        className={`modal border-radius-0 max-width-800 padding-0 overflow-scroll-y`}
        overlayClassName="modal-backdrop"
      >
        <div>
          <PdfViewer
            document={viewedDocument}
            handleClose={() => setIsPdfViewerVisible(false)}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isImageViewerVisible}
        onRequestClose={() => setIsImageViewerVisible(false)}
        // style={{ height: window.innerHeight }}
        className="overflow-scroll-y modal max-width-1000 border-radius-0 padding-0 bg-black"
        overlayClassName="modal-backdrop"
      >
        <div className="fixed-height-600">
          <ImageViewer
            maxHeightImage={600}
            document={viewedDocument}
            handleClose={() => setIsImageViewerVisible(false)}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isBuyMerchandiseVisible}
        onRequestClose={() => setIsBuyMerchandiseVisible(false)}
        className="modal max-width-950 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Buy Merchandise
          </h1>
          <i
            onClick={() => setIsBuyMerchandiseVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "80vh" }}
            className="overflow-scroll-y padding-6 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <BuyMerchandise
              merchandiseList={merchandiseList}
              merchandiseCart={merchandiseCart}
              setMerchandiseCart={setMerchandiseCart}
              fundraiser={fundraiser}
              handleClose={() => setIsBuyMerchandiseVisible(false)}
              handleOnPaymentSuccess={() => {
                // history.go() 
                history.push("/fundraiser/" + fundraiser.fundraiser_details.slug)
              }}
            />
          </div>
        </div>
      </Modal>

    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(PreviewFundraiser);
