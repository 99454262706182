import React from "react";
import Truncate from "react-truncate";
import Flag from 'react-world-flags'
import DOMPurify from "dompurify";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import FormattedMoney from "../../../utilityComponents/FormattedMoney";
import CoverMediaThumbnail from "./components/CoverMediaThumbnail";

const FundraiserFrame = ({
  coverMediaHeight,
  fundraiser
}) => {

  return (
    <div className="d-flex flex-column justify-content-between stretch-full box-shadow border-radius-0 cursor-pointer">
      <div>
        <div
          className="bg-lighter-grey border-top-radius-0"
          style={{ height: coverMediaHeight + "px" }}
        >
          <CoverMediaThumbnail
            coverMedia={fundraiser.cover_media}
            fundraiserDetails={fundraiser}
          />
        </div>
        <p className="d-flex align-items-center padding-top-8 padding-left-4 padding-right-4 padding-left-md-10 padding-right-md-10 padding-bottom-10">
          <div className="col-6 d-flex justify-content-start">
            <i className="material-icons font-size-18 orange-red padding-right-10">
              &#xe55f;
            </i>
            <b className="line-height-16 font-size-md-16 line-height-md-16">
              <Flag code={fundraiser.country} height="16" />
            </b>
            {
              fundraiser.is_story_verified &&
              <i className="fa fa-check font-size-10 white border-radius-10 padding-4 margin-bottom-2 margin-left-6 line-height-10 bg-logo-magenta"></i>
            }   </div>
          <div className="col-6 d-flex justify-content-end">
            <span className="logo-magenta padding-left-5 padding-right-5">
              {fundraiser.no_of_donations} donations
            </span>
          </div>
        </p>

        <div className="padding-left-4 padding-left-md-12 padding-right-4 padding-right-md-12 padding-top-6 padding-top-md-6 padding-bottom-6 padding-bottom-md-10">
          <h3 className="font-size-15 font-size-sm-17 font-size-md-18 black">
            <Truncate lines={1}>{fundraiser.title}</Truncate>
          </h3>
        </div>
        <div className="relative">
          <div
            className={`overflow-hidden fixed-height-50 padding-left-4 padding-left-md-12 padding-right-4 padding-right-md-12`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(fundraiser.story)
            }}>
          </div>
        </div>
      </div>

      <div className="padding-left-4 padding-left-md-12 padding-right-4 padding-right-md-12 padding-bottom-10 padding-bottom-sm-14 padding-bottom-md-14">
        {/* <ProgressBar status={fundraiser.percent_raised} /> */}

        <div className="d-flex padding-bottom-6 padding-top-10 d-flex font-size-14 font-size-sm-17 padding-top-2">
          <div className="col-10" >
            <b className="d-flex align-items-center black">
              <FormattedMoney
                value={fundraiser.amount_raised}
                currency={fundraiser.goal_amount_currency} />
              {/* <span className="padding-left-6 padding-right-6">
            of
          </span> */}
            </b>
            {/* <div className="stretch-full">
          <Truncate lines={1}>
            <FormattedMoney
              value={fundraiser.goal_amount}
              currency={fundraiser.goal_amount_currency} />
          </Truncate>
        </div> */}

          </div>

          <div className="col-2 max-width-30">
            <CircularProgressbar
              value={fundraiser.percent_raised} text={`${fundraiser.percent_raised < 1 ? Math.ceil(fundraiser.percent_raised) : Math.floor(fundraiser.percent_raised)}%`}
              strokeWidth="12"
              styles={{
                text: {
                  // Text color
                  fill: '#ad5790',
                  // Text size
                  fontSize: '26px',
                  fontWeight: 'bold'
                },
                trail: {
                  // Trail color
                  stroke: '#d6d6d6',
                },
                path: {
                  stroke: `#ad5790`,
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundraiserFrame
