import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import Modal from "react-modal";
import VerifyBankAccount from "../../../payments/VerifyBankAccount";
import PreviewVerifiedAccount from "../../../payments/PreviewVerifiedAccount";
import { deleteCharityNGOBankAccount, linkBankAccountToNGO, saveNGOBankAccount, unlinkBankAccountFromNGO } from "../../../../store/actions/charity_ngo";
import SelectSavedBankAccount from "../../../payments/SelectSavedBankAccount";
import { getConfirmAlert } from "../../../../utilities";
import { getUser } from "../../../../store/actions/auth";

const CharityNGOBankAccounts = ({
  dispatch,
  setIsPageLoading,
  charityNgo,
  setCharityNgo,
  setVisibleCharityNgoSetupSlides
}) => {
  const [
    isVerifyAccountModalVisible,
    setIsVerifyAccountModalVisible,
  ] = useState(false);
  const [
    isPreviewVerifiedAccountVisible,
    setIsPreviewVerifiedAccountVisible,
  ] = useState(false);
  const [isSelectSavedBankVisible, setIsSelectSavedBankVisible] = useState(false);
  const [verifiedAccountDetails, setVerifiedAccountDetails] = useState(null);
  const [error, setError] = useState(null);

  return (
    <div className="padding-left-10 padding-right-10 border-radius-0">
      <div className="padding-bottom-8">
        <h2 className="logo-magenta font-size-20 font-size-sm-21">BANK ACCOUNTS</h2>
      </div>
      <div className="horizontal-line " />

      <div className="padding-left-0  padding-top-10 padding-bottom-10">
        {charityNgo &&
          charityNgo.bank_accounts &&
          charityNgo.bank_accounts.length > 0 ?
          charityNgo.bank_accounts.map((b, index) => {
            return (
              <div key={index}
                className="box-shadow border-radius-8 margin-top-10 margin-bottom-10 padding-4 padding-left-12 
            padding-right-12 font-size-13 padding-bottom-10">

                <div className="row">
                  <div className="col-sm-8">
                    <div className="d-flex align-items padding-top-12 padding-bottom-12">
                      <b className="col-5">Account Name</b>
                      <span className="col-7">{b.bank_account_name}</span>
                    </div>

                    <div className="d-flex align-items padding-top-12 padding-bottom-12">
                      <b className="col-5">Account No</b>
                      <span className="col-7">{b.bank_account_number}</span>
                    </div>

                    <div className="d-flex align-items padding-top-12 padding-bottom-12">
                      <b className="col-5">Bank</b>
                      <span className="col-7">{b.bank_name}</span>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <button
                      className="btn white bg-orange border-radius-5 margin-10"
                      type="button"
                      onClick={() => {
                        getConfirmAlert({
                          title: "Confirm!",
                          message: "Are you sure you want to unlink this bank account from this NGO? The account details will remain saved in the system.",
                          onClickYes: () => {
                            dispatch(unlinkBankAccountFromNGO({
                              charity_ngo_id: charityNgo.id,
                              bank_account_id: b.id
                            })).then((res) => {
                              if (res.error) {
                                NotificationManager.error(res.error.message, "Error!")
                              } else {
                                setCharityNgo(res.data)
                                NotificationManager.success(res.message, "Success!")
                              }
                            });
                          },
                          onClickNo: () => null
                        })
                      }}>
                      Unlink</button>

                    <button
                      className="btn white bg-red border-radius-5 margin-10"
                      type="button"
                      onClick={() => {
                        getConfirmAlert({
                          title: "Confirm!",
                          message: "Are you sure you want to completely delete this bank account from the system? You can just unlink it from this NGO instead.",
                          onClickYes: () => {
                            dispatch(
                              deleteCharityNGOBankAccount({
                                charity_ngo_id: charityNgo.id,
                                bank_account_id: b.id
                              })).then((res) => {
                                if (res.error) {
                                  NotificationManager.error(res.error.message, "Error!")
                                } else {
                                  dispatch(getUser()) //To update the latest saved banks

                                  setCharityNgo(res.data)
                                  NotificationManager.success(res.message, "Success!")
                                }
                              });
                          },
                          onClickNo: () => null
                        })
                      }} >Delete</button>
                  </div>
                </div>
              </div>
            )
          })
          :
          <center>
            <p className="padding-top-10 padding-bottom-10 grey">You have no bank accounts linked </p>
            <div className="error-message">{error && error} </div>
          </center>
        }
      </div>

      <button
        className="btn white bg-logo-magenta border-radius-5 margin-10 fixed-width-200"
        type="button"
        onClick={() => setIsSelectSavedBankVisible(true)} >Link a Saved Bank Account</button>
      <button
        className="btn white bg-blue border-radius-5 margin-10 fixed-width-200"
        type="button"
        onClick={() => setIsVerifyAccountModalVisible(true)} >Save New Bank Account</button>

      <div className="horizontal-line margin-top-20 margin-bottom-30" />

      <div className="d-flex justify-content-end margin-bottom-30">
        <button
          className="btn font-size-15 font-size-md-18 margin-right-30 margin-right-sm-50 max-width-160 padding-10 padding-md-16 border-color-logo-magenta logo-magenta border-radius-12"
          onClick={() => {
            setVisibleCharityNgoSetupSlides("DOCUMENTS")
          }}
        >
          <b>Previous</b>
        </button>
        <button
          className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-logo-magenta white border-radius-12"
          type="submit"
          onClick={() => {
            if (charityNgo.bank_accounts.length > 0) {
              setVisibleCharityNgoSetupSlides("FINISH")
            } else {
              setError("Bank account is required")
            }
          }}
        >
          <b>Next</b>
        </button>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isVerifyAccountModalVisible}
        onRequestClose={() => setIsVerifyAccountModalVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Enter Bank Account Details
          </h1>
          <i
            onClick={() => setIsVerifyAccountModalVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-10 padding-top-0 padding-left-md-40 padding-right-md-40">
          <VerifyBankAccount
            setIsPageLoading={setIsPageLoading}
            handleOnAccountVerification={(accountDetails) => {
              setVerifiedAccountDetails(accountDetails);
              setIsVerifyAccountModalVisible(false);
              setIsPreviewVerifiedAccountVisible(true);
            }}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isPreviewVerifiedAccountVisible}
        onRequestClose={() => setIsPreviewVerifiedAccountVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Verified Account Details
          </h1>
          <i
            onClick={() => setIsPreviewVerifiedAccountVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-10 padding-top-0 padding-left-md-40 padding-right-md-40">
          <PreviewVerifiedAccount
            setIsPageLoading={setIsPageLoading}
            verifiedAccountDetails={verifiedAccountDetails}
            handleSaveAccount={(accountDetails) => {
              setError("")
              dispatch(saveNGOBankAccount({
                ...accountDetails,
                charity_ngo_id: charityNgo.id
              })).then((res) => {
                if (res.error) {
                  NotificationManager.error(res.error.message, "Error!")
                } else {
                  dispatch(getUser()) //To update the latest saved banks

                  setCharityNgo(res.data)
                  NotificationManager.success(res.message, "Success!")
                  setIsPreviewVerifiedAccountVisible(false);
                }
              });
            }}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isSelectSavedBankVisible}
        onRequestClose={() => {
          setIsSelectSavedBankVisible(false);
        }}
        className="modal max-width-550 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-18 font-size-sm-21 logo-magenta">
            Select a Saved Bank Account
          </h1>
          <i
            onClick={() => setIsSelectSavedBankVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <SelectSavedBankAccount
              handleSelectSavedBank={(bankDetails) => {
                setError("")
                dispatch(linkBankAccountToNGO({
                  charity_ngo_id: charityNgo.id,
                  bank_account_id: bankDetails.id
                })).then((res) => {
                  if (res.error) {
                    NotificationManager.error(res.error.message, "Error!")
                  } else {
                    setCharityNgo(res.data)
                    NotificationManager.success(res.message, "Success!")
                    setIsSelectSavedBankVisible(false);
                  }
                });
              }}
              handleAddNewBank={() => setIsVerifyAccountModalVisible(true)}
              handleClose={() => setIsSelectSavedBankVisible(false)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(CharityNGOBankAccounts);
