import React, { useState, useEffect } from "react";
import DOMPurify from 'dompurify';
import CurrentWindowWidth from "../CurrentWindowWidth";

const RenderHtmlTemplate = ({
  html
}) => {
  const [initialStoryMarkupHeight, setInitialStoryMarkupHeight] = useState(0);
  const [visibleStoryMarkupHeight, setVisibleStoryMarkupHeight] = useState(null);
  const [actualStoryMarkupHeight, setActualStoryMarkupHeight] = useState(null);
  const [isShowMoreStoryVisible, setIsShowMoreStoryVisible] = useState(true);

  let windowWidth = CurrentWindowWidth();

  const getStoryMarkupHeight = () => {
    const el = document.getElementById('storyMarkup');
    const divHeight = el.offsetHeight
    return divHeight
  }

  useEffect(() => {
    const actionDivHeight = getStoryMarkupHeight()
    setActualStoryMarkupHeight(actionDivHeight)

    // alert(actionDivHeight)

    if(actionDivHeight <= 40){
      setVisibleStoryMarkupHeight(40)
      setInitialStoryMarkupHeight(40)
    } else if(actionDivHeight > 40 && actionDivHeight < 100){
      setVisibleStoryMarkupHeight(actionDivHeight)
      setInitialStoryMarkupHeight(actionDivHeight)
    } else {
      setVisibleStoryMarkupHeight(100)
      setInitialStoryMarkupHeight(100)
    }

    
    setIsShowMoreStoryVisible(true)
  }, [windowWidth])

  return (
    <div>
      <div
        className={`overflow-hidden fixed-height-${visibleStoryMarkupHeight}`}
      >
        <div id="storyMarkup"
          // lines={4}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(html)
          }}
        ></div>
      </div>
      {actualStoryMarkupHeight > initialStoryMarkupHeight &&
        <div className="cursor-pointer margin-top-6">
          {isShowMoreStoryVisible ?
            <span
              className="bg-grey border-radius-4 padding-6"
              onClick={() => {
                setVisibleStoryMarkupHeight(actualStoryMarkupHeight)
                setIsShowMoreStoryVisible(false)
              }} >
              Show More
            </span>
            :
            <span
              className="bg-grey border-radius-4 padding-6"
              onClick={() => {
                setVisibleStoryMarkupHeight(initialStoryMarkupHeight)
                setIsShowMoreStoryVisible(true)
              }} >
              Show Less
            </span>}
        </div>
      }

    </div>
  );
};

export default RenderHtmlTemplate
