import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { CircularProgressbar } from "react-circular-progressbar";
import ProgressBar from "../../../../utilityComponents/ProgressBar";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";
import personOutlineDarkblue from "../../../../images/person_outline_darkblue.svg";
import giveIconWhite from "../../../../images/give_icon_white.svg";
import ShareIcons from "./ShareIcons";
import axiosInstance from "../../../../../helpers/axiosInstance";

const FundraiserSummary = ({
  donationList,
  fundraiser,
  donationsParams,
  // setIsShareOptionsVisible,
  setIsEnterDonationVisible,
  handleGetPublicDonations,
}) => {
  const [url] = useState(`${axiosInstance.defaults.baseURL}/fundraiser/${fundraiser.fundraiser_details.slug}`);
  const [isUrlCopiedVisible, setIsUrlCopiedVisible] = useState(false);

  return (
    <div>
      <div>
        {fundraiser.fundraiser_details.is_amount_raised_visible &&
          <div className="d-flex padding-bottom-20">
            <div className="col-8">
              <b className="font-size-26">
                <FormattedMoney
                  value={fundraiser.fundraiser_details.amount_raised}
                  currency={fundraiser.fundraiser_details.goal_amount_currency}
                />
              </b>
              <div>RAISED</div>
            </div>
            <div className="col-4">
              <b className="font-size-26">
                {fundraiser.fundraiser_details.no_of_donations}
              </b>
              <div>DONATION{fundraiser.fundraiser_details.no_of_donations > 1 && "S"}</div>
            </div>
          </div>
        }

        <div>
          
        <div
          className="d-flex cursor-pointer align-items-center justify-content-center padding-8 padding-top-10 padding-bottom-10 padding-left-16 padding-left-md-30 padding-right-16 padding-md-right-30 bg-logo-magenta margin-bottom-20"
          onClick={() => {
            setIsEnterDonationVisible(true);
          }}
        >
          <img width="18" src={giveIconWhite} />
          <b className="col-11 font-size-15 font-size-md-16 text-center white">
            DONATE NOW
          </b>
        </div>
        
          {fundraiser.fundraiser_details.goal_amount &&
            <div className="d-flex font-size-16">
              <div className="d-flex col-10">
                <span className="padding-right-10" >Goal: </span> <FormattedMoney
                  // value={fundraiser.fundraiser_details.amount_raised}
                  value={fundraiser.fundraiser_details.goal_amount}
                  currency={fundraiser.fundraiser_details.goal_amount_currency}
                />
              </div>
              <div className="col-2 max-width-30">
                <CircularProgressbar
                  value={fundraiser.fundraiser_details.percent_raised}
                  text={`${fundraiser.fundraiser_details.percent_raised < 1 ?
                    Math.ceil(fundraiser.fundraiser_details.percent_raised) :
                    Math.floor(fundraiser.fundraiser_details.percent_raised)}%`}
                  strokeWidth="12"
                  styles={{
                    text: {
                      // Text color
                      fill: '#ad5790',
                      // Text size
                      fontSize: '30px',
                      fontWeight: 'bold'
                    },
                    trail: {
                      // Trail color
                      stroke: '#d6d6d6',
                    },
                    path: {
                      stroke: `#ad5790`,
                    },
                  }}
                />
              </div>
            </div>
          }
          <ProgressBar status={fundraiser.fundraiser_details.percent_raised} />
          <div className="d-flex">
            {fundraiser.fundraiser_details.end_date ?
              `${new Date(fundraiser.fundraiser_details.end_date) > new Date() ? 'Ends' : 'Ended'} 
              ${moment(new Date(fundraiser.fundraiser_details.end_date), 'MMMM Do YYYY, h:mm:ss a').fromNow()}` : null}
          </div>
        </div>

        <div className="relative padding-top-20 padding-bottom-10 overflow-hidden overflow-scroll-x ">
          <ShareIcons
            fundraiserDetails={fundraiser.fundraiser_details}
            url={url}
            setIsUrlCopiedVisible={setIsUrlCopiedVisible} />
          {isUrlCopiedVisible && <div className="absolute top-70-pct padding-left-2 font-size-11">Link copied</div>}
        </div>
      </div>

      {/* <div
        className="d-flex cursor-pointer align-items-center justify-content-center padding-8 padding-left-16 padding-left-md-30 padding-right-16 padding-md-right-30 border-1 border-fundraiser-color border-radius-10"
        onClick={() => setIsShareOptionsVisible(true)}
      >
        <div className="col-1 padding-top-4 text-center logo-magenta">
          <Share
            style={{
              fontSize: "18px",
            }}
          />
        </div>
        <b className="col-11 font-size-15 font-size-md-16 text-center fundraiser-color">
          Share
        </b>
      </div> */}
      {/* <div>
        <p className="font-size-13 grey padding-top-12 padding-bottom-4">
          Created on&nbsp;
          {moment(fundraiser.fundraiser_details.created_at).format(
            "DD MMM YYYY"
          )}
          <p className="font-size-13 logo-magenta">
            &nbsp;
            {fundraiser.fundraiser_details.end_date ?
              `- ${new Date(fundraiser.fundraiser_details.end_date) > new Date() ? 'Ends' : 'Ended'} 
              ${moment(new Date(fundraiser.fundraiser_details.end_date), 'MMMM Do YYYY, h:mm:ss a').fromNow()}` : null}
          </p>
        </p>
      </div>
      <div className="d-flex align-items-center">
        <p className="font-size-15 logo-magenta">{fundraiser.fundraiser_details.is_public ? "Public Fundraiser" : "Private Fundraiser"}</p> &nbsp;
        <p className="font-size-15 logo-magenta">{`| ${fundraiser.fundraiser_details.category_label}`}</p>
      </div> */}
      <div>
        {fundraiser.fundraiser_details.is_amount_raised_visible ?
          <div>
            <p className="font-size-20 logo-magenta padding-bottom-4 margin-top-10">
              <b> Recent Donations</b>
            </p>
            {donationList.donations && donationList.donations.length > 0 ? (
              <div style={{ maxHeight: "250px" }}
                className="overflow-scroll-y font-size-13 color-grey">
                {donationList.donations.map((donor, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex align-items-center padding-4 border-top-1 border-color-logo-magenta"
                    >
                      <div className="max-width-30 fixed-height-30 rounded-circle bg-lighter-blue">
                        <img width="100%" src={personOutlineDarkblue} />
                      </div>
                      <div className="flex-grow-1 padding-left-10 padding-right-10">
                        {donor.is_anonymous ? (
                          <div>Anonymous Donor</div>
                        ) : (
                          <div>{donor.first_name + " " + donor.last_name}</div>
                        )}
                        <div>
                          <b>
                            <FormattedMoney value={donor.donation} currency={donor.donation_currency} />
                          </b>
                        </div>
                      </div>
                      <div className="font-size-12 grey">
                        {moment(
                          new Date(donor.created_at),
                          "MMMM Do YYYY, h:mm:ss a"
                        ).fromNow()}
                      </div>
                    </div>
                  );
                })}
                {donationList.total_count > donationList.no_of_items_per_page ? (
                  <div
                    className="orange-red padding-top-8 cursor-pointer"
                    onClick={() => {
                      handleGetPublicDonations({
                        ...donationsParams,
                        no_of_items_per_page: donationList.no_of_items_per_page + 20,
                      });
                    }}
                  >
                    <b>See more</b>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="font-size-13 grey">
                <span>No donations yet. Be the first to help</span>
              </div>
            )}</div> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(FundraiserSummary);
