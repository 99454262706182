import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import moment from "moment";
import DOMPurify from 'dompurify';
import Select from "react-select";
import Truncate from "react-truncate";
import { getPublishedPosts } from "../../store/actions/blog";
import fbImg from "../images/fb_img.png";
import twitterImg from "../images/twitter_img.png";
import inImg from "../images/in_img.png";
import NewPagination from "../utilityComponents/NewPagination";
import { convertSnakeToStandardCasing } from "../../utilities";
import BlogFooter from "./BlogFooter";
import CurrentWindowWidth from "../CurrentWindowWidth";

const BlogPosts = ({
  dispatch,
  history
}) => {
  let windowWidth = CurrentWindowWidth();

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [publishedPosts, setPublishedPosts] = useState([]);
  const [pinnedPost, setPinnedPost] = useState({});
  const [totalPostCount, setTotalPostCount] = useState(0);
  const [page, setPage] = useState(1);
  const [error, setError] = useState("");
  const [coverImgHeight, setCoverImgHeight] = useState(0);
  const [publishedPostsImgHeight, setPublishedPostsImgHeight] = useState(0);
  const [queryParams, setQueryParams] = useState({
    no_of_items_per_page: 5,
    page: 1,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0,
    category: ""
  });

  const handleGetArticles = (data) => {
    dispatch(getPublishedPosts(data))
      .then(res => {
        if (res.error) {
          setError(res.error.message)
        } else {
          if (res.success == true) {
            setPinnedPost(res.data.pinned_post)
            setPublishedPosts(res.data.posts)
            setTotalPostCount(res.data.total_count)
            setPage(res.data.page)
            setCategoryOptions(res.data.category_options)
          }
        }
      })
  }

  const coverImgRef = useCallback(
    (node) => {
      if (node !== null) {
        setCoverImgHeight(node.getBoundingClientRect().width * 0.65);
      }
    },
    [windowWidth]
  );

  const publishedPostsImgRef = useCallback(
    (node) => {
      if (node !== null) {
        setPublishedPostsImgHeight(node.getBoundingClientRect().width * 0.65);
      }
    },
    [windowWidth]
  );

  useEffect(() => {
    handleGetArticles(queryParams)
    document.title = "Blog Page"
  }, [])


  return (
    <div className="content-container">
      {error ?
        <p className="error-message">{error}</p>
        :
        <div>
          <h1 className="font-size-30 font-size-sm-30 line-height-30 line-height-sm-30 logo-magenta">PouchFunding Blog</h1>

          {pinnedPost.image_url &&
            <div
              onClick={() => history.push(`/blog-post/${pinnedPost.slug}`)}
              className="row bg-lighter-grey border-radius-0 margin-top-30 cursor-pointer">
              <div className="d-flex justify-content-center col-md-5">
                <div
                  ref={coverImgRef}
                  className="border-radius-0 d-block max-width-600"
                  style={{
                    backgroundImage: `url(${pinnedPost.image_url})`,
                    width: "100%",
                    height: coverImgHeight + "px",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                  }}>
                </div>

                {/* <img
            ref={coverImgRef}
            src={pinnedPost.image_url}
            className="border-radius-10 d-block max-width-600"
            style={{ height: coverImgHeight + "px" }}
          /> */}
              </div>
              <div className="col-md-7 padding-top-30 padding-bottom-30 padding-left-10 padding-left-md-30 padding-left-lg-60 padding-right-10 padding-right-md-30 padding-right-lg-60">
                <span className="red">
                  {convertSnakeToStandardCasing(pinnedPost.category)}
                </span>
                <h2 className="padding-top-10 font-size-28 line-height-35 logo-magenta">
                  {pinnedPost.title}
                </h2>
                <p className="padding-top-20 padding-bottom-20">
                  <Truncate lines={5}>
                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(pinnedPost.content) }} />
                  </Truncate>
                </p>
                <span className="grey font-size-13">
                  {moment(pinnedPost.created_at).format("MMMM DD, YYYY")}
                </span>
              </div>
            </div>
          }

          <div className="row margin-top-60 margin-bottom-30">
            <b className="col-lg-3 font-size-28 font-size-sm-30 logo-magenta padding-top-10 padding-bottom-10">
              Latest Articles
            </b>

            <div className="d-flex col-4 stretch-full-lg align-items-center padding-top-10 padding-bottom-10">
              <label className="grey font-size-18 padding-right-14">Categories&nbsp; </label>
              <Select
                className="form-control padding-0 max-width-240 margin-right-20"
                classNamePrefix="react-select"
                placeholder="Select a category"
                options={categoryOptions}
                defaultValue={{ label: "All", value: "ALL" }}
                onChange={(option) => {
                  handleGetArticles({
                    ...queryParams,
                    category: option.value
                  })
                }}
              />
            </div>

            <div className="row col-sm-5 align-items-center justify-content-end padding-top-10 padding-bottom-10">
              <div className="margin-right-20 border-top-2 border-top-color-grey stretch-full" />
              <img src={inImg} className="fixed-width-50" />
              <img src={twitterImg} className="fixed-width-50 margin-left-20 margin-right-20" />
              <img src={fbImg} className="fixed-width-50" />
            </div>
          </div>

          <div
            className="d-flex flex-wrap">
            {publishedPosts.length > 0 ?
              publishedPosts.map((post, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => history.push(`/blog-post/${post.slug}`)}
                    className="margin-0 margin-sm-20 margin-bottom-10 box-shadow max-width-380 border-radius-0 cursor-pointer">

                    <div
                      ref={publishedPostsImgRef}
                      className="max-width-380 border-top-radius-0"
                      style={{
                        backgroundImage: `url(${post.image_url})`,
                        width: "100%",
                        height: publishedPostsImgHeight + "px",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                      }}>
                    </div>

                    {/* <img
                  ref={publishedPostsImgRef}
                  src={post.image_url}
                  className="max-width-380 border-top-radius-10"
                  style={{ height: publishedPostsImgHeight + "px" }}
                /> */}

                    <div className="padding-10 padding-left-20 padding-right-20">
                      <span className="red">
                        {convertSnakeToStandardCasing(post.category)}
                      </span>
                      <h2 className="padding-top-10 font-size-18 line-height-24 logo-magenta">
                        {post.title}
                      </h2>

                      <p className="padding-top-20 padding-bottom-30">
                        <Truncate lines={5}>
                          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }} />
                        </Truncate>
                      </p>

                      <p className="grey font-size-13 padding-bottom-10">
                        {moment(post.created_at).format("MMMM DD, YYYY")}
                      </p>
                    </div>
                  </div>
                )
              })
              :
              <div>
                There are no blog articles that match this query
              </div>}
          </div>

          <div className="padding-top-50 padding-bottom-40">
            <NewPagination
              {...queryParams}
              handleGetQuery={handleGetArticles}
            // key={totalPostCount}
            // activePage={page}
            // itemsPerPage={queryParams.no_of_items_per_page}
            // totalCount={totalPostCount}
            // handleGetQuery={(data) => handleGetArticles(data)}
            />
          </div>

          <BlogFooter history={history} />
        </div>
      }
    </div>
  );
}

export default connect()(BlogPosts);