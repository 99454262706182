import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import {
  generateTransactionRef
} from "../../../../../store/actions/transaction";
import {
  createMerchandiseOrder
} from "../../../../../store/actions/fundraiser";
import { getConfirmAlert, getCurrencySymbol, isEnoughMoneyInWallet, numberWithCommas, refreshPage } from "../../../../../utilities";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";
import MerchandiseView from "../../setup/fragments/MerchandiseView";
import PhoneInput from "react-phone-number-input";
import { NotificationManager } from "react-notifications";

const BuyMerchandise = ({
  isAuthenticated,
  dispatch,
  user,
  handleClose,
  transaction_ref,
  fundraiser,
  handleOnPaymentSuccess,
  merchandiseList
}) => {
  const [resetConfigKey, setResetConfigKey] = useState(0);
  const [paymentConfig, setPaymentConfig] = useState({
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    payment_options: "all",
    customizations: {
      // title: 'my Payment Title',
      // description: 'Payment for items in cart',
      logo: process.env.REACT_APP_POUCH_FUNDING_FAVICON_URL,
    },
  });
  const [currency] = useState(fundraiser.fundraiser_details.goal_amount_currency);

  const handleFlutterPayment = useFlutterwave(paymentConfig, [resetConfigKey]);

  const options = [
    {
      label: "Pay With Bank",
      value: "bank",
    },
    {
      label: "Pay With Pouch Wallet",
      value: "wallet",
    },
  ];

  const handleUpdatePaymentConfig = (values) => {
    setPaymentConfig({
      ...paymentConfig,
      tx_ref: transaction_ref,
      amount: values.total_amount,
      currency: currency,
      customer: {
        email: values.email,
        phonenumber: values.phone_number,
        name: `${values.first_name} ${values.last_name}`,
      },
      meta: {
        service_id: fundraiser.fundraiser_details.id,
        service_type: "fundraiser",
        sub_service_type: "merchandise",
        user_id: user.id ? user.id : "",
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        merchandise_order: JSON.stringify(values)
      }
    });

    // Reset the config key to reload UseFlutterwave
    setResetConfigKey(() => {
      const k = resetConfigKey;
      return k + 1;
    });
  };

  const handleCreateMerchandiseOrder = (values) => {

    if (values.payment_source == "bank") {
      handleFlutterPayment({
        callback: (res) => {
          closePaymentModal(); // this will close the modal programmatically

          dispatch(
            createMerchandiseOrder({
              ...values,
              fundraiser_id: fundraiser.fundraiser_details.id,
              transaction_id: res.transaction_id
            })
          ).then((res) => {
            if (res.error) {
              NotificationManager.error(res.error.message, "Error")
            } else {
              if (res.success == true) {
                NotificationManager.success(res.message, "Success!")
                handleOnPaymentSuccess()
                handleClose();
                // Refresh the page after 4000 milliseconds to avoid the flutterwave bug of recurring response
                refreshPage(4000)
              }
            }
          });

          dispatch(generateTransactionRef());
        },
        onClose: () => {
          dispatch(generateTransactionRef());
        },
      });
    } else {
      if (isEnoughMoneyInWallet(values.total_amount, currency)) {
        getConfirmAlert({
          title: "Confirm!",
          message: `Your Pouch Wallet will be charged ${getCurrencySymbol(currency)}${numberWithCommas(values.total_amount)}. 
                  Do you want to proceed?`,
          onClickYes: () => {
            dispatch(
              createMerchandiseOrder({
                ...values,
                fundraiser_id: fundraiser.fundraiser_details.id
              })
            ).then((res) => {
              if (res.error) {
                NotificationManager.error(res.error.message, "Error!")
              } else {
                if (res.success == true) {
                  NotificationManager.success(res.message, "Success!")
                  handleOnPaymentSuccess()
                  handleClose();
                }
              }
            });
          },
          onClickNo: () => null,
        });
      } else {
        NotificationManager.error(
          `You do not have enough money in you ${values.currency} wallet. Please top up first.`,
          "Notification")
      }
    }
  };

  const getNewMerchandiseList = (merchandiseList, index, newValue) => {
    let newMerchandiseList = merchandiseList.map((item, i) => {
      if (index == i) {
        item.ordered_units = newValue
      }
      return item
    })
    return newMerchandiseList
  }

  const getCalculatedTotalAmount = (merchandiseList) => {
    const totalAmount = merchandiseList.map(item => item.unit_price * item.ordered_units).reduce((a, b) => a + b, 0)

    // To ensure the config always has the latest calculated amount
    // setPaymentConfig({
    //   ...paymentConfig,
    //   amount: totalAmount
    // })
    return totalAmount
  }

  useEffect(() => {
    // Generate a transaction ref
    dispatch(generateTransactionRef());
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          merchandiseList,
          first_name: user ? user.first_name : "",
          last_name: user ? user.last_name : "",
          email: user ? user.email : "",
          phone_number: user ? user.phone_number : "",
          comment: "",
          total_amount: 0,
          payment_source: "bank",
          is_payment_locked: false
        }}
        validationSchema={Yup.object({
          first_name: Yup.string().required("First name is required"),
          last_name: Yup.string().required("Last name is required"),
          email: Yup.string()
            .email("Field must be email")
            .required("Email is required"),
          phone_number: Yup.string().required("Phone number is required"),
          comment: Yup.string().max(400, "Not more than 400 characters"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const newValues = {
            ...values,
            total_amount: getCalculatedTotalAmount(values.merchandiseList)
          }

          handleCreateMerchandiseOrder(newValues)
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
          setFieldTouched
        }) => {
          return (
            <Form>
              <div className="row">
                <FieldArray
                  name="merchandiseList"
                  render={arrayHelpers => (
                    <div>
                      {values.merchandiseList && values.merchandiseList.length > 0 && (
                        values.merchandiseList.map((merch, index) => (
                          <div className="row box-shadow align-items-center margin-bottom-20 border-radius-6 padding-top-6 padding-bottom-6 padding-left-10 padding-right-10">
                            <div className="max-width-20 fixed-height-20">
                              <input
                                className="margin-0"
                                type="checkbox"
                                checked={values.merchandiseList[index].ordered_units > 0}
                                onChange={() => {
                                  if (values.merchandiseList[index].ordered_units > 0) {
                                    setFieldValue(`merchandiseList[${index}].ordered_units`, 0)
                                    const newList = getNewMerchandiseList(values.merchandiseList, index, 0)
                                    values.merchandiseList = newList

                                  } else {
                                    setFieldValue(`merchandiseList[${index}].ordered_units`, 1)
                                    const newList = getNewMerchandiseList(values.merchandiseList, index, 1)
                                    values.merchandiseList = newList
                                  }
                                  handleUpdatePaymentConfig({
                                    ...values,
                                    total_amount: getCalculatedTotalAmount(values.merchandiseList)
                                  })
                                }} />
                            </div>

                            <div className="col-md-7 padding-top-4 padding-bottom-4 padding-right-md-8">
                              <MerchandiseView item={merch} currency={merch.unit_price_currency} />
                            </div>

                            <div className="col-md-4 d-flex align-items-center">
                              <div className="col-6 padding-top-4 padding-bottom-4 padding-right-6">
                                <Field
                                  className="form-control"
                                  type="number"
                                  name={`merchandiseList[${index}].ordered_units`}
                                  onChange={(event) => {
                                    if (event.target.value >= 0) {
                                      setFieldValue(`merchandiseList[${index}].ordered_units`, event.target.value)
                                      const newList = getNewMerchandiseList(values.merchandiseList, index, event.target.value)

                                      handleUpdatePaymentConfig({
                                        ...values,
                                        total_amount: getCalculatedTotalAmount(newList)
                                      })
                                    }
                                  }}
                                />
                              </div>

                              <div className="col-6 padding-top-4 padding-bottom-4 padding-left-6">
                                <FormattedMoney
                                  value={merch.unit_price * merch.ordered_units}
                                  currency={merch.unit_price_currency}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                />

                <div className="row padding-top-20 padding-bottom-20">
                  <div className="col-sm-7"></div>
                  <div className="d-flex col-sm-5 d-flex align-items-center">
                    <b className="font-size-16 max-width-160 margin-right-28 margin-right-sm-2 logo-magenta">TOTAL</b>
                    <b className="font-size-18 normal-green">
                      <FormattedMoney
                        value={getCalculatedTotalAmount(values.merchandiseList)}
                        currency={currency} />
                    </b>
                  </div>
                </div>

                {isAuthenticated ? (
                  <div className="col-sm-6 padding-left-0 padding-right-sm-10 padding-top-12 padding-bottom-12">
                    <label className="form-label">Payment Source</label>
                    <Field
                      component={() => (
                        <Select
                          className="form-control padding-0"
                          classNamePrefix="react-select"
                          placeholder="Select Source"
                          options={options}
                          value={
                            options
                              ? options.find(
                                (option) => option.value === values.payment_source
                              )
                              : ""
                          }
                          onChange={(option) => {
                            setFieldValue("payment_source", option.value);

                            handleUpdatePaymentConfig({
                              ...values,
                              payment_source: option.value,
                              total_amount: getCalculatedTotalAmount(values.merchandiseList)
                            })
                          }}
                        />
                      )}
                    />
                  </div>
                ) : null}

                <div className="row">
                  <div className="col-sm-6 padding-right-0 padding-right-sm-10 padding-top-12 padding-bottom-12">
                    <label className="form-label">First Name</label>
                    <Field
                      className="form-control"
                      placeholder="First Name"
                      name="first_name"
                      onBlur={() => {
                        handleUpdatePaymentConfig({
                          ...values,
                          total_amount: getCalculatedTotalAmount(values.merchandiseList)
                        })
                      }}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="first_name"
                      component="div"
                    />
                  </div>
                  <div className="col-sm-6 padding-left-0 padding-left-sm-10 padding-top-12 padding-bottom-12">
                    <label className="form-label">Last Name</label>
                    <Field
                      className="form-control"
                      placeholder="Last Name"
                      name="last_name"
                      onBlur={() => {
                        handleUpdatePaymentConfig({
                          ...values,
                          total_amount: getCalculatedTotalAmount(values.merchandiseList)
                        })
                      }}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="last_name"
                      component="div"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 padding-right-0 padding-right-sm-10 padding-top-12 padding-bottom-12">
                    <label className="form-label">Email</label>
                    <Field
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      onBlur={() => {
                        handleUpdatePaymentConfig({
                          ...values,
                          total_amount: getCalculatedTotalAmount(values.merchandiseList)
                        })
                      }}
                    />
                    <ErrorMessage
                      className="error-message"
                      name="email"
                      component="div"
                    />
                  </div>
                  <div className="col-sm-6 padding-left-0 padding-left-sm-10 padding-top-12 padding-bottom-12">
                    <label className="form-label">Phone Number</label>
                    <PhoneInput
                      className="d-flex form-control padding-2 padding-left-10 padding-right-10"
                      placeholder="Enter phone number"
                      value={values.phone_number}
                      onChange={(e) => {
                        setFieldValue("phone_number", e);

                        handleUpdatePaymentConfig({
                          ...values,
                          phone_number: e,
                          total_amount: getCalculatedTotalAmount(values.merchandiseList)
                        })
                      }}
                      defaultCountry="NG"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="phone_number"
                      component="div"
                    />
                  </div>
                </div>

                <div className="padding-top-8 padding-bottom-8">
                  <label className="form-label">
                    Should the organizer be allowed to access this payment before delivering the goods?
                  </label>
                  <div className="flex">
                    <label>
                      <input
                        className="radio-btn"
                        type="radio"
                        name="is_payment_locked"
                        checked={values.is_payment_locked == false}
                        onChange={() => {
                          setFieldValue("is_payment_locked", false)

                          handleUpdatePaymentConfig({
                            ...values,
                            is_payment_locked: false,
                            total_amount: getCalculatedTotalAmount(values.merchandiseList)
                          })
                        }}
                      />
                      Yes &nbsp;&nbsp;
                    </label>
                    <label>
                      <input
                        className="radio-btn"
                        type="radio"
                        name="is_payment_locked"
                        checked={values.is_payment_locked == true}
                        onChange={() => {
                          setFieldValue("is_payment_locked", true)

                          handleUpdatePaymentConfig({
                            ...values,
                            is_payment_locked: true,
                            total_amount: getCalculatedTotalAmount(values.merchandiseList)
                          })
                        }}
                      />
                      No
                    </label>
                  </div>
                </div>

                <div className="padding-top-12 padding-bottom-12">
                  <label className="form-label">Comment (optional)</label>
                  <Field
                    className="form-control"
                    component="textarea"
                    rows="4"
                    placeholder="Type your comment here..."
                    name="comment"
                    onBlur={() => {
                      handleUpdatePaymentConfig({
                        ...values,
                        total_amount: getCalculatedTotalAmount(values.merchandiseList)
                      })
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="comment"
                    component="div"
                  />
                </div>

                <div className="padding-top-18 padding-bottom-18">
                  <button
                    className="btn padding-12 border-radius-10 font-size-16 max-width-160 bg-logo-magenta white"
                    type="button"
                    onClick={handleSubmit}
                    disabled={
                      getCalculatedTotalAmount(values.merchandiseList) <= 0 ?
                        true : false}
                  >
                    Place Order
                  </button>
                </div>

              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    transaction_ref: state.transaction.transaction_ref,
    isAuthenticated: !!state.auth.userToken,
  };
};

export default connect(mapStateToProps)(BuyMerchandise);
