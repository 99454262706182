import React, { useEffect } from "react";
import { connect } from "react-redux";
import AllFundraisers from "./AllFundraisers";

const BrowseFundraisers = () => {
  useEffect(() => {
    document.title = "Browse Fundraisers"
  }, [])

  return (
    <div className="content-container">
      <AllFundraisers
        noOfRange={20}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(BrowseFundraisers);
