import React, { useRef } from "react";
import { connect } from "react-redux";
import { convertCountryCodeToName, getDocumentFileformatFromUrl, getFileNameFromFileUrl } from "../../../../utilities";
import Truncate from "react-truncate";
import PdfThumbnail from "../../../utilityComponents/PdfThumbnail";
import NationalPhoneNumber from "../../../utilityComponents/NationalPhoneNumber";
import ngoDummyLogo from "../../../images/ngo_dummy_logo.png"

const CharityNGOOverview = ({
  charityNgo,
  setCharityNgo,
  handleUploadNgoDocument,
}) => {

  const logoRef = useRef(null)

  const handleUploadLogo = () => {
    logoRef.current.click();
  }

  return (
    <div>
      <div>
        <div className="d-flex padding-top-10 padding-bottom-10">
          <input
            ref={logoRef}
            hidden
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.currentTarget.files[0];
              if (file) {
                handleUploadNgoDocument(charityNgo.id, file, "LOGO")
                  .then(r => {
                    if (r.success) {
                      setCharityNgo(r.data)
                    }
                  })
              }
            }} />
          <div className="col-4">
            Logo
          </div>
          <div className="col-8" onClick={() => {
            handleUploadLogo()
          }}>
            <img width="80" height="80" src={charityNgo.logo_file_url ? charityNgo.logo_file_url : ngoDummyLogo} />
          </div>
        </div>
        <div className="d-flex padding-top-10 padding-bottom-10">
          <div className="col-4">
            Organization Name
          </div>
          <b className="col-8">
            {charityNgo.organization_name}
          </b>
        </div>
        <div className="d-flex padding-top-10 padding-bottom-10">
          <div className="col-4">
            Reg. No.
          </div>
          <b className="col-8">
            {charityNgo.registration_number}
          </b>
        </div>
        <div className="d-flex padding-top-10 padding-bottom-10">
          <div className="col-4">
            Country
          </div>
          <b className="col-8">
            {convertCountryCodeToName(charityNgo.country)}
          </b>
        </div>
        <div className="d-flex padding-top-10 padding-bottom-10">
          <div className="col-4">
            State
          </div>
          <b className="col-8">
            {charityNgo.state}
          </b>
        </div>
        <div className="d-flex padding-top-10 padding-bottom-10">
          <div className="col-4">
            City
          </div>
          <b className="col-8">
            {charityNgo.city}
          </b>
        </div>
        <div className="d-flex padding-top-10 padding-bottom-10">
          <div className="col-4">
            Street Address
          </div>
          <b className="col-8">
            {charityNgo.street_address}
          </b>
        </div>
        <div className="d-flex padding-top-10 padding-bottom-10">
          <div className="col-4">
            Phone No.
          </div>
          <b className="col-8">
            <NationalPhoneNumber
              internationalNumber={charityNgo.phone_number}
            />
          </b>
        </div>

        <div className="horizontal-line " />

        <div className="padding-top-20 padding-bottom-20">
          <b>REGISTRATION CERTIFICATE </b>

          {charityNgo.certificate_file_url &&
            <div
              className="fixed-height-120 min-width-120 max-width-120 margin-left-0 margin-right-8 margin-top-4 margin-bottom-4 margin-left-sm-0
                    // margin-right-sm-10 margin-top-sm-10 margin-bottom-sm-10 margin-sm-right-10"
            >
              {getDocumentFileformatFromUrl(charityNgo.certificate_file_url) == "pdf" ? (
                <PdfThumbnail height={90}
                  document={{
                    ...charityNgo,
                    file_url: charityNgo.certificate_file_url
                  }} />
              ) : (
                <img
                  width="100%"
                  height="100%"
                  className="d-block stretch-full border-top-radius-0"
                  src={`${charityNgo.certificate_file_url}?${charityNgo.certificate_file_url}`}
                />
              )}
              <b> <Truncate lines={1}>
                {getFileNameFromFileUrl(charityNgo.certificate_file_url)}
              </Truncate></b>
            </div>
          }
        </div>

        <div className="horizontal-line " />

        <div className="padding-top-20 padding-bottom-20">
          <b>MEMORANDUM OF ASSOCIATION </b>
          <div className="d-flex overflow-hidden overflow-scroll-x">
            {
              charityNgo.incorporated_trustees.length > 0 &&
              charityNgo.incorporated_trustees.map((fileUrl, i) => {
                return (
                  <div
                    key={i}
                    className="relative min-width-120 max-width-120 margin-left-0 margin-right-8 margin-top-4 margin-bottom-4 margin-left-sm-0
                              margin-right-sm-10 margin-top-sm-10 margin-bottom-sm-10 margin-sm-right-10">
                    <div
                      className="fixed-height-100"
                    >
                      {getDocumentFileformatFromUrl(fileUrl) == "pdf" ? (
                        <PdfThumbnail height={90}
                          document={{
                            ...charityNgo,
                            file_url: fileUrl
                          }} />
                      ) : (
                        <img
                          width="100%"
                          height="100%"
                          className="d-block stretch-full border-top-radius-0"
                          src={`${fileUrl}?${fileUrl}`}
                        />
                      )}
                    </div>

                    <b> <Truncate lines={1}>
                      {getFileNameFromFileUrl(fileUrl)}
                    </Truncate></b>
                  </div>
                )
              })
            }
          </div>

          <div className="horizontal-line " />

          <div className="padding-top-20 padding-bottom-20">
            <b>NOTICE OF ADDRESS</b>
            <div className="d-flex overflow-hidden overflow-scroll-x">
              {
                charityNgo.notice_of_address.length > 0 &&
                charityNgo.notice_of_address.map((fileUrl, i) => {
                  return (
                    <div
                      key={i}
                      className="relative min-width-120 max-width-120 margin-left-0 margin-right-8 margin-top-4 margin-bottom-4 margin-left-sm-0
                       margin-right-sm-10 margin-top-sm-10 margin-bottom-sm-10 margin-sm-right-10">
                      <div
                      >
                        {getDocumentFileformatFromUrl(fileUrl) == "pdf" ? (
                          <PdfThumbnail height={90}
                            document={{
                              ...charityNgo,
                              file_url: fileUrl
                            }} />
                        ) : (
                          <img
                            width="100%"
                            height="100%"
                            className="d-block stretch-full border-top-radius-0"
                            src={`${fileUrl}?${fileUrl}`}
                          />
                        )}
                      </div>
                      <b> <Truncate lines={1}>
                        {getFileNameFromFileUrl(fileUrl)}
                      </Truncate></b>
                    </div>
                  )
                })
              }
            </div>

            <div className="horizontal-line " />

            <div className="padding-top-20 padding-bottom-20" >
              <b>BANK ACCOUNTS</b>
              <div>
                {charityNgo &&
                  charityNgo.bank_accounts &&
                  charityNgo.bank_accounts.length > 0 ?
                  charityNgo.bank_accounts.map((b, index) => {
                    return (
                      <div key={index}
                        className="box-shadow border-radius-8 margin-top-10 margin-bottom-10 padding-4 padding-left-12 
            padding-right-12 font-size-13 padding-bottom-10">

                        <div className="row">
                          <div className="col-sm-8">
                            <div className="d-flex align-items padding-top-12 padding-bottom-12">
                              <b className="col-5">Account Name</b>
                              <span className="col-7">{b.bank_account_name}</span>
                            </div>

                            <div className="d-flex align-items padding-top-12 padding-bottom-12">
                              <b className="col-5">Account No</b>
                              <span className="col-7">{b.bank_account_number}</span>
                            </div>

                            <div className="d-flex align-items padding-top-12 padding-bottom-12">
                              <b className="col-5">Bank</b>
                              <span className="col-7">{b.bank_name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :
                  <center>
                    <p className="padding-top-10 padding-bottom-10 grey">You have no bank accounts linked </p>
                  </center>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(CharityNGOOverview);
