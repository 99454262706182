import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { sendTransactionOtp } from "../../../store/actions/transaction";
import FormattedMoney from "../../utilityComponents/FormattedMoney"

const ConfirmWithdrawal = ({
  setIsPageLoading,
  dispatch,
  withdrawalDetails,
  handleSubmitTransfer,
}) => {
  const [otp, setOtp] = useState(null)

  return (
    <div>
      <div className="row padding-top-12 padding-bottom-12">
        <label className="col-6" >Beneficiary Name</label>
        <div className="col-6">
          <b>{withdrawalDetails.name}</b>
        </div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <label className="col-6" >Bank Account No</label>
        <div className="col-6">
          <b>{withdrawalDetails.account_number}</b>
        </div>
      </div>

      {withdrawalDetails.bank_branch_name &&
        <div className="row padding-top-12 padding-bottom-12">
          <label className="col-6" >Bank Branch Name</label>
          <div className="col-6">
            <b>{withdrawalDetails.bank_branch_name}</b>
          </div>
        </div>
      }

      <div className="row padding-top-12 padding-bottom-12">
        <label className="col-6" >Transfer Amount</label>
        <div className="col-6">
          <b><FormattedMoney
            value={withdrawalDetails.amount}
            currency={withdrawalDetails.currency}
            decimalScale={2} /></b>
        </div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <label className="col-6" >Transfer Fee</label>
        <div className="col-6">
          <b><FormattedMoney
            value={withdrawalDetails.fee}
            currency={withdrawalDetails.currency}
            decimalScale={2} /></b>
        </div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <label className="col-6" >Total Amount</label>
        <div className="col-6">
          <b><FormattedMoney
            value={parseFloat(withdrawalDetails.amount) +
              parseFloat(withdrawalDetails.fee)}
            currency={withdrawalDetails.currency}
            decimalScale={2} /></b>
        </div>
      </div>

      <div className="padding-top-12 padding-bottom-12">
        <label className="form-label" >One Time Password</label>
        <div className="row">
          <div className="col-sm-6 padding-right-0 padding-right-sm-20">
            <input
              className="form-control"
              placeholder="Enter OTP"
              type="text"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value)
              }}
            />
          </div>
          <div className="col-sm-6 padding-top-20 padding-top-sm-0">
            <button
              className="btn bg-logo-magenta max-width-120 padding-8"
              type="button"
              onClick={() => {
                setOtp("")
                setIsPageLoading([true, "Sending OTP..."])
                dispatch(sendTransactionOtp())
                  .then(res => {
                    setIsPageLoading([false]);
                    if (res.error) {
                      NotificationManager.error(res.error.message, 'Error!')
                    } else {
                      NotificationManager.success(res.message, 'Success!')
                    }
                  })
              }}
            >
              Generate OTP
            </button>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button
          className="btn margin-top-24 margin-bottom-34 padding-12 bg-logo-magenta white max-width-220 font-size-17 border-radius-8"
          onClick={() => {
            if (!otp) {
              alert("OTP is required")
              return
            }
            handleSubmitTransfer({
              ...withdrawalDetails,
              otp
            });
          }}
        >
          <b>Submit</b>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ConfirmWithdrawal);

