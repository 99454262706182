import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import Truncate from "react-truncate";
import { NotificationManager } from 'react-notifications';
import { HashLink } from 'react-router-hash-link';
import { Fragment } from "react";
import pdfImage from "../fragments/pdfImage.jpg";
import FundraiserCoverMedia from "../../public/components/FundraiserCoverMedia";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney"
import RenderHtmlTemplate from "../../../../utilityComponents/RenderHtmlTemplate"
import { enableOrDisableFundraiser, submitFundraiser, getFundraiserByOwner } from "../../../../../store/actions/fundraiser";
import SubmitIdProofDocs from "../../../../dashboard/account/verificationDocs/SubmitIdProofDocs";
import SubmitAddressProofDocs from "../../../../dashboard/account/verificationDocs/SubmitAddressProofDocs";
import { convertSnakeToStandardCasing, getConfirmAlert, getDocumentFileformatFromUrl } from "../../../../../utilities";
import MerchandiseView from "../fragments/MerchandiseView";

const FundraiserOverview = ({
    user,
    history,
    dispatch,
    setIsPageLoading,
    fundraiser,
    setFundraiser,
    canEdit = true,
    viewOnly = false
}) => {
    const [isSubmitIdProofDocVisible, setIsSubmitIdProofDocVisible] = useState(false);
    const [isSubmitIdAddressDocVisible, setIsSubmitIdAddressDocVisible] = useState(false);
    const [isSubmitCompanyVisible, setIsSubmitCompanyVisible] = useState(false);

    return (
        <div className="margin-bottom-20">
            {
                fundraiser.required_actions.length > 0 && (
                    <div>
                        <h3 className="font-size-18 logo-magenta padding-right-0 padding-right-md-20 padding-right-lg-30">
                            Required Actions
                        </h3>
                        {fundraiser.required_actions.map((action, index) => {
                            return (
                                <div
                                    className="d-flex justify-content-between margin-top-6 margin-bottom-12"
                                    key={index}
                                >
                                    {action.message && (
                                        <div className="d-flex col-8 align-items-center font-size-12 font-size-sm-14 padding-right-4">
                                            <i className="fa fa-exclamation-triangle red padding-right-8"></i>
                                            <p>{action.message}</p>
                                        </div>
                                    )}

                                    {action.action == "submit_proof_of_id" ? (
                                        <div className="max-width-300">
                                            <button
                                                className="btn font-size-12 float-end font-size-md-14 max-width-140 border-radius-8 bg-logo-magenta border-color-logo-magenta white padding-4 padding-md-8"
                                                type="button"
                                                onClick={() => {
                                                    setIsSubmitIdProofDocVisible(true)
                                                }}
                                            >
                                                Take Action
                                            </button>
                                        </div>
                                    ) : null}

                                    {action.action == "submit_proof_of_address" ? (
                                        <div className="max-width-300">
                                            <button
                                                className="btn font-size-12 float-end font-size-md-14 max-width-140 border-radius-8 bg-logo-magenta border-color-logo-magenta white padding-4 padding-md-8"
                                                type="button"
                                                onClick={() => {
                                                    setIsSubmitIdAddressDocVisible(true)
                                                }}
                                            >
                                                Take Action
                                            </button>
                                        </div>
                                    ) : null}

                                    {action.action == "submit_form" ? (
                                        <div className="max-width-300">
                                            <HashLink
                                                smooth
                                                to={`/dashboard/fundraiser/edit/${action.fundraiser_id}?activeSlide=fundraiserSummary#submitForm`}
                                                scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}
                                            >
                                                <button
                                                    className="btn font-size-12 float-end font-size-md-14 max-width-140 border-radius-8 bg-logo-magenta border-color-logo-magenta white padding-4 padding-md-8"
                                                    type="button"
                                                // onClick={() => {
                                                //     // history.push(`/dashboard/fundraiser/edit/${action.fundraiser_id}?activeSlide=fundraiserSummary`)
                                                // }}
                                                >
                                                    Take Action
                                                </button>
                                            </HashLink>
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}
                        <div className="horizontal-line margin-top-24 margin-bottom-20" />
                    </div>
                )}

            {fundraiser && fundraiser.fundraiser_details && (
                <div>
                    <div className="margin-bottom-10">
                        <div className="max-width-500">
                            <FundraiserCoverMedia fundraiser={fundraiser} />
                        </div>
                        <div className="row">
                            {fundraiser.additional_cover_pictures.length > 0 && fundraiser.additional_cover_pictures.map((fileObj, index) => (
                                <Fragment key={index}>
                                    <div className="min-width-130 max-width-130 margin-left-0 margin-right-8 margin-top-4 margin-bottom-4 margin-left-sm-0 margin-right-sm-10 margin-top-sm-10 margin-bottom-sm-10 margin-sm-right-10 cursor-pointer bg-lighter-blue ">
                                        <div className="relative">
                                            <div
                                                className="fixed-height-100"
                                            >
                                                <img
                                                    width="100%"
                                                    height="100%"
                                                    className="d-block stretch-full"
                                                    src={`${fileObj.file_url}?${fileObj.file_url}`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            ))}
                        </div>

                        {canEdit && fundraiser.is_fundraiser_editable == true ? (
                            <button
                                className="btn max-width-150 font-size-14 padding-6 margin-top-14 margin-bottom-10 bg-logo-magenta white border-radius-8"
                                type="button"
                                onClick={() => {
                                    history.push(`/dashboard/fundraiser/edit/${fundraiser.fundraiser_details.id}?activeSlide=coverMedia`)
                                }}
                            >
                                Edit Cover Media
                            </button>
                        ) : null}
                    </div>

                    <div className="horizontal-line" />

                    <div className="margin-top-20 margin-bottom-20">
                        <div>
                            <div className="margin-bottom-10">
                                <b className="font-size-17 font-size-sm-19 logo-magenta">
                                    Fundraiser Description
                                </b>
                            </div>

                            <div className="font-size-13 font-size-md-14 border-color-light-grey">
                                <div className="d-flex padding-top-10 padding-bottom-4">
                                    <div className="col-4">
                                        Status
                                    </div>
                                    <div className="col-4">
                                        <b>{convertSnakeToStandardCasing(fundraiser.fundraiser_details.status)}</b>
                                    </div>
                                    <div className="col-2">
                                        {fundraiser.fundraiser_details.status == "ACTIVE" &&
                                            <button
                                                className="btn bg-orange-red padding-4 font-size-13 font-size-md-14"
                                                type="button"
                                                onClick={() => {
                                                    getConfirmAlert({
                                                        title: "Confirm",
                                                        message: "Are you sure you want to disable this fundraiser campaign? The public will not be able to see it anymore",
                                                        onClickYes: () => {
                                                            setIsPageLoading([true, "processing..."]);

                                                            dispatch(enableOrDisableFundraiser({
                                                                fundraiserId: fundraiser.fundraiser_details.id,
                                                                action: "disable"
                                                            }))
                                                                .then(res => {
                                                                    setIsPageLoading([false]);

                                                                    if (res.error) {
                                                                        NotificationManager.error(res.error.message, "Error!")
                                                                    } else {
                                                                        if (res.success) {
                                                                            NotificationManager.success(res.message, "Success!")
                                                                            setFundraiser(res.data)
                                                                        }
                                                                    }
                                                                })
                                                        },
                                                        onClickNo: () => null
                                                    })
                                                }}
                                            >Pause</button>}

                                        {fundraiser.fundraiser_details.status == "INACTIVE" &&
                                            <button
                                                type="button"
                                                className="btn bg-blue padding-4 margin-right-10 font-size-13 font-size-md-14"
                                                onClick={() => {
                                                    getConfirmAlert({
                                                        title: "Confirm",
                                                        message: "Are you sure you want to enable this fundraiser campaign?",
                                                        onClickYes: () => {
                                                            setIsPageLoading([true, "processing..."]);

                                                            dispatch(enableOrDisableFundraiser({
                                                                fundraiserId: fundraiser.fundraiser_details.id,
                                                                action: "enable"
                                                            }))
                                                                .then(res => {
                                                                    setIsPageLoading([false]);

                                                                    if (res.error) {
                                                                        NotificationManager.error(res.error.message, "Error!")
                                                                    } else {
                                                                        if (res.success) {
                                                                            NotificationManager.success(res.message, "Success!")
                                                                            setFundraiser(res.data)
                                                                        }
                                                                    }
                                                                })
                                                        },
                                                        onClickNo: () => null
                                                    })
                                                }}
                                            >Enable</button>}
                                    </div>
                                </div>

                                <div className="d-flex padding-top-10 padding-bottom-10">
                                    <div className="col-4">
                                        ID
                                    </div>
                                    <div className="col-8">
                                        <b>#{fundraiser.fundraiser_details.id}</b>
                                    </div>
                                </div>

                                <div className="d-flex padding-top-10 padding-bottom-10">
                                    <div className="col-4">
                                        Title
                                    </div>
                                    <div className="col-8">
                                        <b className="d-block stretch-full">
                                            <Truncate lines={2}>
                                                {fundraiser.fundraiser_details.title}
                                            </Truncate>
                                        </b>
                                    </div>
                                </div>

                                <div className="d-flex padding-top-10 padding-bottom-10">
                                    <div className="col-4">
                                        Your Story
                                    </div>
                                    <div className="col-8">
                                        <b>
                                            <RenderHtmlTemplate html={fundraiser.fundraiser_details.story} /></b>
                                    </div>
                                </div>

                                <div className="d-flex padding-top-10 padding-bottom-10">
                                    <div className="col-4">
                                        Amount Raised
                                    </div>
                                    <div className="col-8">
                                        <b>
                                            <FormattedMoney
                                                value={fundraiser.fundraiser_details.amount_raised}
                                                currency={fundraiser.fundraiser_details.goal_amount_currency}
                                            /></b>
                                    </div>
                                </div>

                                {fundraiser.fundraiser_details.goal_amount &&
                                    <div className="d-flex padding-top-10 padding-bottom-10">
                                        <div className="col-4">
                                            Goal Amount
                                        </div>
                                        <div className="col-8">
                                            <b>
                                                <FormattedMoney
                                                    value={fundraiser.fundraiser_details.goal_amount}
                                                    currency={fundraiser.fundraiser_details.goal_amount_currency}
                                                /></b>
                                        </div>
                                    </div>}

                                <div className="d-flex padding-top-10 padding-bottom-10">
                                    <div className="col-4">
                                        Campaign
                                    </div>
                                    <div className="col-8">
                                        <b>{fundraiser.fundraiser_details.is_public ? (
                                            "Public"
                                        ) : (
                                            "Private"
                                        )}</b>
                                    </div>
                                </div>

                                <div className="d-flex padding-top-10">
                                    <div className="col-4 padding-bottom-8">
                                        Merchandise
                                    </div>
                                    <div className="col-8">
                                        {fundraiser.fundraiser_details &&
                                            fundraiser.fundraiser_details.merchandise &&
                                            fundraiser.fundraiser_details.merchandise.length > 0 &&
                                            fundraiser.fundraiser_details.merchandise.map((el, index) => {
                                                return (
                                                    <div key={index} className="row margin-bottom-20 margin-bottom-20 border-radius-4 padding-4">
                                                        <MerchandiseView
                                                            item={el}
                                                            currency={el.unit_price_currency}
                                                        />
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {canEdit && fundraiser.is_fundraiser_editable == true ? (
                            <button
                                className="btn max-width-80 font-size-14 padding-6 margin-top-14 margin-bottom-10 bg-logo-magenta white border-radius-8"
                                type="button"
                                onClick={() => {
                                    history.push(`/dashboard/fundraiser/edit/${fundraiser.fundraiser_details.id}?activeSlide=editFundraiserDescription`)
                                }}
                            >
                                Edit
                            </button>
                        ) : null}
                    </div>

                    {fundraiser.documents.length ? (
                        <div>
                            <div className="horizontal-line" />

                            <div className="margin-top-20 margin-bottom-20">

                                <div className="margin-bottom-10">
                                    <b className="font-size-17 font-size-sm-19 logo-magenta">
                                        Supporting Document
                                    </b>
                                </div>

                                <div className="flex flex-wrap">
                                    {fundraiser.documents.map((fileObj, index) => (
                                        <div key={index} className="margin-4">
                                            <img
                                                className="fixed-width-90 fixed-height-80 border-radius-4"
                                                src={
                                                    getDocumentFileformatFromUrl(fileObj.file_url) == "image"
                                                        ? fileObj.file_url
                                                        : pdfImage
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>

                                {canEdit ? (
                                    <button
                                        className="btn max-width-80 font-size-14 padding-6 margin-top-14 margin-bottom-10 bg-logo-magenta white border-radius-8"
                                        type="button"
                                        onClick={() => {
                                            history.push(`/dashboard/fundraiser/edit/${fundraiser.fundraiser_details.id}?activeSlide=supportingDocs`)
                                        }}
                                    >
                                        Edit
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                    {/* 
                    {
                        <div>

                            <div className="horizontal-line" />
                        </div>

                    } */}

                    {/* <div>
                        {fundraiser.fundraiser_details.bank_account_detail_id && (
                            <div>
                                {fundraiser.fundraiser_details.bank_account_detail_id ? (
                                    <div>
                                        <ManagerBankDetailsTable
                                            setIsPageLoading={setIsPageLoading}
                                            fundraiser={fundraiser}
                                            canEdit={false}
                                        />
                                    </div>
                                ) : (
                                    null
                                )}
                            </div>
                        )}

                        {fundraiser.third_party_beneficiary.id &&
                            fundraiser.third_party_beneficiary.full_name &&
                            fundraiser.third_party_beneficiary.bank_name ? (
                            <div>
                                <BeneficiaryBankDetailsTable
                                    thirdPartyBeneficiary={fundraiser.third_party_beneficiary}
                                    setFundraiser={setFundraiser}
                                    canEdit={false}
                                />
                            </div>
                        ) : null}

                        {canEdit ? (
                            <button
                                className="btn max-width-80 font-size-14 padding-6 margin-top-14 margin-bottom-10 bg-logo-magenta white border-radius-8"
                                type="button"
                                onClick={() => {
                                    history.push(`/dashboard/fundraiser/edit/${fundraiser.fundraiser_details.id}?activeSlide=paymentDetails`)
                                }}
                            >
                                Edit
                            </button>
                        ) : null}
                    </div> */}
                </div>
            )}
            <div className="horizontal-line margin-top-20 margin-bottom-30" />

            {!viewOnly &&
                <div
                    className="d-flex justify-content-end margin-bottom-30"
                    id="submitForm"
                >
                    <button
                        type="button"
                        className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-logo-magenta white border-radius-12"
                        onClick={() => {
                            setIsPageLoading([true, "Loading..."]);

                            dispatch(submitFundraiser(fundraiser.fundraiser_details.id))
                                .then(res => {
                                    setIsPageLoading([false]);
                                    if (res.error) {
                                        NotificationManager.error(res.error.message, 'Error!')
                                    } else {
                                        if (res.success == true) {
                                            history.push("/dashboard/fundraiser")
                                            NotificationManager.success(res.message, 'Success!')
                                        }
                                    }
                                })
                        }}
                    >
                        <b>Submit</b>
                    </button>
                </div>}

            <Modal
                ariaHideApp={false}
                isOpen={isSubmitIdProofDocVisible}
                onRequestClose={() => setIsSubmitIdProofDocVisible(false)}
                className="modal max-width-750 padding-0"
                overlayClassName="modal-backdrop"
            >
                <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
                    <h1 className="font-size-20 font-size-md-22 logo-magenta text-center stretch-full">
                        Submit Proof of ID Documents for Verification
                    </h1>
                    <i
                        onClick={() => setIsSubmitIdProofDocVisible(false)}
                        className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
                    ></i>
                </div>

                <div className="horizontal-line" />

                <div
                    style={{ maxHeight: "80vh" }}
                    className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
                >
                    <SubmitIdProofDocs
                        document={user.id_proof_doc}
                        setIsPageLoading={setIsPageLoading}
                        done={async () => {
                            const response = await dispatch(getFundraiserByOwner(fundraiser.fundraiser_details.id));

                            setIsPageLoading([false]);

                            if (response.error) {
                                NotificationManager.error(response.error.message, "Error!")
                            } else {
                                if (response.success == true) {
                                    setFundraiser(response.data);
                                    return response.data
                                }
                            }
                        }} />
                </div>
            </Modal>

            <Modal
                ariaHideApp={false}
                isOpen={isSubmitIdAddressDocVisible}
                onRequestClose={() => setIsSubmitIdAddressDocVisible(false)}
                className="modal max-width-750 padding-0"
                overlayClassName="modal-backdrop"
            >
                <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
                    <h1 className="font-size-20 font-size-md-22 logo-magenta text-center stretch-full">
                        Submit Proof of Address Documents for Verification
                    </h1>
                    <i
                        onClick={() => setIsSubmitIdAddressDocVisible(false)}
                        className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
                    ></i>
                </div>

                <div className="horizontal-line" />

                <div
                    style={{ maxHeight: "80vh" }}
                    className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
                >
                    <SubmitAddressProofDocs
                        documents={user.address_proof_docs}
                        setIsPageLoading={setIsPageLoading}
                        handleClose={() => setIsSubmitIdAddressDocVisible(false)} />
                </div>
            </Modal>

        </div>
    );
};

const mapStateToProps = (state, props) => {
    return {
        user: state.auth.user,
    };
};

export default connect(mapStateToProps)(FundraiserOverview);
