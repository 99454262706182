import React, { useState, useEffect } from "react";
import { useSpring, animated } from 'react-spring';
import Modal from "react-modal";
import AllFundraisers from "../public/AllFundraisers";
import fundraiserImage from "../../../images/fundraiser_setup.svg";
import contractIcon from "../../../images/contract_icon_darkblue.svg";
import calendarIcon from "../../../images/calendar_icon_darkblue.svg";
import imageGalleryIcon from "../../../images/image-gallery_icon_darkblue.svg";
import playButtonIcon from "../../../images/play_button_icon_orange.svg";
import CurrentWindowWidth from "../../../CurrentWindowWidth";

const FundraiserHome = ({
  history,
  setIsPageLoading
}) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isExplainerVideoOpen, setIsExplainerVideoOpen] = useState(false)

  let windowWidth = CurrentWindowWidth();

  const fadeStyles = useSpring({
    from: { opacity: 0 },
    config: { duration: 2000 },
    loop: {
      opacity: 1,
    },
  })

  const slideStyles = useSpring({
    from: { x: -100, opacity: 0 },
    config: { duration: 1000 },
    loop: {
      x: 0, opacity: 1,
    },
  })

  useEffect(() => {
    document.title = "Fundraiser Home"
  }, [])

  return (
    <animated.div className="content-container"
      style={fadeStyles}>
      <div>
        <div className="padding-top-30 padding-top-md-40 padding-left-0 padding-left-lg-40 padding-right-0 padding-right-lg-40">
          <div className="row">
            <animated.div className="col-md-4 padding-bottom-30"
              style={slideStyles}>
              <div className="d-flex flex-column align-items-center">
                <h1 className="font-size-30 font-size-lg-40 margin-bottom-4 orange">
                  Fundraiser
                </h1>
                <img className="max-width-400" src={fundraiserImage} />
                <div className="d-flex stretch-full justify-content-between padding-top-30 padding-top-sm-60">
                  <button
                    className="btn max-width-230 border-radius-14 font-size-13 font-size-sm-15 font-size-md-17 margin-right-8 margin-right-sm-20 padding-6 padding-top-12 padding-top-sm-18 padding-bottom-12 padding-bottom-sm-18 bg-fundraiser-color white"
                    onClick={() => {
                      history.push("/dashboard/fundraiser/new");
                    }}
                  >
                    <b>Set up a Fundraiser</b>
                  </button>
                  <button
                    className="btn d-flex align-items-center justify-content-evenly font-size-13 font-size-sm-15 font-size-md-17 max-width-230 border-radius-14 margin-left-8 margin-left-sm-20 padding-6 padding-top-12 padding-top-sm-18 padding-bottom-12 padding-bottom-sm-18 border-2 border-color-orange bg-white orange"
                    onClick={() => setIsExplainerVideoOpen(true)}
                  >
                    <img className="padding-left-6 padding-left-sm-0 padding-right-6 padding-right-sm-0 max-width-30"
                      src={playButtonIcon}
                    />
                    <b>Explainer Video</b>
                  </button>
                </div>
              </div>
            </animated.div>

            <div className="col-md-8">
              <p className="font-size-17 font-size-lg-20 line-height-24 line-height-lg-28 padding-top-10 padding-left-0 padding-left-md-40 padding-right-0 padding-right-md-40">
                A very easy, fast and reliable way to privately or publicly
                raise funds for people or causes that are dear to you
              </p>
              <div className="d-flex flex-wrap justify-content-around text-center padding-top-30 padding-top-md-60">
                <div className="max-width-260 margin-10 margin-top-40 margin-bottom-40">
                  <div className="relative fixed-height-240 d-flex flex-column align-items-center padding-20 padding-sm-30 border-radius-14 bg-fundraiser-light-color">
                    <div className="absolute top-0 left-50-pct transform-50-50 bg-transparent padding-4 border-3 border-color-fundraiser-color rounded-circle">
                      <b className="d-flex align-items-center justify-content-center fixed-height-50 fixed-width-50 font-size-24 rounded-circle bg-fundraiser-color white">
                        1
                      </b>
                    </div>

                    <img
                      className="padding-top-50 padding-bottom-20 max-width-50"
                      src={contractIcon}
                    />
                    <div className="max-width-180">
                      <b className="font-size-17 line-height-20">
                        {/* Describe the campaign goals */}
                        Set up your fundraiser campaign
                      </b>
                    </div>
                  </div>
                </div>

                <div className="max-width-260 margin-10 margin-top-40 margin-bottom-40">
                  <div className="relative fixed-height-240 d-flex flex-column align-items-center padding-20 padding-sm-30 border-radius-14 bg-fundraiser-light-color">
                    <div className="absolute top-0 left-50-pct transform-50-50 bg-transparent padding-4 border-3 border-color-fundraiser-color rounded-circle">
                      <b className="d-flex align-items-center justify-content-center fixed-height-50 fixed-width-50 font-size-24 rounded-circle bg-fundraiser-color white">
                        2
                      </b>
                    </div>

                    <img
                      className="padding-top-50 padding-bottom-20 max-width-50"
                      src={imageGalleryIcon}
                    />
                    <div className="max-width-200">
                      <b className="font-size-17 line-height-20">
                        Verify your ID and address
                      </b>
                    </div>
                  </div>
                </div>

                <div className="max-width-260 margin-10 margin-top-40 margin-bottom-40">
                  <div className="relative fixed-height-240 d-flex flex-column align-items-center padding-20 padding-sm-30 border-radius-14 bg-fundraiser-light-color">
                    <div className="absolute top-0 left-50-pct transform-50-50 bg-transparent padding-4 border-3 border-color-fundraiser-color rounded-circle">
                      <b className="d-flex align-items-center justify-content-center fixed-height-50 fixed-width-50 font-size-24 rounded-circle bg-fundraiser-color white">
                        3
                      </b>
                    </div>

                    <img
                      className="padding-top-50 padding-bottom-20 max-width-50"
                      src={calendarIcon}
                    />
                    <div className="max-width-240">
                      <b className="font-size-17 line-height-20">
                        Share to get at least 3 donations, go worldwide and get funded
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="padding-left-4 padding-left-md-20 padding-right-4 padding-right-md-20 ">
        <div className="padding-top-20 padding-bottom-20">
          <AllFundraisers
            noOfRange={12}
            selectedCategory={selectedCategory}
            setIsPageLoading={setIsPageLoading} />
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={isExplainerVideoOpen}
          onRequestClose={() => setIsExplainerVideoOpen(false)}
          className="modal"
          overlayClassName="modal-backdrop"
        >
          <iframe
            width={0.9 * windowWidth}
            height={0.45 * windowWidth}
            src="https://www.youtube.com/embed/lTTOqSyT3Es?autoplay=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          ></iframe>
        </Modal>
      </div>
    </animated.div>
  );
};

export default FundraiserHome;
