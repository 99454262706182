import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import dummyPrivateFundraiserCoverImage from "../../setup/privateFundraiserCover.jpg";
import CurrentWindowWidth from "../../../../CurrentWindowWidth";

const CoverMediaThumbnail = ({
  coverMedia,
  isBorderRadiusTopLeft,
  fundraiserDetails
}) => {
  const [coverMediaType, setCoverMediaType] = useState("");
  const [fileType, setFileType] = useState("");
  const [coverImgHeight, setCoverImgHeight] = useState(0);

  let windowWidth = CurrentWindowWidth();

  const coverImgRef = useCallback(
    (node) => {
      if (node !== null) {
        setCoverImgHeight(node.getBoundingClientRect().width * 0.65);
      }
    },
    [windowWidth]
  );

  useEffect(() => {
    if (coverMedia.social_media_type) {
      setCoverMediaType("socialMedia");
    } else {
      setCoverMediaType("");
    }

    if (coverMedia.embed_link) {
      if (coverMedia.file_type == "video") {
        setFileType("video");
      } else if (coverMedia.file_type == "image") {
        setFileType("image");
      } else {
        setFileType("");
      }
    }
  }, [fileType, coverMediaType]);

  return (
    <div style={{ pointerEvents: "none", height: "100%" }}>

      {!fundraiserDetails.is_public && !fileType && !coverMediaType &&
        <div className="relative">
          <div className="absolute top-0 bottom-0 left-0 right-0 d-flex align-items-center justify-content-center white font-size-24 font-size-sm-26">
            <p className="text-center padding-2 font-size-13">
              Private Fundraiser
            </p>
          </div>
          <img
            className={isBorderRadiusTopLeft ? "border-top-left-radius-0" : "border-top-radius-0"}
            width="100%"
            style={{ maxHeight: "100px" }}
            src={dummyPrivateFundraiserCoverImage}
          />
        </div>}

      {fileType == "image" || fileType == "video" ?
        <div style={{ height: "100%" }} >
          <div
            ref={coverImgRef}
            className={`stretch-full background-image ${isBorderRadiusTopLeft ? "border-top-left-radius-0" : "border-top-radius-0"}`}
            style={{
              backgroundImage: `url(${coverMedia.thumbnail_url}`,
              height: "100%",
              maxHeight: coverImgHeight + "px",
            }}>
          </div>
        </div>
        : null}

      {coverMediaType == "socialMedia" && (
        <div style={{ height: "100%" }}>
          {coverMedia.thumbnail_url ?
            <img
              width="100%"
              height="100%"
              className={isBorderRadiusTopLeft ? "border-top-left-radius-0" : "border-top-radius-0"}
              src={`${coverMedia.thumbnail_url}?${Date.now()}`}
            />
            :
            <iframe
              width="100%"
              height="100%"
              className={isBorderRadiusTopLeft ? "border-top-left-radius-0" : "border-top-radius-0"}
              src={`${coverMedia.embed_link}?${Date.now()}`}
              frameBorder="0"
              allowFullScreen="allowFullScreen"
            ></iframe>}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(CoverMediaThumbnail);