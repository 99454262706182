import React from "react";
import { propTypes } from "react-country-flag";
import NumberFormat from 'react-number-format';

const MoneyInputField = ({
  value,
  onChange,
  placeholder,
  ...props
}) => {
  return (
    <NumberFormat
      className="form-control padding-0 padding-left-10 focus-none bg-transparent"
      placeholder={placeholder}
      displayType="input"
      value={value}
      onValueChange={
        (values) => {
          onChange(values.floatValue)
        }
      }
      thousandSeparator={true}
      decimalScale={0}
      {...props}
    />
  );
};

export default MoneyInputField
