import React from "react";
import { connect } from "react-redux";
import { hijackUser } from "../../store/actions/hijack"

const StartHijack = ({ dispatch, ...props }) => {

  const userEmail = props.match.params.email

  const handleHijackUser = (email) => {
    dispatch(hijackUser(email))
  }

  return (
    <div className="container">
      <div>
        <br />
        <br />
        <h2>Start Hijack</h2>
        <br />
        <br />
        <button type="button" onClick={() => {
          handleHijackUser(userEmail)
        }} >Hijack {userEmail}</button>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(StartHijack);
