import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { unfollowFundraiserUpdates } from "../../../../store/actions/fundraiser";

const UnfollowFundraiserUpdates = ({
  user,
  dispatch,
  history
}) => {
  const search = useLocation().search
  const [fundraiserId] = useState(new URLSearchParams(search).get('fundraiser_id'));
  const [email] = useState(new URLSearchParams(search).get('email'));

  useEffect(() => {
    dispatch(unfollowFundraiserUpdates({
      fundraiser_id: fundraiserId,
      email,
    }))
      .then(res => {
        if (res.error) {
          NotificationManager.error(res.error.message, "Error!")
          history.push("/browse-fundraisers")

        } else {
          if (res.success) {
            NotificationManager.success(res.message, "Success!")
            history.push("/browse-fundraisers")
          }
        }
      })
  }, [])

  return (
    <div className="container">

    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(UnfollowFundraiserUpdates);
