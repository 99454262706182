import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import { connect } from "react-redux";
import { getTermsAndConditions } from "../../store/actions/blog";
import moment from "moment";

const TermsAndConditions = ({ dispatch }) => {
  const [termsAndConditions, setTermsAndConditions] = useState({})
  const [error, setError] = useState("")

  useEffect(() => {
    document.title = "Terms and Conditions"

    dispatch(getTermsAndConditions())
      .then(res => {
        if (res.error) {
          setError(res.error.message)
        } else {
          if (res.success) {
            setTermsAndConditions(res.data)
          }
        }
      })
  }, [])

  return (
    <div className="content-container justify-content-center">
      {error ?
        error
        :
        <div>
          <center>
            <h1>
              {termsAndConditions.title}
            </h1>
          </center>
          <br />
          <div>
            Last updated <b>{moment(termsAndConditions.updated_at).format("YYYY-MM-DD")}</b>
          </div>
          <br />
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(termsAndConditions.content) }} />
        </div>
      }
    </div>
  );
};

export default connect()(TermsAndConditions);

