import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { NotificationManager } from 'react-notifications';
import { getConfirmAlert, getCurrencySymbol, refreshPage } from "../../../utilities";
import {
  deleteSavedCard,
  generateTransactionRef,
  saveChargeAuthorization,
} from "../../../store/actions/transaction";
import { getUser } from "../../../store/actions/auth";
import masterCardLogo from "../../images/master_card_logo.png";
import visaCardLogo from "../../images/visa_card_logo.png"

const MyBankCards = ({ user, dispatch, transaction_ref }) => {

  //For Flutterwave
  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: transaction_ref,
    amount: user.currency_data.find(item => item.currency == user.preferred_currency).card_auth_charge,
    currency: user.currency_data.find(item => item.currency == user.preferred_currency).currency,
    payment_options: "card",
    preauthorize: true,
    customer: {
      email: user.email,
      phonenumber: user.phone_number,
      name: `${user.first_name} ${user.last_name}`,
    },
    customizations: {
      title: 'Card Authorization',
      description: 'For card authorization',
      logo: process.env.REACT_APP_POUCH_FUNDING_FAVICON_URL,
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  useEffect(() => {
    // Generate a transaction ref
    dispatch(generateTransactionRef());

    dispatch(getUser());
  }, []);

  return (
    <div>
      {user.saved_bank_cards.length > 0 ? (
        <div>
          {user.saved_bank_cards.map((card, index) => {
            return (
              <div className="row align-items-center" key={index}>
                <div className="col-sm-6 margin-top-10 margin-botton-4">
                  <div className="bg-grad-light-blue-lighter-green-right max-width-320 border-radius-8 padding-10 padding-sm-18">
                    {card.card_type == "MASTERCARD" ? <img width="50px" src={masterCardLogo} /> : null}
                    {card.card_type == "VISA" ? <img width="50px" src={visaCardLogo} /> : null}

                    <div className="d-flex justify-content-between white padding-top-10 padding-bottom-10">
                      <div className="font-size-18 font-size-sm-22">{card.first_6digits.substring(0, 4)}</div>
                      <div className="font-size-18 font-size-sm-22">{card.first_6digits.substring(4, 6)}**</div>
                      <div>
                        <span className="font-size-18 font-size-sm-22">****</span>
                      </div>
                      <div className="d-flex align-items-center font-size-18 font-size-sm-22">
                        {card.last_4digits}
                      </div>
                    </div>
                    {/* <div>
                    <span>Issuer</span>: <span>{card.issuer}</span>
                  </div> */}
                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="font-size-10 line-height-10">
                          Card Holder
                        </div>
                        <div className="font-size-14 font-size-sm-15 white">
                          {user.first_name} {user.last_name}
                        </div>
                      </div>
                      <div>
                        <div className="font-size-10 line-height-10">
                          Expiry Date
                        </div>
                        <div className="font-size-14 font-size-sm-15 white">{card.expiry}</div>
                      </div>
                    </div>
                  </div>
                  {card.is_expired &&
                    <div className="red">
                      This card has expired!
                    </div>
                  }
                </div>
                <div className="col-sm-6 margin-top-20 margin-top-sm-10 margin-botton-4">
                  <button
                    className="btn font-size-15 max-width-250 stretch-full-sm margin-left-0 margin-left-sm-50 bg-white red border-radius-10 border-color-red padding-10"
                    type="button"
                    onClick={() => {
                      getConfirmAlert({
                        title: "Confirm delete!",
                        message: "Are you sure you want to delete this card?",
                        onClickYes: () => {
                          dispatch(deleteSavedCard(card)).then((res) => {
                            if (res.error) {
                              NotificationManager.success(res.error.message, 'Error!')
                            } else {
                              NotificationManager.success(res.message, 'Success!')
                            }
                          });
                        },
                        onClickNo: () => null,
                      })
                    }}
                  >
                    <b>Delete Card</b>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <p>You do not have any saved bank card.</p>
        </div>
      )}

      <div
        className="d-flex align-items-center logo-magenta margin-top-24 margin-bottom-20 cursor-pointer"
        onClick={() => {
          getConfirmAlert({
            title: "Confirm!",
            message: `To verify your bank card, you will be charged ${getCurrencySymbol(config.currency)}${config.amount} 
            which will be refunded immediately. Do you want to continue?`,
            onClickYes: () => {
              handleFlutterPayment({
                callback: (res) => {
                  closePaymentModal(); // this will close the modal programmatically

                  dispatch(generateTransactionRef());

                  if (res.status == "successful") {
                    dispatch(
                      saveChargeAuthorization({
                        transaction_id: res.transaction_id,
                      })
                    ).then((res) => {
                      if (res.error) {
                        NotificationManager.error(res.error.message, 'Error!')
                      } else {
                        if (res.success == true) {
                          NotificationManager.success(res.message, 'Success!')

                          // Refresh the page after 4000 milliseconds to avoid the flutterwave bug of recurring response
                          refreshPage(4000)
                        }
                      }
                    });
                  }
                },
                onClose: () => { },
              });
            },
            onClickNo: () => null,
          });
        }}
      >
        <div className="d-flex align-items-center justify-content-center padding-10 padding-sm-20 fixed-width-30 fixed-height-30 rounded-circle font-size-26 font-size-sm-38 bg-logo-magenta white">
          +
        </div>
        <div className="margin-left-12 font-size-18 font-size-sm-22">Add New Card</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    transaction_ref: state.transaction.transaction_ref,
  };
};

export default connect(mapStateToProps)(MyBankCards);
