import React, { useState, useEffect } from "react";
import { FiberManualRecord, Stop } from "@material-ui/icons";

const VideoCameraModal = ({ handleMakeVideo, handleAcceptVideo }) => {
  const [isPlaybackVideoVisible, setIsPlaybackVideoVisible] = useState(false);
  const [isPreviewVideoVisible, setIsPreviewVideoVisible] = useState(false);
  const [recordingTimeSeconds, setRecordingTimeSeconds] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isStartButtonVisible, setIsStartButtonVisible] = useState(false);
  const [isStopButtonVisible, setIsStopButtonVisible] = useState(false);
  const [
    isUploadDiscardButtonsVisible,
    setIsUploadDiscardButtonsVisible, 
  ] = useState(false);
  const [recordedVideoSrc, setRecordedVideoSrc] = useState(null);

  let getPreviewVideo = () => {
    return document.getElementById("previewVideo");
  };
  let getPlaybackVideo = () => {
    return document.getElementById("playbackVideo");
  };

  const handleTimerReset = () => {
    setRecordingTimeSeconds(0);
    setIsTimerActive(false);
  };

  const formatTime = (timeInSeconds) => {
    const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

    return {
      minutes: result.substr(3, 2),
      seconds: result.substr(6, 2),
    };
  };

  const startRecording = (stream, lengthInMS) => {
    let recorder = new MediaRecorder(stream);
    let data = [];

    recorder.ondataavailable = (event) => data.push(event.data);
    recorder.start();

    setIsTimerActive(true);

    let stopped = new Promise((resolve, reject) => {
      recorder.onstop = resolve;
      recorder.onerror = (event) => reject(event.name);
    });

    let recorded = () => recorder.state == "recording" && recorder.stop();

    return Promise.all([stopped, recorded]).then(() => {
      return data;
    });
  };

  const handleLaunchCamera = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        let previewVideo = getPreviewVideo();
        previewVideo.srcObject = stream;
        previewVideo.captureStream =
          previewVideo.captureStream || previewVideo.mozCaptureStream;

        setIsStartButtonVisible(true);
        return new Promise((resolve) => (previewVideo.onplaying = resolve));
      })
      .catch();
  };

  const handleStartRecording = () => {
    setIsStartButtonVisible(false);

    setIsPreviewVideoVisible(true);

    let previewVideo = getPreviewVideo();

    setIsStopButtonVisible(true);

    startRecording(previewVideo.captureStream(), 0)
      .then((recordedChunks) => {
        let playbackVideo = getPlaybackVideo();
        let recordedBlob = new Blob(recordedChunks, { type: "video/mp4" });
        console.log(recordedBlob)
        playbackVideo.src = URL.createObjectURL(recordedBlob);

        setRecordedVideoSrc(recordedBlob);
      })
      .catch();
  };

  const handleStopRecording = () => {
    setIsStopButtonVisible(false);
    setIsPlaybackVideoVisible(true);

    let previewVideo = getPreviewVideo();
    previewVideo.srcObject.getTracks().forEach((track) => track.stop()); //Stop the camera stream

    setIsPreviewVideoVisible(false);
    setIsUploadDiscardButtonsVisible(true);
  };

  const handleDiscardRecording = () => {
    setIsPlaybackVideoVisible(false);
    handleTimerReset();
    setIsStartButtonVisible(false);
    setIsStopButtonVisible(false);
    setIsUploadDiscardButtonsVisible(false);
    setRecordedVideoSrc(null);

    setIsPreviewVideoVisible(true);

    handleLaunchCamera();
  };

  useEffect(() => {
    let interval = null;
    if (isTimerActive) {
      interval = setInterval(() => {
        setRecordingTimeSeconds(
          (recordingTimeSeconds) => recordingTimeSeconds + 1
        );
      }, 1000);
    } else if (!isTimerActive && recordingTimeSeconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, recordingTimeSeconds]);

  useEffect(() => {
    setIsPreviewVideoVisible(true);
    handleLaunchCamera();
  }, []);

  return (
    <div style={{ position: "relative", backgroundColor: "black" }}>
      {isPlaybackVideoVisible ? (
        <div style={{ maxWidth: "520px" }}>
          <video
            id="playbackVideo"
            width="100%"
            height="auto"
            style={{ marginBottom: "-7px", outline: "none" }}
            controls
          ></video>
        </div>
      ) : null}
      {isPreviewVideoVisible ? (
        <div style={{ maxWidth: "520px" }}>
          <video
            id="previewVideo"
            width="100%"
            height="auto"
            autoPlay
            muted
            style={{ marginBottom: "-7px", outline: "none" }}
          ></video>
          <div
            style={{
              left: "50%",
              top: "10px",
              color: "white",
              transform: "translateX(-50%)",
              position: "absolute",
              display: "table",
            }}
          >
            {isTimerActive ? (
              <div className="flex vertical-align">
                <FiberManualRecord
                  style={{ color: "red" }}
                  onClick={() => handleStartRecording()}
                />
                <span>
                  {formatTime(recordingTimeSeconds).minutes} :
                  {formatTime(recordingTimeSeconds).seconds}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      <div>
        {isStartButtonVisible ? (
          <div
            style={{
              left: "50%",
              bottom: "0px",
              transform: "translateX(-50%)",
              position: "absolute",
            }}
          >
            <FiberManualRecord
              style={{
                borderRadius: "50%",
                fontSize: "45px",
                color: "red",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              }}
              onClick={() => handleStartRecording()}
            />
          </div>
        ) : null}
        {isStopButtonVisible ? (
          <div
            style={{
              left: "50%",
              bottom: "0px",
              transform: "translateX(-50%)",
              position: "absolute",
            }}
          >
            <Stop
              style={{
                borderRadius: "50%",
                fontSize: "45px",
                color: "red",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              }}
              onClick={() => handleStopRecording()}
            />
          </div>
        ) : null}
        <div> 
          {isUploadDiscardButtonsVisible ? (
            <div className="flex">
              <div
                className="flex vertical-align btn"
                style={{ color: "red", marginRight: "30px" }}
                onClick={() => handleDiscardRecording()}
              >
                Discard
              </div>
              <div
                className="flex vertical-align btn"
                onClick={() => handleAcceptVideo(recordedVideoSrc)}
              >
                Upload
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { VideoCameraModal as default };
