import React from "react";
import moment from "moment";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";
import { convertSnakeToStandardCasing } from "../../../../../utilities";

const ViewDonation = ({
  donation
}) => {

  return (
    <div className="logo-magenta">

      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <b className="min-width-110">Date</b>
        <span>{moment(donation.created_at).format("Do MMM YYYY HH:mm a")}</span>
      </div>
      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <b className="min-width-110"> ID</b>
        <span>#{donation.id}</span>
      </div>
      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <b className="min-width-110">Fundraiser ID</b>
        <span>#{donation.fundraiser_id}</span>
      </div>

      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <b className="min-width-110">Transaction ID</b>
        <span>#{donation.transaction_id}</span>
      </div>

      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <b className="min-width-110">Wallet Tx. ID</b>
        <span>#{donation.wallet_tx_id}</span>
      </div>

      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <b className="min-width-110">Status</b>
        <span>{convertSnakeToStandardCasing(donation.status)}</span>
      </div>

      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <b className="min-width-110">Donation</b>
        <span className="normal-green">
          <FormattedMoney
            value={donation.donation}
            currency={donation.donation_currency}
          /> </span>
      </div>

      {/* <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <b className="min-width-110">Settled Amount</b>
        <span className="normal-green">
          <FormattedMoney
            value={donation.settled_amount}
            currency={donation.settled_amount_currency}
          /> </span>
      </div> */}


      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <b className="min-width-110">First Name</b>
        <span>{donation.first_name}</span>
      </div>

      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <b className="min-width-110">Last Name</b>
        <span>{donation.last_name}</span>
      </div>

      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <b className="min-width-110">Email</b>
        <span className="text-break">{donation.email}</span>
      </div>

      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <b className="min-width-110">Comment</b>
        <span>{donation.comment}</span>
      </div>
    </div>
  );
};

export default ViewDonation
