import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { NotificationManager } from "react-notifications";
import {
  base64ToBlob,
  getConfirmAlert,
  generateVideoThumbnail,
  generateImageThumbnail,
} from "../../../../utilities";
import AddOrTakePhotoModal from "./fragments/AddOrTakePhotoModal";
import AddOrMakeVideoModal from "./fragments/AddOrMakeVideoModal";
import PhotoCameraModal from "./fragments/PhotoCameraModal";
import VideoCameraModal from "./fragments/VideoCameraModal";
import AddSocialMediaVideoModal from "./fragments/AddSocialMediaVideoModal";
import ImageEditor from "./fragments/ImageEditor";
import {
  addFundraiserCoverMedia,
  removeFundraiserCoverMedia,
  uploadAdditionalCoverPicture,
} from "../../../../store/actions/fundraiser";
import dummyPrivateFundraiserCoverImage from "./privateFundraiserCover.jpg"
import UploadAdditionalCoverPicture from "./UploadAdditionalCoverPicture";

const UploadCoverMedia = ({
  user,
  history,
  dispatch,
  fundraiserId,
  coverMedia,
  additionalCoverPictures,
  setFundraiser,
  setIsPageLoading,
  setIsCreatingNew,
  isPrivateFundraiser
}) => {
  const [displayedCoverPhoto, setDisplayedCoverPhoto] = useState(false);
  const [editableCoverPhoto, setEditableCoverPhoto] = useState(null);
  const [selectedCoverVideoToUpload, setSelectedCoverVideoToUpload] = useState(
    null
  );
  const [displayedCoverVideo, setDisplayedCoverVideo] = useState(false);
  const [displayedSocialMediaVideo, setDisplayedSocialMediaVideo] = useState(
    false
  );
  const [
    isAskToUploadCoverVideoModalOpen,
    setAskToUploadCoverVideoModalOpen,
  ] = useState(false);
  const [isAddOrTakePhotoModalOpen, setIsAddOrTakePhotoModalOpen] = useState(
    false
  );
  const [isAddOrMakeVideoModalOpen, setIsAddOrMakeVideoModalOpen] = useState(
    false
  );
  const [isPhotoCameraModalVisible, setIsPhotoCameraModalVisible] = useState(
    false
  );
  const [isVideoCameraModalVisible, setIsVideoCameraModalVisible] = useState(
    false
  );
  const [
    isUploadButtonsGroupEnabled,
    setIsUploadButtonsGroupEnabled,
  ] = useState(true);
  const [isImageEditorVisible, setIsImageEditorVisible] = useState(false);
  const [
    isAddSocialMediaVideoModalVisible,
    setIsAddSocialMediaVideoModalVisible,
  ] = useState(false);
  const [socialMediaVideoObj, setSocialMediaVideoObj] = useState({});

  useEffect(() => {
    setIsPageLoading([true, "Loading..."]);
    if (coverMedia) {
      let fileFormat = "";
      if (
        coverMedia.social_media_type == null &&
        coverMedia.file_key &&
        coverMedia.embed_link
      ) {
        setIsUploadButtonsGroupEnabled(false); //To hide group of upload buttons
        const arr = coverMedia.embed_link.split(".")
        const format = arr[arr.length - 1];

        if (
          /png/i.test(format) ||
          /jpeg/i.test(format) ||
          /jpg/i.test(format)
        ) {
          fileFormat = "image";
        } else if (/pdf/i.test(format)) {
          fileFormat = "pdf";
        }

        if (fileFormat == "image") {
          setDisplayedCoverPhoto(true);
        } else {
          setDisplayedCoverVideo(true);
        }
      } else {
        if (coverMedia.file_key == null && coverMedia.embed_link) {
          setDisplayedSocialMediaVideo(true);
          setIsUploadButtonsGroupEnabled(false);
        }
      }
      setIsPageLoading([false]);
    }
  }, [coverMedia]);

  const coverPhotoInput = useRef(null);
  const coverVideoInput = useRef(null);

  const onClickRetakePhoto = () => {
    setIsImageEditorVisible(false);
    setIsAddOrTakePhotoModalOpen(true);
  };

  const onClickTakePhoto = () => {
    setIsAddOrTakePhotoModalOpen(false);
    setIsPhotoCameraModalVisible(true);
  };

  const onClickMakeVideo = () => {
    setIsAddOrMakeVideoModalOpen(false);
    setIsVideoCameraModalVisible(true);
  };

  const onClickUploadPhoto = () => {
    setIsAddOrTakePhotoModalOpen(false);
    coverPhotoInput.current.click();
  };

  const onClickUploadVideo = () => {
    coverVideoInput.current.click();
  };

  const handleTakePhoto = (dataUri) => {
    setIsPhotoCameraModalVisible(false); //To close photo camera aperture
    setEditableCoverPhoto(dataUri); //For the image to be edited
    setDisplayedCoverVideo(false); //To remove any displayed cover video
    setIsImageEditorVisible(true); //Show editable picture preview
  };

  const handleMakeVideo = () => {
    setIsVideoCameraModalVisible(false); //To close video camera aperture
  };

  const handleAcceptVideo = async (videoBlob) => {
    setIsPageLoading([true, "Uploading..."]);
    const fileType = `${videoBlob.type.split("/")[0]}.${videoBlob.type.split("/")[1]
      }`;
    let formData = new FormData(); //formdata object

    formData.append("file", videoBlob, fileType);
    formData.append("file_type", "video");
    formData.append("fundraiser_id", fundraiserId);
    formData.append("is_social_media", false);
    formData.append("embed_link", null);
    formData.append("social_media_type", null);

    dispatch(addFundraiserCoverMedia(formData))
      .then((response) => {
        setIsPageLoading([false]);
        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            setIsVideoCameraModalVisible(false);
            setFundraiser(response.data);
          }
        }
      });
  };

  const handleSaveImage = async (croppedImage) => {
    setIsPageLoading([true, "Uploading..."]);
    const blob = base64ToBlob(croppedImage);
    const fileType = `${blob.type.split("/")[0]}.${blob.type.split("/")[1]}`;

    let thumbnail;

    if (blob.size > 100000) {
      // If larger than 100 KB
      thumbnail = await generateImageThumbnail(croppedImage, 100);
      thumbnail = base64ToBlob(thumbnail);
    } else {
      thumbnail = "";
    }

    let formData = new FormData(); //formdata object
    formData.append("file", blob, fileType);
    formData.append("file_type", "image");
    formData.append("fundraiser_id", fundraiserId);
    formData.append("is_social_media", false);
    formData.append("embed_link", null);
    formData.append("social_media_type", null);
    // formData.append("thumbnail", thumbnail);

    dispatch(addFundraiserCoverMedia(formData))
      .then((response) => {
        setIsPageLoading([false]);
        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            setIsImageEditorVisible(false);
            setFundraiser(response.data);
          }
        }
      });
  };

  const handleSaveSocialMediaVideo = (obj) => {
    setIsPageLoading([true, "Uploading..."]);
    setIsAddSocialMediaVideoModalVisible(false);

    setSocialMediaVideoObj(obj);

    dispatch(
      addFundraiserCoverMedia({
        fundraiser_id: fundraiserId,
        is_social_media: "true",
        embed_link: obj.embed_url,
        thumbnail_url: obj.thumbnail_url,
        social_media_type: obj.social_media_type,
      })
    ).then((response) => {
      setIsPageLoading([false]);

      if (response.error) {
        NotificationManager.error(response.error.message, "Error!")
      } else {
        if (response.success == true) {
          setIsUploadButtonsGroupEnabled(false);
          setFundraiser(response.data);
        }
      }
    });
  };

  const handleUploadAdditionalCoverPicture = (file) => {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("fundraiser_id", fundraiserId)

    dispatch(uploadAdditionalCoverPicture(formData))
      .then(res => {
        if (res.error) {
          NotificationManager.error(res.error.message, "Error!")
        } else {
          if (res.success) {
            setFundraiser(res.data)
            NotificationManager.success(res.message, "Success!")
          }
        }
      })
  }

  const AskToUploadCoverVideo = () => {
    return (
      <div>
        <div>
          Selected file:
          {selectedCoverVideoToUpload ? selectedCoverVideoToUpload.name : null}
        </div>

        <button
          className="button add-or-take-btn"
          style={{ backgroundColor: "red" }}
          onClick={() => {
            setAskToUploadCoverVideoModalOpen(false);
            setSelectedCoverVideoToUpload(null);
          }}
        >
          Cancel
        </button>

        <button
          className="button add-or-take-btn "
          style={{ backgroundColor: "#2b74bc" }}
          onClick={async () => {
            setIsPageLoading([true, "Uploading..."]);

            const thumbnail = await generateVideoThumbnail(
              selectedCoverVideoToUpload
            );

            let formData = new FormData(); //formdata object
            formData.append("file", selectedCoverVideoToUpload);
            formData.append("file_type", "video");
            formData.append("fundraiser_id", fundraiserId);
            formData.append("is_social_media", false);
            formData.append("embed_link", null);
            formData.append("social_media_type", null);
            // formData.append("thumbnail", thumbnail.blob);

            dispatch(addFundraiserCoverMedia(formData))
              .then((response) => {
                setIsPageLoading([false]);
                setEditableCoverPhoto(null);
                setIsUploadButtonsGroupEnabled(false);
                setAskToUploadCoverVideoModalOpen(false);

                if (response.error) {
                  NotificationManager.error(response.error.message, "Error!")
                } else {
                  if (response.success == true) {
                    setFundraiser(response.data);
                  }
                }
              });
          }}
        >
          Upload
        </button>
      </div>
    );
  };


  return (
    <div className="padding-left-10 padding-right-10">

      <div className="padding-left-md-50 padding-right-md-50 padding-bottom-8">
        <h2 className="logo-magenta font-size-20 font-size-sm-21">
          Upload Cover Media
        </h2>
      </div>

      <div className="padding-left-md-50 padding-right-md-50">

        <div className="row upload-container">
          <div className="col-sm-6 upload-container__left">
            {isUploadButtonsGroupEnabled ? (
              <div className="padding-bottom-10">
                {!coverMedia.embed_link && isPrivateFundraiser ?
                  <div className="relative">
                    <div className="absolute top-0 bottom-0 left-0 right-0 d-flex align-items-center justify-content-center white font-size-24 font-size-sm-26">
                      <div>
                        <p className="text-center padding-2">Private Fundraiser</p>
                        <p className="text-center padding-2">By</p>
                        <p className="text-center padding-2">{user.first_name} {user.last_name}</p>
                      </div>
                    </div>
                    <img
                      className="border-radius-6"
                      width="100%"
                      style={{ maxHeight: "300px" }}
                      src={dummyPrivateFundraiserCoverImage}
                    />
                  </div>
                  :
                  <div className="dashed fixed-height-220">
                    <span>Preview Box</span>
                  </div>
                }
              </div>) : null
            }

            {!isUploadButtonsGroupEnabled ? (
              <div >
                {displayedCoverPhoto ? (
                  <div
                    className="d-flex justify-content-between flex-column padding-6 bg-black"
                    style={{ height: "100%" }}
                  >
                    <img
                      width="100%"
                      // height="auto"
                      // height="260px"
                      // src={coverMedia.embed_link}
                      src={`${coverMedia.embed_link}?${Date.now()}`}
                    />
                    <div className="d-flex justify-content-between align-items-center padding-top-4">
                      <div
                        onClick={() => {
                          setIsAddOrTakePhotoModalOpen(true);
                        }}
                        className="font-size-13 white cursor-pointer"
                      >
                        <b>Change</b>
                      </div>
                      <div
                        className="font-size-13 red cursor-pointer"
                        onClick={() => {
                          getConfirmAlert({
                            title: "Confirm Delete Image",
                            message:
                              "Are you sure you want to delete this image?",
                            onClickYes: () => {
                              setIsPageLoading([true, "Deleting..."]);
                              dispatch(
                                removeFundraiserCoverMedia({
                                  fundraiser_id: fundraiserId,
                                })
                              ).then((response) => {
                                setIsPageLoading([false]);
                                if (response.error) {
                                  NotificationManager.error(response.error.message, "Error!")
                                } else {
                                  if (response.success == true) {
                                    setDisplayedCoverPhoto(false);
                                    setIsUploadButtonsGroupEnabled(true);
                                    setFundraiser(response.data);
                                  }
                                }
                              });
                            },
                            onClickNo: () => { },
                          });
                        }}
                      >
                        <b>Delete</b>
                      </div>
                    </div>
                  </div>
                ) : null}

                {displayedCoverVideo ? (
                  <div className="bg-black padding-10 padding-bottom-30">
                    <video
                      width="100%"
                      height="260px"
                      style={{
                        outline: "none",
                      }}
                      // src={coverMedia.embed_link}
                      src={`${coverMedia.embed_link}?${Date.now()}`}
                      controls
                    />

                    <div className="flex">
                      <div
                        className="font-size-13 red margin-right-30 cursor-pointer"
                        onClick={() => {
                          getConfirmAlert({
                            title: "Confirm Delete Video",
                            message:
                              "Are you sure you want to delete this video?",
                            onClickYes: () => {
                              setIsPageLoading([true, "Deleting..."]);
                              dispatch(
                                removeFundraiserCoverMedia({
                                  fundraiser_id: fundraiserId,
                                })
                              ).then((response) => {
                                setIsPageLoading([false]);
                                if (response.error) {
                                  NotificationManager.error(response.error.message, "Error!")
                                } else {
                                  if (response.success == true) {
                                    setDisplayedCoverVideo(false);
                                    setIsUploadButtonsGroupEnabled(true);
                                    setFundraiser(response.data);
                                  }
                                }
                              });
                            },
                            onClickNo: () => { },
                          });
                        }}
                      >
                        Remove
                      </div>
                      <div className="font-size-13 white cursor-pointer"
                        onClick={() => {
                          setIsAddOrMakeVideoModalOpen(true);
                        }}
                      >
                        <b>Change</b>
                      </div>
                    </div>
                  </div>
                ) : null}

                {displayedSocialMediaVideo ? (
                  <div className="bg-black relative padding-6 padding-bottom-45">
                    <iframe
                      // width={socialMediaVideoObj.iframeSize.width}
                      // height={socialMediaVideoObj.iframeSize.height}
                      width="100%"
                      height="260px"
                      // src={coverMedia.embed_link}
                      src={`${coverMedia.embed_link}?${Date.now()}`}
                      frameBorder="0"
                      allowFullScreen="allowFullScreen"
                    ></iframe>

                    <div className="flex background-photo-image">
                      <div className="font-size-13 red margin-right-30 cursor-pointer"
                        onClick={() => {
                          getConfirmAlert({
                            title: "Confirm Delete Video",
                            message:
                              "Are you sure you want to delete this video?",
                            onClickYes: () => {
                              setIsPageLoading([true, "Deleting..."]);
                              dispatch(
                                removeFundraiserCoverMedia({
                                  fundraiser_id: fundraiserId,
                                })
                              ).then((response) => {
                                setIsPageLoading([false]);
                                if (response.error) {
                                  NotificationManager.error(response.error.message, "Error!")
                                } else {
                                  if (response.success == true) {
                                    setDisplayedSocialMediaVideo(false);
                                    setIsUploadButtonsGroupEnabled(true);
                                    setFundraiser(response.data);
                                  }
                                }
                              });
                            },
                            onClickNo: () => { },
                          });
                        }}
                      >
                        <b>Remove</b>
                      </div>
                      <div
                        className="font-size-13 white cursor-pointer"
                        onClick={() => {
                          setIsAddSocialMediaVideoModalVisible(true);
                        }}
                      >
                        <b>Change</b>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            <div>
              {isImageEditorVisible ? (
                <div>
                  <ImageEditor
                    src={editableCoverPhoto}
                    // addFundraiserCoverMedia={addFundraiserCoverMedia}
                    handleSaveImage={handleSaveImage}
                    onClickRetakePhoto={onClickRetakePhoto}
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="col-sm-6 upload-container__right">
            <center>
              <div style={{ maxWidth: "220px" }}>
                <div>
                  <div className="row">
                    <button
                      className="button button--darkblue "
                      disabled={!isUploadButtonsGroupEnabled}
                      onClick={() => {
                        setIsAddOrTakePhotoModalOpen(true);
                      }}
                    >
                      Upload or take a photo
                    </button>
                    <span className="font-size-11">Max. 1MB. JPEG, JPG or PNG</span>
                  </div>
                  <div>
                    <span><b>Or</b></span>
                  </div>
                  <div className="row">
                    <button
                      className="button button--orange"
                      disabled={!isUploadButtonsGroupEnabled}
                      onClick={() => {
                        setIsAddOrMakeVideoModalOpen(true);
                      }}
                    >
                      Make or add a video
                    </button>
                    <span className="font-size-11">Max. 25MB. MP4, MOV or AVI</span>
                  </div>
                  <div>
                    <span><b>Or</b></span>
                  </div>
                  <div className="row">
                    <button
                      className="button button--darkgreen"
                      disabled={!isUploadButtonsGroupEnabled}
                      onClick={() => {
                        setIsAddSocialMediaVideoModalVisible(true);
                      }}
                    >
                      Add a social media video
                    </button>
                    <span className="font-size-11">YouTube, Facebook or Instagram</span>
                  </div>
                </div>
                {/* ) : null} */}

                <div>
                  <input
                    hidden
                    ref={coverPhotoInput}
                    name="coverMedia"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      if (file) {
                        if (file.size > 10000000) {
                          //Maximum of 10MB
                          alert("File too large. Max. 10MB");
                        } else {
                          let reader = new FileReader();
                          reader.onloadend = () => {
                            setEditableCoverPhoto(reader.result);
                            setIsImageEditorVisible(true);
                          };
                          reader.readAsDataURL(file);
                        }
                      }
                    }}
                  />
                  <input
                    hidden
                    ref={coverVideoInput}
                    name="coverMedia"
                    type="file"
                    accept="video/*"
                    onChange={async (e) => {
                      const file = e.currentTarget.files[0];
                      if (file) {
                        if (file.size > 25000000) {
                          //Maximum of 25MB
                          alert("File too large. Max. 25MB. Compress and try to upload again");
                        } else {
                          setSelectedCoverVideoToUpload(file);
                          setIsAddOrMakeVideoModalOpen(false);
                          setAskToUploadCoverVideoModalOpen(true);
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </center>
          </div>
        </div>

        <div>
          <h2 className="padding-top-20">
            Additional Cover Pictures
          </h2>

          <UploadAdditionalCoverPicture
            fundraiserId={fundraiserId}
            additionalCoverPictures={additionalCoverPictures}
            setFundraiser={setFundraiser}
            setIsPageLoading={setIsPageLoading} />
        </div>
      </div>

      <div className="horizontal-line margin-top-20 margin-bottom-30" />

      <div className="d-flex justify-content-end margin-bottom-30">
        <button
          className="btn font-size-15 font-size-md-18 margin-right-30 margin-right-sm-50 max-width-160 padding-10 padding-md-16 border-color-logo-magenta logo-magenta border-radius-12"
          onClick={() => {
            setIsCreatingNew(false);
            history.push(`/dashboard/fundraiser/edit/${fundraiserId}?activeSlide=editFundraiserDescription`)
          }}
        >
          <b>Previous</b>
        </button>
        <button
          className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-logo-magenta white border-radius-12"
          type="submit"
          onClick={() => {
            if (
              displayedCoverPhoto ||
              displayedCoverVideo ||
              coverMedia.embed_link ||
              isPrivateFundraiser
            ) {
              history.push(`/dashboard/fundraiser/edit/${fundraiserId}?activeSlide=supportingDocs`)
            } else {
              alert("Upload a cover media");
            }
          }}
        >
          <b>Next</b>
        </button>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isAddOrTakePhotoModalOpen}
        onRequestClose={() => setIsAddOrTakePhotoModalOpen(false)}
        className="modal"
        overlayClassName="modal-backdrop"
      >
        <AddOrTakePhotoModal
          onClickTakePhoto={onClickTakePhoto}
          onClickUploadPhoto={onClickUploadPhoto}
        />
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isAddOrMakeVideoModalOpen}
        onRequestClose={() => setIsAddOrMakeVideoModalOpen(false)}
        className="modal"
        overlayClassName="modal-backdrop"
      >
        <AddOrMakeVideoModal
          onClickMakeVideo={onClickMakeVideo}
          onClickUploadVideo={onClickUploadVideo}
        />
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isAskToUploadCoverVideoModalOpen}
        onRequestClose={() => setAskToUploadCoverVideoModalOpen(false)}
        className="modal"
        overlayClassName="modal-backdrop"
      >
        <AskToUploadCoverVideo />
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isPhotoCameraModalVisible}
        onRequestClose={() => setIsPhotoCameraModalVisible(false)}
        className="modal black-background"
        overlayClassName="modal-backdrop"
        contentLabel="Take a picture"
      >
        <PhotoCameraModal handleTakePhoto={handleTakePhoto} />
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isVideoCameraModalVisible}
        onRequestClose={() => setIsVideoCameraModalVisible(false)}
        className="modal black-background"
        overlayClassName="modal-backdrop"
        contentLabel="Make a video"
      >
        <VideoCameraModal
          handleMakeVideo={handleMakeVideo}
          handleAcceptVideo={handleAcceptVideo}
        />
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isAddSocialMediaVideoModalVisible}
        onRequestClose={() => setIsAddSocialMediaVideoModalVisible(false)}
        className="modal min-width"
        overlayClassName="modal-backdrop"
      >
        <AddSocialMediaVideoModal
          handleSaveSocialMediaVideo={handleSaveSocialMediaVideo}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(UploadCoverMedia);
