import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { NotificationManager } from 'react-notifications';
import { p2pCreditTransfer } from "../../../store/actions/pouchWallet";
import {
  sendTransactionOtp,
} from "../../../store/actions/transaction";
import { getCurrencySymbol, isEnoughMoneyInWallet, numberWithCommas } from "../../../utilities";
import MoneyInputField from "../../utilityComponents/MoneyInputField"

const TransferCredit = ({
  balances,
  setIsPageLoading,
  dispatch,
  handleClose,
  setSpecificTransaction
}) => {
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    const currencyObjects = balances.map((balance) => {
      return {
        label: `${balance.amount_currency} (balance: ${getCurrencySymbol(
          balance.amount_currency
        )}${numberWithCommas(balance.available_balance)})`,
        value: balance.amount_currency,
      };
    });

    setCurrencies(currencyObjects);
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          amount: null,
          currency: "",
          recipient_email: "",
          otp: ""
        }}
        validationSchema={Yup.object({
          amount: Yup.number()
            .typeError("Amount must be a number")
            .positive("Amount must be a positive number")
            .integer("Amount must be a whole number")
            .required("Amount is required")
            .nullable(),
          currency: Yup.string().required("Currency is required"),
          recipient_email: Yup.string()
            .required("Recipient email is required")
            .email("Must be an email"),
          otp: Yup.string()
            .min(6, "OTP must be 6 digits")
            .max(6, "OTP must be 6 digits")
            .required("OTP is required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setIsPageLoading([true, "Loading..."]);

          dispatch(p2pCreditTransfer(values)).then((res) => {
            setIsPageLoading([false]);

            if (res.error) {
              NotificationManager.error(res.error.message, 'Error Message!')
            } else {
              if (res.success == true) {
                NotificationManager.success(res.message, 'Success Message!')
                setSpecificTransaction(res.data)
                handleClose();
              }
            }
          });
        }}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <Form>
            <div className="padding-bottom-12">
              <label className="form-label font-size-15">
                Currency Wallet
              </label>
              <Field
                component={() => (
                  <Select
                    className="form-control padding-0 border-color-logo-magenta"
                    classNamePrefix="react-select"
                    placeholder="Select currency"
                    options={currencies}
                    value={
                      currencies
                        ? currencies.find(
                          (option) => option.value === values.currency
                        )
                        : ""
                    }
                    onChange={(option) => {
                      setFieldValue("currency", option.value);
                    }}
                  />
                )}
              />
            </div>

            <div className="padding-top-12 padding-bottom-12">
              <label className="form-label font-size-15">Amount</label>
              <div className="form-control d-flex padding-0 border-color-logo-magenta fixed-height-40">
                <div className="d-flex align-items-center justify-content-center max-width-80 bg-logo-magenta white border-top-left-radius-8 border-bottom-left-radius-8">
                  {getCurrencySymbol(values.currency) ? (
                    <span>{getCurrencySymbol(values.currency)}</span>
                  ) : (
                    <span>- - -</span>
                  )}
                </div>

                <MoneyInputField
                  onChange={(amount) => {
                    setFieldValue("amount", amount)
                  }}
                  placeholder="Enter Amount"
                />
              </div>
              <ErrorMessage
                className="error-message"
                name="amount"
                component="div"
              />
              <span
                className="error-message">
                {(values.amount && isEnoughMoneyInWallet(values.amount, values.currency) == false) && "Insufficient fund"}
              </span>
            </div>

            <div className="padding-top-12 padding-bottom-12">
              <label className="form-label font-size-15">Recipient Email</label>
              <Field
                className="form-control padding-top-10 padding-bottom-10 border-color-logo-magenta"
                placeholder="Enter the email of the recipient"
                name="recipient_email"
              />
              <ErrorMessage
                className="error-message"
                name="recipient_email"
                component="div"
              />
            </div>

            <div className="padding-top-12 padding-bottom-12">
              <label className="form-label font-size-15">One Time Password</label>
              <button
                className="btn margin-top-6 margin-bottom-16 padding-10 bg-fundraiser-color white max-width-160 font-size-15 border-radius-8"
                type="button"
                onClick={() => {
                  setFieldValue("otp", "")
                  setIsPageLoading([true, "Sending OTP..."])
                  dispatch(sendTransactionOtp())
                    .then(res => {
                      setIsPageLoading([false]);
                      if (res.error) {
                        NotificationManager.error(res.error.message, 'Error!')
                      } else {
                        NotificationManager.success(res.message, 'Success!')
                      }
                    })
                }}>Generate OTP</button>
              <Field
                className="form-control padding-top-10 padding-bottom-10 border-color-logo-magenta"
                placeholder="Enter one time password"
                name="otp"
              />
              <ErrorMessage
                className="error-message"
                name="otp"
                component="div"
              />
            </div>

            <div className="d-flex justify-content-end">
              <button
                className="btn margin-top-24 margin-bottom-24 padding-12 bg-logo-magenta white max-width-250 font-size-17 border-radius-8"
                type="button"
                onClick={handleSubmit}
                disabled={!isEnoughMoneyInWallet(values.amount, values.currency)}
              >
                <b>Submit</b>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(TransferCredit);
