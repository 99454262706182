import React from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { submitNGO } from "../../../../store/actions/charity_ngo";
import CharityNGOOverview from "./CharityNGOOverview";

const CharityNGOPreview = ({
  dispatch,
  history,
  charityNgo,
  setCharityNgo,
  setVisibleCharityNgoSetupSlides,
  handleUploadNgoDocument
}) => {
  return (
    <div className="padding-left-10 padding-right-10 border-radius-0">
      <div className="padding-bottom-8">
        <h2 className="logo-magenta font-size-20 font-size-sm-21">PREVIEW</h2>
      </div>

      <div className="horizontal-line " />

      <CharityNGOOverview
        charityNgo={charityNgo}
        setCharityNgo={setCharityNgo}
        handleUploadNgoDocument={handleUploadNgoDocument}
      />

      <div className="horizontal-line margin-top-20 margin-bottom-30" />

      <div className="d-flex justify-content-end margin-bottom-30">
        <button
          className="btn font-size-15 font-size-md-18 margin-right-30 margin-right-sm-50 max-width-160 padding-10 padding-md-16 border-color-logo-magenta logo-magenta border-radius-12"
          onClick={() => {
            setVisibleCharityNgoSetupSlides("BANK_ACCOUNTS")
          }}
        >
          <b>Previous</b>
        </button>
        <button
          className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-logo-magenta white border-radius-12"
          type="submit"
          onClick={() => {
            dispatch(submitNGO(charityNgo.id))
              .then((res) => {
                if (res.error) {
                  NotificationManager.error(res.error.message, "Error!")
                } else {
                  if (res.success) {
                    NotificationManager.success(res.message, "Success!")
                    history.push("/dashboard/account")
                  }
                }
              })
          }}
        >
          <b>Submit</b>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(CharityNGOPreview);
