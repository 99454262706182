import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import BeneficiaryBankDetails from "./fragments/BeneficiaryBankDetails";
import SelectSavedBankAccount from "../../../payments/SelectSavedBankAccount";
import VerifyBankAccount from "../../../payments/VerifyBankAccount";
import ManagerBankDetailsTable from "./fragments/ManagerBankDetailsTable";
import BeneficiaryBankDetailsTable from "./fragments/BeneficiaryBankDetailsTable";
import { editFundraiserDetails } from "../../../../store/actions/fundraiser";
import { saveBankAccountDetails } from "../../../../store/actions/transaction";
import {
  addThirdPartyBeneficiary,
  removeThirdPartyBeneficiary,
} from "../../../../store/actions/fundraiser";
import { NotificationManager } from "react-notifications";

const PaymentDetails = ({
  history,
  dispatch,
  user,
  fundraiserId,
  fundraiser_details,
  fundraiser,
  thirdPartyBeneficiary,
  setFundraiser,
  setIsPageLoading,
}) => {
  const [
    isBeneficiaryBankDetailsModal,
    setIsBeneficiaryBankDetailsModal,
  ] = useState(false);
  const [isVerifyBankVisible, setIsVerifyBankVisible] = useState(false);
  const [isCreateNewBankDetails, setCreateNewBankDetails] = useState(true);
  const [isSelectSavedBankVisible, setIsSelectSavedBankVisible] = useState(
    false
  );

  const handleAddYourBankDetails = () => {
    if (user.saved_bank_accounts.length) {
      setIsSelectSavedBankVisible(true);
    } else {
      setIsVerifyBankVisible(true);
    }
  };

  const handleSaveBankDetails = (verifiedBankDetails) => {
    setIsPageLoading([true, "Processing..."]);

    if (thirdPartyBeneficiary.id) {
      dispatch(removeThirdPartyBeneficiary(thirdPartyBeneficiary)).then(
        (response) => {
          if (response.error) {
            NotificationManager.error(response.error.message, "Error!")
          } else {
            if (response.success == true) {
              dispatch(saveBankAccountDetails(verifiedBankDetails)).then(
                (res) => {
                  if (res.errorMessage) {
                  } else {
                    const fundraiserDetails = {
                      ...fundraiser_details,
                      bank_account_detail_id: res.data.id,
                    };
                    dispatch(editFundraiserDetails(fundraiserDetails)).then(
                      (response) => {
                        setIsPageLoading([false]);
                        if (response.error) {
                          NotificationManager.error(response.error.message, "Error!")
                        } else {
                          if (response.success == true) {
                            setFundraiser(response.data);
                            setIsVerifyBankVisible(false);
                          }
                        }
                      }
                    );
                  }
                }
              );
            }
          }
        }
      );
    } else {
      dispatch(saveBankAccountDetails(verifiedBankDetails))
        .then((response) => {
          if (response.error) {
          } else {
            if (response.success == true) {
              const fundraiserDetails = {
                ...fundraiser_details,
                bank_account_detail_id: response.data.id,
              };
              //Save the fundraiser
              dispatch(editFundraiserDetails(fundraiserDetails))
                .then(
                  (response) => {
                    setIsPageLoading([false]);
                    if (response.error) {
                      NotificationManager.error(response.error.message, "Error!")
                    } else {
                      if (response.success == true) {
                        setFundraiser(response.data);
                        setIsVerifyBankVisible(false);
                      }
                    }
                  }
                );
            }
          }
        });
    }
  };

  return (
    <div className="padding-left-10 padding-right-10 border-radius-8">

      {thirdPartyBeneficiary && (
        <div>
          <Formik
            initialValues={{ is_for_someone_else: thirdPartyBeneficiary && thirdPartyBeneficiary.id ? true : false }}
            onSubmit={(values, { setSubmitting }) => {
              if (
                values.is_for_someone_else ||
                fundraiser.bank_account_details.id
              ) {
                history.push(`/dashboard/fundraiser/edit/${fundraiserId}?activeSlide=fundraiserSummary`)
              } else {
                alert("Add bank details");
              }
              setSubmitting(false);
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              values,
            }) => (
              <Form>
                <div className="padding-left-md-50 padding-right-md-50 padding-bottom-8">
                  <h2 className="logo-magenta font-size-20 font-size-sm-21">Payment Setup</h2>
                </div>

                <div className="padding-left-md-50 padding-right-md-50">
                  <div className="d-flex margin-bottom-10">
                    <label className="form-control-label">
                      Is it for someone else?
                    </label>
                    &nbsp;
                    <label>
                      <Field
                        className="radio-btn"
                        type="radio"
                        checked={values.is_for_someone_else}
                        onChange={() => {
                          setFieldValue("is_for_someone_else", true);
                          if (
                            !thirdPartyBeneficiary.first_name &&
                            !thirdPartyBeneficiary.bank_name
                          ) {
                            setIsBeneficiaryBankDetailsModal(true);
                            setCreateNewBankDetails(true);
                          }
                        }}
                        name="is_for_someone_else"
                      />
                      <b>Yes</b> &nbsp;&nbsp;
                    </label>
                    <label>
                      <Field
                        className="radio-btn"
                        type="radio"
                        checked={!values.is_for_someone_else}
                        onChange={() => {
                          setFieldValue("is_for_someone_else", false);
                        }}
                        name="is_for_someone_else"
                      />
                      <b>No</b>
                    </label>
                  </div>

                  {!values.is_for_someone_else && (
                    <div>
                      {!fundraiser_details.bank_account_detail_id ? (
                        <div
                          className="btn bg-logo-magenta white max-width-170 padding-6 border-radius-8"
                          onClick={() => {
                            handleAddYourBankDetails();
                          }}
                        >
                          Add your bank details
                        </div>
                      ) : (
                        <div>
                          <ManagerBankDetailsTable
                            setIsPageLoading={setIsPageLoading}
                            fundraiser={fundraiser}
                            setFundraiser={setFundraiser}
                            editFundraiserDetails={editFundraiserDetails}
                            setIsSelectSavedBankVisible={
                              setIsSelectSavedBankVisible
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {values.is_for_someone_else &&
                    thirdPartyBeneficiary.full_name &&
                    thirdPartyBeneficiary.bank_name ? (
                    <div>
                      <BeneficiaryBankDetailsTable
                        fundraiser_id={fundraiserId}
                        setIsPageLoading={setIsPageLoading}
                        setCreateNewBankDetails={setCreateNewBankDetails}
                        setIsBeneficiaryBankDetailsModal={
                          setIsBeneficiaryBankDetailsModal
                        }
                        thirdPartyBeneficiary={thirdPartyBeneficiary}
                        removeThirdPartyBeneficiary={removeThirdPartyBeneficiary}
                        setFundraiser={setFundraiser}
                        setIsForSomeoneElse={() => {
                          setFieldValue("is_for_someone_else", false);
                        }}
                      />
                    </div>
                  ) : null}

                </div>

                <div className="horizontal-line margin-top-20 margin-bottom-30" />

                <div className="d-flex justify-content-end margin-bottom-30">
                  <button
                    className="btn font-size-15 font-size-md-18 margin-right-30 margin-right-sm-50 max-width-160 padding-10 padding-md-16 border-color-logo-magenta logo-magenta border-radius-12"
                    onClick={() => {
                      history.push(`/dashboard/fundraiser/edit/${fundraiserId}?activeSlide=supportingDocs`)
                    }}
                  >
                    <b>Previous</b>
                  </button>
                  <button
                    className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-logo-magenta white border-radius-12"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    <b>Next</b>
                  </button>
                </div>

                <Modal
                  ariaHideApp={false}
                  isOpen={isVerifyBankVisible}
                  onRequestClose={() => {
                    setIsVerifyBankVisible(false);
                  }}
                  className="modal max-width-650 padding-0"
                  overlayClassName="modal-backdrop"
                >
                  <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
                    <h1 className="font-size-20 font-size-md-22 logo-magenta">
                      {/* Select a saved Bank Account */}
                    </h1>
                    <i
                      onClick={() => setIsVerifyBankVisible(false)}
                      className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
                    ></i>
                  </div>
                  <div className="horizontal-line margin-0" />
                  <div className="padding-8">
                    <div
                      style={{ maxHeight: "80vh" }}
                      className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
                    >
                      <VerifyBankAccount
                        setIsPageLoading={setIsPageLoading}
                        handleOnAccountVerification={(verifiedBank) =>
                          handleSaveBankDetails(verifiedBank)
                        }
                      />
                    </div>
                  </div>
                </Modal>

                <Modal
                  ariaHideApp={false}
                  isOpen={isBeneficiaryBankDetailsModal}
                  onRequestClose={() => {
                    setIsBeneficiaryBankDetailsModal(false);
                    if (
                      !thirdPartyBeneficiary.first_name &&
                      !thirdPartyBeneficiary.bank_name
                    ) {
                      setFieldValue("is_for_someone_else", false);
                    }
                  }}
                  className="modal max-width-650 padding-0"
                  overlayClassName="modal-backdrop"
                >
                  <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
                    <h1 className="font-size-20 font-size-md-22 logo-magenta">
                      Third-Party Beneficiary Information
                    </h1>
                    <i
                      onClick={() => setIsBeneficiaryBankDetailsModal(false)}
                      className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
                    ></i>
                  </div>

                  <div className="horizontal-line margin-0" />

                  <div className="padding-8">
                    <div
                      style={{ maxHeight: "80vh" }}
                      className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
                    >
                      <BeneficiaryBankDetails
                        fundraiser_id={fundraiserId}
                        setIsPageLoading={setIsPageLoading}
                        isCreateNewBankDetails={isCreateNewBankDetails}
                        setCreateNewBankDetails={setCreateNewBankDetails}
                        setIsBeneficiaryBankDetailsModal={
                          setIsBeneficiaryBankDetailsModal
                        }
                        setIsVerifyBankVisible={setIsVerifyBankVisible}
                        fundraiser_details={fundraiser_details}
                        thirdPartyBeneficiary={thirdPartyBeneficiary}
                        setFundraiser={setFundraiser}
                        editFundraiserDetails={editFundraiserDetails}
                        addThirdPartyBeneficiary={addThirdPartyBeneficiary}
                      />
                    </div>
                  </div>
                </Modal>

                <Modal
                  ariaHideApp={false}
                  isOpen={isSelectSavedBankVisible}
                  onRequestClose={() => {
                    setIsSelectSavedBankVisible(false);
                  }}
                  className="modal max-width-550 padding-0"
                  overlayClassName="modal-backdrop"
                >
                  <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
                    <h1 className="font-size-18 font-size-sm-22 logo-magenta">
                      Select a saved Bank Account
                    </h1>
                    <i
                      onClick={() => setIsSelectSavedBankVisible(false)}
                      className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
                    ></i>
                  </div>

                  <div className="horizontal-line margin-0" />

                  <div className="padding-8">
                    <div
                      style={{ maxHeight: "80vh" }}
                      className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
                    >
                      <SelectSavedBankAccount
                        handleSelectSavedBank={(bankDetails) => {
                          handleSaveBankDetails({
                            ...bankDetails,
                            account_bank: bankDetails.bank_code,
                          });
                        }}
                        handleAddNewBank={() => setIsVerifyBankVisible(true)}
                        handleClose={() => setIsSelectSavedBankVisible(false)}
                      />
                    </div>
                  </div>
                </Modal>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(PaymentDetails);
