import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useSpring, animated } from 'react-spring'
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from "react-modal";
import { signOut } from '../store/actions/auth';
import logo from "./images/logo.png";
import defaultAvatar from "./images/defaultAvatar.png";
import UseOnClickOutside from "./UseOnClickOutside";
import MyNotifications from "../components/tableTemplates/MyNotifications";
import CurrentWindowWidth from "./CurrentWindowWidth";
import { getConfirmAlert } from '../utilities';

const Header = ({ socketUrl, signOutUser, user, isAuthenticated }) => {
  const [isBackgroundColor, setBackgroundColor] = useState(false);
  const [isNotificationDesktop, setIsNotificationDesktop] = useState(false);
  const [isAccountDropDown, setIsAccountDropDown] = useState(false);
  const [isServicesDropDownDesktop, setIsServicesDropDownDesktop] = useState(false);
  const [isNotificationMobile, setIsNotificationMobile] = useState(false);
  const [isNotificationsVisible, setIsNotificationsVisible] = useState(false);
  const [isServicesDropDownMobile, setIsServicesDropDownMobile] = useState(false);
  const [recentNotificationObj, setRecentNotificationObj] = useState(null);
  const [notificationLastSeen, setNotificationLastSeen] = useState(null);
  const [noOfUnseenNotifications, setNoOfUnseenNotifications] = useState(0);
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [accountDropDnPosition, setAccountDropDnPosition] = useState(0);
  const [notificationDropDnPosition, setNotificationDropDnPosition] = useState(0);

  const pathName = window.location.pathname

  let windowWidth = CurrentWindowWidth();

  const getAccountDropDnPositionRef = useCallback(
    (node) => {
      if (node !== null) {
        setAccountDropDnPosition(node.getBoundingClientRect().left + 25);
      }
    },
    [windowWidth, accountDropDnPosition]
  );

  const getBellIconPositionRef = useCallback(
    (node) => {
      if (node !== null) {
        setNotificationDropDnPosition(node.getBoundingClientRect().left + 2);
      }
    },
    [windowWidth, notificationDropDnPosition]
  );

  const accountDropDownRef = useRef();
  UseOnClickOutside(accountDropDownRef, () => setIsAccountDropDown(false));

  const servicesClickRef = useRef();
  UseOnClickOutside(servicesClickRef, () => setIsServicesDropDownDesktop(false));

  const notificationDesktopClickRef = useRef();
  UseOnClickOutside(notificationDesktopClickRef, () => setIsNotificationDesktop(false));

  const mobileClickRef = useRef();
  UseOnClickOutside(mobileClickRef, () => {
    setIsMobileMenu(false);
    setIsServicesDropDownMobile(false);
    setIsNotificationMobile(false);
  });

  const mobileNotificationClickRef = useRef();
  UseOnClickOutside(mobileNotificationClickRef, () => setIsNotificationMobile(false));

  const slideStyles = useSpring({
    from: { x: 500 },
    config: { duration: 800 },
    loop: { x: 0 }
  })

  const notificationSocketRef = useRef();

  const initializeNotificationSockets = (subType, limit) => {

    notificationSocketRef.current = new WebSocket(`${socketUrl}/ws/notification/header/${subType}/connect`)

    notificationSocketRef.current.onopen = e => {
      notificationSocketRef.current.send(JSON.stringify({
        type: "notification_obj",
        limit,
        user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
      }))

      notificationSocketRef.current.send(JSON.stringify({
        type: "no_of_unseen_notifications",
        user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
      }))
    }

    notificationSocketRef.current.onmessage = e => {
      // Listens to the notification socket
      const data = JSON.parse(e.data)

      if (data.type == "notification_obj") {
        if (data.notification_obj) {
          setRecentNotificationObj(data.notification_obj)
        }
      }
      if (data.type == "notification_last_seen") {
        if (data.notification_last_seen) {

          setNotificationLastSeen(data.notification_last_seen)

          // Resets the no of unseen notifications
          handleSetNoOfUnseenNotifications()
        }
      }

      if (data.type == "notification_last_seen") {
        if (data.notification_last_seen) {
          setNoOfUnseenNotifications(data.notification_last_seen)
        }
      }
    }

    notificationSocketRef.current.onerror = e => {
      console.log('error', e)
    }
  }

  const handleGetNotifications = () => {
    notificationSocketRef.current.send(
      JSON.stringify({
        type: "notification_obj",
        limit: parseInt(recentNotificationObj.limit) + 10
      })
    )
  }

  const handleUpdateNotificationLastSeen = () => {
    notificationSocketRef.current.send(JSON.stringify({
      type: "notification_last_seen"
    }))
  }

  const handleSetNoOfUnseenNotifications = () => {

    if (recentNotificationObj &&
      recentNotificationObj.notifications &&
      recentNotificationObj.notifications.length > 0) {

      const notificationsNotYetSeen = recentNotificationObj.notifications.filter(
        item => item.created_at > notificationLastSeen)
      setNoOfUnseenNotifications(notificationsNotYetSeen.length)
    }
  }

  useEffect(() => {
    if (user) {
      initializeNotificationSockets("initial", 5)
    }
  }, [])

  const handleScroll = () => {
    // Get the current scroll position
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
    if (currentScrollPosition < 0) {
      return;
    }
    if (currentScrollPosition < 80) {
      setBackgroundColor(false);
    } else {
      setBackgroundColor(true);
    }
  };

  useEffect(() => {
    if (pathName == "/") {
      // Home page
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setBackgroundColor(true)
    }
  }, [pathName]);



  const Notifications = ({

  }) => {
    return (
      <div>
        {recentNotificationObj && recentNotificationObj.notifications && recentNotificationObj.notifications.length > 0 ?
          <div style={{ maxHeight: "70vh" }} className="overflow-scroll-y padding-left-10 padding-right-10 margin-top-0 margin-bottom-6">
            {/* 
            {recentNotificationObj.notifications.map((item, index) => {
              return <div
                key={index}
                className="padding-top-6 padding-bottom-6"
              >
                <div className={`d-flex align-items-center hover-bg-light-grey border-1 border-radius-8 border-color-grey padding-left-10 padding-right-10 padding-top-4 padding-bottom-4 cursor-pointer
  ${true ? "bg-pale-navy" : "bg-transparent"}`}>
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={moneyPoolAvatar}
                      className="rounded-circle"
                      width={38}
                      height={38} />
                  </div>
                  <div className="padding-left-10">
                    <div className="logo-magenta font-size-14 line-height-16">You have pending pool inviation</div>
                    <div className="font-size-11 orange-red"> 1 days ago</div>
                  </div>
                </div>
              </div>
            })} */}

            <MyNotifications recentNotifications={recentNotificationObj.notifications} />

            {recentNotificationObj.total_count > recentNotificationObj.notifications.length ?
              <div className="padding-top-6 padding-bottom-6 text-center border-top-1 border-top-color-lighter-blue">
                <span
                  className="font-size-13 normal-blue hover-logo-magenta cursor-pointer"
                  onClick={() => {
                    handleGetNotifications()
                  }}
                >See more</span>
              </div> : null}
          </div> :
          <div className="padding-left-10">
            You do not have any notifications
          </div>}
      </div>
    )
  }

  return (
    <header className={`header ${isBackgroundColor ? 'bg-white' : 'bg-transparent'}`}>
      <div className="padding-top-0 padding-top-sm-10 padding-bottom-0 padding-bottom-sm-10  padding-left-0 padding-right-4 padding-left-md-30 padding-left-lg-80 padding-right-0 padding-right-md-30 padding-right-lg-80">
        <div className="header__content padding-top-10 padding-top-lg-4 padding-bottom-10 padding-bottom-lg-4">

          <div className="d-flex justify-content-center stretch-full-lg">
            <NavLink to="/"><img className="d-block fixed-width-140" src={logo} /></NavLink>
          </div>
          {/* 
          {isAuthenticated ?
            <img
              onClick={() => setIsMobileMenu(true)}
              width="45px"
              height="45px"
              className="d-block-display-lg rounded-circle cursor-pointer border-1 border-color-lighter-grey"
              src={`${user.profile_picture ? user.profile_picture : defaultAvatar
                }?${Date.now()}`}
            />
            :
            <i className="fa fa-list font-size-22 padding-top-10 padding-bottom-10 "
              onClick={() => setIsMobileMenu(true)}></i>} */}

          <div className='d-block-display-lg'>
            {isAuthenticated ?
              <img
                onClick={() => setIsMobileMenu(true)}
                width="35px"
                height="35px"
                className="d-block-display-lg rounded-circle cursor-pointer border-color-logo-magenta border-1"
                src={`${user.profile_picture ? user.profile_picture : defaultAvatar
                  }?${Date.now()}`}
              />
              :
              <i className="fa fa-list logo-magenta font-size-22 padding-top-10 padding-bottom-10 padding-right-10"
                onClick={() => setIsMobileMenu(true)}></i>}
          </div>

          {isMobileMenu ?
            <div className="d-block-display-lg border-1 border-color-lighter-grey fixed bg-back-drop-color left-0 right-0 top-0 bottom-0">
              <animated.div
                ref={mobileClickRef}
                style={{ ...slideStyles }}
                className="d-flex flex-column max-width-220 absolute top-0 right-0 bg-white padding-bottom-20 border-bottom-left-radius-0">

                <div className="padding-12 padding-left-24 padding-right-24">
                  <i
                    onClick={() => {
                      setIsMobileMenu(false);
                      setIsServicesDropDownMobile(false);
                    }}
                    className="fa fa-close d-inline-block orange-red hover-dodgerblue font-size-22 cursor-pointer">
                  </i>
                </div>

                {isAuthenticated ?
                  <NavLink to="/dashboard/account"
                    onClick={() => setIsMobileMenu(false)}
                    className="bg-upkeep-stipends-light-color hover-bg-light-grey hover-dodgerblue logo-magenta border-top-1 border-top-color-light-grey border-bottom-1 border-bottom-color-light-grey text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta"
                  >
                    <div className="d-flex align-items-center">
                      {user && user.profile_picture ? <img className="fixed-width-30 fixed-height-30 rounded-circle border-color-logo-magenta border-1"
                        src={`${user.profile_picture}?${Date.now()}`}
                      />
                        :
                        <i className="fa fa-user-circle grey font-size-30"></i>}

                      <div className="margin-left-12">Account Settings</div>
                    </div>
                  </NavLink>
                  : null}

                {isAuthenticated ?
                  <NavLink to="/dashboard/home"
                    onClick={() => setIsMobileMenu(false)}
                    className="hover-dodgerblue logo-magenta border-top-1 border-top-color-light-grey text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta"
                  >
                    <div className="flex-grow-1">Dashboard Home</div>
                  </NavLink>
                  : null}

                {isAuthenticated ?
                  <div>
                    <div
                      onClick={() => {
                        setIsNotificationsVisible(true)
                        handleUpdateNotificationLastSeen();
                        setIsNotificationMobile(true);
                      }}
                      className="d-flex align-items-center padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey cursor-pointer">
                      <div className="flex-grow-1">Notification</div>

                      <div className="relative">
                        <i className="fa fa-bell font-size-18"></i>
                        {noOfUnseenNotifications > 0 &&
                          <div className="absolute top-0 left-50-pct transformY-50 fixed-width-20 fixed-height-20 d-flex align-items-center justify-content-center font-size-12 rounded-circle bg-red white">
                            {noOfUnseenNotifications}
                          </div>
                        }
                      </div>
                    </div>
                  </div> : null}

                {/* <div
                  className={`d-flex align-items-center padding-12 padding-left-24 padding-right-24 cursor-pointer hover-dodgerblue hover-bg-light-grey hover-dodgerblue
                    ${isServicesDropDownMobile ? "dodgerblue" : "logo-magenta"}`}
                  onClick={() => { setIsServicesDropDownMobile(!isServicesDropDownMobile) }}>
                  <p className="flex-grow-1">Service </p>
                  {isServicesDropDownMobile ?
                    <i className="fa fa-angle-up"></i> : <i className="fa fa-angle-right"></i>}
                </div> */}
                {isServicesDropDownMobile ?
                  <div className="d-flex flex-column bg-lighter-grey border-radius-0 margin-left-2 margin-right-2">
                    {/* <NavLink to={isAuthenticated ? "/dashboard/money-pool" : "/money-pool"}
                      onClick={() => {
                        setIsMobileMenu(false);
                        setIsServicesDropDownMobile(false);
                      }}
                      className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey">
                      Money Pool
                    </NavLink>

                    <NavLink to={isAuthenticated ? "/dashboard/upkeep" : "/upkeep"}
                      onClick={() => {
                        setIsMobileMenu(false);
                        setIsServicesDropDownMobile(false);
                      }}
                      className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey">
                      Upkeep Money
                    </NavLink> */}

                    {/* <NavLink to={isAuthenticated ? "/dashboard/fundraiser" : "/fundraiser"}
                      onClick={() => {
                        setIsMobileMenu(false);
                        setIsServicesDropDownMobile(false);
                      }}
                      className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey">
                      Fundraiser
                    </NavLink> */}
                    {/* 
                    <NavLink to={isAuthenticated ? "/dashboard/pay-bills" : "/pay-bills"}
                      onClick={() => {
                        setIsMobileMenu(false);
                        setIsServicesDropDownMobile(false);
                      }}
                      className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey">
                      Airtime & Bills
                    </NavLink> */}
                  </div> : null}
                <NavLink to="/browse-fundraisers"
                  onClick={() => setIsMobileMenu(false)}
                  className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey">
                  Search Fundraisers
                </NavLink>

                <NavLink to="/about-us"
                  onClick={() => setIsMobileMenu(false)}
                  className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey">
                  About Us
                </NavLink>

                <NavLink to="/faq"
                  onClick={() => setIsMobileMenu(false)}
                  className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey">
                  FAQ
                </NavLink>

                <NavLink to="/blog"
                  onClick={() => setIsMobileMenu(false)}
                  className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey">
                  Blog
                </NavLink>



                {!isAuthenticated ? <NavLink to="/login">
                  <div className="padding-12 padding-left-24 padding-right-24">
                    <button
                      onClick={() => setIsMobileMenu(false)}
                      className="btn stretch-full bg-transparent logo-magenta border-color-logo-magenta border-radius-0 padding-8 padding-left-24 padding-right-24">
                      Login
                    </button>
                  </div>
                </NavLink> : null}

                {!isAuthenticated ? <NavLink to="/register">
                  <div className="padding-12 padding-left-24 padding-right-24">
                    <button
                      onClick={() => setIsMobileMenu(false)}
                      className="btn stretch-full bg-logo-magenta border-radius-0 white padding-8 padding-left-24 padding-right-24">
                      Create an account
                    </button>
                  </div>
                </NavLink> : null}

                {isAuthenticated ?
                  <div className="padding-12 padding-left-24 padding-right-24">
                    <button
                      onClick={() => {
                        signOutUser();
                        setIsMobileMenu(false);
                      }}
                      className="btn stretch-full bg-logo-magenta border-radius-0 white padding-8 padding-left-24 padding-right-24"
                    >
                      Sign Out
                    </button>
                  </div> : null}
              </animated.div>
            </div> : null}

          <div className="d-flex d-flex-hidden-lg align-items-center flex-grow-1">

            {/* <div className="cursor-pointer margin-left-80 margin-right-40"
              onMouseEnter={() => setIsServicesDropDownDesktop(true)}
              onMouseLeave={() => setIsServicesDropDownDesktop(false)}
            >
              <div className={`d-flex align-items-center hover-dodgerblue  ${isServicesDropDownDesktop ? "dodgerblue" : "logo-magenta"}`}
                onClick={() => setIsServicesDropDownDesktop(true)}>Services&nbsp;
                {isServicesDropDownDesktop ? <i className="fa fa-angle-up"></i> : <i className="fa fa-angle-down"></i>}
              </div>

              {isServicesDropDownDesktop ?
                <div ref={servicesClickRef} className="absolute max-width-160">
                  <div className="relative margin-top-10">
                    <div className="absolute top-0 left-34-pct transformY-50">
                      <div className="d-inline-table rotate-right-45">
                        <div className="border-6 border-left-color-white border-top-color-white border-right-color-transparent border-bottom-color-transparent"></div>
                      </div>
                    </div>

                    <div className="d-flex flex-column bg-white box-shadow border-radius-6">
                      <NavLink className="text-decoration-none padding-12 padding-left-16 padding-right-16 logo-magenta hover-dodgerblue hover-bg-light-grey"
                        to="/money-pool"
                        onClick={() => setIsServicesDropDownDesktop(false)}>
                        Money Pool
                      </NavLink>

                      <NavLink className="text-decoration-none padding-12 padding-left-16 padding-right-16 logo-magenta hover-dodgerblue hover-bg-light-grey"
                        to="/upkeep"
                        onClick={() => setIsServicesDropDownDesktop(false)}>
                        Upkeep Money
                      </NavLink>

                      <NavLink className="text-decoration-none padding-12 padding-left-16 padding-right-16 logo-magenta hover-dodgerblue hover-bg-light-grey"
                        to="/fundraiser"
                        onClick={() => setIsServicesDropDownDesktop(false)}>
                        Fundraiser
                      </NavLink>

                      <NavLink className="text-decoration-none padding-12 padding-left-16 padding-right-16 logo-magenta hover-dodgerblue hover-bg-light-grey"
                        to="/pay-bills"
                        onClick={() => setIsServicesDropDownDesktop(false)}>
                        Airtime & Bills
                      </NavLink>
                    </div>
                  </div>
                </div> : null}
            </div> */}

            <NavLink className="header__text logo-magenta hover-dodgerblue" to="/browse-fundraisers">Search Fundraisers</NavLink>
            <NavLink className="header__text logo-magenta hover-dodgerblue" to="/about-us">About Us</NavLink>
            <NavLink className="header__text logo-magenta hover-dodgerblue" to="/faq">FAQ</NavLink>
            <NavLink className="header__text logo-magenta hover-dodgerblue" to="/blog">Blog</NavLink>
          </div>

          <div className="d-flex d-flex-hidden-lg align-items-center flex-end">
            {!isAuthenticated ? <NavLink to="/login"><button className="btn bg-dark-white logo-magenta border-radius-0 margin-right-20 padding-10 border-color-logo-magenta">Login</button></NavLink> : null}

            {!isAuthenticated ? <NavLink to="/register"><button className="btn bg-logo-magenta max-width-140 white border-radius-0 padding-10 padding-left-14 padding-right-14 border-color-logo-magenta">Create an account</button></NavLink> : null}

            {isAuthenticated ?
              <div ref={notificationDesktopClickRef} >
                <div ref={getBellIconPositionRef}
                  className={`relative header__text cursor-pointer hover-dodgerblue ${isNotificationDesktop ? "dodgerblue" : "logo-magenta"}`}
                  onClick={() => {
                    setIsNotificationsVisible(true)
                    handleUpdateNotificationLastSeen();
                    setIsNotificationDesktop(!isNotificationDesktop);
                  }}>
                  <i className="fa fa-bell font-size-22"> </i>
                  {noOfUnseenNotifications > 0 &&
                    <div className="absolute top-0 left-50-pct transformY-50 fixed-width-20 fixed-height-20 d-flex align-items-center justify-content-center font-size-12 rounded-circle bg-red white">
                      {noOfUnseenNotifications}
                    </div>
                  }
                </div>

                {isNotificationDesktop && isNotificationsVisible ?
                  <div className="absolute margin-top-20 right-1-pct max-width-360 bg-white border-radius-0 box-shadow">

                    <div className="fixed top-69 transformY-50" style={{ left: (notificationDropDnPosition) + 'px' }}>
                      <div className="d-inline-table rotate-right-45">
                        <div className="border-8 border-left-color-white border-top-color-white border-right-color-transparent border-bottom-color-transparent"></div>
                      </div>
                    </div>

                    <div className="logo-magenta font-size-20 padding-10 margin-bottom-6 border-bottom-1 border-color-grey">
                      <b >Notifications</b>
                    </div>

                    <Notifications />

                  </div> : null}
              </div> : null}

            {isAuthenticated ?
              <div className="cursor-pointer"
                onClick={() => {
                  setIsNotificationDesktop(false);
                  setIsAccountDropDown(!isAccountDropDown);
                }}>
                <div ref={getAccountDropDnPositionRef}>
                  <img
                    width="55px"
                    height="55px"
                    className="rounded-circle border-color-logo-magenta border-1" 
                    src={`${user.profile_picture ? user.profile_picture : defaultAvatar
                      }?${Date.now()}`}
                  />
                </div>

                {isAccountDropDown ?
                  <div ref={accountDropDownRef} className="absolute max-width-200 right-16 margin-top-14">
                    <div className="fixed top-81 transformY-50" style={{ left: (accountDropDnPosition) + 'px' }}>
                      <div className="d-inline-table rotate-right-45">
                        <div className="border-8 border-left-color-white border-top-color-white border-right-color-transparent border-bottom-color-transparent"></div>
                      </div>
                    </div>

                    <div className="d-flex flex-column bg-white box-shadow border-radius-0 padding-top-12 padding-bottom-12">
                      <NavLink className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey"
                        to="/dashboard/home"
                      >
                        Dashboard Home
                      </NavLink>

                      {/* <NavLink className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey"
                        to="/dashboard/money-pool"
                      >
                        Cyclic Money Pool
                      </NavLink>

                      <NavLink className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey"
                        to="/dashboard/upkeep"
                      >
                        Upkeep Stipend
                      </NavLink> */}

                      <NavLink className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey"
                        to="/dashboard/fundraiser"
                      >
                        Fundraiser
                      </NavLink>

                      {/* 
                      <NavLink className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey"
                        to="/dashboard/pay-bills"
                      >
                        Airtime & Bills
                      </NavLink>
                      
                      <NavLink className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey"
                        to="/dashboard/pouch-wallet"
                      >
                        Pouch Wallet
                      </NavLink> */}

                      <NavLink className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey"
                        to="/dashboard/account"
                      >
                        Account Settings
                      </NavLink>

                      <div className="text-decoration-none padding-12 padding-left-24 padding-right-24 logo-magenta hover-dodgerblue hover-bg-light-grey"
                        onClick={() => {
                          getConfirmAlert({
                            title: "Confirm!",
                            message: "Are you sure you want to logout?",
                            onClickYes: () => {
                              signOutUser()
                            },
                            onClickNo: () => null
                          })
                        }}
                      >
                        Log Out
                      </div>
                    </div>
                  </div> : null}
              </div>
              : null}
          </div>

          <Modal
            ariaHideApp={false}
            isOpen={isNotificationMobile && isNotificationsVisible}
            onRequestClose={() => setIsNotificationMobile(false)}
            className="modal padding-0 border-radius-0"
            overlayClassName="modal-backdrop padding-0 align-items-start"
          >

            <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
              <h1 className="font-size-20 font-size-md-22 logo-magenta">
                Notifications
              </h1>
              <i
                onClick={() => setIsNotificationMobile(false)}
                className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
              ></i>
            </div>
            <div className="horizontal-line margin-0" />
            <div className="padding-8">
              <div
                style={{ minHeight: "1000vh" }}
                className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
              >
                <Notifications />
              </div>
            </div>
          </Modal>

        </div>
      </div>
    </header>
  )
}

const mapDispatchToProps = (dispatch) => ({
  signOutUser: () => dispatch(signOut())
});

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: !!state.auth.userToken,
  socketUrl: state.auth.socketUrl
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);
// export default Header