import React, { useState, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";

const MerchandiseView = ({
  item,
  currency
}) => {
  return (
    <div className="d-flex align-items-center">
      <img
        className="d-block border-radius-6 min-width-40 max-width-40"
        height="40"
        width="40"
        onClick={() => {
          // uploadPictureRef.current.click()
        }}
        src={`${item.merchandise_picture_file_url
          ? item.merchandise_picture_file_url + "?" + Date.now()
          : "data:image/png;base64," + item.base64_file
          }`}
      />

      <div className="row align-items-center padding-left-10 padding-left-sm-20 stretch-full">
        <p className="col-sm-8 font-size-13 font-size-sm-13 padding-top-2 padding-bottom-2 logo-magenta text-break">
          <b>{item.description}</b>
        </p>

        <div className="d-flex col-sm-4 font-size-13">
          <span className="flex-wrap  normal-green font-weight-bold padding-top-2 padding-bottom-2">
            <FormattedMoney value={item.unit_price} currency={currency} />
          </span>
          <b className="padding-top-2 padding-bottom-2">&nbsp;/unit</b>
        </div>
      </div>
    </div>
  );
};

export { MerchandiseView as default };
