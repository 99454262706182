import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { NotificationManager } from 'react-notifications';
import countryList from "react-select-country-list";
import Select from "react-select";
import { becomeAnAgent } from "../../../../store/actions/agent";
import PhoneInputField from "../../../utilityComponents/PhoneInputField";

const BecomeOurAgent = ({ user, dispatch, handleClose }) => {

  const [countryCode, setCountryCode] = useState("NG");
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    // Get default locale
    const locale = localStorage.getItem("locale");
    if (locale && locale != "undefined") {
      const parsedLocale = JSON.parse(locale);
      if (parsedLocale.country_code) {
        setCountryCode(parsedLocale.country_code);
      }
    }

    const options = countryList().getData();
    setCountries(options);
  }, [])

  return (
    <div>
      <Formik
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          phone_number: user.phone_number,
          email: user.email,
          state: user.state,
          city: user.city,
          country: user.country,
          street_address: user.street_address,

        }}
        validationSchema={Yup.object({
          first_name: Yup.string().required("Frst name is required"),
          last_name: Yup.string().required("last name is required"),
          phone_number: Yup.string().required("Phone number is required"),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          dispatch(becomeAnAgent(values))
            .then((res) => {
              setSubmitting(false);
              if (res.error) {
                NotificationManager.error(res.error.message, "Error!")
              } else {
                if (res.success == true) {
                  localStorage.setItem("showWantToBeAgent", false)
                  NotificationManager.success("Your request has been submitted. We will get in touch with you soon.", 'Success!')
                  handleClose()
                }
              }
            });
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="row">
              <div className="col-sm-6 padding-top-10 padding-bottom-10 padding-right-0 padding-right-sm-10">
                <label className="form-label">First Name</label>
                <Field
                  className="form-control padding-10"
                  placeholder="Enter first name"
                  name="first_name"
                  onBlur={() => {
                    // Just to ensure the initial country is
                    // entered to Formik
                    setFieldValue("country", countryCode);
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  name="first_name"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-top-10 padding-bottom-10 padding-left-0 padding-left-sm-10">
                <div>
                  <label className="form-label">Last Name</label>
                  <Field
                    className="form-control padding-10"
                    placeholder="Enter last name"
                    name="last_name"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="last_name"
                    component="div"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 padding-top-10 padding-bottom-10 padding-right-0 padding-right-sm-10">
                <label className="form-label">Email</label>
                <Field
                  className="form-control padding-10"
                  placeholder="Enter email"
                  name="email"
                  disabled={true}
                />
                <ErrorMessage
                  className="error-message"
                  name="email"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-top-10 padding-left-sm-10 padding-bottom-10 padding-right-0 padding-right-sm-10">
                <label className="form-label">Phone Number</label>
                <div
                  className="form-control padding-4 padding-left-10"
                >
                  <PhoneInputField
                    name="phone_number"
                    value={values.phone_number}
                    onChange={setFieldValue}
                    placeholderText="Enter phone number"
                    type="number"
                  />
                </div>
                <ErrorMessage
                  className="error-message"
                  name="phone_number"
                  component="div"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">Address</label>
                <Field
                  className="form-control"
                  placeholder="Enter Street Address"
                  name="street_address"
                />
                <ErrorMessage
                  className="error-message"
                  name="street_address"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-top-10 padding-bottom-10 padding-left-0 padding-left-sm-10">
                <label className="form-label">City</label>
                <Field
                  className="form-control"
                  placeholder="Enter city"
                  name="city"
                />
                <ErrorMessage
                  className="error-message"
                  name="city"
                  component="div"
                />
              </div>
            </div>
            
            <div className="row">
              <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">State or Province</label>
                <Field
                  className="form-control"
                  placeholder="Enter state"
                  name="state"
                />
                <ErrorMessage
                  className="error-message"
                  name="state"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-top-10 padding-bottom-10 padding-left-0 padding-left-sm-10">
                <label className="form-label">Country</label>
                <Field
                  component={() => (
                    <Select
                      className="form-control padding-0 padding-left-0"
                      classNamePrefix="react-select"
                      placeholder="Select country"
                      options={countries}
                      value={
                        countries
                          ? countries.find(
                            (option) => option.value === countryCode
                          )
                          : ""
                      }
                      onChange={(option) => {
                        // alert(option.value)
                        setCountryCode(option.value)
                        // setLocaleCountry(option.value);
                        setFieldValue("country", option.value);
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  className="error-message"
                  name="country"
                  component="div"
                />
              </div>
            </div>
            

            {/* <p>Send us a request to become our sales agent and we will contact you with more details.</p> */}

            <button
              className="btn bg-logo-magenta white font-size-16 margin-top-12 margin-top-sm-20 margin-bottom-12 padding-10 max-width-180"
              onClick={handleSubmit}
            >
              Send
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(BecomeOurAgent);
