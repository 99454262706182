import React, { useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { NotificationManager } from 'react-notifications';
import { updateUser, getUser, signOut } from "../../store/actions/auth"

const BVNVerification = ({ dispatch, user, handleClose }) => {

  return (
    <div>
      <Formik
        initialValues={{
          bvn: "",
        }}
        validationSchema={Yup.object({
          bvn: Yup.string().required(
            "BVN is required"
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          // setSubmitting(false);
          // // requestPasswordResetEmail(values);
          // dispatch(updateUser({
          //   ...user,
          //   phone_number: values.phoneNumber
          // }))
          //   .then((res) => {
          //     dispatch(getUser())
          //     handleClose()

          //     if (res.error) {
          //       NotificationManager.error(res.error.message, 'Error!')
          //     } else {
          //       NotificationManager.success("Your profile has been updated with your phone number", 'Success!')
          //     }
          //   })
        }}
      >
        {({
          setFieldValue,
          isSubmitting,
          values,
        }) => (
          <Form>

            <div className="margin-bottom-8">
              <div>
                <Field
                  className="input padding-top-14 padding-top-md-16 padding-bottom-14 padding-bottom-md-16 padding-left-0 padding-right-10 font-size-15 font-size-lg-17 border-color-logo-magenta border-0 border-bottom-2 border-radius-0 focus-none bg-transparent"
                  placeholder="Enter your BVN"
                  name="bvn"
                />
                <ErrorMessage
                  className="red font-size-12"
                  name="bvn"
                  component="div"
                />
              </div>
            </div>
            <button
              className="btn max-width-150 font-size-15 font-size-md-17 margin-top-10 margin-top-md-20 margin-bottom-10 margin-bottom-md-20 padding-16 border-radius-14 bg-logo-magenta white"
              type="submit"
              disabled={isSubmitting}
            >
              Verify
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(BVNVerification);
