import React, { useState, useEffect } from "react";

function useOnClickOutside(ref, handler) {
  const [isMouseUp, setIsMouseUp] = useState(false);

  useEffect(() => {
    const onClick = () => {
      setIsMouseUp(true);
    };

    const listener = (event) => {
      // Do nothing if clicking ref's element or descendant elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      if (isMouseUp) {
        handler(event);
        setIsMouseUp(false);
      }
    };

    document.addEventListener("click", onClick);
    document.addEventListener("mouseup", listener);
    // document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("click", onClick);
      document.removeEventListener("mouseup", listener);
      // document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
