import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'

export const HijackRoute = ({
  match,
  isAuthenticated,
  isHijacking,
  isSuperUser,
  isStaff,
  component: Component,
  ...rest
}) => {

  return (
    <Route {...rest} component={(props) => {
      return isAuthenticated && isStaff && isSuperUser ? (
        <div>
          <Component {...props} />
        </div>
      ) : (
        <Redirect to={`/login`} />
      )
    }} />
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.userToken,
  isSuperUser: !!state.auth.user.is_superuser,
  isStaff: !!state.auth.user.is_staff,
});

export default connect(mapStateToProps)(HijackRoute);
