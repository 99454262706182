import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { getPaymentHistory } from "../../../../store/actions/transaction"
import FormattedMoney from "../../../utilityComponents/FormattedMoney"
import FilterSorter from "../../../utilityComponents/FilterSorter";
import { convertSnakeToStandardCasing } from "../../../../utilities";
import NewPagination from "../../../utilityComponents/NewPagination";

const MyPaymentHistory = ({
  user,
  dispatch,
  setIsPageLoading,
}) => {
  const [initialParams, setInitialParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  });
  const [paymentHistoryObj, setPaymentHistoryObj] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [initialTotalCount, setInitialTotalCount] = useState(0);

  let currencies = user.supported_currencies.map(item => {
    return {
      label: item,
      value: item
    }
  })

  currencies = [{ label: "All", value: "" }, ...currencies]

  const handleGetPaymentHistory = async (params) => {
    const res = await dispatch(getPaymentHistory(params))
    if (res.error) {
      NotificationManager(res.error.message, "Error!")
    }
    else {
      if (res.success == true) {
        setPaymentHistoryObj(res.data)
        setInitialParams(res.data.original_query_params)
        return res.data
      }
    }
  }

  useEffect(() => {
    handleGetPaymentHistory(initialParams)
      .then(data => {
        if (data) {
          setInitialTotalCount(data.total_count)
        }
      })

  }, [])

  return (
    <div>
      {initialTotalCount > 0 &&
        <div>
          <FilterSorter
            sorterOptions={[
              { label: 'Created Date', value: 'created_at' },
              { label: 'Amount', value: 'amount' }
            ]}
            initialSortBy={initialParams.sort_by}
            queryOrder={initialParams.query_order}
            filterOptions={[
              {
                status: [
                  { label: "All", value: '' },
                  { label: 'Pending', value: 'PENDING' },
                  { label: 'Cleared', value: 'CLEARED' }
                ]
              },
              {
                service_type: [
                  { label: "All", value: '' },
                  { label: 'Upkeep', value: 'upkeep' },
                  { label: 'Fundraiser', value: 'fundraiser' },
                  { label: 'Pool', value: 'pool' },
                  { label: 'Wallet', value: 'wallet' },
                  { label: 'Authorization charge', value: 'auth_charge' },
                ]
              },
              {
                currency: currencies
              }
            ]}
            onSelectQuery={(values) => {
              setIsSearching(true)
              const newQueryObj = {
                ...initialParams,
                ...values
              }
              handleGetPaymentHistory(newQueryObj)
            }}
          />
        </div>}

      {paymentHistoryObj &&
        paymentHistoryObj.payments.length > 0 ?
        <div>

          <div className="row d-flex-hidden-sm align-items-center font-size-11 font-size-sm-13 font-size-md-15 padding-4 padding-md-16 padding-left-md-36 padding-right-md-36">
            <div className="col-sm-6 row align-items-center">
              <div className="col-3 padding-4">ID</div>
              <div className="col-4 padding-4">Service Type</div>
              <div className="col-5 padding-4"> Date</div>
            </div>
            <div className="col-sm-6 row align-items-center">
              <div className="col-3 padding-4"></div>
              <div className="col-4 padding-4">Amount</div>
              <div className="col-5 padding-4">Status</div>
            </div>
          </div>
          {
            paymentHistoryObj.payments.map((payment, index) => {
              return (
                <div
                  key={index}
                  className="row margin-bottom-14 margin-bottom-sm-18 align-items-center bg-lighter-grey font-size-11 font-size-sm-13 font-size-md-15 padding-4 padding-md-16 padding-left-md-36 padding-right-md-36 border-radius-6">
                  <div className="col-sm-6 row align-items-center padding-bottom-8">
                    <div className="col-3 padding-2">
                      <div className="d-block-display-sm">
                        ID
                      </div>
                      <div>
                        <b>#{payment.id}</b>
                      </div>
                    </div>
                    <div className="col-4 padding-2">
                      <div className="d-block-display-sm">
                        Type
                      </div>
                      <div>
                        <b>{convertSnakeToStandardCasing(payment.service_type)}</b>
                      </div>
                    </div>
                    <div className="col-5 padding-2">
                      <div className="d-block-display-sm">
                        Date
                      </div>
                      <div>
                        <b>{moment(String(payment.created_at)).format("DD MMM 'YY, HH:MM a")}</b>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 row align-items-center padding-bottom-8">
                    <div className="col-3 padding-2"></div>
                    <div className="col-4 padding-2">
                      <div className="d-block-display-sm">
                        Amount
                      </div>
                      <div>
                        <b><FormattedMoney value={payment.amount} currency={payment.amount_currency} /></b>
                      </div>
                    </div>
                    <div className="col-5 padding-2">
                      <div className="d-block-display-sm">
                        Status
                      </div>
                      <div>
                        <span className="font-size-11 font-size-sm-13 font-size-md-15 normal-green">
                          <b>{convertSnakeToStandardCasing(payment.status)}</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }

          {
            (paymentHistoryObj && (paymentHistoryObj.total_count > initialParams.no_of_items_per_page)) ?
              <NewPagination
                {...initialParams}
                handleGetQuery={handleGetPaymentHistory}
              /> :
              null
          }
        </div> :
        <div className="grey padding-top-10 padding-bottom-20 padding-bottom-sm-10 text-center">
          You do not have any payment {isSearching && "that matches this query"}
        </div>}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};


export default connect(mapStateToProps)(MyPaymentHistory);
