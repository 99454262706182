import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { NotificationManager } from 'react-notifications';
import Select from "react-select";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-number-input";
import { getCurrencyList, register, socialMediaSignIn } from "../../../../store/actions/auth";
import { referAFriend } from "../../../../store/actions/referral";
import PhoneInputField from "../../../utilityComponents/PhoneInputField";

const RegisterACustomer = ({ dispatch }) => {
  const [error, setError] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("NG");
  const [localeCurrency, setLocaleCurrency] = useState("NGN");

  useEffect(() => {
    const options = countryList().getData();
    setCountries(options);

    dispatch(getCurrencyList()).then((res) => {
      if (res.error) {
        return;
      } else {
        if (res.success == true) {
          const currencies = res.data.map((cur) => {
            return {
              label: cur,
              value: cur,
            };
          });
          setCurrencies(currencies);
        }
      }
    });

    // Get default locale
    const locale = localStorage.getItem("locale");
    if (locale && locale != "undefined") {
      const parsedLocale = JSON.parse(locale);
      if (parsedLocale.country_code) {
        setCountryCode(parsedLocale.country_code);
      }
      if (parsedLocale.currency) {
        const localeCurrency = parsedLocale.currency;
        if (currencies.includes(localeCurrency)) {
          setLocaleCurrency(localeCurrency);
        }
      }
    }
  }, [currencies.length])

  return (
    <div>
      <Formik
        initialValues={{
          referred_first_name: "",
          referred_last_name: "",
          referred_email: "",
        }}
        validationSchema={Yup.object({
          referred_first_name: Yup.string().required("Frst name is required"),
          referred_last_name: Yup.string().required("last name is required"),
          referred_email: Yup.string().required("Email is required"),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setError("");

          dispatch(referAFriend(values)).then((res) => {
            setSubmitting(false);
            if (res.error) {
              setError(res.error.message);
            } else {
              if (res.success == true) {
                NotificationManager.success("Referral email has been sent.", 'Success!')
                resetForm();
              }
            }
          });
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <Form>
            {error ? <p className="padding-bottom-10 red">{error}</p> : null}
            <div className="row">
              <div className="col-sm-6 padding-top-10 padding-bottom-10 padding-right-0 padding-right-sm-10">
                <label className="form-label">First Name</label>
                <Field
                  className="form-control padding-10"
                  placeholder="Enter first name"
                  name="firstName"
                  onBlur={() => {
                    // Just to ensure the initial country and preferredCurrency values are
                    // entered to Formik
                    setFieldValue("country", countryCode);
                    setFieldValue("preferredCurrency", localeCurrency);
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  name="firstName"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-top-10 padding-bottom-10 padding-left-0 padding-left-sm-10">
                <div>
                  <label className="form-label">Last Name</label>
                  <Field
                    className="form-control padding-10"
                    placeholder="Enter last name"
                    name="lastName"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="lastName"
                    component="div"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 padding-top-10 padding-bottom-10 padding-right-0 padding-right-sm-10">
                <label className="form-label">Email</label>
                <Field
                  className="form-control padding-10"
                  placeholder="Enter email"
                  name="email"
                />
                <ErrorMessage
                  className="error-message"
                  name="email"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-top-10 padding-left-sm-10 padding-bottom-10 padding-right-0 padding-right-sm-10">
                <label className="form-label">Phone Number</label>
                <div
                  className="form-control padding-4 padding-left-10"
                >
                  <PhoneInputField
                    name="phoneNumber"
                    // value={value.phone_number}
                    onChange={setFieldValue}
                    placeholderText="Enter phone number"
                    type="number"
                  />
                </div>

                {/* <PhoneInput
                  className="d-flex form-control"  
                  placeholder="Enter phone number"
                  // value={values.phoneNumber}
                  name="phoneNumber"
                  onChange={(e) => {
                    setFieldValue("phoneNumber", e);
                  }}
                  defaultCountry={countryCode}
                /> */}
                <ErrorMessage
                  className="error-message"
                  name="phoneNumber"
                  component="div"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 padding-top-10 padding-bottom-10 padding-right-0 padding-right-sm-10">
                <label className="form-label">Country</label>
                <Field
                  component={() => (
                    <Select
                      className="form-control padding-0 padding-left-0"
                      classNamePrefix="react-select"
                      placeholder="Select country"
                      options={countries}
                      value={
                        countries
                          ? countries.find(
                            (option) => option.value === countryCode
                          )
                          : ""
                      }
                      onChange={(option) => {
                        // alert(option.value)
                        setCountryCode(option.value)
                        // setLocaleCountry(option.value);
                        setFieldValue("country", option.value);
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  className="error-message"
                  name="country"
                  component="div"
                />
              </div>
            </div>

            <button
              className="btn bg-logo-magenta white font-size-16 margin-top-12 margin-top-sm-20 margin-bottom-12 padding-10 max-width-180"
              onClick={handleSubmit}
            >
              Create Customer
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    transaction_ref: state.transaction.transaction_ref,
  };
};

export default connect(mapStateToProps)(RegisterACustomer);
