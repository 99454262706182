import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import LoadingPage from "../utilityComponents/LoadingPage";
import DashboardHome from "./DashboardHome";
import Fundraiser from "./Fundraiser";
import PouchWallet from "./PouchWallet";
import Account from "./account/Account";
import AddPhoneNumber from "../authForms/AddPhoneNumber";
import { signOut } from "../../store/actions/auth";
import CurrentWindowWidth from "../CurrentWindowWidth";
import UseOnClickOutside from "../UseOnClickOutside";
import homeIcon from "../images/home_icon.svg"
import fundraiserIcon from "../images/fundraiser_icon.svg"
import dashboardIcon from "../images/dashboard-fill_icon.svg"
import { convertSnakeToStandardCasing, getConfirmAlert } from "../../utilities";

const DashBoardPage = ({ history, match, signOut, user }) => {
  const displayedComponent = match.params.displayedComponent;
  const action = match.params.action;

  let windowWidth = CurrentWindowWidth();

  // const mobileBreakpoint = 576;
  // const tabletBreakpoint = 768;
  // const desktopBreakpoint = 992;

  // const mobileBreakpoint = 540;
  // const tabletBreakpoint = 720;
  // const desktopBreakpoint = 960;

  const mobileBreakpoint = 576;
  const tabletBreakpoint = 768;
  const desktopBreakpoint = 1024;

  // const [leftContainerDimensions, setLeftContainerDimensions] = useState({
  //   width: 0,
  //   height: 0,
  // }); 

  const [isReload, setIsReload] = useState(false);
  const [visibleForm, setVisibleForm] = useState(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState([false]);
  const [isLeftContainerHidden, setIsLeftContainerHidden] = useState(true);
  const [isLeftContainerFixed, setLeftContainerFixed] = useState(true);
  const [isAddPhoneNumberVisible, setIsAddPhoneNumberVisible] = useState(false);
  const [showWantToBeAgent, setShowWantToBeAgent] = useState(true);

  // Create a ref that we add to the element for which we want to detect outside clicks
  const clickRef = useRef();

  UseOnClickOutside(clickRef, () => {
    if (windowWidth <= desktopBreakpoint) {
      setIsLeftContainerHidden(true);
    }
    else {
      setIsLeftContainerHidden(false);
    }
  });

  const getBottomPosition = useRef();

  const handleBottomPosition = () => {
    if (clickRef.current && clickRef.current.getBoundingClientRect().top < 0) {
      setIsReload(true);
    }

    if (getBottomPosition.current) {
      // console.log("clickRef.current");
      // console.log(clickRef.current.offsetTop); 

      // console.log(window.innerHeight)
      // console.log(windowWidth)
      // console.log(desktopBreakpoint)

      if (windowWidth > desktopBreakpoint) {
        if (getBottomPosition.current.getBoundingClientRect().bottom < window.innerHeight) {
          setLeftContainerFixed(false);
        } else {
          setIsReload(false);
          setLeftContainerFixed(true);
        }
      }
    }
  };

  const onClickContinue = (toWhere) => {
    history.push(`/dashboard/${toWhere}`);
  };

  useEffect(() => {
    // Check if the user has a phone number registered
    if (!user.phone_number) {
      setIsAddPhoneNumberVisible(true);
    }
  }, [user]);

  useEffect(() => {
    if (history.location.search.split("?")[1] == "get_started") {
      setIsCreatingNew(true);

      if (displayedComponent == "fundraiser") {
        setVisibleForm("createFundraiserDetails");
      }
    }

    if (windowWidth <= desktopBreakpoint) {
      if (clickRef.current && clickRef.current.getBoundingClientRect().top != 0) {
        setIsLeftContainerHidden(true);
      }
    } else {
      setIsLeftContainerHidden(false);
    }

    handleBottomPosition();

    window.addEventListener("scroll", handleBottomPosition);

    return () => {
      window.removeEventListener("scroll", handleBottomPosition);
    };
  }, [windowWidth, isReload,
    clickRef.current && clickRef.current.getBoundingClientRect().top
  ]);

  useEffect(() => {
    document.title = "Dashboard " + displayedComponent
    let showWantToBeAgent = localStorage.getItem("showWantToBeAgent")

    if (showWantToBeAgent) {
      setShowWantToBeAgent(JSON.parse(showWantToBeAgent))
    }
  }, []);

  useEffect(() => {
    document.title = "Dashboard " + convertSnakeToStandardCasing(displayedComponent)
  }, [displayedComponent]);

  return (
    <div className="content-container" ref={getBottomPosition}>
      <LoadingPage isPageLoading={isPageLoading} />
      <div>
        <div
          className="fixed d-block-display-lg top-12 left-6 left-md-16"
          style={{ zIndex: 5000 }}
        >
          {isLeftContainerHidden ?
            <img src={dashboardIcon} width={24} className="cursor-pointer"
              onClick={() => {
                setIsLeftContainerHidden(false)
              }}
            />
            :
            <i className="fa fa-close font-size-24 red cursor-pointer"
              onClick={() => {
                setIsLeftContainerHidden(true)
              }}></i>
          }
        </div>

        <div className="d-flex">
          {!isLeftContainerHidden ?
            <div style={{ minHeight: window.innerHeight + "px" }}>

              <div className={`d-flex align-items-end flex-1 
                ${windowWidth < desktopBreakpoint ? "fixed top-0 bottom-0 left-0 right-0 bg-light-back-drop-color" : "padding-top-120 static fixed-width-240"}`}
              // style={{ paddingTop: windowWidth < desktopBreakpoint ? "0px" : window.innerHeight + "px" }}
              // style={{ paddingTop: windowWidth < desktopBreakpoint ? "0px" : topPosition + "px" }}
              >
                <div
                  ref={clickRef}
                  className={`fixed-width-240 bg-logo-purple white font-size-16 padding-top-100 
                    ${isLeftContainerFixed ? "fixed top-0 bottom-0 left-0 right-0" : "fixed top-0 bottom-0 left-0 right-0"}`}
                >
                  <div
                    className={`d-flex align-items-center margin-top-12 margin-bottom-12 margin-right-10 padding-top-12 padding-bottom-12 ${displayedComponent == "home" ? "dashboard-active-btn" : null}`}
                    onClick={() => onClickContinue("home")}
                  >
                    <img src={homeIcon} width={28} className="margin-left-20 margin-right-20" />
                    <label>Home</label>
                  </div>

                  <div
                    className={`d-flex align-items-center margin-top-12 margin-bottom-12 margin-right-10 padding-top-12 padding-bottom-12 ${displayedComponent == "fundraiser" ? "dashboard-active-btn" : null}`}
                    onClick={() => onClickContinue("fundraiser")}
                  >
                    <img src={fundraiserIcon} width={28} className="margin-left-20 margin-right-20" />
                    <label>Fundraiser</label>
                  </div>
                  
                  <div
                    className={`d-flex align-items-center margin-top-12 margin-bottom-12 margin-right-10 padding-top-12 padding-bottom-12 ${displayedComponent == "account" ? "dashboard-active-btn" : null}`}
                    onClick={() => onClickContinue("account")}
                  >
                    <i className="far fa-user font-size-28 margin-left-20 margin-right-20"></i>
                    <label>Account</label>
                  </div>

                  <div className="d-flex align-items-center margin-top-74 margin-bottom-74 margin-right-10 padding-top-12 padding-bottom-12"
                    onClick={() => {
                      getConfirmAlert({
                        title: "Confirm!",
                        message: "Are you sure you want to logout?",
                        onClickYes: () => {
                          signOut()
                        },
                        onClickNo: () => null
                      })
                    }}>
                    <i className="fas fa-power-off font-size-28 margin-left-20 margin-right-20"></i>
                    <label>Logout</label>
                  </div>
                </div>
              </div>
            </div>
            : null}

          <div
            style={{ minHeight: "800px" }}
            className=" stretch-full"
          >
            <div>
              {displayedComponent == "home" ? (
                <DashboardHome setIsPageLoading={setIsPageLoading} />
              ) : null}

              {displayedComponent == "fundraiser" ? (
                <Fundraiser
                  history={history}
                  match={match}
                  action={action}
                  user={user}
                  isCreatingNew={isCreatingNew}
                  setIsCreatingNew={setIsCreatingNew}
                  visibleForm={visibleForm}
                  setVisibleForm={setVisibleForm}
                  setIsPageLoading={setIsPageLoading}
                  windowWidth={windowWidth}
                  mobileBreakpoint={mobileBreakpoint}
                  tabletBreakpoint={tabletBreakpoint}
                  desktopBreakpoint={desktopBreakpoint}
                />
              ) : null}

              {displayedComponent == "pouch-wallet" ? (
                <PouchWallet
                  setIsPageLoading={setIsPageLoading}
                  history={history} />
              ) : null}

              {displayedComponent == "account" ? (
                <Account
                  history={history}
                  visibleForm={visibleForm}
                  setVisibleForm={setVisibleForm}
                  setIsPageLoading={setIsPageLoading}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={isAddPhoneNumberVisible}
        // onRequestClose={() => setIsAddPhoneNumberVisible(false)}
        className="modal max-width-560 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Update Your Information
          </h1>
          {/* <i
            onClick={() => setIsAddPhoneNumberVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i> */}
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-10 padding-left-md-30 padding-right-md-30"
          >
            <AddPhoneNumber handleClose={() => setIsAddPhoneNumberVisible(false)} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
});

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: !!state.auth.userToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardPage);
