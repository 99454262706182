import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CreateCharityNgoDetails from "./CharityNgoDetails";
import EditCharityNgoDetails from "./CharityNgoDetails";
import SetupFormHeader from "../../../utilityComponents/SetupFormHeader";
import CharityNgoDocuments from "./CharityNgoDocuments";
import CharityNGOBankAccounts from "./CharityNGOBankAccounts";
import CharityNGOPreview from "./CharityNGOPreview";
import CharityNgoDirectors from "./CharityNgoDirectors";

const CharityNGOSetupSlides = ({
  history,
  setIsPageLoading,
  isCreatingNew = true,
  setIsCreatingNew,
  setCharityNgo,
  charityNgo,
  visibleCharityNgoSetupSlides,
  handleSubmitCharityNgo,
  handleUploadNgoDocument,
  handleDeleteDocument,
  setVisibleCharityNgoSetupSlides,

}) => {
  const [initialCharityNgoDetails] = useState({
    organization_name: "",
    registration_number: "",
    country: "",
    city: "",
    state: "",
    street_address: "",
    phone_number: "",
    certificate_file: ""
  })
  const slides = [isCreatingNew ? 'DETAILS' : 'EDIT_DETAILS', 'DIRECTORS', 'DOCUMENTS', 'BANK_ACCOUNTS', 'FINISH']

  useEffect(() => {
    if (isCreatingNew) {
      setVisibleCharityNgoSetupSlides("DETAILS");
    } else {
      setVisibleCharityNgoSetupSlides("EDIT_DETAILS");
    }
  }, []);

  return (
    <div>
      <SetupFormHeader slides={slides} activeSlide={visibleCharityNgoSetupSlides} />

      <div className="padding-top-10">

        {visibleCharityNgoSetupSlides == "DETAILS" && isCreatingNew && (
          <CreateCharityNgoDetails
            history={history}
            initialCharityNgoDetails={initialCharityNgoDetails}
            setCharityNgo={setCharityNgo}
            isCreatingNew={isCreatingNew}
            setIsCreatingNew={setIsCreatingNew}
            setIsPageLoading={setIsPageLoading}
            setVisibleCharityNgoSetupSlides={setVisibleCharityNgoSetupSlides}
            handleSubmitCharityNgo={handleSubmitCharityNgo}
          />
        )}

        {visibleCharityNgoSetupSlides == "DIRECTORS" &&
          <CharityNgoDirectors
            charityNgo={charityNgo}
            setCharityNgo={setCharityNgo}
            setIsCreatingNew={setIsCreatingNew}
            setVisibleCharityNgoSetupSlides={setVisibleCharityNgoSetupSlides}
            setIsPageLoading={setIsPageLoading}
          />}

        {visibleCharityNgoSetupSlides == "EDIT_DETAILS" && !isCreatingNew && charityNgo && (
          <EditCharityNgoDetails
            history={history}
            setCharityNgo={setCharityNgo}
            isCreatingNew={isCreatingNew}
            charityNgo={charityNgo}
            setIsPageLoading={setIsPageLoading}
            setVisibleCharityNgoSetupSlides={setVisibleCharityNgoSetupSlides}
            handleSubmitCharityNgo={handleSubmitCharityNgo}
            handleDeleteDocument={handleDeleteDocument}
            handleUploadNgoDocument={handleUploadNgoDocument}
          />
        )}

        {visibleCharityNgoSetupSlides == "DOCUMENTS" && charityNgo &&
          <CharityNgoDocuments
            charityNgo={charityNgo}
            setCharityNgo={setCharityNgo}
            setIsPageLoading={setIsPageLoading}
            setIsCreatingNew={setIsCreatingNew}
            handleUploadNgoDocument={handleUploadNgoDocument}
            handleDeleteDocument={handleDeleteDocument}
            setVisibleCharityNgoSetupSlides={setVisibleCharityNgoSetupSlides}
          />
        }

        {visibleCharityNgoSetupSlides == "BANK_ACCOUNTS" &&
          <CharityNGOBankAccounts
            charityNgo={charityNgo}
            setCharityNgo={setCharityNgo}
            setIsPageLoading={setIsPageLoading}
            setVisibleCharityNgoSetupSlides={setVisibleCharityNgoSetupSlides}
          />
        }

        {visibleCharityNgoSetupSlides == "FINISH" &&
          <CharityNGOPreview
            history={history}
            charityNgo={charityNgo}
            setCharityNgo={setCharityNgo}
            setIsPageLoading={setIsPageLoading}
            setVisibleCharityNgoSetupSlides={setVisibleCharityNgoSetupSlides}
            handleUploadNgoDocument={handleUploadNgoDocument}
          />
        }

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CharityNGOSetupSlides);
