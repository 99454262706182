import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney"
import { getCurrencySymbol } from "../../../../../utilities";
import { sendTransactionOtp } from "../../../../../store/actions/transaction";
import { NotificationManager } from "react-notifications";

const ConfirmRaisedFundWithdrawal = ({
  dispatch,
  fundraiserId,
  amountToWithdraw,
  balance,
  currency,
  setAmountToWithdraw,
  handleGetWithdrawalDetails,
  setIsPageLoading
}) => {
  const [otp, setOtp] = useState(null)

  useEffect(() => {
  }, [])


  return (
    <div>
      <div className="d-flex align-items-center padding-top-12 padding-bottom-12">
        <label className="fixed-width-120">Cleared Balance</label>
        <b className="logo-magenta">
          <FormattedMoney
            value={balance}
            currency={currency}
          />
        </b>
      </div>

      <div className="d-flex align-items-center padding-top-12 padding-bottom-12">
        <label className="fixed-width-120">Amount</label>
        <div className="d-flex align-items-center">
          <b>{getCurrencySymbol(currency)}</b>&nbsp;&nbsp;
          <input
            className="form-control logo-magenta"
            type="number"
            onChange={(e) => setAmountToWithdraw(e.target.value)}
          />
        </div>
      </div>

      <button
        className="btn bg-logo-magenta padding-10 max-width-140 font-size-15 margin-top-26 margin-bottom-8"
        type="button"
        onClick={() => {
          handleGetWithdrawalDetails({
            fundraiser_id: fundraiserId,
            is_withdrawing_all: false,
            amount: amountToWithdraw,
          })
        }}
        disabled={!amountToWithdraw}
      >
        Submit
      </button>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ConfirmRaisedFundWithdrawal);
