import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { NotificationManager } from "react-notifications";
import { getConfirmAlert } from "../../../utilities";
import {
  deleteBankAccountDetails,
  saveBankAccountDetails,
} from "../../../store/actions/transaction";
import { getUser } from "../../../store/actions/auth";
import VerifyBankAccount from "../../payments/VerifyBankAccount";
import PreviewVerifiedAccount from "../../payments/PreviewVerifiedAccount";

const BankTransferRecipients = ({ user, setIsPageLoading, dispatch }) => {
  const [
    isVerifyAccountModalVisible,
    setIsVerifyAccountModalVisible,
  ] = useState(false);
  const [
    isPreviewVerifiedAccountVisible,
    setIsPreviewVerifiedAccountVisible,
  ] = useState(false);
  const [verifiedAccountDetails, setVerifiedAccountDetails] = useState(null);

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <div>
      <div>
        {user.saved_bank_accounts.length > 0 ? (
          <div>
            {user.saved_bank_accounts.map((bank, index) => {
              return (
                <div key={index}>
                  <div className="row font-size-15 font-size-sm-17 padding-top-12 padding-bottom-12">
                    <div className="d-flex align-items padding-top-12 padding-bottom-12">
                      <b className="min-width-170">Account Name</b>
                      <span>{bank.bank_account_name}</span>
                    </div>

                    <div className="d-flex align-items padding-top-12 padding-bottom-12">
                      <b className="min-width-170">Account Number</b>
                      <span>{bank.bank_account_number}</span>
                    </div>

                    <div className="d-flex align-items padding-top-12 padding-bottom-12">
                      <b className="min-width-170">Bank</b>
                      <span>{bank.bank_name}</span>
                    </div>

                    <div className="padding-top-4 padding-bottom-4 d-flex fjustify-content-between align-items-center">
                      <i
                        className="far fa-trash-alt col-2 stretch-full-sm red font-size-20 cursor-pointer"
                        type="button"
                        onClick={() => {
                          getConfirmAlert({
                            title: "Confirm delete!",
                            message:
                              "Are you sure you want to delete this transfer recipient?",
                            onClickYes: () => {
                              dispatch(deleteBankAccountDetails(bank)).then(
                                (res) => {
                                  if (res.error) {
                                    NotificationManager.error(res.error.message, "Error!")
                                  } else {
                                    if (res.success == true) {
                                      NotificationManager.success(res.message, "Success!")
                                    }
                                  }
                                }
                              );
                            },
                            onClickNo: () => null,
                          });
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="horizontal-line margin-0" />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="margin-top-40">
            <p>You do not have any saved bank transfer recipient.</p>
          </div>
        )}

        <div
          className="d-flex align-items-center logo-magenta margin-top-30 margin-bottom-30 cursor-pointer"
          onClick={() => setIsVerifyAccountModalVisible(true)}
        >
          <div className="d-flex align-items-center justify-content-center padding-10 padding-sm-18 fixed-width-30 fixed-height-30 rounded-circle font-size-24 font-size-sm-32 bg-logo-magenta white">
            +
          </div>
          <div className="margin-left-12 font-size-18 font-size-sm-20">
            Add New Recipient
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isVerifyAccountModalVisible}
        onRequestClose={() => setIsVerifyAccountModalVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Enter Bank Account Details
          </h1>
          <i
            onClick={() => setIsVerifyAccountModalVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-10 padding-top-0 padding-left-md-40 padding-right-md-40">
          <VerifyBankAccount
            setIsPageLoading={setIsPageLoading}
            handleOnAccountVerification={(accountDetails) => {
              setVerifiedAccountDetails(accountDetails);
              setIsVerifyAccountModalVisible(false);
              setIsPreviewVerifiedAccountVisible(true);
            }}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isPreviewVerifiedAccountVisible}
        onRequestClose={() => setIsPreviewVerifiedAccountVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 logo-magenta">
            Verified Account Details
          </h1>
          <i
            onClick={() => setIsPreviewVerifiedAccountVisible(false)}
            className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-10 padding-top-0 padding-left-md-40 padding-right-md-40">
          <PreviewVerifiedAccount
            setIsPageLoading={setIsPageLoading}
            verifiedAccountDetails={verifiedAccountDetails}
            handleSaveAccount={(accountDetails) => {
              dispatch(saveBankAccountDetails(accountDetails)).then((res) => {
                if (res.error) {
                  NotificationManager.error(res.error.message, "Error!")
                } else {
                  NotificationManager.success(res.message, "Success!")
                  setIsPreviewVerifiedAccountVisible(false);
                }
              });
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(BankTransferRecipients);
