import React from "react";
import { connect } from "react-redux";
import Truncate from "react-truncate";
import moment from "moment";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";
import { convertSnakeToStandardCasing } from "../../../../../utilities";

const FundraiserWithdrawalDetails = ({
  details
}) => {

  return (
    <div className="logo-magenta margin-10">
      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <span className="min-width-110">Status</span>
        <b>{convertSnakeToStandardCasing(details.status)}</b>
      </div>
      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <span className="min-width-110">ID</span>  <b>#{details.id}</b>
      </div>
      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <span className="min-width-110">Reference</span>  <b>#{details.reference}</b>
      </div>
      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <span className="min-width-110">Date</span>  <b>{moment(details.created_at).format("DD MMM YYYY, HH:mm a")}</b>
      </div>
      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <span className="min-width-110">Amount</span> <b><FormattedMoney value={details.amount} currency={details.amount_currency} /></b>
      </div>
      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <span className="min-width-110">Platform Fee</span> <b><FormattedMoney value={details.transfer && details.transfer.platform_fee} currency={details.amount_currency} /></b>
      </div>
      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <span className="min-width-110">Transfer Fee</span> <b><FormattedMoney value={details.transfer && details.transfer.transfer_fee} currency={details.amount_currency} /></b>
      </div>
      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <span className="min-width-110">Account Name</span> <b>{details.transfer && details.transfer.beneficiary_name}</b>
      </div>
      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <span className="min-width-110"> Account No.</span> <b>{details.transfer && details.transfer.beneficiary_account_number}</b>
      </div>
      <div className="d-flex align-items padding-top-12 padding-bottom-12">
        <span className="min-width-110"> Bank Name</span> <b>{details.transfer && details.transfer.beneficiary_bank_name}</b>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(FundraiserWithdrawalDetails);
