import React, { useState, useEffect } from "react";
import Select from "react-select";

const SelectNGOBankAccount = ({
    handleSelectNGOBank,
    handleClose,
    ngoBankAccounts = []
}) => {
    const [bankAccounts, setBankAccounts] = useState([])
    const [selectedBank, setSelectedBank] = useState(null)

    useEffect(() => {
        const options = ngoBankAccounts.map(bank => {
            return {
                label: `${bank.bank_name} ${bank.bank_account_number} ${bank.bank_account_name}`,
                value: bank
            }
        })
        setBankAccounts(options)

    }, [])

    return (
        <div>
            <Select
                className="form-control padding-0 margin-top-4 margin-bottom-10"
                classNamePrefix="react-select"
                placeholder="Select a Bank Account"
                options={bankAccounts}
                onChange={(option) => {
                    setSelectedBank(option.value)
                }}
            />
            <div>
                <div>
                    <button
                        className="btn margin-top-6 margin-bottom-10 bg-logo-magenta stretch-full font-size-16 padding-12 border-radius-8"
                        type="button"
                        onClick={() => {
                            handleSelectNGOBank(selectedBank)
                            handleClose()
                        }}
                    ><b>Submit</b></button>
                </div>
            </div>
        </div>
    )
}


export default SelectNGOBankAccount