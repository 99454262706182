import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Select from "react-select";
import countryList from "react-select-country-list";
import PhoneInputField from "../../../utilityComponents/PhoneInputField";
import { createOrUpdateNGODirector } from "../../../../store/actions/charity_ngo";
import { NotificationManager } from "react-notifications";

const NgoDirectorDetails = ({
  dispatch,
  user,
  charityNgo,
  setCharityNgo,
  handleClose,
  director
}) => {

  const [countries, setCountries] = useState([]);

  const uploadRef = useRef(null)

  useEffect(() => {
    // Get country list
    const options = countryList().getData();
    setCountries(options);

  }, [])

  return (
    <div>
      <Formik
        initialValues={{
          ngo_director_id: director.id,
          first_name: director.first_name,
          last_name: director.last_name,
          email: director.email,
          role: director.role,
          country: director.country,
          city: director.city,
          state: director.state,
          street_address: director.street_address,
          phone_number: director.phone_number,
          type_of_identity: director.type_of_identity,
          identity_number: director.identity_number,
          identity_file: "",
          identity_file_url: director.identity_file_url
        }}

        validationSchema={Yup.object({
          first_name: Yup.string().required("First name required"),
          last_name: Yup.string().required("Last name is required"),
          email: Yup.string().email("Must be an email").required("Email is required").nullable(),
          role: Yup.string().required("Roleis required"),
          country: Yup.string().required("Select a country"),
          city: Yup.string().required("City is required"),
          state: Yup.string().required("State is required"),
          street_address: Yup.string().required("Street address is required"),
          phone_number: Yup.string().required("Phone number is required"),
          type_of_identity: Yup.string().required("Identity type is required"),
          identity_file: Yup.string()
            .when("ngo_director_id", {
              is: (directorId) => {
                if (directorId) {
                  return false
                } else {
                  return true
                }
              },
              then: Yup.string().required("Please upload an ID"),
              otherwise: Yup.string(),
            }),
          identity_number: Yup.string().required("Identity number is required"),
        })}

        onSubmit={(values, { setSubmitting }) => {
          values = {
            charity_ngo_id: charityNgo.id,
            ...values
          }

          const formData = new FormData()

          for (var key in values) {
            if (values[key]) {
              formData.append(key, values[key]);
            }
          }

          dispatch(createOrUpdateNGODirector(formData))
            .then(res => {
              if (res.error) {
                NotificationManager.error(res.error.message, "Error!")
              } else {
                if (res.success) {
                  setCharityNgo(res.data)
                  NotificationManager.success(res.message, "Success!")
                  handleClose()
                }
              }
            })
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          isSubmitting,
          values,
          errors
        }) => (
          <Form>
            <div className="margin-bottom-30">
              <div className="row">
                <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">First Name</label>
                  <Field
                    className="form-control"
                    placeholder="Enter first name"
                    name="first_name"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="first_name"
                    component="div"
                  />
                </div>

                <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Last Name</label>
                  <Field
                    className="form-control"
                    placeholder="Enter last name"
                    name="last_name"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="last_name"
                    component="div"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Email</label>
                  <Field
                    className="form-control"
                    placeholder="Enter email"
                    name="email"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="email"
                    component="div"
                  />
                </div>
                <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Role</label>
                  <Field
                    component={() => (
                      <Select
                        className="form-control padding-0"
                        classNamePrefix="react-select"
                        placeholder="Select role"
                        options={user.ngo_director_positions}
                        value={
                          user.ngo_director_positions
                            ? user.ngo_director_positions.find(
                              (option) => option.value === values.role
                            )
                            : ""
                        }
                        onChange={(option) => {
                          setFieldValue("role", option.value);
                        }}
                      />
                    )}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="role"
                    component="div"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Country</label>
                  <Field
                    component={() => (
                      <Select
                        className="form-control padding-0"
                        classNamePrefix="react-select"
                        placeholder="Select country"
                        options={countries}
                        value={
                          countries
                            ? countries.find(
                              (option) => option.value === values.country
                            )
                            : ""
                        }
                        onChange={(option) => {
                          setFieldValue("country", option.value);
                        }}
                      />
                    )}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="country"
                    component="div"
                  />
                </div>

                <div className="d-flex col-sm-6 padding-right-0 padding-right-sm-20 padding-top-0 padding-bottom-10">
                  <div className="col-sm-6 padding-right-0 padding-right-sm-10 padding-top-10 padding-bottom-0">
                    <label className="form-label">City</label>
                    <Field
                      className="form-control"
                      placeholder="Enter City"
                      name="city"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="city"
                      component="div"
                    />
                  </div>

                  <div className="col-sm-6 padding-right-0 padding-right-sm-10 padding-top-10 padding-bottom-0">
                    <label className="form-label">State</label>
                    <Field
                      className="form-control"
                      placeholder="Enter State"
                      name="state"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="state"
                      component="div"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Street Address</label>
                  <Field
                    className="form-control"
                    placeholder="Enter Street Address"
                    name="street_address"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="street_address"
                    component="div"
                  />
                </div>
                <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Phone Number</label>
                  <div
                    className="form-control padding-top-4 padding-bottom-4">
                    <PhoneInputField
                      placeholderText="Enter Phone Number"
                      value={values.phone_number}
                      onChange={setFieldValue}
                      defaultCountry={values.country ? values.country : user.country}
                      name="phone_number"
                    />
                  </div>
                  <ErrorMessage
                    className="error-message"
                    name="phone_number"
                    component="div"
                  />
                </div>

              </div>

              <div className="row">
                <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Type of ID</label>
                  <Select
                    className="form-control padding-0 margin-top-10 margin-top-sm-0"
                    classNamePrefix="react-select"
                    placeholder="Select type of ID"
                    options={user.id_proof_options}
                    value={
                      user.id_proof_options
                        ? user.id_proof_options.find(
                          (option) => option.value === values.type_of_identity
                        )
                        : ""
                    }
                    onChange={(option) => {
                      console.log(option.value)
                      setFieldValue("type_of_identity", option.value);
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="type_of_identity"
                    component="div"
                  />
                </div>
                <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Identity Number</label>
                  <Field
                    className="form-control"
                    placeholder="Enter identity number"
                    name="identity_number"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="identity_number"
                    component="div"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-20">
                  <button
                    type="button"
                    className="btn white bg-blue "
                    onClick={() => {
                      uploadRef.current.click();
                    }}
                  >Upload ID</button>
                  <ErrorMessage
                    className="error-message"
                    name="identity_file"
                    component="div"
                  />
                  <input
                    type="file"
                    hidden
                    ref={uploadRef}
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      if (file) {
                        if (file.size > 1000000) {
                          //Maximum of 10B
                          alert("File too large. Max. 1MB");
                        } else {
                          setFieldValue("identity_file", file)
                        }
                      }
                    }} />
                </div>
                <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                  {(values.identity_file || values.identity_file_url) &&
                    <span className="text-decoration-underline"
                      onClick={() => {
                        if (values.identity_file) {
                          let link = document.getElementById("download")
                          // handleOpenFileInBrowser(values.identity_file)
                          link.href = URL.createObjectURL(values.identity_file);
                          window.open(link.href)
                        }

                        if (values.identity_file_url) {
                          window.open(values.identity_file_url)
                        }
                      }}
                      id="download" >
                      {values.identity_file && values.identity_file.name}
                    </span>}
                </div>
              </div>
              <button
                type="button"
                className="btn border-radius-4 white bg-logo-magenta fixed-width-150 fixed-height-50 font-size-16"
                onClick={handleSubmit}
              >Save Director</button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(NgoDirectorDetails);
