import React, { useState } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import 'react-markdown-editor-lite/lib/index.css';
import { getConfirmAlert, getDocumentFileformatFromUrl, getFileNameFromFileUrl } from "../../../../utilities";
import Dropzone from "react-dropzone";
import PdfThumbnail from "../../../utilityComponents/PdfThumbnail";
import Truncate from "react-truncate";

const CharityNgoDocuments = ({
  charityNgo,
  setCharityNgo,
  setIsCreatingNew,
  setIsPageLoading,
  handleUploadNgoDocument,
  handleDeleteDocument,
  setVisibleCharityNgoSetupSlides
}) => {
  const [regCertError, setRegCertError] = useState("")
  const [iTError, setITError] = useState("")
  const [nAError, setNAError] = useState("")

  const handleSetError = () => {
    let isError

    if (charityNgo.certificate_file_url &&
      charityNgo.incorporated_trustees.length > 0 &&
      charityNgo.notice_of_address.length > 0
    ) {
      isError = false
      return isError
    } else {
      if (!charityNgo.certificate_file_url) {
        setRegCertError("Registration certificate must be uploaded")
      }
      if (charityNgo.incorporated_trustees.length == 0) {
        setITError("Incorporated trustees docs must be uploaded")
      }

      if (charityNgo.notice_of_address.length == 0) {
        setNAError("Notices of address docs must be uploaded")
      }
      
      isError = true
      return isError
    }
  }

  return (
    <div>
      <Formik
        initialValues={setCharityNgo}
      >
        {({

        }) => (
          <Form>
            <div className="padding-left-10 padding-right-10 border-radius-0">
              <div className="padding-bottom-8">
                <h2 className="logo-magenta font-size-20 font-size-sm-21">DOCUMENTS</h2>
              </div>
              <div className="horizontal-line " />

              <div>
                <div className="padding-left-0  padding-top-10 padding-bottom-10">
                  <label className="form-label">Registration Certificate</label>
                  {charityNgo.certificate_file_url ?
                    <div>
                      <div className="relative max-width-100 ">
                        <div
                          className="fixed-height-100 min-width-100 max-width-100 margin-left-0 margin-right-8 margin-top-4 margin-bottom-4 margin-left-sm-0
                          // margin-right-sm-10 margin-top-sm-10 margin-bottom-sm-10 margin-sm-right-10"
                        >
                          {getDocumentFileformatFromUrl(charityNgo.certificate_file_url) == "pdf" ? (
                            <PdfThumbnail height={90}
                              document={{
                                ...charityNgo,
                                file_url: charityNgo.certificate_file_url
                              }} />
                          ) : (
                            <img
                              width="100%"
                              height="100%"
                              className="d-block stretch-full border-top-radius-0"
                              src={`${charityNgo.certificate_file_url}?${charityNgo.certificate_file_url}`}
                            />
                          )}
                          <b> <Truncate lines={1}>
                            {getFileNameFromFileUrl(charityNgo.certificate_file_url)}
                          </Truncate></b>
                        </div>

                        <i
                          className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                          onClick={() => {
                            getConfirmAlert({
                              title: "Confirm delete!",
                              message: "Are you sure you want to delete this file?",
                              onClickYes: () => {
                                // setFieldValue("certificate_file", "")
                                handleDeleteDocument({
                                  charity_ngo_id: charityNgo.id,
                                  document_type: "REGISTRATION_CERTIFICATE",
                                  file_url: charityNgo.certificate_file_url
                                })
                              },
                              onClickNo: () => null,
                            });
                          }}
                        ></i>
                      </div>
                      <div
                        className="font-size-12 padding-4 padding-left-8 padding-right-8 text-truncate border-bottom-radius-0"
                      >
                      </div>
                    </div>
                    :
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        setRegCertError("")
                        handleUploadNgoDocument(charityNgo.id, acceptedFiles[0], "REGISTRATION_CERTIFICATE")
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="d-flex align-content-left justify-content-left">
                          <div
                            className="d-inline-block cursor-pointer"
                            style={{ outline: "none" }}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} accept="image/*,.pdf" />
                            <p className="d-inline-block font-size-13 font-size-sm-14 line-height-16 line-height-sm-22 padding-6 padding-left-16 padding-right-16 white border-radius-0 bg-charcoal opacity-75">
                              Drag 'n' drop or click to select file.
                            </p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  }
                  <span className="error-message">
                    {regCertError && regCertError}
                  </span>
                </div>

                <div className="horizontal-line " />

                <div className="padding-left-0  padding-top-10 padding-bottom-10">
                  <label className="form-label">Incorporated Trustees or Guarantors</label>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      setITError("")
                      handleUploadNgoDocument(charityNgo.id, acceptedFiles[0], "INCORPORATED_TRUSTEES")
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="d-flex align-content-left justify-content-left">
                        <div
                          className="d-inline-block cursor-pointer"
                          style={{ outline: "none" }}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} accept="image/*,.pdf" />
                          <p className="d-inline-block font-size-13 font-size-sm-14 line-height-16 line-height-sm-22 padding-6 padding-left-16 padding-right-16 white border-radius-0 bg-charcoal opacity-75">
                            Drag 'n' drop or click to select file.
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="d-flex overflow-hidden overflow-scroll-x">
                    {
                      charityNgo.incorporated_trustees.length > 0 &&
                      charityNgo.incorporated_trustees.map((fileUrl, i) => {
                        return (
                          <div
                            key={i}
                            className="relative min-width-120 max-width-120 margin-left-0 margin-right-8 margin-top-4 margin-bottom-4 margin-left-sm-0
                              margin-right-sm-10 margin-top-sm-10 margin-bottom-sm-10 margin-sm-right-10">
                            <div
                              className="fixed-height-100"
                            >
                              {getDocumentFileformatFromUrl(fileUrl) == "pdf" ? (
                                <PdfThumbnail height={90}
                                  document={{
                                    ...charityNgo,
                                    file_url: fileUrl
                                  }} />
                              ) : (
                                <img
                                  width="100%"
                                  height="100%"
                                  className="d-block stretch-full border-top-radius-0"
                                  src={`${fileUrl}?${fileUrl}`}
                                />
                              )}
                            </div>

                            <b> <Truncate lines={1}>
                              {getFileNameFromFileUrl(fileUrl)}
                            </Truncate></b>

                            <i
                              className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                              onClick={() => {
                                getConfirmAlert({
                                  title: "Confirm delete!",
                                  message: "Are you sure you want to delete this file?",
                                  onClickYes: () => {
                                    handleDeleteDocument({
                                      charity_ngo_id: charityNgo.id,
                                      document_type: "INCORPORATED_TRUSTEES",
                                      file_url: fileUrl
                                    })
                                  },
                                  onClickNo: () => null,
                                });
                              }}
                            ></i>
                          </div>
                        )
                      })
                    }
                  </div>

                  <span className="error-message">
                    {iTError && iTError}
                  </span>
                </div>

                <div className="horizontal-line " />

                <div className="padding-left-0  padding-top-10 padding-bottom-10">
                  <label className="form-label">Notice of Address</label>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      setNAError("")
                      handleUploadNgoDocument(charityNgo.id, acceptedFiles[0], "NOTICE_OF_ADDRESS")
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="d-flex align-content-left justify-content-left">
                        <div
                          className="d-inline-block cursor-pointer"
                          style={{ outline: "none" }}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} accept="image/*,.pdf" />
                          <p className="d-inline-block font-size-13 font-size-sm-14 line-height-16 line-height-sm-22 padding-6 padding-left-16 padding-right-16 white border-radius-0 bg-charcoal opacity-75">
                            Drag 'n' drop or click to select file.
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>

                  <div className="d-flex overflow-hidden overflow-scroll-x">
                    {
                      charityNgo.notice_of_address.length > 0 &&
                      charityNgo.notice_of_address.map((fileUrl, i) => {
                        return (
                          <div
                            key={i}
                            className="relative min-width-120 max-width-120 margin-left-0 margin-right-8 margin-top-4 margin-bottom-4 margin-left-sm-0
                       margin-right-sm-10 margin-top-sm-10 margin-bottom-sm-10 margin-sm-right-10">
                            <div
                            >
                              {getDocumentFileformatFromUrl(fileUrl) == "pdf" ? (
                                <PdfThumbnail height={90}
                                  document={{
                                    ...charityNgo,
                                    file_url: fileUrl
                                  }} />
                              ) : (
                                <img
                                  width="100%"
                                  height="100%"
                                  className="d-block stretch-full border-top-radius-0"
                                  src={`${fileUrl}?${fileUrl}`}
                                />
                              )}
                            </div>
                            <b> <Truncate lines={1}>
                              {getFileNameFromFileUrl(fileUrl)}
                            </Truncate></b>

                            <i
                              className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                              onClick={() => {
                                getConfirmAlert({
                                  title: "Confirm delete!",
                                  message: "Are you sure you want to delete this file?",
                                  onClickYes: () => {
                                    handleDeleteDocument({
                                      charity_ngo_id: charityNgo.id,
                                      document_type: "NOTICE_OF_ADDRESS",
                                      file_url: fileUrl
                                    })
                                  },
                                  onClickNo: () => null,
                                });
                              }}
                            ></i>
                          </div>
                        )
                      })
                    }
                  </div>

                  <span className="error-message">
                    {nAError && nAError}
                  </span>
                </div>
              </div>

              <div className="horizontal-line margin-top-20 margin-bottom-30" />

              <div className="d-flex justify-content-end margin-bottom-30">
                <button
                  className="btn font-size-15 font-size-md-18 margin-right-30 margin-right-sm-50 max-width-160 padding-10 padding-md-16 border-color-logo-magenta logo-magenta border-radius-12"
                  onClick={() => {
                    setIsCreatingNew(false);
                    setVisibleCharityNgoSetupSlides("DIRECTORS")
                  }}
                >
                  <b>Previous</b>
                </button>
                <button
                  className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-logo-magenta white border-radius-12"
                  type="submit"
                  onClick={() => {
                    const isError = handleSetError()
                    if(!isError){
                      setVisibleCharityNgoSetupSlides("BANK_ACCOUNTS")
                    }
                  }}
                >
                  <b>Next</b>
                </button>
              </div>

            </div>

          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CharityNgoDocuments);
