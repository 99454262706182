import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney"
import { sendTransactionOtp } from "../../../../../store/actions/transaction";
import { convertSnakeToStandardCasing, getCurrencySymbol } from "../../../../../utilities";

const ConfirmFundWithdrawal = ({
  dispatch,
  fundraiserId,
  summaryOfWithdrawal,
  handleMakeWithdrawal,
  handleGetWithdrawalDetails,
  setIsPageLoading,
  setIsConfirmWithrawalVisible
}) => {
  const [otp, setOtp] = useState(null)
  const [isFirstTime, setIsFirstTime] = useState(true)

  useEffect(() => {
  }, [])

  return (
    <div>
      <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
        <h1 className="font-size-20 font-size-md-22 logo-magenta">
          Confirm withdrawal
        </h1>
        <i
          onClick={() => setIsConfirmWithrawalVisible(false)}
          className="fa fa-close font-size-22 logo-magenta cursor-pointer hover-red"
        ></i>
      </div>

      <div className="horizontal-line margin-0" />


      <div className="padding-8">
        <div style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
        >

          {summaryOfWithdrawal.is_withdrawing_all ?
            <div className="d-flex align-items-center padding-top-12 padding-bottom-12">
              <label className="fixed-width-180">Amount</label>
              <b className="logo-magenta">
                <FormattedMoney
                  value={summaryOfWithdrawal.total_amount}
                  currency={summaryOfWithdrawal.currency} />
              </b>
            </div>
            :
            <div className="d-flex align-items-center padding-top-12 padding-bottom-12">
              <label className="fixed-width-180">Amount</label>
              <div className="d-flex align-items-center">
                <span className="logo-magenta">
                  {getCurrencySymbol(summaryOfWithdrawal.currency)}&nbsp;&nbsp;
                </span>
                <input
                  className="form-control logo-magenta"
                  type="number"
                  placeholder="Enter Amount"
                  value={summaryOfWithdrawal.total_amount}
                  onBlur={(e) => {
                    handleGetWithdrawalDetails({
                      fundraiser_id: fundraiserId,
                      is_withdrawing_all: false,
                      amount: e.target.value
                    })
                  }}
                />
              </div>
            </div>}

          <div className="d-flex align-items-center padding-top-12 padding-bottom-12">
            <label className="fixed-width-180">Platform Commission ({summaryOfWithdrawal.commission_rate * 100}%) </label>
            <b className="logo-magenta">
              <FormattedMoney
                value={summaryOfWithdrawal.commission}
                currency={summaryOfWithdrawal.currency} />
            </b>
          </div>

          <div className="d-flex align-items-center padding-top-12 padding-bottom-12">
            <label className="fixed-width-180">Transfer Fee </label>
            <b className="logo-magenta">
              <FormattedMoney
                value={summaryOfWithdrawal.fee}
                currency={summaryOfWithdrawal.currency} />
            </b>
          </div>

          <div className="d-flex align-items-center padding-top-12 padding-bottom-12">
            <label className="fixed-width-180">Amount to Receive </label>
            <b className="logo-magenta">
              <FormattedMoney
                value={summaryOfWithdrawal.amount_to_receive}
                currency={summaryOfWithdrawal.currency} />
            </b>
          </div>

          <div className="d-flex align-items-center padding-top-12 padding-bottom-12">
            <label className="fixed-width-180">Beneficiary Name </label>
            <b className="logo-magenta">{convertSnakeToStandardCasing(summaryOfWithdrawal.beneficiary.account_name)}</b>
          </div>

          <div className="d-flex align-items-center padding-top-12 padding-bottom-12">
            <label className="fixed-width-180">Beneficiary Account No. </label>
            <b className="logo-magenta">{summaryOfWithdrawal.beneficiary.bank_account_number}</b>
          </div>

          <div className="d-flex align-items-center padding-top-12 padding-bottom-12">
            <label className="fixed-width-180">Beneficiary Bank Name </label>
            <b className="logo-magenta">{summaryOfWithdrawal.beneficiary.bank_name}</b>
          </div>

          <div className="d-flex align-items-center padding-top-12 padding-bottom-12">
            <label className="fixed-width-180">One Time Password</label>
            <div>
              <input
                className="form-control logo-magenta"
                placeholder="Enter OTP"
                type="text"
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value)
                }}
              />
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-end padding-top-30 padding-bottom-24">
            <button
              className="btn padding-10 bg-logo-magenta max-width-140 font-size-15 margin-right-14 margin-right-sm-30"
              type="button"
              onClick={() => {
                if (!otp) {
                  alert("OTP is required")
                } else {
                  handleMakeWithdrawal({
                    fundraiser_id: fundraiserId,
                    is_withdrawing_all: summaryOfWithdrawal.is_withdrawing_all,
                    amount: summaryOfWithdrawal.total_amount,
                    otp
                  })
                }
              }}>
              Confirm
            </button>

            <button
              className="btn padding-10 bg-orange max-width-140 font-size-15"
              type="button"
              onClick={() => {
                setIsFirstTime(false)
                setOtp("")
                setIsPageLoading([true, "Sending OTP..."])
                dispatch(sendTransactionOtp())
                  .then(res => {
                    setIsPageLoading([false]);
                    if (res.error) {
                      NotificationManager.error(res.error.message, 'Error!')
                    } else {
                      NotificationManager.success(res.message, 'Success!')
                    }
                  })
              }}
            >
              {isFirstTime ? "Generate" : "Resend"} OTP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ConfirmFundWithdrawal);
