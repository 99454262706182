import React, { useEffect } from 'react';
import { Router, Route, Switch, withRouter, useLocation } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { connect } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import CookieConsent from "react-cookie-consent";
// import { Offline } from "react-detect-offline";
import PrivateRoute from './PrivateRoute';
import AuthRoute from './AuthRoute';
import PublicRoute from './PublicRoute';
import AdminRoute from './AdminRoute';
import HijackRoute from './HijackRoute';
import Header from '../components/Header';
import Home from '../components/Home';
import Footer from '../components/Footer';
import VerificationEmailSent from '../components/statusPages/VerificationEmailSent';
import VerifedEmailRedirect from '../components/statusPages/VerifedEmailRedirect';
import PasswordResetEmailSent from '../components/statusPages/PasswordResetEmailSent';
import DashboardPage from '../components/dashboard/DashboardPage';
import FundraiserHome from '../components/services/fundraiser/setup/FundraiserHome';
import BrowseFundraisers from '../components/services/fundraiser/public/BrowseFundraisers';
import ViewFundraiser from '../components/services/fundraiser/public/ViewFundraiser';
import NotFoundPage from '../components/NotFoundPage';
import Login from '../components/authForms/Login';
import ForgotPassword from '../components/authForms/ForgotPassword';
import Register from '../components/authForms/Register';
import RequestConfirmationEmail from '../components/authForms/RequestConfirmationEmail';
import ConfirmPasswordReset from '../components/authForms/ConfirmPasswordReset';
import Playground from '../components/Playground';
import FaqHome from '../components/faq/FaqHome'
import AboutUs from '../components/AboutUs'
import ContactUs from '../components/ContactUs'
import BlogPosts from '../components/blog/BlogPosts'
import BlogArticle from '../components/blog/BlogArticle'
import TermsAndConditions from '../components/legal/TermsAndConditions'
import PrivacyPolicy from '../components/legal/PrivacyPolicy'
import LoadingPage from "../components/utilityComponents/LoadingPage"
import UnfollowFundraiserUpdates from '../components/services/fundraiser/public/UnfollowFundraiserUpdates';

import StartHijack from '../components/hijack/StartHijack'
import HijackedUserProfile from '../components/hijack/HijackedUserProfile'
import setupTawk from '../tawkTo';

export const history = createBrowserHistory();

function preventModalBackgroundScrolling() {
  document.body.style.overflow = 'hidden';
  return () => document.body.style.overflow = 'unset';
}
// preventModalBackgroundScrolling()

const _ScrollToTop = (props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

const AppRouter = ({
  dispatch,
  isPageLoading,
  isNabbing,
  isCookieConsentNeeded
}) => {
  useEffect(() => {
    if (process.env.NODE_ENV == "production") {
      setupTawk()
    }
  }, [])

  return (
    <Router history={history}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}>
        <ScrollToTop>
          <div>
            <LoadingPage isPageLoading={[isPageLoading]} />

            <div className="app-router__fixed-header">
              <Header />
            </div>

            {/* <div className="app-router__internet-warning text-center">
              <Offline>
                <div className="red padding-8">
                  You are currently without an internet connection!
                </div>
              </Offline>
            </div> */}

            <div className="app-router__fixed-header__page ">
              <div>
                <Switch>
                  <PublicRoute path="/" component={Home} exact={true} />
                  <PublicRoute path="/playground" component={Playground} />
                  <PublicRoute path="/fundraiser/:fundraiserSlug" component={ViewFundraiser} />
                  <PublicRoute path="/fundraiser" component={FundraiserHome} />
                  <PublicRoute path="/browse-fundraisers" component={BrowseFundraisers} />
                  <PublicRoute path="/unfollow-fundraiser-updates" component={UnfollowFundraiserUpdates} />
                  <PublicRoute path="/faq" component={FaqHome} />
                  <PublicRoute path="/about-us" component={AboutUs} />
                  <PublicRoute path="/contact-us" component={ContactUs} />
                  <PublicRoute path="/blog" component={BlogPosts} />
                  <PublicRoute path="/blog-post/:slug" component={BlogArticle} />
                  <PublicRoute path="/terms-and-conditions" component={TermsAndConditions} />
                  <PublicRoute path="/privacy-policy" component={PrivacyPolicy} />

                  <PrivateRoute path="/dashboard/:displayedComponent/:action/:id" component={DashboardPage} />
                  <PrivateRoute path="/dashboard/:displayedComponent/:action" component={DashboardPage} />
                  <PrivateRoute path="/dashboard/:displayedComponent" component={DashboardPage} />

                  <AdminRoute path="/admin/login" component={Login} />

                  <HijackRoute path="/hijack/start-hijack/:email" component={StartHijack} />
                  <HijackRoute path="/hijack/hijacked-user-profile" component={HijackedUserProfile} />

                  <AuthRoute path="/status/verification-email-sent" component={VerificationEmailSent} />
                  <AuthRoute path="/status/password-reset-email-sent" component={PasswordResetEmailSent} />
                  <AuthRoute path="/verify-email/:key" component={VerifedEmailRedirect} />
                  <AuthRoute path="/register" component={Register} />
                  <AuthRoute path="/login" component={Login} />
                  <AuthRoute path="/forgot-password" component={ForgotPassword} />
                  <AuthRoute path="/request-confirmation-email" component={RequestConfirmationEmail} />
                  <AuthRoute path="/auth/password-reset-confirm/:uid/:token" component={ConfirmPasswordReset} />

                  <Route component={NotFoundPage} />
                </Switch>
              </div>
              <div style={{ zIndex: 600 }} ><Footer /></div>
            </div>

            {
              isCookieConsentNeeded &&
              <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="cookieConsent"
                style={{ background: "#2B373B", zIndex: 10000 }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={90}
                // debug={process.env.NODE_ENV != "production"}
              >This website uses cookies to enhance the user experience.
              </CookieConsent>
            }

            <NotificationContainer />
          </div>
        </ScrollToTop>
      </GoogleOAuthProvider>
    </Router>
  )
}

const mapStateToProps = (state) => {
  return {
    isNabbing: state.auth.isNabbing,
    isPageLoading: state.auth.isPageLoading,
    isCookieConsentNeeded: state.auth.isCookieConsentNeeded,
  };
};

export default connect(mapStateToProps)(AppRouter)
